import React, { Component } from "react";
// import Moment from "react-moment";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { PaginatedList } from 'react-paginated-list'

// import GoogleMapReact from "google-map-react";
import controllable from "react-controllables";
// import { StickyContainer, Sticky } from "react-sticky";
import { Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import SearchForm from "../../../components/partials/dashboard/SearchFormStyle/search-form-style-1";
// import SkeletonLoading from "../../../components/partials/components/skeletonLoading/skeleton-style-01";
// import Loader from '../../../components/partials/components/loader'
// import PanneauxItem from "../../../components/partials/components/panneauxItem";

import linkData from "../../../restApiService/linkData";
import extClient from "../../../restApiService/extClient";
import extClientReservation from "../../../restApiService/extClientReservation";
import cartCheckout from "../../../restApiService/cartCheckout";
import cartCheckoutLine from "../../../restApiService/cartCheckoutLine";

import "../../../assets/css/searchresults.css";
// import ListItem from "rsuite/esm/List/ListItem";
controllable(["center", "zoom", "markers"]);

const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));

const SideDataList = React.lazy(() => import("./components/sideDataList"));
const Summary = React.lazy(() => import("./components/summary"));
// const MapViewer = React.lazy(() => import("./components/mapViewer"));

class user_reservations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservationData: [],
      clientList: [],
      villeData: [],
      villeListData: [],
      artereData: [],
      communeData: [],
      communeListData: [],
      formatData: [],
      formatListData: [],
      typeArtereListData: [],
      modeleListData: [],
      referenceListData: [],
      espaceDataIsLoading: true,
      espaceDataList: [],
      espaceDataListTmp: [],
      requestEspaceDataListTmp: [],
      espaceDataListForMap: [],
      searchData: [],
      filterboxIsOpen: false,
      searchFormboxIsOpen: false,
      searchType: "",
      title: "",
      afterLoop: false,
      showMap: false,
      handleLoading: false,
      valueFieldIsEmpty: true,
      MapLoading: false,
      oneViewSelected: false,
      oneViewSelectedId: 0,
      defaultlat: 0,
      defaultlng: 0,
      alreadySearch: false,
    };
  }

  OneViewSelected = (espaces) => {
    // this.setState({ MapLoading: true });
    // let espaceDataListTmp = [...this.state.espaceDataListTmp]

    this.setState({ espaceDataListForMap: espaces });

    setTimeout(() => {
      // let filteredList = this.state.espaceDataListForMap;
      this.setState({
        oneViewSelected: true,
        // espaceDataListForMap: filteredList,
        // defaultlat: Number(filteredList[0].panneaux.geolatitude),
        // defaultlng: Number(filteredList[0].panneaux.geolongitude),
        // showMap: true,
      });
    }, 400);
    // setTimeout(() => {
    //   this.setState({ MapLoading: false });
    // }, 600);
  };

  getRegiePendingReservation = (id) => {
    linkData
      .getLinkDataByRegieId(id)
      .then((rep) => {
        this.setState({
          reservationData: rep.data,
          espaceDataIsLoading: false,
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    localStorage.removeItem("checkout");
    this.getRegieClient();
    this.setState({ MapLoading: true });
    this.getRegiePendingReservation(currentUserInfo.regieId);
  }

  componentDidUpdate(prevProps) {}

  cartCheckoutFunction = () => {
    const { sousTotal } = this.state;
    if (currentUserInfo) {
      this.setState({ handleLoading: true });
      localStorage.removeItem("reservedItem");

      if (this.state.option == 2) {
        this.setRegieClient();
      }

      let Total_cart = {
        nom_campagne: this.state.new_client_item.campagne,
        userId: currentUserInfo?.id,
        sousTotal: sousTotal,
        tva: 0,
        tsp: 0,
        odp: 0,
        tm: 0,
        totalTtc: 0,
        date_reservation:
          moment(parseInt(localStorage.start)).format("DD/MM/YYYY") +
          " - " +
          moment(parseInt(localStorage.end)).format("DD/MM/YYYY"),
        prix_extra: 0,
        transport: 0,
        espace_reserve: this.emailItems,
        etat: 0,
      };

      cartCheckout
        .setReservation(Total_cart)
        .then((req) => {
          let client_reservation = {
            extClientId: this.state.clientId,
            reservationId: req.data.data.id,
          };
          this.setExtClientReservation(client_reservation);

          let cartitem = JSON.parse(this.state.espaceDataListForMap.chosen);
          for (var i = 0; i < cartitem.length; i++) {
            let itemInCart = cartitem[i];

            var total_cartiem = {
              panneauxId: itemInCart.id,
              date_reservation:
                moment(parseInt(this.state.espaceDataListForMap.start)).format(
                  "DD/MM/YYYY"
                ) +
                " - " +
                moment(parseInt(this.state.espaceDataListForMap.end)).format(
                  "DD/MM/YYYY"
                ),
              start: this.state.espaceDataListForMap.start,
              end: this.state.espaceDataListForMap.end,
              prix: 0,
              reservationId: req.data.data.id,
            };

            //   store each reservation line
            cartCheckoutLine
              .setReservationLine(total_cartiem)
              .then((req) => {
                // remove item from cart
                this.handleClickToRemove(itemInCart);
              })
              .catch((error) => {});

            if (i == cartitem.length - 1) {
              let link_Data = {
                used: 2,
              };

              linkData.updateLinkData(
                this.state.espaceDataListForMap.code,
                link_Data
              );
              setTimeout(() => {
                this.setState({ handleLoading: false });
                this.props.history.push("/partner/checkout/complete");
              }, 1000);
            }
          }
        })
        .catch((error) => {
          this.setState({ handleLoading: false });
        });
    } else {
      localStorage.setItem("checkout", "ad6476a36ec35fdd4d9f");
      this.props.history.push("/auth/sign-in");
    }
  };

  handleChangeClientId = (value) => {
    console.log(value);
    let new_client_item = { ...this.state.new_client_item };
    new_client_item["clientId"] = value;
    this.setState({ new_client_item });
    this.setState({ clientId: value });
    setTimeout(() => {
      this.fieldIsEmptyFunction(["clientId", "campagne"]);
    }, 300);
  };

  handleChangeClientItem = (event) => {
    console.log(event);
    let new_client_item = { ...this.state.new_client_item };
    new_client_item[event.target.name] = event.target.value;
    this.setState({ new_client_item });
    setTimeout(() => {
      this.fieldIsEmptyFunction([
        "firstname",
        "lastname",
        "phone",
        "email",
        "campagne",
      ]);
    }, 300);
  };

  handleChangeCampagneName = (event) => {
    console.log(event);
    let new_client_item = { ...this.state.new_client_item };
    new_client_item[event.target.name] = event.target.value;
    this.setState({ new_client_item });

    setTimeout(() => {
      if (this.state.option == 2) {
        this.fieldIsEmptyFunction([
          "firstname",
          "lastname",
          "phone",
          "email",
          "campagne",
        ]);
      } else {
        this.fieldIsEmptyFunction(["clientId", "campagne"]);
      }
    }, 300);
  };

  getRegieClient = () => {
    extClient
      .getEntityByRegieId(currentUserInfo.regieId)
      .then((req) => this.setState({ clientList: req.data }));
  };

  setRegieClient = () => {
    let new_client_item = { ...this.state.new_client_item };
    new_client_item["regieId"] = currentUserInfo.regieId;
    this.setState({ new_client_item });
    extClient
      .setEntity(new_client_item)
      .then((req) => this.setState({ clientId: req.data.data.id }));
  };

  setExtClientReservation = (data) => {
    extClientReservation
      .setEntity(data)
      .then((req) => this.setState({ clientId: req.data.id }));
  };

  fieldIsEmptyFunction = (lines) => {
    // Check if each item of the object is not empty
    let new_client_item = { ...this.state.new_client_item };
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (
        new_client_item[line] === null ||
        new_client_item[line] === undefined ||
        new_client_item[line] === ""
      ) {
        this.setState({ valueFieldIsEmpty: true });
        break;
      } else {
        this.setState({ valueFieldIsEmpty: false });
      }
    }
  };

  handleChange = (value) => {
    this.setState({ option: value, valueFieldIsEmpty: true });
  };

  //   <PanneauxItem />
  render() {
    let {
      // filterboxIsOpen,
      // searchFormboxIsOpen,
      espaceDataIsLoading,
      // espaceDataList,
      // espaceDataListForMap,
      // requestEspaceDataListTmp,
      // showMap,
      // defaultlat,
      // defaultlng,
      oneViewSelected,
      // MapLoading,
      // oneViewSelectedId,
      afterLoop,
      // searchType,
      // title,
      reservationData,
    } = this.state;

    return (
      <>
        <section className="login-content">
          <Row className="m-0 bg-white">
            <Col md="7">
              {afterLoop ? (
                <Row className="justify-content-center">
                  <Col md="12">
                    <div className="text-center pt-5">
                      <h2 className="text-left">
                        <svg
                          width="64"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.81409 20.4368H19.1971C20.7791 20.4368 21.7721 18.7267 20.9861 17.3527L13.8001 4.78775C13.0091 3.40475 11.0151 3.40375 10.2231 4.78675L3.02509 17.3518C2.23909 18.7258 3.23109 20.4368 4.81409 20.4368Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.0024 13.4147V10.3147"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.995 16.5H12.005"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </h2>
                      <h2>OUPS!</h2>
                      <h3 className="text-left">Nous sommes désolés</h3>
                      <p className="text-left">
                        Aucun espace ne correspond à votre recherche
                      </p>
                      <p className="text-left">
                        Essayez de modifier vos données pour découvrir d'autres
                        espaces.
                      </p>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="text-center result-header button justify-content-center pt-5 w-100">
                      <button
                        className="mr-1"
                        onClick={() =>
                          this.setState({ searchFormboxIsOpen: true })
                        }
                      >
                        <svg
                          width="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="11.7669"
                            cy="11.7666"
                            r="8.98856"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.0186 18.4851L21.5426 22"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>{" "}
                        faire une nouvelle recherche
                      </button>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row className="justify-content-center">
                  <Col md="12" className="result-header-o mb-5">
                    <div className="result-header p-sticky">
                      <div>
                        <h3 className="text-left search-title p-2">
                          <strong>Campagnes en attente</strong>
                        </h3>
                      </div>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="result-boby">
                      <SideDataList
                        espaceDataIsLoading={espaceDataIsLoading}
                        espaceDataList={reservationData}
                        OneViewSelected={this.OneViewSelected}
                        oneViewSelectedId={this.state.oneViewSelectedId}
                        alreadySearch={this.state.alreadySearch}
                        handleStartSearch={this.handleStartSearch}
                        handleChangeDate={this.handleChangeDate}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            <Col md="5" className="p-0 h-950 p-sticky bg-grey overflow-hidden">
              {/* <MapViewer
                showMap={showMap}
                defaultlat={defaultlat}
                defaultlng={defaultlng}
                espaceDataList={espaceDataListForMap}
              /> */}
              {oneViewSelected && (
                <Summary
                  items={this.props.items}
                  option={this.state.option}
                  clientList={this.state.clientList}
                  valueFieldIsEmpty={this.state.valueFieldIsEmpty}
                  handleChange={this.handleChange}
                  handleChangeClientId={this.handleChangeClientId}
                  handleChangeClientItem={this.handleChangeClientItem}
                  handleChangeCampagneName={this.handleChangeCampagneName}
                  handleLoading={this.state.handleLoading}
                  cartCheckout={this.cartCheckoutFunction}
                  fieldIsEmptyFunction={this.fieldIsEmptyFunction}
                />
              )}
            </Col>
          </Row>
        </section>
        {/* search form drawer */}

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

export default user_reservations;
