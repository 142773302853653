import axios from "axios";
import config from "./config";

export default {
  setAccount: (data) => {
    return axios.post(`${config.URL_END_POINT}/account/add`, data);
  },
  getAccountList: () => {
    return axios.get(`${config.URL_END_POINT}/account/list/`);
  },
  getAccountListByRoleId: (roleId) => {
    return axios.get(`${config.URL_END_POINT}/account/list/${roleId}`);
  },
  getAdminAccountList: () => {
    return axios.get(`${config.URL_END_POINT}/account/admin/list/`);
  },
  getCustomerAccountList: () => {
    return axios.get(`${config.URL_END_POINT}/account/customer/list/`);
  },
  getAccountById: (id) => {
    return axios.get(`${config.URL_END_POINT}/account/${id}`);
  },
  getAccountBySlug: (slug) => {
    return axios.get(`${config.URL_END_POINT}/account/slug/${slug}`);
  },
  updateAccount: (id, data) => {
    return axios.put(`${config.URL_END_POINT}/account/update/${id}`, data);
  },
  deleteAccount: (id) => {
    return axios.delete(`${config.URL_END_POINT}/account/delete/${id}`);
  },
};
