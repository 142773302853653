import React, { Component } from 'react'

export default class skeleton extends Component {
  render() {
    return (
      <>
        <div id="skeleton-05">
          <div class="wrapper">
            <div class="element box item-05-01" data-id="0"></div>
            <div class="element box item-05-02" data-id="1"></div>
            <div class="element box item-05-03" data-id="2"></div>
          </div>
        </div>
      </>
    )
  }
}
