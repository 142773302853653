export default {
  data: [
    { id: 32, title: 'Action Plus Abidjan' },
    { id: 20, title: 'Active Média' },
    { id: 53, title: 'ADB- Project and Consulting' },
    { id: 14, title: 'Alshana' },
    { id: 23, title: 'Canal Street' },
    { id: 61, title: 'Cerise' },
    { id: 37, title: 'Cible' },
    { id: 40, title: 'DevAfrika' },
    { id: 67, title: 'DUMP PUB' },
    { id: 58, title: 'Elite Plus' },
    { id: 26, title: 'Elite Services Plus' },
    { id: 24, title: 'Emergence' },
    { id: 63, title: 'Formation' },
    { id: 43, title: 'Gek-Ci' },
    { id: 56, title: 'Global Out Of Home Media' },
    { id: 22, title: 'Globus Art 2000' },
    { id: 57, title: 'Gscom Média' },
    { id: 41, title: 'Icone Média' },
    { id: 17, title: 'Idylle Média' },
    { id: 36, title: 'Interface' },
    { id: 12, title: 'Light Média' },
    { id: 15, title: 'Madingo' },
    { id: 35, title: 'Malcom' },
    { id: 21, title: 'Médiatic' },
    { id: 16, title: 'Médiaways' },
    { id: 42, title: 'Melis Com' },
    { id: 13, title: 'Millénium' },
    { id: 38, title: 'NjCom' },
    { id: 51, title: 'Optimum' },
    { id: 45, title: 'Pio' },
    { id: 31, title: 'Pixel Group' },
    { id: 19, title: 'Pub Régie' },
    { id: 34, title: 'PubliStar' },
    { id: 59, title: 'Pyramide Média' },
    { id: 25, title: 'Quartz' },
    { id: 50, title: 'Selfie Média' },
    { id: 33, title: 'Synthetik' },
    { id: 47, title: 'Varley Halls' },
    { id: 39, title: 'Visuel Concept' },
  ],
}
