import React, { Component } from "react";
import Moment from "react-moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FsLightbox from "fslightbox-react";
import { Row, Col, Modal, Form, Button, Image } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import PageHeader from "../../../components/partials/dashboard/HeaderStyle/partner-page-header";
import sep from "../../../components/partials/components/number-separator";
import Card from "../../../components/Card";

// services importation
import reservation from "../../../restApiService/reservation";
import reservationLine from "../../../restApiService/reservation_line";

class reservationDetail extends Component {
  state = {
    item: [],
    entityDataTmp: [],
    FsLightboxData: [],
    entityData: [],
    entityLineData: [],
    selectEntityData: [],
    files: [],
    fileTmp: [],
    files_o: [],
    makeAction: false,
    actionType: "",
    searchTerm: "",
    show: false,
    show2: false,
    showFsLightbox: false,
  };

  handleClose = () => this.setState({ show: false });
  handleClose2 = () => this.setState({ show2: false });
  handleShow = () =>
    this.setState({ fileTmp: [], show: true, actionType: "add" });

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  };

  getEntityData = (reservationId) => {
    reservation
      .getEntityId(reservationId)
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          entityData: response.data[0],
        });

        if (response.data.length > 0) {
          this.setState({ selectEntityData: this.state.entityData[0] });
          for (let i = 0; i < response.data.length; i++) {
            this.setState({
              FsLightboxData: [
                ...this.state.FsLightboxData,
                response.data[i].file,
              ],
            });
          }
        } else {
          this.setState({
            FsLightboxData: [...this.state.FsLightboxData, response.data.file],
          });
        }
      })
      .catch((error) => console.log("first"));
  };

  getEntityLineData = (reservationId) => {
    reservationLine
      .getEntityList(reservationId)
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          entityLineData: response.data,
          entityDataTmp: response.data,
        });

        if (response.data.length > 0) {
          this.setState({ selectEntityData: this.state.entityData[0] });
          for (let i = 0; i < response.data.length; i++) {
            this.setState({
              FsLightboxData: [
                ...this.state.FsLightboxData,
                response.data[i].file,
              ],
            });
          }
        } else {
          this.setState({
            FsLightboxData: [...this.state.FsLightboxData, response.data.file],
          });
        }
      })
      .catch((error) => console.log("first"));
  };

  selectEntity = (action, item) => {
    if (action === "read") {
      this.setState({
        selectEntityData: item,
        makeAction: false,
        actionType: "",
      });
    } else {
      this.setState({
        item: item,
        files: [item.file],
        show2: true,
        actionType: "update",
      });
    }
  };

  saveHandledData = () => {
    let item = { ...this.state.item };
    const promiseVar = toast.loading("Traitement en cours...");
    console.log(item);
    if (this.state.actionType === "add") {
      const formData = new FormData();
      formData.append("files", this.state.files_o[0]);
      formData.append("title", item.title);
      reservation
        .setEntity(formData)
        .then((response) => {
          this.componentDidMount();
          this.handleClose();
          toast.update(promiseVar, {
            render: "Opération éffectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) =>
          toast.update(promiseVar, {
            render: "Une erreur est susvenue",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        );
    } else {
      delete item["createdAt"];
      delete item["updatedAt"];
      const formData = new FormData();
      formData.append("files", this.state.files_o[0]);
      formData.append("title", item.title);
      reservation
        .updateEntityId(item.id, formData)
        .then((response) => {
          this.componentDidMount();
          this.handleClose2();
          toast.update(promiseVar, {
            render: "Opération éffectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) =>
          toast.update(promiseVar, {
            render: "Une erreur est susvenue",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        );
    }
  };

  updateReservationStatus = (id) => {
    const promiseVar = toast.loading("Traitement en cours...");
    let data = {
      etat: 1,
    };
    reservation
      .updateEntityId(id, data)
      .then((response) => {
        this.componentDidMount();
        // this.setState({ selectEntityData: this.state.entityData[0] })
        toast.update(promiseVar, {
          render: "Opération éffectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) =>
        toast.update(promiseVar, {
          render: "Une erreur est susvenue",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      );
  };

  removeItem = (id) => {
    const promiseVar = toast.loading("Traitement en cours...");
    reservation
      .deleteEntityId(id)
      .then((response) => {
        // this.setState({ selectEntityData: this.state.entityData[0] })
        toast.update(promiseVar, {
          render: "Opération éffectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        this.props.history.push("/admin/content/reservation/panneau");
      })
      .catch((error) =>
        toast.update(promiseVar, {
          render: "Une erreur est susvenue",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      );
  };

  componentDidMount() {
    this.getEntityData(this.props.match.params.reservationId);
    this.getEntityLineData(this.props.match.params.reservationId);
  }

  render() {
    const {
      show,
      show2,
      entityData,
      entityLineData,
      selectEntityData,
      item,
      makeAction,
      actionType,
      showFsLightbox,
      FsLightboxData,
    } = this.state;

    const files = this.state.files.map((file) => (
      <li key={file.name}>
        <div className="thumb">
          <div className="thumbInner">
            <img
              src={file.preview ?? file}
              className="thumbInner-img"
              // Revoke data uri after image is loaded
              onLoad={() => {
                URL.revokeObjectURL(file.preview ?? file);
              }}
              alt=""
            />
          </div>
          {file.name} - {file.size} {file.size && "bytes"}
        </div>
      </li>
    ));
    console.log("FsLightboxData", FsLightboxData);
    return (
      <div className="bg-grey-light">
        <PageHeader
          coloredText="Campagnes : "
          normalText={entityData?.nom_campagne}
          bgColor="#13367c"
          markerColor="#d24f3f"
        />
        {/* <FsLightbox toggler={showFsLightbox} sources={FsLightboxData} /> */}
        <div className="container">
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title"></h4>
                  </div>
                  <div>
                    {entityData?.etat !== 1 && (
                      <button
                        className="ent-btn rm-btn ent-btn-default ext-btn mt-lg-0 mt-md-0 mt-3 mr-2"
                        onClick={() =>
                          this.removeItem(this.props.match.params.reservationId)
                        }
                      >
                        <span>Annuler la reservation</span>
                      </button>
                    )}
                  </div>
                </Card.Header>
                <Card.Body className="px-0 reservation-detail">
                  <div class="OrderInfo">
                    <div class="LeftSideContent">
                      <span class="InvoiceNumber">Nom de campagne</span>
                      <h3 class="Title">{entityData?.nom_campagne}</h3>
                    </div>
                    <div class="RightSideContent">
                      <p>
                        <span class="orderStatusSpan">
                          Statut de la reservation:{" "}
                        </span>
                        <span class="orderStatus">
                          {entityData?.etat === 0 && (
                            <span className={`badge bg-yellow`}>
                              En attente
                            </span>
                          )}
                          {entityData?.etat === 1 && (
                            <span className={`badge bg-success`}>Validée</span>
                          )}
                        </span>
                      </p>
                      <p>
                        <span class="orderDateSpan">Date de création: </span>
                        <span class="orderDate">
                          <Moment format="DD/MM/YYYY">
                            {entityData?.createdAt}
                          </Moment>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="BillingInformation">
                    <div class="LeftSideContent">
                      <h3 class="Title">Info. client</h3>
                      <div>
                        <p class="NameEmail">
                          <span class="Name">
                            {entityData?.user?.firstname +
                              " " +
                              entityData?.user?.lastname}
                          </span>
                          <span
                            class="Email"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {entityData?.user?.email}
                          </span>
                        </p>
                        <p class="Phone">
                          <span
                            class="phone"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            Téléphone: {entityData?.user?.phone}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="RightSideContent">
                      <h3 class="Title">Info. supplémentaires</h3>
                      <div>
                        <p class="NameEmail">
                          <span class="Name">
                            Période de reservation :{" "}
                            {entityData?.date_reservation}
                          </span>
                          {/* <span
                            class="Email"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            
                          </span> */}
                        </p>
                        <p class="NameEmail">
                          <span class="Name">Montant TTC</span>
                          <strong
                            class="Email"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {sep.separator(entityData?.totalTtc)} FCFA
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      id="user-list-table"
                      className="table table-striped"
                      role="grid"
                      data-toggle="data-table"
                    >
                      <thead>
                        <tr className="ligth">
                          <th className="text-l-box">Nom de l'espace</th>
                          <th>Format</th>
                          <th>Propriétaire</th>
                          <th>Code</th>
                          <th style={{ textAlign: "right" }}>
                            Montant par espace
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {entityLineData.map((item, idx) => (
                          <tr key={idx}>
                            <td className="text-l-box">
                              {item?.panneaux?.title}
                            </td>
                            <td>{item?.panneaux?.format?.title}</td>
                            <td>{item?.panneaux?.regie?.title}</td>
                            <td className="text-l-box">
                              {item?.panneaux?.code}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {sep.separator(item?.prix)} FCFA
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div class="TotalBill">
                      <p>
                        Sous-total :{" "}
                        <span>{sep.separator(entityData?.sousTotal)} FCFA</span>
                      </p>
                      <p>
                        TVA : <span>{sep.separator(entityData?.tva)} FCFA</span>
                      </p>
                      <p>
                        TSP : <span>{sep.separator(entityData?.tsp)} FCFA</span>
                      </p>
                      <p>
                        ODP : <span>{sep.separator(entityData?.odp)} FCFA</span>
                      </p>
                      <p>
                        TM : <span>{sep.separator(entityData?.tm)} FCFA</span>
                      </p>
                      <h3>
                        Total :{" "}
                        <span>{sep.separator(entityData?.totalTtc)} FCFA</span>
                      </h3>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default reservationDetail;
