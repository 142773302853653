import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { PaginatedList } from 'react-paginated-list'
import PaginationList from "react-pagination-list";
import {
  Drawer,
  CheckboxGroup,
  Checkbox,
  Popover,
  Whisper,
  ButtonToolbar,
  Loader,
} from "rsuite";

import GoogleMapReact from "google-map-react";
import controllable from "react-controllables";
import { StickyContainer, Sticky } from "react-sticky";
import { Row, Col, Image, Form, Button, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import SearchForm from "../../../components/partials/dashboard/SearchFormStyle/search-form-style-1";
import SkeletonLoading from "../../../components/partials/components/skeletonLoading/skeleton-style-01";
// import Loader from '../../../components/partials/components/loader'
import PanneauxItem from "../../../components/partials/components/panneauxItem";

import ville from "../../../restApiService/ville";
import artere from "../../../restApiService/artere";
import commune from "../../../restApiService/commune";
import format from "../../../restApiService/format";
import type_artere from "../../../restApiService/type-artere";
import modele from "../../../restApiService/modele";
import reference from "../../../restApiService/reference";
import searchRequest from "../../../restApiService/searchRequest";

// component importation
// import SideDataList from './components/sideDataList'
// import MapViewer from './components/mapViewer'

import "../../../assets/css/searchresults.css";
import ListItem from "rsuite/esm/List/ListItem";
controllable(["center", "zoom", "markers"]);
const K_MARGIN_TOP = 30;
const K_MARGIN_RIGHT = 30;
const K_MARGIN_BOTTOM = 30;
const K_MARGIN_LEFT = 30;

const SideDataList = React.lazy(() => import("./components/sideDataList"));
const MapViewer = React.lazy(() => import("./components/mapViewer"));

const K_HOVER_DISTANCE = 30;

const decipher = (salt) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return (encoded) =>
    encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
};

class search_results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      villeData: [],
      villeListData: [],
      artereData: [],
      communeData: [],
      communeListData: [],
      formatData: [],
      formatListData: [],
      typeArtereListData: [],
      modeleListData: [],
      referenceListData: [],
      espaceDataIsLoading: true,
      espaceDataList: [],
      espaceDataListTmp: [],
      requestEspaceDataListTmp: [],
      espaceDataListForMap: [],
      searchData: [],
      filterboxIsOpen: false,
      searchFormboxIsOpen: false,
      searchType: "",
      title: "",
      afterLoop: false,
      showMap: false,
      MapLoading: false,
      oneViewSelected: false,
      oneViewSelectedId: 0,
      defaultlat: 0,
      defaultlng: 0,
      alreadySearch: false,
    };
  }

  componentDidMount() {
    console.log(this.props.match.params);
  }

  //   <PanneauxItem />
  render() {
    let {
      filterboxIsOpen,
      searchFormboxIsOpen,
      espaceDataIsLoading,
      espaceDataList,
      espaceDataListForMap,
      requestEspaceDataListTmp,
      showMap,
      defaultlat,
      defaultlng,
      oneViewSelected,
      MapLoading,
      oneViewSelectedId,
      afterLoop,
      searchType,
      title,
    } = this.state;

    return (
      <>
        ok
        {/* search form drawer */}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        {/* {espaceDataIsLoading && (
          <div className="search-loader-box">
            <div className="box">
              <Loader center vertical content="loading" size="lg" />
            </div>
          </div>
        )} */}
      </>
    );
  }
}

export default search_results;
