const componentItem = (props) => {
  return (
    <>
      <div className="update-form">
        <div className="entityCardAddForm">
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Titre</p>
            <p class="entityInfoDetails">
              <input
                className="form-control"
                name="title"
                value={props.item?.title}
                onChange={props.handleChange}
              />
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default componentItem
