import Dropzone from "react-dropzone";

const componentItem = (props) => {
  console.log(props);
  const files = props.files.map((file) => (
    <li key={file.name}>
      <div className="thumb">
        <div className="thumbInner">
          <img
            src={file.preview ?? file}
            className="thumbInner-img"
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview ?? file);
            }}
            alt=""
          />
        </div>
        {file.name} - {file.size} {file.size && "bytes"}
      </div>
    </li>
  ));
  return (
    <>
      <div className="update-form">
        <div className="entityCardAddForm">
          <div class="dropzone--box">
            <p className="mt-4">
              <strong>
                Ajouter au maximum 2 images pour votre espace d'affichage
              </strong>
            </p>
            <Dropzone onDrop={props.onDrop}>
              {({ getRootProps, getInputProps }) => (
                <section className="dropzonebox">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>
                      Faites glisser et déposez des fichiers ici, ou cliquez
                      pour sélectionner des fichiers.
                    </p>
                  </div>
                  <aside>
                    <ul>{files}</ul>
                  </aside>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
    </>
  );
};

export default componentItem;
