import React, { Component } from "react";
import { Row, Col, Modal, Form, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageHeader from "../../../components/partials/dashboard/HeaderStyle/partner-page-header";
import Card from "../../../components/Card";

class slider extends Component {
  state = {};

  render() {
    return (
      <div>
        <PageHeader
          coloredText="Conditions"
          normalText="générales d'utilisation"
          bgColor="#313131"
          markerColor="#eaba42"
        />
        <div class="content pb-5">
          <div class="container">
            <p>
              {" "}
              <strong>- Préambule et objet</strong> <br></br>
              Le site internet www.nymba.ci (ci-après le « SITE ») est la
              propriété exclusive de la société Varley Halls, société par
              actions pluripersonnelle au capital de 1.000.000 FCFA. VarleyHalls
              Immatriculée au Registre du Commerce et des Sociétés d’Abidjan
              sous le numéro CI-ABJ-2019-B-13043, au numéro de compte
              Contribuable 1935972 J et dont le siège social est situé à COCODY
              Riviera Triangle , adresse mail : infos@origin7pub.com . «
              nymba.ci » est une marque déposée.<br></br>
              Les présentes conditions générales d’utilisation ont vocation à
              s’appliquer à toute personne naviguant sur le SITE (ci-après «
              l’INTERNAUTE » ou collectivement « les INTERNAUTES »). En
              utilisant le SITE, l’INTERNAUTE reconnait expressément être lié
              par les conditions d’utilisation telles que définies ci-après, les
              accepter et les respecte.
            </p>
            <p>
              - Informations présentées sur le SITE Les photographies et images
              présentes sur le SITE sont non-contractuelles.
            </p>
            <p>
              <strong>- Liens vers d’autres sites</strong>
              Le SITE peut contenir des hyperliens ou pointer de toute autre
              manière vers des sites web ou des pages web administrées par de
              tierces parties. Nymba n’est en aucun cas responsable de leur
              contenu et de leurs caractéristiques. Le fait que Nymba propose
              des liens n’implique en aucun cas l’approbation implicite du
              contenu de ces sites ou pages web.
            </p>
            <p>
              <strong>- Garanties et responsabilités</strong> <br></br>
              Nymba décline toute responsabilité notamment :<br></br>- pour
              toute interruption du fonctionnement du SITE ;<br></br>- pour
              toute survenance de bogues ;<br></br>- pour tout dommage résultant
              d'une intrusion frauduleuse d'un tiers ayant entraîné une
              modification des informations mises à disposition sur le SITE ;
              <br></br>- si un des liens hypertexte référencés sur le SITE
              venait à fournir un contenu contraire à la bienséance et aux lois
              en vigueur.
            </p>
            <p>
              De manière générale, Nymba décline toute responsabilité pour tout
              dommage, matériel ou immatériel, direct ou indirect, quelles qu'en
              soient les causes, origines, natures ou conséquences en ce compris
              notamment les pertes de profits, de clientèle, de données ou tout
              autre perte de biens incorporels pouvant survenir à raison de
              l'accès de quiconque au SITE ou de l'impossibilité d'y accéder ou
              du crédit accordé à une quelconque information provenant
              directement ou indirectement de ce dernier. Les INTERNAUTES
              déclarent connaître et comprendre internet, ses aléas et ses
              limites et notamment ses caractéristiques fonctionnelles et
              performances techniques, les risques d'interruption, les temps de
              réponses pour consulter, interroger ou transférer des
              informations, les risques, quels qu'ils soient, inhérents à toute
              connexion sur le réseau.
            </p>
            <p>
              <strong>- Propriété intellectuelle</strong> <br></br>
              Sont de la propriété de Nymba et soumis aux lois régissant la
              propriété intellectuelle, notamment la mise en page, la structure
              et les éléments graphiques et textuels du SITE. L’utilisation du
              SITE ne confère aucun droit aux INTERNAUTES. Ces droits restent la
              propriété exclusive de Nymba. Ainsi, tous les textes, données,
              logos, images, photographies, marques (sans que cette liste ne
              soit exhaustive) reproduits sur le SITE sont réservés et protégés
              par le Code de la Propriété Intellectuelle, notamment les droits
              d’auteurs, le droit des marques, etc. Toute utilisation et/ou
              reproduction est interdite et constitutive de contrefaçon
              sanctionnable conformément aux dispositions dudit Code. En
              conséquence, les INTERNAUTES ne peuvent en aucun cas et d’aucune
              manière, reproduire, représenter, diffuser, commercialiser,
              concéder tout ou partie de l’un quelconque des éléments reproduits
              sur le SITE et tout ou partie du SITE en général, sans l’accord
              préalable et exprès de Nymba. En outre, il est interdit aux
              INTERNAUTES de réaliser des copies du SITE sans l’autorisation
              préalable de Nymba. Toute utilisation illicite de tout ou partie
              du SITE pourra donner lieu à des poursuites. La création d’un lien
              vers le SITE doit faire l’objet d’une autorisation préalable et
              écrite de la part de Nymba.
            </p>
            <p>
              <strong>- Données personnelles</strong> <br></br>
              Les données personnelles collectées et ultérieurement traitées par
              Nymba sont celles que les INTERNAUTES transmettent volontairement
              via le SITE à Nymba, ou qui ont fait l'objet d'une collecte
              antérieure spéciale soumise à autorisation. Les INTERNAUTES
              autorisent nymba.ci à sauvegarder ces informations personnelles
              dans un fichier de Nymba, en vue de l’exécution des services
              proposés par Nymba via la PLATEFORME, du profilage automatisé des
              INTERNAUTES, de la gestion de la clientèle, d’études de marché, de
              la réception d’informations de la part de Nymba. Toutefois, Nymba
              s’engage à ne pas transmettre les données fournies à des tiers.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default slider;
