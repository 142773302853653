import React, { Component } from "react";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";
import Logo from "../../../components/partials/components/logo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// RestApi service import
import carriere from "../../../restApiService/carriere";
import carrierimg from "../../../assets/images/pages/christina-wocintechchat-com-4PU-OC8sW98-unsplash.jpg";

class Entity extends Component {
  state = {
    item: [],
    cv_file: [],
    lm_file: [],
    handleLoginLoading: false,
  };

  handleChange = (event) => {
    let item = { ...this.state.item };
    item[event.target.name] = event.target.value;
    this.setState({ item });
  };

  handleFile = (file, event) => {
    if (file === 1) {
      this.setState({
        cv_file: event.target.files[0],
      });
    } else {
      this.setState({
        lm_file: event.target.files[0],
      });
    }
  };

  handleSubmission = (e) => {
    e.preventDefault();
    const promiseVar = toast.loading(
      "Recherche des information du client en cours..."
    );
    this.setState({ handleLoginLoading: true });
    let item = { ...this.state.item };
    delete item["cv"];
    delete item["lm"];
    this.setState({ item });
    carriere
      .setEntity(item)
      .then((response) => {
        const formData = new FormData();
        const formData02 = new FormData();
        if (this.state.cv_file.length !== 0) {
          formData.append("files", this.state.cv_file);
          carriere.updateEntityId(1, response.data?.data?.id, formData);
        }

        setTimeout(() => {
          if (this.state.lm_file.length !== 0) {
            formData02.append("files", this.state.lm_file);
            carriere.updateEntityId(2, response.data?.data?.id, formData02);
          }
        }, 400);
        document.getElementById("create-form")?.reset();
        this.setState({ handleLoginLoading: false });
        toast.update(promiseVar, {
          render: "Connexion effectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => {
        toast.update(promiseVar, {
          render: "Informations incorrectes",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        this.setState({ handleLoginLoading: false });
      });
  };

  render() {
    const { handleLoginLoading } = this.state;
    return (
      <>
        <section className="login-content">
          <Row className="m-0 align-items-center bg-white vh-100">
            <Col md="6">
              <Row className="justify-content-center">
                <Col md="12">
                  <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                    <Card.Body>
                      <h2 className="mb-2">Obtenez votre billet pour Nymba</h2>
                      <p className="mb-4">
                        Ensemble, nous pouvons changer le monde de la publicité.
                      </p>
                      <Form id="create-form">
                        <Row>
                          <Col lg="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="full-name" className="">
                                Nom*
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className=""
                                name="lastname"
                                id="full-name"
                                onChange={this.handleChange}
                                placeholder=" "
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="last-name" className="">
                                Prenom(s)*
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className=""
                                name="firstname"
                                id="last-name"
                                onChange={this.handleChange}
                                placeholder=" "
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="email" className="">
                                Adresse email*
                              </Form.Label>
                              <Form.Control
                                type="email"
                                className=""
                                id="email"
                                name="email"
                                onChange={this.handleChange}
                                placeholder=" "
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="phone" className="">
                                Numéro de téléphone*
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className=""
                                id="phone"
                                name="phone"
                                onChange={this.handleChange}
                                placeholder=" "
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="12">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="phone" className="">
                                Localisation
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className=""
                                id="localisation"
                                name="localisation"
                                onChange={this.handleChange}
                                placeholder=" "
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="12">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="phone" className="">
                                Attachez votre CV
                              </Form.Label>
                              <Form.Control
                                type="file"
                                className=""
                                id="cv"
                                name="cv"
                                accept=".pdf"
                                onChange={(e) => this.handleFile(1, e)}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="12">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="phone" className="">
                                Attachez votre lettre de motivation (facultatif)
                              </Form.Label>
                              <Form.Control
                                type="file"
                                className=""
                                id="lm"
                                name="lm"
                                accept=".pdf"
                                onChange={(e) => this.handleFile(2, e)}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <div className="d-flex justify-content-center">
                          {handleLoginLoading ? (
                            <Button variant="btn btn-yellow w-100" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Inscription...
                            </Button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-yellow w-100"
                              onClick={this.handleSubmission}
                            >
                              Soumettre ma demande
                            </button>
                          )}
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col
              md="6"
              className="d-md-block d-none bg-yellow p-0 mt-n1 vh-100 overflow-hidden"
            >
              <Image
                src={carrierimg}
                className="Image-fluid gradient-main animated-scaleX"
                alt="images"
              />
            </Col>
          </Row>
        </section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

export default Entity;
