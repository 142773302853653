import React, { Component } from "react";
import { Row, Col, Modal, Form, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageHeader from "../../../components/partials/dashboard/HeaderStyle/partner-page-header";
import Card from "../../../components/Card";

class slider extends Component {
  state = {};

  render() {
    return (
      <div>
        <PageHeader
          coloredText="Qui"
          normalText="sommes-nous ?"
          bgColor="#313131"
          markerColor="#eaba42"
        />
        <div class="content pb-5">
          <div class="container">
            <p>
              Varley Halls est une régie publicitaire qui entend proposer aux
              annonceurs (entreprise qui communique) un certain nombre de
              services et produits innovants. Plusieurs produits s’adossant sur
              le digital sont en cours de conception.<br></br>
              Parmi ces produits, la plateforme « Nymba » vient comme une grosse
              innovation qui révolutionne l’affichage urbain en Côte d’Ivoire et
              en Afrique.
            </p>
            <p>
              Nymba est une application web qui digitalise le processus de
              réservation d’espaces d’affichage urbain (panneaux, murs, dalles,
              super sign,...)
            </p>
            <p>
              Avant Nymba, faire une campagne d’affichage était un véritable
              casse-tête pour les agences de communication et les annonceurs. Le
              processus était celui-là :<br></br>- Trouver les contacts de
              régies publicitaires propriétaires d’espace d’affichage urbain (Il
              y a en une cinquantaine au minimum et les contacter n’est pas
              toujours évident).
              <br></br>- Demander leurs disponibilités dans la période pendant
              laquelle on souhaite communiquer
              <br></br>- Faire un tri et choisir les espaces qui nous
              conviennent sans visibilité claire sur la performance du support
              <br></br>- Ramener ce choix aux régies avec le risque que certains
              supports ne soient plus disponibles - Etc.
            </p>
            <p>
              Aujourd’hui, avec Nymba tout ce processus est un jeu d’enfant.
              <br></br>
              Directement, à partir de notre page Web , l’annonceur ou l’agence
              de communication n’a juste qu’à indiquer la période pendant
              laquelle il souhaite communiquer, l’endroit où il souhaite être
              affiché et le format qui l’intéresse.<br></br>
              Et notre plateforme lui propose alors toutes les disponibilités
              qui correspondent à sa recherche.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default slider;
