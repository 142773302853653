import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, Button, DateRangePicker } from "rsuite";
import RemindIcon from "@rsuite/icons/legacy/Remind";
import { Row, Col, Form, Popover, OverlayTrigger } from "react-bootstrap";
import sep from "./number-separator";
import { Carousel } from "react-bootstrap";
import { addToCart, removeItem } from "../../../store/actions/cartActions";
import { addToWish, removeWishItem } from "../../../store/actions/wishActions";

const {
  allowedMaxDays,
  // allowedDays,
  // allowedRange,
  // beforeToday,
  // afterToday,
  // combine,
} = DateRangePicker;

const PanneauxItem = (props) => {
  const [newCampagne, setNewCampagne] = useState(false);
  const [itemId, setItemId] = useState(0);
  const [campagne, setCampagne] = useState("");
  const [open, setOpen] = React.useState(false);
  // const [alreadyOpen, setAlreadyOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    // setAlreadyOpen(true);
    setOpen(false);
    // setTimeout(() => {
    //   setAlreadyOpen(false)
    // }, 500)
  };

  const handleChange = (event) => {
    let campagne = event.target.value;
    setCampagne(campagne);
  };
  const handleClick = (type, item) => {
    setOpen(false);
    if (window.location.href.includes("/search-engine/")) {
      let notSame = props.items.filter(
        (items) => items.start !== item.start && items.end !== item.start
      );
      if (notSame.length === 0) {
        props.addToCart(item);
      } else {
        setOpen(true);
      }
    } else {
      if (type === "wish") {
        props.addToWish(item);
      } else {
        let notSame = props.items.filter(
          (items) =>
            Number(items.start) !== Number(item.start) &&
            Number(items.end) !== Number(item.end)
        );
        // console.log("notSame", notSame);
        // console.log("notSame size", notSame.length);
        // console.log("item", item);
        if (notSame.length === 0) {
          if (localStorage.getItem("cpgne")) {
            props.addToCart(item);
          } else {
            setItemId(item);
            handleNewCampagne();
          }
        } else {
          setOpen(true);
        }
      }
    }

    // props.addToCart(id)
  };

  const handleAddNewCampagne = () => {
    // save campagne name
    localStorage.setItem("cpgne", campagne);
    // add to cart
    props.addToCart(itemId);
    // close modal
    handleNewCampagne();
  };
  const handleClickToRemove = (type, item) => {
    if (type === "wish") {
      props.removeWishItem(item);
    } else {
      props.removeItem(item);
    }
  };
  const handleNewCampagne = () => {
    setNewCampagne(!newCampagne);
  };

  return (
    <>
      <div
        className={
          props.oneViewSelectedId === props.data.id
            ? "home-container one-view-selected"
            : "home-container"
        }
      >
        <div className="home-container3">
          <div className="home-container4">
            {window.location.href.includes("/app/") && (
              <>
                {props.wish_items.map((x) => x.id).indexOf(props.data.id) ===
                -1 ? (
                  <button
                    class="add-to-wish"
                    onClick={() => {
                      handleClick("wish", props.data);
                    }}
                  >
                    <i class="far fa-heart" aria-hidden="true"></i>
                  </button>
                ) : (
                  <button
                    class="add-to-wish"
                    onClick={() => {
                      handleClickToRemove("wish", props.data);
                    }}
                  >
                    <i class="fas fa-heart" aria-hidden="true"></i>
                  </button>
                )}
              </>
            )}

            {!props.partner && (
              <Link to={"/app/espaces/" + props.data?.slug}>
                <Carousel
                  indicators={true}
                  className="home-image"
                  interval={null}
                >
                  {props.data.storages.map((item) => (
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={item.image}
                        alt={item.image}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Link>
            )}
            {props.partner && (
              <Carousel
                indicators={true}
                className="home-image"
                interval={null}
              >
                {props.data.storages.map((item) => (
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={item.image}
                      alt={item.image}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
            <div className="format-badge">{props.data.format.title}</div>
          </div>
          <div className="home-container5">
            <div className="home-text">
              {!props.partner && (
                <Link to={"/app/espaces/" + props.data?.slug}>
                  <span>
                    <h1>{props.data.title} </h1>{" "}
                  </span>
                </Link>
              )}

              {props.partner && (
                <span>
                  <h1>{props.data.title} </h1>{" "}
                </span>
              )}
            </div>

            <span className="home-text1"> {props.data.sens}</span>
            <span className="home-text2">
              {props.data.description.slice(0, 100) + "..."}
            </span>
            <span className="home-text3">
              <button
                hidden={props.noPins ? true : false}
                onClick={() => props.OneViewSelected(props.data.id)}
              >
                <i class="fas fa-map-marker-alt"></i> Indiquer sur la carte
              </button>
              {!props.partner && (
                <p className="rating">
                  {[...Array(Number(props.data.rate))].map(
                    (elementInArray, index) => (
                      <i className="fa fa-star" aria-hidden="true" key={index}>
                        &nbsp;
                      </i>
                    )
                  )}
                </p>
              )}
            </span>
          </div>
          <div className="home-container6">
            {!props.partner && (
              <span className="home-text4">
                {props.data.prixCalculate
                  ? sep.separator(props.data.prixCalculate)
                  : sep.separator(props.data.prix_minimum)}{" "}
                FCFA
              </span>
            )}

            <span className="home-text5">
              <span className="home-text6">
                {props.data.duration ? props.data.duration : "2 semaines"} de
                présence montant HT (hors taxes)
              </span>
              <br></br>
            </span>

            <>
              {!props.alreadySearch && (
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Header as="h3">
                        Indiquez une periode reservation
                      </Popover.Header>
                      <Popover.Body>
                        <p>
                          {" "}
                          <DateRangePicker
                            placeholder="À quelle période ?"
                            disabledDate={allowedMaxDays(365)}
                            onChange={props.handleChangeDate}
                            format="yyyy-MM-dd"
                          />
                        </p>
                        <p>
                          <Button
                            onClick={props.handleStartSearch}
                            appearance="primary"
                            className="w-100 bg-yellow"
                          >
                            Lancer la recherche
                          </Button>
                        </p>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <button className="home-button button">
                    <svg
                      width="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.75 3.25L4.83 3.61L5.793 15.083C5.87 16.02 6.653 16.739 7.593 16.736H18.502C19.399 16.738 20.16 16.078 20.287 15.19L21.236 8.632C21.342 7.899 20.833 7.219 20.101 7.113C20.037 7.104 5.164 7.099 5.164 7.099"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.125 10.7949H16.898"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.15435 20.2026C7.45535 20.2026 7.69835 20.4466 7.69835 20.7466C7.69835 21.0476 7.45535 21.2916 7.15435 21.2916C6.85335 21.2916 6.61035 21.0476 6.61035 20.7466C6.61035 20.4466 6.85335 20.2026 7.15435 20.2026Z"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.4346 20.2026C18.7356 20.2026 18.9796 20.4466 18.9796 20.7466C18.9796 21.0476 18.7356 21.2916 18.4346 21.2916C18.1336 21.2916 17.8906 21.0476 17.8906 20.7466C17.8906 20.4466 18.1336 20.2026 18.4346 20.2026Z"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                    Choisir
                  </button>
                </OverlayTrigger>
              )}
              {props.alreadySearch &&
                props.items.map((x) => x.id).indexOf(props.data.id) === -1 && (
                  <button
                    className="home-button button"
                    onClick={() => {
                      handleClick("cart", props.data);
                    }}
                  >
                    <svg
                      width="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.75 3.25L4.83 3.61L5.793 15.083C5.87 16.02 6.653 16.739 7.593 16.736H18.502C19.399 16.738 20.16 16.078 20.287 15.19L21.236 8.632C21.342 7.899 20.833 7.219 20.101 7.113C20.037 7.104 5.164 7.099 5.164 7.099"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.125 10.7949H16.898"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.15435 20.2026C7.45535 20.2026 7.69835 20.4466 7.69835 20.7466C7.69835 21.0476 7.45535 21.2916 7.15435 21.2916C6.85335 21.2916 6.61035 21.0476 6.61035 20.7466C6.61035 20.4466 6.85335 20.2026 7.15435 20.2026Z"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.4346 20.2026C18.7356 20.2026 18.9796 20.4466 18.9796 20.7466C18.9796 21.0476 18.7356 21.2916 18.4346 21.2916C18.1336 21.2916 17.8906 21.0476 17.8906 20.7466C17.8906 20.4466 18.1336 20.2026 18.4346 20.2026Z"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                    Choisir
                  </button>
                )}
              {props.alreadySearch &&
                props.items.map((x) => x.id).indexOf(props.data.id) !== -1 && (
                  <button
                    className="home-button button remove"
                    onClick={() => {
                      handleClickToRemove("cart", props.data);
                    }}
                  >
                    <svg
                      width="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20.708 6.23975H3.75"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>{" "}
                    Retirer
                  </button>
                )}
            </>
          </div>
        </div>
      </div>

      <Modal open={newCampagne} onClose={handleNewCampagne}>
        <Modal.Header>
          <Modal.Title>Vous démarrez une campagne publicitaire ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="campagne" className="">
                    Nommer cette campagne
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className=""
                    id="campagne"
                    name="campagne"
                    onChange={handleChange}
                    placeholder=" "
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {campagne ? (
            <Button
              onClick={handleAddNewCampagne}
              appearance="primary"
              className="w-100"
            >
              Créer
            </Button>
          ) : (
            <Button
              onClick={handleAddNewCampagne}
              appearance="primary"
              className="w-100 bg-yellow"
              disabled
            >
              Créer
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        backdrop="static"
        role="alertdialog"
        open={open}
        onClose={handleClose}
        size="xs"
      >
        <Modal.Body>
          <p>
            <RemindIcon style={{ color: "#ffb300", fontSize: 24 }} />
            Vous avez déjà une campagne en cours
          </p>
          <p>
            <strong>NB:</strong> Une campagne est caractérisée par une date de
            début et une date de fin
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Ok, j'ai compris
          </Button>
          {/* <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  let addedItemsTmp = state.cartReducer;
  let addedWishItemsTmp = state.wishReducer;
  let notMatch = state.cartReducer.notMatch;
  return {
    items: addedItemsTmp.addedItems,
    notMatch: notMatch,
    wish_items: addedWishItemsTmp.addedWishItems,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id) => {
      dispatch(addToCart(id));
    },
    removeItem: (id) => {
      dispatch(removeItem(id));
    },
    addToWish: (id) => {
      dispatch(addToWish(id));
    },
    removeWishItem: (id) => {
      dispatch(removeWishItem(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PanneauxItem);
