import React, { Component } from "react";
import { connect } from "react-redux";
import GoogleMapReact from "google-map-react";
import {
  Row,
  Col,
  Form,
  Popover,
  OverlayTrigger,
  Carousel,
} from "react-bootstrap";
import {
  Popover as Popovers,
  Whisper,
  Button,
  Modal,
  DateRangePicker,
} from "rsuite";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import GoogleMapReact, Marker from 'google-map-react'
// RestApi service import
import sep from "../../../components/partials/components/number-separator";
import panneaux from "../../../restApiService/panneaux";
import searchRequest from "../../../restApiService/searchRequest";
import { addToCart, removeItem } from "../../../store/actions/cartActions";
import { addToWish, removeWishItem } from "../../../store/actions/wishActions";

const {
  allowedMaxDays,
  // allowedDays,
  // allowedRange,
  // beforeToday,
  // afterToday,
  // combine,
} = DateRangePicker;

class single_espace extends Component {
  state = {
    espaceData: [],
    storages: [],
    searchData: [],
    espaceDataIsLoading: true,
    rate: 1,
    showMap: false,
    newCampagne: false,
    itemId: 0,
    campagne: "",
    alreadySearch: false,
  };

  handleChange = (event) => {
    let campagne = event.target.value;
    this.setState({ campagne: campagne });
  };

  handleChangeDate = (event) => {
    let searchData = { ...this.state.searchData };
    if (event != null) {
      const startDate = moment(event[0]);
      const endDate = moment(event[1]);
      searchData["start"] = new Date(event[0]).getTime();
      searchData["end"] = new Date(event[1]).getTime();
      console.log(searchData);
      let range = endDate.diff(startDate, "days");
      searchData["range"] = range;
      localStorage.setItem("duration", range);
      this.setState({ searchData });
      setTimeout(() => {
        if (range <= 16) {
          toast.info(
            "Choisissez une période supérieure à 15 jours et inférieure à 1 an",
            {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }, 500);
    }
  };

  handleClick = (type, item) => {
    if (type === "wish") {
      this.props.addToWish(item);
    } else {
      if (localStorage.getItem("cpgne")) {
        this.props.addToCart(item);
      } else {
        this.setState({ itemId: item });
        this.handleNewCampagne();
      }
    }
  };

  handleAddNewCampagne = () => {
    // save campagne name
    localStorage.setItem("cpgne", this.state.campagne);
    // add to cart
    this.props.addToCart(this.state.itemId);
    // close modal
    this.handleNewCampagne();
  };

  handleClickToRemove = (type, item) => {
    if (type === "wish") {
      this.props.removeWishItem(item);
    } else {
      this.props.removeItem(item);
    }
  };

  handleNewCampagne = () => {
    this.setState({ newCampagne: !this.state.newCampagne });
  };

  getBestPointDataList = (slug) => {
    panneaux
      .getEntityBySlug(slug)
      .then((rep) => {
        this.setState({
          espaceData: rep.data[0],
          storages: rep.data[0].storages,
          rate: Number(rep.data[0].rate),
        });
        setTimeout(() => {
          this.setState({ espaceDataIsLoading: false });
        }, 500);
        setTimeout(() => {
          this.setState({ showMap: true });
        }, 500);
      })
      .catch((error) => {});
  };

  handleStartSearch = () => {
    let searchData = { ...this.state.searchData };

    localStorage.setItem("end", searchData.end);
    localStorage.setItem("range", searchData.range);
    localStorage.setItem("start", searchData.start);

    this.SearchRequest(searchData);
    this.setState({ alreadySearch: true });
  };

  SearchRequest = (data) => {
    let searchData = { ...this.state.searchData };
    if (
      localStorage.getItem("end") &&
      localStorage.getItem("range") &&
      localStorage.getItem("start")
    ) {
      searchData["end"] = localStorage.getItem("end");
      searchData["range"] = localStorage.getItem("range");
      searchData["start"] = localStorage.getItem("start");
      this.setState({ searchData });
    }

    searchRequest
      .panneauxSearchEngine(searchData)
      .then((req) => {
        let filterDataToGetEspace = req.data.filter(
          (item) => item.id === this.state.espaceData.id
        );

        if (filterDataToGetEspace.length > 0) {
          this.setState({
            espaceData: filterDataToGetEspace[0],
          });
        } else {
          toast.warn(
            "Oups! ce espace n'est malheureusement pas disponible pour cette periode",
            {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          localStorage.removeItem("duration");
        }
      })
      .catch((error) => {});
  };

  componentDidMount() {
    this.getBestPointDataList(this.props.match.params.slug);
    if (localStorage.getItem("duration")) {
      this.setState({ alreadySearch: true });
    }
  }

  render() {
    const { espaceDataIsLoading, espaceData, rate, showMap } = this.state;
    const AnyReactComponent = ({ text }) => <div>{text}</div>;
    const defaultProps = {
      center: {
        lat: espaceData.geolatitude,
        lng: espaceData.geolongitude,
      },
      zoom: 11,
    };
    const distanceToMouse = (pt, mp) => {
      if (pt && mp) {
        // return distance between the marker and mouse pointer
        return Math.sqrt(
          (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
        );
      }
    };
    const points = [{ id: 1, title: "Round Pond", lat: 51.506, lng: -0.184 }];

    const MyMarker = ({ text, tooltip, $hover }) => {
      const handleClick = () => {
        console.log(`You clicked on ${tooltip}`);
        alert(`You clicked on ${tooltip}`);
      };

      return (
        // <div
        //   className={$hover ? 'circle hover' : 'circle'}
        //   onClick={handleClick}
        // >
        //   <span className="circleText" title={tooltip}>
        //     {text}
        //   </span>
        // </div>
        <>
          <Whisper
            placement="top"
            trigger="click"
            controlId="control-id-click"
            speaker={speaker}
          >
            <Button className="pin-button">
              <div class={$hover ? "map-pin circle hover" : "map-pin"}></div>
              <div class="pulse"></div>
            </Button>
          </Whisper>
        </>
      );
    };
    const speaker = (
      <Popover title="">
        <img
          alt="First slide"
          className="d-block w-100"
          src="https://nymba-files.s3.us-west-2.amazonaws.com/autoroute%20du%20nord.JPG"
        />
        <p>This is a default Popover </p>
        <p>Content</p>
        <p>
          <a>link</a>
        </p>
      </Popover>
    );
    return (
      <>
        <section id="tabs">
          <div className="container">
            <div className="row top-h">
              <div className="col-md-12">
                <h2 className="page-title">{this.state.espaceData.title}</h2>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item" aria-current="page">
                      Home
                    </li>
                    <li class="breadcrumb-item" aria-current="page">
                      Espaces
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {this.state.espaceData.title}
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="col-md-8">
                <Carousel indicators={true}>
                  {this.state.storages.map((item) => (
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={item.image}
                        alt={item.image}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              <div className="col-md-4">
                <div className="pricing-box">
                  <span className="pricing">
                    {espaceData.prixCalculate
                      ? sep.separator(espaceData.prixCalculate)
                      : sep.separator(espaceData.prix_minimum)}{" "}
                    FCFA
                  </span>
                  <span className="pricing-description">
                    {espaceData.duration
                      ? espaceData.duration
                      : "2 semaines minimum"}{" "}
                    de présence montant HT (hors taxes)
                  </span>
                </div>
                <div className="detail side">
                  <h3 className="title">Description</h3>
                  <div className="content side">
                    <p>{espaceData.description}</p>
                  </div>
                </div>

                <div className="tocart">
                  <div>
                    <>
                      {!this.state.alreadySearch && (
                        <OverlayTrigger
                          trigger="click"
                          placement="left"
                          overlay={
                            <Popover id="popover-basic">
                              <Popover.Header as="h3">
                                Indiquez une periode reservation
                              </Popover.Header>
                              <Popover.Body>
                                <p>
                                  {" "}
                                  <DateRangePicker
                                    placeholder="À quelle période ?"
                                    disabledDate={allowedMaxDays(365)}
                                    onChange={this.handleChangeDate}
                                    format="yyyy-MM-dd"
                                  />
                                </p>
                                <p>
                                  <Button
                                    onClick={this.handleStartSearch}
                                    appearance="primary"
                                    className="w-100 bg-yellow"
                                  >
                                    Lancer la recherche
                                  </Button>
                                </p>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <button className="home-button button n-full">
                            <svg
                              width="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.75 3.25L4.83 3.61L5.793 15.083C5.87 16.02 6.653 16.739 7.593 16.736H18.502C19.399 16.738 20.16 16.078 20.287 15.19L21.236 8.632C21.342 7.899 20.833 7.219 20.101 7.113C20.037 7.104 5.164 7.099 5.164 7.099"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M14.125 10.7949H16.898"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.15435 20.2026C7.45535 20.2026 7.69835 20.4466 7.69835 20.7466C7.69835 21.0476 7.45535 21.2916 7.15435 21.2916C6.85335 21.2916 6.61035 21.0476 6.61035 20.7466C6.61035 20.4466 6.85335 20.2026 7.15435 20.2026Z"
                                fill="currentColor"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M18.4346 20.2026C18.7356 20.2026 18.9796 20.4466 18.9796 20.7466C18.9796 21.0476 18.7356 21.2916 18.4346 21.2916C18.1336 21.2916 17.8906 21.0476 17.8906 20.7466C17.8906 20.4466 18.1336 20.2026 18.4346 20.2026Z"
                                fill="currentColor"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>{" "}
                            Ajouter à ma campagne
                          </button>
                        </OverlayTrigger>
                      )}
                      {this.state.alreadySearch &&
                        this.props.items
                          .map((x) => x.id)
                          .indexOf(espaceData.id) === -1 && (
                          <button
                            className="home-button button n-full"
                            onClick={() => {
                              this.handleClick("type", espaceData);
                            }}
                          >
                            <svg
                              width="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.75 3.25L4.83 3.61L5.793 15.083C5.87 16.02 6.653 16.739 7.593 16.736H18.502C19.399 16.738 20.16 16.078 20.287 15.19L21.236 8.632C21.342 7.899 20.833 7.219 20.101 7.113C20.037 7.104 5.164 7.099 5.164 7.099"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M14.125 10.7949H16.898"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.15435 20.2026C7.45535 20.2026 7.69835 20.4466 7.69835 20.7466C7.69835 21.0476 7.45535 21.2916 7.15435 21.2916C6.85335 21.2916 6.61035 21.0476 6.61035 20.7466C6.61035 20.4466 6.85335 20.2026 7.15435 20.2026Z"
                                fill="currentColor"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M18.4346 20.2026C18.7356 20.2026 18.9796 20.4466 18.9796 20.7466C18.9796 21.0476 18.7356 21.2916 18.4346 21.2916C18.1336 21.2916 17.8906 21.0476 17.8906 20.7466C17.8906 20.4466 18.1336 20.2026 18.4346 20.2026Z"
                                fill="currentColor"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>{" "}
                            Ajouter à ma campagne
                          </button>
                        )}
                      {this.state.alreadySearch &&
                        this.props.items
                          .map((x) => x.id)
                          .indexOf(espaceData.id) !== -1 && (
                          <button
                            className="home-button button remove w-100"
                            onClick={() => {
                              this.handleClickToRemove("cart", espaceData);
                            }}
                          >
                            <svg
                              width="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20.708 6.23975H3.75"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>{" "}
                            Retirer
                          </button>
                        )}
                    </>
                  </div>
                </div>

                <div className="towish">
                  <div>
                    {this.state.alreadySearch &&
                    this.props.wish_items
                      .map((x) => x.id)
                      .indexOf(espaceData.id) === -1 ? (
                      <button
                        className="n-full"
                        onClick={() => {
                          this.handleClick("wish", espaceData);
                        }}
                      >
                        <i className="far fa-heart" aria-hidden="true"></i>{" "}
                        Ajouter à mes coups de coeur{" "}
                      </button>
                    ) : (
                      <button
                        className="n-full-o"
                        onClick={() => {
                          this.handleClickToRemove("wish", espaceData);
                        }}
                      >
                        <i className="fas fa-heart" aria-hidden="true"></i>{" "}
                        Retirer de mes coups de coeur{" "}
                      </button>
                    )}
                  </div>
                </div>
                <div className="goback">
                  <button
                    className="rules-link rules-link-js jquery-once-1-processed"
                    onClick={this.props.history.goBack}
                  >
                    Revenir à la liste{" "}
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="detail">
                  <h3 className="title">Données géographiques</h3>
                  <div className="content">
                    <ul>
                      <li>
                        <label for="">Ville</label>
                        <p>{espaceData.ville?.title}</p>
                      </li>
                      <li>
                        <label for="">Commune</label>
                        <p>{espaceData.commune?.title}</p>
                      </li>
                      <li className="ref">
                        <label for="">Référence</label>
                        <ul>
                          <li></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="detail">
                  <h3 className="title">Données sur la voie de circulation</h3>
                  <div className="content">
                    <ul>
                      <li>
                        <label for="">Artère</label>
                        <p>{espaceData.artere?.title}</p>
                      </li>
                      <li>
                        <label for="">Type d'artère</label>
                        <p>{espaceData.type_atere?.title}</p>
                      </li>
                      <li>
                        <label for="">Position de l’axe</label>
                        <p>{espaceData.positionAxe}</p>
                      </li>
                      <li>
                        <label for="">Position du panneau</label>
                        <p>{espaceData.positionPanneau}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="detail">
                  <h3 className="title">Données sur l'espace</h3>
                  <div className="content">
                    <ul>
                      <li>
                        <label for="">Type espace d'affichage</label>
                        <p>{espaceData.type_espace}</p>
                      </li>
                      <li>
                        <label for="">Format</label>
                        <p>{espaceData.format?.title}</p>
                      </li>
                      <li>
                        <label for="">Modèle</label>
                        <p>{espaceData.modele?.title}</p>
                      </li>
                      <li>
                        <label for="">Eclairage</label>
                        <p>{espaceData.eclairage}</p>
                      </li>
                      <li>
                        <label for="">Qualité du panneau</label>
                        <p>{espaceData.qualite}</p>
                      </li>
                      <li>
                        <label for="">Note du l'espace</label>

                        <div className="etoile-panneaux-o">
                          {[...Array(rate)].map((elementInArray, index) => (
                            <i className="fa fa-star" aria-hidden="true">
                              &nbsp;
                            </i>
                          ))}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="content-p-map-o" id="p-map-o">
                  {showMap && (
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyBehIL8ya5JuiGrRO7PfHEy_4qmw2ldvw8",
                      }}
                      // defaultCenter={{ lat: 5.3287073, lng: -4.0018263 }}
                      defaultCenter={{
                        lat: Number(espaceData.geolatitude),
                        lng: Number(espaceData.geolongitude),
                      }}
                      distanceToMouse={distanceToMouse}
                      defaultZoom={15}
                    >
                      <MyMarker
                        key={1}
                        lat={Number(espaceData.geolatitude)}
                        lng={Number(espaceData.geolongitude)}
                        text={1}
                        tooltip={"title"}
                      />
                      {/* <Marker
                      key="marker_1"
                      position={{
                        lat: 5.348617,
                        lng: -4.0497055,
                      }}
                    /> */}
                    </GoogleMapReact>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal open={this.state.newCampagne} onClose={this.handleNewCampagne}>
          <Modal.Header>
            <Modal.Title>Vous démarrez une campagne publicitaire ?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col lg="12">
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="campagne" className="">
                      Nommer cette campagne
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className=""
                      id="campagne"
                      name="campagne"
                      onChange={this.handleChange}
                      placeholder=" "
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {this.state.campagne ? (
              <Button
                onClick={this.handleAddNewCampagne}
                appearance="primary"
                className="w-100"
              >
                Créer
              </Button>
            ) : (
              <Button
                onClick={this.handleAddNewCampagne}
                appearance="primary"
                className="w-100 bg-yellow"
                disabled
              >
                Créer
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.cartReducer;
  let addedWishItemsTmp = state.wishReducer;
  return {
    items: addedItemsTmp.addedItems,
    wish_items: addedWishItemsTmp.addedWishItems,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id) => {
      dispatch(addToCart(id));
    },
    removeItem: (id) => {
      dispatch(removeItem(id));
    },
    addToWish: (id) => {
      dispatch(addToWish(id));
    },
    removeWishItem: (id) => {
      dispatch(removeWishItem(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(single_espace);
