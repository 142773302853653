import axios from "axios";
import config from "./config";

export default {
  setReservation: (data) => {
    return axios.post(
      `${config.URL_END_POINT}/reservation`,
      data,
      config.headersConfig
    );
  },
  getUserReservation: (id) => {
    return axios.get(
      `${config.URL_END_POINT}/reservation/user/${id}/list`,
      config.headersConfig
    );
  },
  saveCart: (data) => {
    return axios.post(`${config.URL_END_POINT}/cart`, data);
  },
  getUserSavedCart: (id) => {
    return axios.get(`${config.URL_END_POINT}/cart/user/${id}`);
  },
  delUserCart: (id) => {
    return axios.delete(`${config.URL_END_POINT}/cart/${id}`);
  },
};
