import React, { Component } from "react";
import Slider from "react-slick";
import Hero from "../../components/partials/dashboard/Hero/hero-style-1";
// import SkeletonLoading from '../../components/skeletonLoading/skeleton-style-01'
import SkeletonLoading02 from "../../components/partials/components/skeletonLoading/skeleton-style-02";
import SkeletonLoading03 from "../../components/partials/components/skeletonLoading/skeleton-style-03";
import SkeletonLoading04 from "../../components/partials/components/skeletonLoading/skeleton-style-04";
import PanneauxItemStyle01 from "../../components/partials/components/panneauxItem-style-01";
import PanneauxItemStyle02 from "../../components/partials/components/panneauxItem-style-02";
import TestimomyItem from "../../components/partials/components/testimomyItem";

// picture importation
import img01 from "../../assets/images/icons/001.png";
import img02 from "../../assets/images/icons/002.png";
import img03 from "../../assets/images/icons/003.png";

// RestApi service import
import panneaux from "../../restApiService/panneaux";
import slider from "../../restApiService/slider";
import artere from "../../restApiService/artere";
import format from "../../restApiService/format";
import testimonies from "../../restApiService/testimonies";

class index extends Component {
  state = {
    bestPointIsLoading: true,
    arteres01IsLoading: true,
    arteres02IsLoading: true,
    arteres03IsLoading: true,
    arteres04IsLoading: true,
    arteres05IsLoading: true,
    arteres06IsLoading: true,
    arteres07IsLoading: true,
    arteres08IsLoading: true,
    formatViewIsLoading: true,
    testimonyViewIsLoading: true,
    sliderDataList: [],
    bestPointDataList: [],
    arteresDataList: [],
    arteresLayoutDataList: [],
    formatViewDataList: [],
    testimonyViewDataList: [],
  };

  getSliderDataList = () => {
    slider
      .getEntityList()
      .then((rep) => {
        this.setState({ sliderDataListList: rep.data });
      })
      .catch((error) => {});
  };
  getBestPointDataList = () => {
    panneaux
      .getBestPanneauxByRate()
      .then((rep) => {
        this.setState({ bestPointDataList: rep.data.slice(0, 10) });
        setTimeout(() => {
          this.setState({ bestPointIsLoading: false });
        }, 500);
      })
      .catch((error) => {});
  };
  getArteresDataList = () => {
    artere
      .getEntityList()
      .then((rep) => {
        artere.getArtereLayoutList().then((rep02) => {
          let top_left_layout = rep.data.filter(
            (list) => list.id === rep02.data[0].top_left_layout
          );
          let top_right_layout_top = rep.data.filter(
            (list) => list.id === rep02.data[0].top_right_layout_top
          );
          let top_right_layout_bottom = rep.data.filter(
            (list) => list.id === rep02.data[0].top_right_layout_bottom
          );
          let middle_left_layout_top = rep.data.filter(
            (list) => list.id === rep02.data[0].middle_left_layout_top
          );
          let middle_left_layout_bottom = rep.data.filter(
            (list) => list.id === rep02.data[0].middle_left_layout_bottom
          );
          let middle_right_layout = rep.data.filter(
            (list) => list.id === rep02.data[0].middle_right_layout
          );
          let bottom_left_layout = rep.data.filter(
            (list) => list.id === rep02.data[0].bottom_left_layout
          );
          let bottom_right_layout = rep.data.filter(
            (list) => list.id === rep02.data[0].bottom_right_layout
          );

          this.setState({
            arteresLayoutDataList: [
              ...this.state.arteresLayoutDataList,
              top_left_layout[0],
            ],
            arteres01IsLoading: top_left_layout[0].image != null ? false : true,
          });
          this.setState({
            arteresLayoutDataList: [
              ...this.state.arteresLayoutDataList,
              top_right_layout_top[0],
            ],
            arteres02IsLoading:
              top_right_layout_top[0].image != null ? false : true,
          });
          this.setState({
            arteresLayoutDataList: [
              ...this.state.arteresLayoutDataList,
              top_right_layout_bottom[0],
            ],
            arteres03IsLoading:
              top_right_layout_bottom[0].image != null ? false : true,
          });
          this.setState({
            arteresLayoutDataList: [
              ...this.state.arteresLayoutDataList,
              middle_left_layout_top[0],
            ],
            arteres04IsLoading:
              middle_left_layout_top[0].image != null ? false : true,
          });
          this.setState({
            arteresLayoutDataList: [
              ...this.state.arteresLayoutDataList,
              middle_left_layout_bottom[0],
            ],
            arteres05IsLoading:
              middle_left_layout_bottom[0].image != null ? false : true,
          });
          this.setState({
            arteresLayoutDataList: [
              ...this.state.arteresLayoutDataList,
              middle_right_layout[0],
            ],
            arteres06IsLoading:
              middle_right_layout[0].image != null ? false : true,
          });
          this.setState({
            arteresLayoutDataList: [
              ...this.state.arteresLayoutDataList,
              bottom_left_layout[0],
            ],
            arteres07IsLoading:
              bottom_left_layout[0].image != null ? false : true,
          });
          this.setState({
            arteresLayoutDataList: [
              ...this.state.arteresLayoutDataList,
              bottom_right_layout[0],
            ],
            arteres08IsLoading:
              bottom_right_layout[0].image != null ? false : true,
          });
        });
        // setTimeout(() => {
        //   this.setState({ bestPointIsLoading: false })
        // }, 500)
      })
      .catch((error) => {});
  };
  getFormatViewDataList = () => {
    format
      .getEntityPanneauxList()
      .then((rep) => {
        const filterDataToRemoveNoEspaceFormat = rep.data.filter(
          (format) => format.panneauxes?.length > 0
        );
        this.setState({ formatViewDataList: filterDataToRemoveNoEspaceFormat });
        setTimeout(() => {
          this.setState({ formatViewIsLoading: false });
        }, 500);
      })
      .catch((error) => {});
  };
  getTestimonyViewDataList = () => {
    testimonies
      .getEntityList()
      .then((rep) => {
        this.setState({ testimonyViewDataList: rep.data.slice(0, 10) });
        setTimeout(() => {
          this.setState({ testimonyViewIsLoading: false });
        }, 500);
      })
      .catch((error) => {});
  };

  renderArrows01 = () => {
    return (
      <div className="slider-arrow">
        <button
          className="arrow-btn prev"
          onClick={() => this.slider01.slickPrev()}
        >
          <svg
            width="32"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5 19L8.5 12L15.5 5"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button
          className="arrow-btn next"
          onClick={() => this.slider01.slickNext()}
        >
          <svg
            width="32"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 5L15.5 12L8.5 19"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    );
  };

  renderArrows02 = () => {
    return (
      <div className="slider-arrow">
        <button
          className="arrow-btn prev"
          onClick={() => this.slider02.slickPrev()}
        >
          <svg
            width="32"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5 19L8.5 12L15.5 5"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button
          className="arrow-btn next"
          onClick={() => this.slider02.slickNext()}
        >
          <svg
            width="32"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 5L15.5 12L8.5 19"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    );
  };

  componentDidMount = async () => {
    await this.getSliderDataList();
    await this.getBestPointDataList();
    await this.getArteresDataList();
    await this.getFormatViewDataList();
    await this.getTestimonyViewDataList();
  };

  render() {
    const FeaturesBox = () => {
      return (
        <section className="features-box">
          <div className="template-title has-over text-over-top text-center">
            <div className="container-title-box">
              <h2 className="title">Nymba</h2>
              <p className="subtitle">
                La plateforme Nymba est une solution de digitalisation du
                processus d’achat d’espace d’affichage urbain.
              </p>
            </div>
          </div>

          <div className="section-iconbox">
            <div className="container">
              <div className="row">
                <div className="col-4 col-md-4 col-sm-4 size-table">
                  <div className="iconbox text-center">
                    <div className="iconbox-icon">
                      <img src={img01} alt="" />
                    </div>
                    <div className="iconbox-content text-center">
                      <p>Rechercher Dispositif</p>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-md-4 col-sm-4 size-table">
                  <div className="iconbox text-center">
                    <div className="iconbox-icon">
                      <img src={img02} alt="" />
                    </div>
                    <div className="iconbox-content text-center">
                      <p>Choix Dispositif</p>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-md-4 col-sm-4 size-table">
                  <div className="iconbox text-center">
                    <div className="iconbox-icon">
                      <img src={img03} />
                    </div>
                    <div className="iconbox-content text-center">
                      <p>Réservation en Ligne</p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-3 col-sm-6 size-table">
                  <div className="iconbox text-center">
                    <div className="iconbox-icon">
                      <img
                        src="https://nymba.origin7.com/assets/images/iconbox/04.png"
                        alt=""
                      />
                    </div>
                    <div className="iconbox-content">
                      <p>Paiement Sécurisé</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      );
    };

    const { bestPointIsLoading, formatViewIsLoading, testimonyViewIsLoading } =
      this.state;
    // slide 01 settings
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      lazyLoad: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const settings02 = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      lazyLoad: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        <div className="admin-search-engine">
          <Hero
            sliderDataListList={this.state.sliderDataListList}
            props={this.props}
          />
          <FeaturesBox />
          <div className="section-1">
            <div className="container-fluid">
              <div className="block-title-box">
                <h2 class="block-title">
                  Les meilleurs emplacements du moment en Côte d'Ivoire
                </h2>
                {this.renderArrows01()}
              </div>

              {bestPointIsLoading ? (
                <SkeletonLoading02 />
              ) : (
                <Slider ref={(c) => (this.slider01 = c)} {...settings}>
                  {this.state.bestPointDataList.map((item, index) => (
                    <div>
                      <PanneauxItemStyle01 data={item} />
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </div>
          <div className="section-2">
            <div className="container-fluid">
              <h2 class="block-title">
                Affichez-vous sur les plus grandes artères
              </h2>
              <SkeletonLoading03
                data={this.state.arteresLayoutDataList}
                arteres01IsLoading={this.state.arteres01IsLoading}
                arteres02IsLoading={this.state.arteres02IsLoading}
                arteres03IsLoading={this.state.arteres03IsLoading}
                arteres04IsLoading={this.state.arteres04IsLoading}
                arteres05IsLoading={this.state.arteres05IsLoading}
                arteres06IsLoading={this.state.arteres06IsLoading}
                arteres07IsLoading={this.state.arteres07IsLoading}
                arteres08IsLoading={this.state.arteres08IsLoading}
              />
            </div>
          </div>
          <div className="section-3">
            <div className="container-fluid">
              <div className="block-title-box">
                <h2 class="block-title">
                  Sur tous types de formats d'affichage
                </h2>
                {this.renderArrows02()}
              </div>
              {formatViewIsLoading ? (
                <SkeletonLoading02 />
              ) : (
                <Slider ref={(c) => (this.slider02 = c)} {...settings}>
                  {this.state.formatViewDataList.map((item, index) => (
                    <div>
                      <PanneauxItemStyle02 data={item} />
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </div>
          <div className="section-4">
            <div className="container-fluid">
              <h2 class="block-title">Ce que disent les utilisateurs</h2>
              <p class="block-t-subtitle">
                Ne vous contentez pas de nous croire sur parole - consultez
                quelques-uns des derniers avis d'utilisateurs de Nymba et parlez
                nous aussi de votre expérience !!
              </p>
              {testimonyViewIsLoading ? (
                <SkeletonLoading04 />
              ) : (
                <Slider {...settings02}>
                  {this.state.testimonyViewDataList.map((item, index) => (
                    <div>
                      <TestimomyItem data={item} />
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default index;
