import React, { Component } from 'react'
import Item from './skeletonShema/skeletonLoading03'

export default class skeletonLoading extends Component {
  componentDidMount() {}
  render() {
    return (
      <div id="skeleton-04">
        <Item />
        <Item />
        <Item />
      </div>
    )
  }
}
