import React, { Component } from 'react'

export default class skeletonLoading extends Component {
  componentDidMount() {}
  render() {
    return (
      <>
        <div class="wrapper">
          <div class="element box item-03-01" data-id="0"></div>
          <div class="element box item-03-02" data-id="1"></div>
          <div class="element box item-03-03" data-id="2"></div>
          <div class="element box item-03-04" data-id="3"></div>
          <div class="element box item-03-05" data-id="4"></div>
          <div class="element circle item-03-06" data-id="5"></div>
          <div class="element box item-03-07" data-id="6"></div>
          <div class="element box item-03-08" data-id="7"></div>
        </div>
      </>
    )
  }
}
