import React, { Component } from "react";
import { Navbar, Dropdown, Container } from "react-bootstrap";
// Container,Dropdown,Button
import { Badge } from "rsuite";
import { Link } from "react-router-dom";
import CustomToggle from "../../../dropdowns";
// mobile-offcanvas
import MobildeOffcanvas from "../../components/mobile-offcanvas";
//Horizontal-nav
import HorizontalNav from "./horizontal-nav";

//img
import avatars1 from "../../../../assets/images/avatars/01.png";
import avatars2 from "../../../../assets/images/avatars/avtar_1.png";
import avatars3 from "../../../../assets/images/avatars/avtar_2.png";
import avatars4 from "../../../../assets/images/avatars/avtar_3.png";
import avatars5 from "../../../../assets/images/avatars/avtar_4.png";
import avatars6 from "../../../../assets/images/avatars/avtar_5.png";
// logo
import Logo from "../../components/logo-partner";
// const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));
class HeaderStyle1 extends Component {
  state = {
    bounceIn: false,
    bounceIn02: false,
  };
  logOut = () => {
    localStorage.removeItem("userInfo");
    setTimeout(() => {
      window.location.reload();
    }, 400);
  };

  componentDidUpdate(prevProps) {
    console.log("prevProps", prevProps);
    if (prevProps.items !== this.props.items) {
      console.log("new changes is detect");
      this.setState({ bounceIn: true });
      setTimeout(() => {
        this.setState({ bounceIn: false });
      }, 1000);
    }
    if (prevProps.wishItems !== this.props.wishItems) {
      console.log("new changes is detect");
      this.setState({ bounceIn02: true });
      setTimeout(() => {
        this.setState({ bounceIn02: false });
      }, 1000);
    }
  }

  render() {
    return (
      <>
        <div className="top-header-box container-fluid">
          <div className="top-header">
            <p>
              <i class="fa fa-phone" aria-hidden="true"></i> (+225) 07 08 53 55
              78
            </p>
            <div class="follow-us">
              <ul className="left-panel list-inline mb-0 p-0">
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/company/nymba/"
                    target="_blank"
                  >
                    <i class="fab fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://web.facebook.com/Nymba-106906621022811/?ref=page_internal"
                    target="_blank"
                  >
                    <i class="fab fa-facebook-f" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Navbar expand="xl" className="nav iq-navbar p-sticky">
          <Container fluid className="navbar-inner">
            <MobildeOffcanvas />
            <Navbar.Brand as="div" className="col-lg-2 col-lg-3 ">
              <p className="d-flex">
                <strong>{this.props?.regieName}</strong>
              </p>
            </Navbar.Brand>
            {/* <HorizontalNav /> */}
            <Navbar.Toggle aria-controls="navbarSupportedContent">
              <span className="navbar-toggler-icon">
                <span className="navbar-toggler-bar bar1 mt-2"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </span>
            </Navbar.Toggle>
            <Navbar.Collapse className="col-md-2" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <Dropdown as="li" className="nav-item mr-2 m-l-2">
                  <Link to="/search-engine/mon-panier">
                    <Badge
                      content={this.props.items.length}
                      className={this.state.bounceIn ? "bounceIn" : ""}
                    >
                      <svg
                        width="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.75 3.25L4.83 3.61L5.793 15.083C5.87 16.02 6.653 16.739 7.593 16.736H18.502C19.399 16.738 20.16 16.078 20.287 15.19L21.236 8.632C21.342 7.899 20.833 7.219 20.101 7.113C20.037 7.104 5.164 7.099 5.164 7.099"
                          stroke="#13367c"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.125 10.7949H16.898"
                          stroke="#13367c"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.15435 20.2026C7.45535 20.2026 7.69835 20.4466 7.69835 20.7466C7.69835 21.0476 7.45535 21.2916 7.15435 21.2916C6.85335 21.2916 6.61035 21.0476 6.61035 20.7466C6.61035 20.4466 6.85335 20.2026 7.15435 20.2026Z"
                          fill="currentColor"
                          stroke="#13367c"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M18.4346 20.2026C18.7356 20.2026 18.9796 20.4466 18.9796 20.7466C18.9796 21.0476 18.7356 21.2916 18.4346 21.2916C18.1336 21.2916 17.8906 21.0476 17.8906 20.7466C17.8906 20.4466 18.1336 20.2026 18.4346 20.2026Z"
                          fill="currentColor"
                          stroke="#13367c"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Badge>
                  </Link>
                </Dropdown>
              </ul>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default HeaderStyle1;
