export default {
  data: [
    { id: 43, title: 'Abengourou', frais_transport: '0' },
    { id: 12, title: 'Abidjan', frais_transport: '0' },
    { id: 31, title: 'Aboisso', frais_transport: '0' },
    { id: 32, title: 'Adiaké', frais_transport: '0' },
    { id: 47, title: 'Adzopé', frais_transport: '0' },
    { id: 46, title: 'Agboville', frais_transport: '0' },
    { id: 44, title: 'Agnibilekro', frais_transport: '0' },
    { id: 41, title: 'Anyama', frais_transport: '0' },
    { id: 21, title: 'Assinie', frais_transport: '0' },
    { id: 42, title: 'Attingué ', frais_transport: '0' },
    { id: 20, title: 'Bassam', frais_transport: '0' },
    { id: 64, title: 'Bingerville', frais_transport: '0' },
    { id: 45, title: 'Bondoukou', frais_transport: '0' },
    { id: 48, title: 'Bongouanou', frais_transport: '0' },
    { id: 37, title: 'Bonoua', frais_transport: '0' },
    { id: 50, title: 'Bouaflé', frais_transport: '0' },
    { id: 14, title: 'Bouaké', frais_transport: '0' },
    { id: 39, title: 'Dabou', frais_transport: '0' },
    { id: 18, title: 'Daloa', frais_transport: '0' },
    { id: 51, title: 'Daoukro', frais_transport: '0' },
    { id: 35, title: 'Dimbokro', frais_transport: '0' },
    { id: 52, title: 'Divo', frais_transport: '0' },
    { id: 53, title: 'Fresco', frais_transport: '0' },
    { id: 19, title: 'Gagnoa', frais_transport: '0' },
    { id: 40, title: 'Jacqueville', frais_transport: '0' },
    { id: 54, title: 'Katiola', frais_transport: '0' },
    { id: 33, title: 'Kong', frais_transport: '0' },
    { id: 16, title: 'Korhogo', frais_transport: '0' },
    { id: 55, title: 'Lakota', frais_transport: '0' },
    { id: 56, title: 'Méagui', frais_transport: '0' },
    { id: 65, title: "N'Zuanouan", frais_transport: '0' },
    { id: 57, title: 'Oumé', frais_transport: '0' },
    { id: 15, title: 'San-pédro', frais_transport: '0' },
    { id: 58, title: 'Sinfra', frais_transport: '0' },
    { id: 59, title: 'Soubré', frais_transport: '0' },
    { id: 60, title: 'Tiassalé', frais_transport: '0' },
    { id: 61, title: 'Tiébissou', frais_transport: '0' },
    { id: 62, title: 'Toumodi', frais_transport: '0' },
    { id: 13, title: 'Yamoussoukro', frais_transport: '0' },
  ],
}
