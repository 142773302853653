import React, { Component } from "react";
import Moment from "react-moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FsLightbox from "fslightbox-react";
import { Row, Col, Modal, Form, Button, Image } from "react-bootstrap";
import {
  Drawer,
  CheckboxGroup,
  Checkbox,
  Popover,
  Whisper,
  ButtonToolbar,
  Loader,
} from "rsuite";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import PageHeader from "../../../components/partials/dashboard/HeaderStyle/partner-page-header";
import SearchForm from "../../../components/partials/dashboard/SearchFormStyle/search-form-style-1";
import sep from "../../../components/partials/components/number-separator";
import Card from "../../../components/Card";

// services importation
import reservation from "../../../restApiService/reservation";

const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));

class slider extends Component {
  state = {
    item: [],
    entityDataTmp: [],
    FsLightboxData: [],
    entityData: [],
    selectEntityData: [],
    files: [],
    fileTmp: [],
    files_o: [],
    makeAction: false,
    actionType: "",
    searchTerm: "",
    show: false,
    show2: false,
    showFsLightbox: false,
    searchFormboxIsOpen: false,
  };

  handleClose = () => this.setState({ show: false });
  handleClose2 = () => this.setState({ show2: false });
  handleShow = () =>
    this.setState({ fileTmp: [], show: true, actionType: "add" });

  onDrop = (files) => {
    this.setState({
      files_o: files,
      files: files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  };

  getEntityData = () => {
    reservation
      .getEntityRegieList(currentUserInfo.regieId)
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          entityData: response.data,
          entityDataTmp: response.data,
        });

        if (response.data.length > 0) {
          this.setState({ selectEntityData: this.state.entityData[0] });
          for (let i = 0; i < response.data.length; i++) {
            this.setState({
              FsLightboxData: [
                ...this.state.FsLightboxData,
                response.data[i].file,
              ],
            });
          }
        } else {
          this.setState({
            FsLightboxData: [...this.state.FsLightboxData, response.data.file],
          });
        }
      })
      .catch((error) => console.log("first"));
  };

  selectEntity = (action, item) => {
    if (action === "read") {
      this.setState({
        selectEntityData: item,
        makeAction: false,
        actionType: "",
      });
    } else {
      this.setState({
        item: item,
        files: [item.file],
        show2: true,
        actionType: "update",
      });
    }
  };

  saveHandledData = () => {
    let item = { ...this.state.item };
    const promiseVar = toast.loading("Traitement en cours...");
    console.log(item);
    if (this.state.actionType === "add") {
      const formData = new FormData();
      formData.append("files", this.state.files_o[0]);
      formData.append("title", item.title);
      reservation
        .setEntity(formData)
        .then((response) => {
          this.componentDidMount();
          this.handleClose();
          toast.update(promiseVar, {
            render: "Opération éffectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) =>
          toast.update(promiseVar, {
            render: "Une erreur est susvenue",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        );
    } else {
      delete item["createdAt"];
      delete item["updatedAt"];
      const formData = new FormData();
      formData.append("files", this.state.files_o[0]);
      formData.append("title", item.title);
      reservation
        .updateEntityId(item.id, formData)
        .then((response) => {
          this.componentDidMount();
          this.handleClose2();
          toast.update(promiseVar, {
            render: "Opération éffectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) =>
          toast.update(promiseVar, {
            render: "Une erreur est susvenue",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        );
    }
  };

  removeItem = (id) => {
    const promiseVar = toast.loading("Traitement en cours...");
    reservation
      .deleteEntityId(id)
      .then((response) => {
        this.componentDidMount();
        // this.setState({ selectEntityData: this.state.entityData[0] })
        toast.update(promiseVar, {
          render: "Opération éffectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) =>
        toast.update(promiseVar, {
          render: "Une erreur est susvenue",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      );
  };

  componentDidMount() {
    this.getEntityData();
  }

  render() {
    const { entityData, searchFormboxIsOpen } = this.state;

    return (
      <div className="bg-grey-light">
        <PageHeader
          coloredText="Mes"
          normalText="campagnes"
          bgColor="#13367c"
          markerColor="#d24f3f"
        />
        <div className="container">
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">
                      Liste des reservations ({entityData.length}){" "}
                    </h4>
                  </div>
                  <div>
                    <button
                      className="ent-btn add-btn ent-btn-default ext-btn mt-lg-0 mt-md-0 mt-3 blue-btn"
                      onClick={() =>
                        this.setState({ searchFormboxIsOpen: true })
                      }
                    >
                      <i className="btn-inner">
                        <svg
                          width="25"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                      </i>
                      <span>Faire une campagne</span>
                    </button>
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                  <div className="table-responsive">
                    <table
                      id="user-list-table"
                      className="table table-striped"
                      role="grid"
                      data-toggle="data-table"
                    >
                      <thead>
                        <tr className="ligth">
                          <th className="text-l-box">Nom de la campagne</th>
                          <th>Période de reservation</th>
                          <th>Montant TTC</th>
                          <th>Status</th>
                          <th>Création</th>
                          <th min-width="100px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {entityData.map((item, idx) => (
                          <tr key={idx}>
                            <td className="text-l-box">{item.nom_campagne}</td>
                            <td>{item?.date_reservation}</td>
                            <td>{sep.separator(item?.totalTtc)}</td>
                            <td>
                              {item.etat === 0 && (
                                <span className={`badge bg-yellow`}>
                                  En attente
                                </span>
                              )}
                              {item.etat === 1 && (
                                <span className={`badge bg-success`}>
                                  Validée
                                </span>
                              )}
                            </td>
                            <td>
                              <Moment format="DD/MM/YYYY">
                                {item.createdAt}
                              </Moment>
                            </td>
                            <td>
                              <div className="flex align-items-center list-user-action">
                                <Link
                                  className="btn btn-sm btn-icon"
                                  to={
                                    "/partner/content/reservation/detail/" +
                                    item.id
                                  }
                                >
                                  <span className="btn-inner">
                                    <svg
                                      class="icon-32"
                                      width="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      {" "}
                                      <path
                                        d="M15.7161 16.2234H8.49609"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>{" "}
                                      <path
                                        d="M15.7161 12.0369H8.49609"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>{" "}
                                      <path
                                        d="M11.2521 7.86011H8.49707"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>{" "}
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M15.909 2.74976C15.909 2.74976 8.23198 2.75376 8.21998 2.75376C5.45998 2.77076 3.75098 4.58676 3.75098 7.35676V16.5528C3.75098 19.3368 5.47298 21.1598 8.25698 21.1598C8.25698 21.1598 15.933 21.1568 15.946 21.1568C18.706 21.1398 20.416 19.3228 20.416 16.5528V7.35676C20.416 4.57276 18.693 2.74976 15.909 2.74976Z"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>{" "}
                                    </svg>
                                  </span>
                                </Link>{" "}
                                <button
                                  className="btn btn-sm btn-icon"
                                  onClick={() => this.removeItem(item.id)}
                                >
                                  <span className="btn-inner">
                                    <svg
                                      width="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      stroke="currentColor"
                                    >
                                      <path
                                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M20.708 6.23975H3.75"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </span>
                                </button>{" "}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <Drawer
          size={"xs"}
          placement={"top"}
          open={searchFormboxIsOpen}
          onClose={() => this.setState({ searchFormboxIsOpen: false })}
          className="search-form-drawer admin-side"
        >
          <Drawer.Body>
            <div className="search-form-drawer-box">
              <SearchForm props={this.props} />
            </div>
          </Drawer.Body>
        </Drawer>
      </div>
    );
  }
}

export default slider;
