import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SkeletonLoading02 from "../../../components/partials/components/skeletonLoading/skeleton-style-02";
import PanneauxItemStyle01 from "../../../components/partials/components/panneauxItem-style-01";
// RestApi service import
import panneaux from "../../../restApiService/panneaux";
// picture importation
import img01 from "../../../assets/images/icons/checked-o.png";
import img02 from "../../../assets/images/icons/messenger.png";

class checkoutComplete extends Component {
  state = {
    bestPointIsLoading: true,
    bestPointDataList: [],
  };

  getBestPointDataList = () => {
    panneaux
      .getBestPanneauxByRate()
      .then((rep) => {
        this.setState({ bestPointDataList: rep.data.slice(0, 10) });
        setTimeout(() => {
          this.setState({ bestPointIsLoading: false });
        }, 500);
      })
      .catch((error) => {});
  };

  renderArrows01 = () => {
    return (
      <div className="slider-arrow">
        <button
          className="arrow-btn prev"
          onClick={() => this.slider01.slickPrev()}
        >
          <svg
            width="32"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5 19L8.5 12L15.5 5"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button
          className="arrow-btn next"
          onClick={() => this.slider01.slickNext()}
        >
          <svg
            width="32"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 5L15.5 12L8.5 19"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    );
  };

  componentDidMount() {
    this.getBestPointDataList();
  }

  render() {
    const { bestPointIsLoading } = this.state;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      lazyLoad: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        <div id="top"></div>
        <div className="end-of-checkout">
          <div className="end-of-content text-center">
            <img alt="" src={img01} />
            <p>Félicitations, vos panneaux ont été réservés !</p>
            <p>
              <Link
                className="btn btn-new-o blue-btn"
                to="/partner/content/reservation/panneau"
              >
                Lancer une nouvelle campagne
              </Link>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default checkoutComplete;
