import React, { Component } from "react";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";
import Logo from "../../../components/partials/components/logo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// RestApi service import
import auth from "../../../restApiService/auth";

// img
import facebook from "../../../assets/images/brands/fb.svg";
import google from "../../../assets/images/brands/gm.svg";
import instagram from "../../../assets/images/brands/im.svg";
import linkedin from "../../../assets/images/brands/li.svg";
import auth1 from "../../../assets/images/auth/01.png";
import {
  addUserInfos,
  removeUserInfos,
} from "../../../store/actions/userActions";

class SignIn extends Component {
  state = {
    item: [],
    handleLoginLoading: false,
  };

  handleChange = (event) => {
    console.log(event.target.name);
    let item = { ...this.state.item };
    item[event.target.name] = event.target.value;
    this.setState({ item });
  };

  handleLogin = () => {
    const promiseVar = toast.loading(
      "Recherche des information du client en cours..."
    );
    this.setState({ handleLoginLoading: true });
    const { item } = this.state;
    auth
      .signIn(item)
      .then((response) => {
        // localStorage.setItem("userInfo", JSON.stringify(response.data.user));
        this.props.addUserInfos(response.data.user);
        toast.update(promiseVar, {
          render: "Connexion effectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          this.setState({ handleLoginLoading: false });
          if (localStorage.getItem("checkout")) {
            window.location.href = "/#/app/mon-panier";
          } else {
            // window.location.href = '/'
            // console.log(response.data.user.role.title);
            if (
              response.data.user?.role?.title.toLowerCase() == "admin" ||
              response.data.user?.role?.title.toLowerCase() == "collaborateur"
            ) {
              // this.props.history.push("/admin/dashboard");
              window.location.href = "/#/admin/dashboard";
            } else if (
              response.data.user?.role?.title.toLowerCase() == "regie"
            ) {
              // this.props.history.push("/partner");
              window.location.href = "/#/partner";
            } else {
              window.location.href = "/#/";
              // this.props.history.push("/");
            }
          }
        }, 2000);
      })
      .catch((error) => {
        toast.update(promiseVar, {
          render: "Informations incorrectes",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        this.setState({ handleLoginLoading: false });
      });
  };

  render() {
    const { handleLoginLoading } = this.state;
    return (
      <>
        <section className="login-content">
          <Row className="m-0 align-items-center bg-white vh-100">
            <Col md="6">
              <Row className="justify-content-center">
                <Col md="10">
                  <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                    <Card.Body>
                      <Link
                        to="/app"
                        className="navbar-brand login d-flex align-items-center mb-3"
                      >
                        <Logo />
                      </Link>
                      <h2 className="mb-2">Se connecter</h2>
                      <p className="mb-4">
                        Connectez-vous afin de profiter des services Nymba en
                        toute simplicité.
                      </p>
                      <Form>
                        <Row>
                          <Col lg="12">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="email" className="">
                                Email
                              </Form.Label>
                              <Form.Control
                                type="email"
                                className=""
                                id="email"
                                name="email"
                                onChange={this.handleChange}
                                aria-describedby="email"
                                placeholder=" "
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="12" className="">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="password" className="">
                                Password
                              </Form.Label>
                              <Form.Control
                                type="password"
                                className=""
                                id="password"
                                name="password"
                                onChange={this.handleChange}
                                aria-describedby="password"
                                placeholder=" "
                              />
                              <Form.Control.Feedback tooltip type="invalid">
                                Please provide a valid zip.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col
                            lg="12"
                            className="d-flex justify-content-between"
                          >
                            <Form.Check className="form-check mb-3">
                              <Form.Check.Input
                                type="checkbox"
                                id="customCheck1"
                              />
                              <Form.Check.Label htmlFor="customCheck1">
                                Se souvenir de moi
                              </Form.Check.Label>
                            </Form.Check>
                            <Link to="/auth/recoverpw">
                              Mot de passe oublié ?
                            </Link>
                          </Col>
                        </Row>
                        <div className="d-flex justify-content-center">
                          {handleLoginLoading ? (
                            <Button variant="btn btn-yellow w-100" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Connexion...
                            </Button>
                          ) : (
                            <Button
                              onClick={this.handleLogin}
                              type="button"
                              variant="btn btn-yellow w-100"
                            >
                              Se connecter
                            </Button>
                          )}
                        </div>
                        {/* <p className="text-center my-3">
                          or sign in with other accounts?
                        </p>
                        <div className="d-flex justify-content-center">
                          <ListGroup
                            as="ul"
                            className="list-group-horizontal list-group-flush"
                          >
                            <ListGroup.Item as="li" className="border-0 pb-0">
                              <Link to="#">
                                <Image src={facebook} alt="fb" />
                              </Link>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="border-0 pb-0">
                              <Link to="#">
                                <Image src={google} alt="gm" />
                              </Link>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="border-0 pb-0">
                              <Link to="#">
                                <Image src={instagram} alt="im" />
                              </Link>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="border-0 pb-0">
                              <Link to="#">
                                <Image src={linkedin} alt="li" />
                              </Link>
                            </ListGroup.Item>
                          </ListGroup>
                        </div> */}
                        <p className="mt-3 text-center">
                          Vous n'avez pas encore de compte?
                        </p>
                        <p className="text-center mt-0">
                          <Link to="/auth/sign-up" className="text-underline">
                            Créer un compte
                          </Link>
                        </p>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {/* <div className="sign-bg">
                <svg
                  width="280"
                  height="230"
                  viewBox="0 0 431 398"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.05">
                    <rect
                      x="-157.085"
                      y="193.773"
                      width="543"
                      height="77.5714"
                      rx="38.7857"
                      transform="rotate(-45 -157.085 193.773)"
                      fill="#3B8AFF"
                    />
                    <rect
                      x="7.46875"
                      y="358.327"
                      width="543"
                      height="77.5714"
                      rx="38.7857"
                      transform="rotate(-45 7.46875 358.327)"
                      fill="#3B8AFF"
                    />
                    <rect
                      x="61.9355"
                      y="138.545"
                      width="310.286"
                      height="77.5714"
                      rx="38.7857"
                      transform="rotate(45 61.9355 138.545)"
                      fill="#3B8AFF"
                    />
                    <rect
                      x="62.3154"
                      y="-190.173"
                      width="543"
                      height="77.5714"
                      rx="38.7857"
                      transform="rotate(45 62.3154 -190.173)"
                      fill="#3B8AFF"
                    />
                  </g>
                </svg>
              </div> */}
            </Col>
            <Col
              md="6"
              className="d-md-block d-none bg-yellow p-0 mt-n1 vh-100 overflow-hidden"
            >
              <Image
                // src={auth1}
                src="https://nymba-files.s3.us-west-2.amazonaws.com/Photo%202.jpg"
                className="Image-fluid gradient-main animated-scaleX"
                alt="images"
              />
            </Col>
          </Row>
        </section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.userInfoReducer;
  return {
    items: addedItemsTmp.addedUserInfos,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addUserInfos: (item) => {
      dispatch(addUserInfos(item));
    },
    removeUserInfos: (item) => {
      dispatch(removeUserInfos(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
