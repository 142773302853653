export default {
  data: [
    { id: 15, title: '12m²' },
    { id: 19, title: '64m²' },
    { id: 23, title: '24m²' },
    { id: 26, title: '108m²' },
    { id: 33, title: '2410m²' },
    { id: 34, title: '2032m²' },
    { id: 36, title: '1205m²' },
    { id: 37, title: '1016m²' },
    { id: 38, title: '60m²' },
    { id: 39, title: '120m²' },
    { id: 40, title: '36m²' },
    { id: 41, title: '81m²' },
    { id: 42, title: '200m²' },
    { id: 43, title: '160m²' },
    { id: 44, title: '40m²' },
    { id: 45, title: '18m²' },
  ],
}
