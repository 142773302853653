import React, { Component } from "react";
import Slider from "react-slick";
import SearchForm from "../SearchFormStyle/search-form-style-1";
import Loader from "../../components/loader";
import SkeletonStyle05 from "../../components/skeletonLoading/skeleton-style-05";

class hero extends Component {
  state = {
    isLoading: true,
  };
  componentWillMount() {}

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }

  render() {
    const settings = {
      fade: true,
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      lazyLoad: true,
      autoplaySpeed: 3000,
    };
    const { isLoading } = this.state;
    const { sliderDataListList } = this.props;
    return (
      <>
        {/* <Loader /> */}
        {/* <SkeletonLoading /> */}

        <div className="hero-section">
          {isLoading ? (
            <SkeletonStyle05 />
          ) : (
            <>
              <div className="overlay">&nbsp;</div>
              <Slider {...settings}>
                {sliderDataListList?.map((item, index) => (
                  <div key={index}>
                    <img alt="" typeof="foaf:Image" src={item?.file} />
                  </div>
                ))}
              </Slider>
              <div class="container">
                <div class="wel-box">
                  <h1>Nymba, affichez-vous!</h1>
                  <p>
                    Trouvez en un clic les meilleurs espaces d'affichage aux
                    meilleurs prix.
                  </p>
                </div>
              </div>
            </>
          )}

          <SearchForm props={this.props.props} />
        </div>
      </>
    );
  }
}

export default hero;
