import React, { Component } from 'react'
import Item from './skeletonShema/skeletonLoading02'
import ArtereLayoutItem from '../artereLayoutItem'

export default class skeletonLoading extends Component {
  state = {}
  componentDidMount() {}
  render() {
    const {
      arteres01IsLoading,
      arteres02IsLoading,
      arteres03IsLoading,
      arteres04IsLoading,
      arteres05IsLoading,
      arteres06IsLoading,
      arteres07IsLoading,
      arteres08IsLoading,
    } = this.props

    return (
      <div className="container-fluid">
        <div class="g-block-1">
          <div class="row">
            <div class="col-md-8 cols-1">
              {arteres01IsLoading ? (
                <Item />
              ) : (
                <ArtereLayoutItem data={this.props.data[0]} />
              )}
            </div>
            <div class="col-md-4  cols-1-1">
              {arteres02IsLoading ? (
                <Item />
              ) : (
                <ArtereLayoutItem data={this.props.data[1]} />
              )}

              {arteres03IsLoading ? (
                <Item />
              ) : (
                <ArtereLayoutItem data={this.props.data[2]} />
              )}
            </div>
          </div>
        </div>

        <div class="g-block-2">
          <div class="row">
            <div class="col-md-4 cols-2">
              {arteres04IsLoading ? (
                <Item />
              ) : (
                <ArtereLayoutItem data={this.props.data[3]} />
              )}
              {arteres05IsLoading ? (
                <Item />
              ) : (
                <ArtereLayoutItem data={this.props.data[4]} />
              )}
            </div>
            <div class="col-md-8 cols-2-0">
              {arteres06IsLoading ? (
                <Item />
              ) : (
                <ArtereLayoutItem data={this.props.data[5]} />
              )}
            </div>
          </div>
        </div>

        <div class="g-block-3">
          <div class="row">
            <div class="col-md-6 cols-3">
              {arteres07IsLoading ? (
                <Item />
              ) : (
                <ArtereLayoutItem data={this.props.data[6]} />
              )}
            </div>
            <div class="col-md-6 cols-4">
              {arteres08IsLoading ? (
                <Item />
              ) : (
                <ArtereLayoutItem data={this.props.data[7]} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
