import Moment from "react-moment";
import { Row, Col, Form, Button } from "react-bootstrap";

const componentItem = (props) => {
  return (
    <>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Nom prénom</p>
        <p class="entityInfoDetails">
          {props.selectEntityData?.firstname +
            " " +
            props.selectEntityData?.lastname}
        </p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Email</p>
        <p class="entityInfoDetails">{props.selectEntityData?.email}</p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Téléphone</p>
        <p class="entityInfoDetails">{props.selectEntityData?.phone}</p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Adresse</p>
        <p class="entityInfoDetails">{props.selectEntityData?.localisation}</p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Description</p>
        <p class="entityInfoDetails">{props.selectEntityData?.description}</p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Date de création</p>
        <p class="entityInfoDetails">
          <Moment format="DD/MM/YYYY">
            {props.selectEntityData?.createdAt}
          </Moment>
        </p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Date de modification</p>
        <p class="entityInfoDetails">
          <Moment format="DD/MM/YYYY">
            {props.selectEntityData?.updatedAt}
          </Moment>
        </p>
      </div>
    </>
  );
};

export default componentItem;
