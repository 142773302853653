export default {
  data: [
    {
      id: 490,
      extClientId: 88,
      sousTotal: 300000,
      tva: 54000,
      tsp: 10620,
      odp: 36000,
      tm: 36000,
      totalTtc: 490620,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: null,
      end: null,
      prix_extra: 0,
      transport: '0',
      nom_campagne: '',
      etat: 'unlock',
    },
    {
      id: 491,
      extClientId: 55,
      sousTotal: 200000,
      tva: 36000,
      tsp: 7080,
      odp: 24000,
      tm: 24000,
      totalTtc: 327080,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: null,
      end: null,
      prix_extra: 0,
      transport: '0',
      nom_campagne: '',
      etat: 'unlock',
    },
    {
      id: 492,
      extClientId: 55,
      sousTotal: 0,
      tva: 0,
      tsp: 0,
      odp: 0,
      tm: 0,
      totalTtc: 0,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: null,
      end: null,
      prix_extra: 0,
      transport: '0',
      nom_campagne: '',
      etat: '',
    },
    {
      id: 493,
      extClientId: 55,
      sousTotal: 0,
      tva: 0,
      tsp: 0,
      odp: 0,
      tm: 0,
      totalTtc: 0,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: null,
      end: null,
      prix_extra: 0,
      transport: '0',
      nom_campagne: '',
      etat: 'unlock',
    },
  ],
}
