import Moment from "react-moment";
import { Row, Col, Form, Button } from "react-bootstrap";

const componentItem = (props) => {
  return (
    <>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Nom prénom</p>
        <p class="entityInfoDetails">
          {props.selectEntityData?.firstname +
            " " +
            props.selectEntityData?.lastname}
        </p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Email</p>
        <p class="entityInfoDetails">{props.selectEntityData?.email}</p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Téléphone</p>
        <p class="entityInfoDetails">{props.selectEntityData?.phone}</p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Adresse</p>
        <p class="entityInfoDetails">{props.selectEntityData?.localisation}</p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Curriculum vitae (CV)</p>
        <p class="entityInfoDetails">
          {props.selectEntityData?.cv ? (
            <a href={props.selectEntityData?.cv} target="_blank">
              Télécharger
            </a>
          ) : (
            "--"
          )}
        </p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Lettre de motivation</p>
        <p class="entityInfoDetails">
          {props.selectEntityData?.lm ? (
            <a href={props.selectEntityData?.lm} target="_blank">
              Télécharger
            </a>
          ) : (
            "--"
          )}
        </p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Date de création</p>
        <p class="entityInfoDetails">
          <Moment format="DD/MM/YYYY">
            {props.selectEntityData?.createdAt}
          </Moment>
        </p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Date de modification</p>
        <p class="entityInfoDetails">
          <Moment format="DD/MM/YYYY">
            {props.selectEntityData?.updatedAt}
          </Moment>
        </p>
      </div>
    </>
  );
};

export default componentItem;
