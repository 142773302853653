import Moment from "react-moment";
import { Row, Col, Form, Button } from "react-bootstrap";

const componentItem = (props) => {
  return (
    <>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Nom complet</p>
        <p class="entityInfoDetails">{props.selectEntityData?.nom_prenom}</p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Fonction</p>
        <p class="entityInfoDetails">
          {props.selectEntityData?.fonction_temoignage}
        </p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Temoignage</p>
        <p class="entityInfoDetails">{props.selectEntityData?.message}</p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Date de création</p>
        <p class="entityInfoDetails">
          <Moment format="DD/MM/YYYY">
            {props.selectEntityData?.createdAt}
          </Moment>
        </p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Date de modification</p>
        <p class="entityInfoDetails">
          <Moment format="DD/MM/YYYY">
            {props.selectEntityData?.updatedAt}
          </Moment>
        </p>
      </div>
    </>
  );
};

export default componentItem;
