import React from "react";
import { Link } from "react-router-dom";

const ArtereLayoutItem = (props) => {
  // console.log(props);
  return (
    <div class="content-commune-home">
      <div class="img-commune-home">
        <Link to={"/app/espaces/artere/" + props.data?.slug}>
          <img alt="" typeof="foaf:Image" src={props.data?.image} />
        </Link>
      </div>
      <div class="title-link-commune-home">
        <div class="title-commune-home">
          <span>&nbsp;</span>
          <Link to={"/app/espaces/artere/" + props.data?.slug}>
            {props.data.title}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ArtereLayoutItem;
