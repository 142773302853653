import Dropzone from "react-dropzone";

const componentItem = (props) => {
  console.log(props);
  const files = props.files.map((file) => (
    <li key={file.name}>
      <div className="thumb">
        <div className="thumbInner">
          <img
            src={file.preview ?? file}
            className="thumbInner-img"
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview ?? file);
            }}
            alt=""
          />
        </div>
        {file.name} - {file.size} {file.size && "bytes"}
      </div>
    </li>
  ));
  const files02 = props.files02.map((file) => (
    <li key={file.name}>
      <div className="thumb">
        <div className="thumbInner">
          <img
            src={file.preview ?? file}
            className="thumbInner-img"
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview ?? file);
            }}
            alt=""
          />
        </div>
        {file.name} - {file.size} {file.size && "bytes"}
      </div>
    </li>
  ));
  return (
    <>
      <div className="update-form">
        <div className="entityCardAddForm">
          <div class="dropzone--box">
            <p className="mt-4">
              <strong>
                Ajouter au maximum 2 images pour votre espace d'affichage
              </strong>
            </p>
            <div className="row">
              <div className="col-md-6">
                {!props.fileEdited01 && (
                  <div className="old-img">
                    <img
                      src={props?.storagefile01?.image}
                      alt=""
                      className="storagefile-img"
                    />
                    <button onClick={() => props.filesEdited(1)}>
                      <svg
                        class="icon-32"
                        width="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.7476 20.4428H21.0002"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.78 3.79479C13.5557 2.86779 14.95 2.73186 15.8962 3.49173C15.9485 3.53296 17.6295 4.83879 17.6295 4.83879C18.669 5.46719 18.992 6.80311 18.3494 7.82259C18.3153 7.87718 8.81195 19.7645 8.81195 19.7645C8.49578 20.1589 8.01583 20.3918 7.50291 20.3973L3.86353 20.443L3.04353 16.9723C2.92866 16.4843 3.04353 15.9718 3.3597 15.5773L12.78 3.79479Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11.021 6.00098L16.4732 10.1881"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </button>
                  </div>
                )}
                {props.fileEdited01 && (
                  <div className="dropping-box">
                    <Dropzone onDrop={props.onDrop}>
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzonebox">
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <p>
                              Faites glisser et déposez des fichiers ici, ou
                              cliquez pour sélectionner des fichiers.
                            </p>
                          </div>
                          <aside>
                            <ul>{files}</ul>
                          </aside>
                        </section>
                      )}
                    </Dropzone>
                    <button onClick={() => props.filesEdited(1)}>
                      <svg
                        class="icon-32"
                        width="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="M14.3955 9.59497L9.60352 14.387"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                        <path
                          d="M14.3971 14.3898L9.60107 9.59277"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                      </svg>
                    </button>
                  </div>
                )}
              </div>
              <div className="col-md-6">
                {!props.fileEdited02 && (
                  <div className="old-img">
                    <img
                      src={props?.storagefile02?.image}
                      alt=""
                      className="storagefile-img"
                    />
                    <button onClick={() => props.filesEdited(2)}>
                      <svg
                        class="icon-32"
                        width="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.7476 20.4428H21.0002"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.78 3.79479C13.5557 2.86779 14.95 2.73186 15.8962 3.49173C15.9485 3.53296 17.6295 4.83879 17.6295 4.83879C18.669 5.46719 18.992 6.80311 18.3494 7.82259C18.3153 7.87718 8.81195 19.7645 8.81195 19.7645C8.49578 20.1589 8.01583 20.3918 7.50291 20.3973L3.86353 20.443L3.04353 16.9723C2.92866 16.4843 3.04353 15.9718 3.3597 15.5773L12.78 3.79479Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11.021 6.00098L16.4732 10.1881"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </button>
                  </div>
                )}
                {props.fileEdited02 && (
                  <div className="dropping-box">
                    <Dropzone onDrop={props.onDrop02}>
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzonebox">
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <p>
                              Faites glisser et déposez des fichiers ici, ou
                              cliquez pour sélectionner des fichiers.
                            </p>
                          </div>
                          <aside>
                            <ul>{files02}</ul>
                          </aside>
                        </section>
                      )}
                    </Dropzone>
                    <button onClick={() => props.filesEdited(2)}>
                      <svg
                        class="icon-32"
                        width="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="M14.3955 9.59497L9.60352 14.387"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                        <path
                          d="M14.3971 14.3898L9.60107 9.59277"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default componentItem;
