import Dropzone from "react-dropzone";

const componentItem = (props) => {
  // console.log(props);
  const files = props.files.map((file) => (
    <li key={file.name}>
      <div className="thumb">
        <div className="thumbInner">
          <img
            src={file.preview ?? file}
            className="thumbInner-img"
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview ?? file);
            }}
            alt=""
          />
        </div>
        {file.name} - {file.size} {file.size && "bytes"}
      </div>
    </li>
  ));
  return (
    <>
      <div className="update-form">
        <div className="entityCardAddForm">
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Titre</p>
            <p class="entityInfoDetails">
              <input
                className="form-control"
                name="title"
                value={props.item?.title}
                onChange={props.handleChange}
              />
            </p>
          </div>
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Taux de fréquentation</p>
            <p class="entityInfoDetails">
              <input
                className="form-control"
                name="frequentation"
                value={props.item?.frequentation}
                onChange={props.handleChange}
              />
            </p>
          </div>
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Pays</p>
            <p class="entityInfoDetails">
              <select
                className="form-control"
                name="countryId"
                value={props.item?.countryId}
                onChange={props.handleChange}
              >
                <option>Sélectionnez...</option>
                {props.countryList?.map((item, index) => (
                  <option value={item.id}> {item.title} </option>
                ))}
              </select>
            </p>
          </div>
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Image</p>
            <p class="entityInfoDetails">
              <Dropzone onDrop={props.onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzonebox">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <p>
                        Faites glisser et déposez des fichiers ici, ou cliquez
                        pour sélectionner des fichiers.
                      </p>
                    </div>
                    <aside>
                      <ul>{files}</ul>
                    </aside>
                  </section>
                )}
              </Dropzone>
            </p>
          </div>
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Disposition</p>
          </div>

          <div class="entityCardInfos">
            <p class="entityInfoDetails">
              <ul className="layout-selection-box">
                <li>
                  <button
                    className={
                      props.layoutItemTmp?.top_left_layout === props.item.id
                        ? "layout-selected"
                        : ""
                    }
                    onClick={() =>
                      props.layoutSettingForUpdate("top_left_layout")
                    }
                  >
                    <img src={props.row1lc1} alt="" />
                  </button>
                  <p>Position 01</p>
                </li>
                <li>
                  <button
                    className={
                      props.layoutItemTmp?.top_right_layout_top ===
                      props.item.id
                        ? "layout-selected"
                        : ""
                    }
                    onClick={() =>
                      props.layoutSettingForUpdate("top_right_layout_top")
                    }
                  >
                    <img src={props.row1rc1} alt="" />
                  </button>
                  <p>Position 02</p>
                </li>
                <li>
                  <button
                    className={
                      props.layoutItemTmp?.top_right_layout_bottom ===
                      props.item.id
                        ? "layout-selected"
                        : ""
                    }
                    onClick={() =>
                      props.layoutSettingForUpdate("top_right_layout_bottom")
                    }
                  >
                    <img src={props.row1rc2} alt="" />
                  </button>
                  <p>Position 03</p>
                </li>
                <li>
                  <button
                    className={
                      props.layoutItemTmp?.middle_left_layout_top ===
                      props.item.id
                        ? "layout-selected"
                        : ""
                    }
                    onClick={() =>
                      props.layoutSettingForUpdate("middle_left_layout_top")
                    }
                  >
                    <img src={props.row2lc1} alt="" />
                  </button>
                  <p>Position 04</p>
                </li>
                <li>
                  <button
                    className={
                      props.layoutItemTmp?.middle_left_layout_bottom ===
                      props.item.id
                        ? "layout-selected"
                        : ""
                    }
                    onClick={() =>
                      props.layoutSettingForUpdate("middle_left_layout_bottom")
                    }
                  >
                    <img src={props.row2lc2} alt="" />
                  </button>
                  <p>Position 05</p>
                </li>
                <li>
                  <button
                    className={
                      props.layoutItemTmp?.middle_right_layout === props.item.id
                        ? "layout-selected"
                        : ""
                    }
                    onClick={() =>
                      props.layoutSettingForUpdate("middle_right_layout")
                    }
                  >
                    <img src={props.row2rc1} alt="" />
                  </button>
                  <p>Position 06</p>
                </li>
                <li>
                  <button
                    className={
                      props.layoutItemTmp?.bottom_left_layout === props.item.id
                        ? "layout-selected"
                        : ""
                    }
                    onClick={() =>
                      props.layoutSettingForUpdate("bottom_left_layout")
                    }
                  >
                    <img src={props.row3lc1} alt="" />
                  </button>
                  <p>Position 07</p>
                </li>
                <li>
                  <button
                    className={
                      props.layoutItemTmp?.bottom_right_layout === props.item.id
                        ? "layout-selected"
                        : ""
                    }
                    onClick={() =>
                      props.layoutSettingForUpdate("bottom_right_layout")
                    }
                  >
                    <img src={props.row3rc1} alt="" />
                  </button>
                  <p>Position 08</p>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default componentItem;
