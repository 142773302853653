import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import Hero from "../../components/partials/dashboard/Hero/partner-hero-style-1";
// import SkeletonLoading from '../../components/skeletonLoading/skeleton-style-01'

import { addUserInfos, removeUserInfos } from "../../store/actions/userActions";
// RestApi service import
import panneaux from "../../restApiService/panneaux";
import reservationLine from "../../restApiService/reservation_line";
import exClient from "../../restApiService/extClient";
// const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));
const currentDate = new Date();

class index extends Component {
  state = {
    entityCount: 0,
    entityInProgressCount: 0,
    entityAllReservateCount: 0,
    regieClientCount: 0,
    bestPointIsLoading: true,
    arteres01IsLoading: true,
    arteres02IsLoading: true,
    arteres03IsLoading: true,
    arteres04IsLoading: true,
    arteres05IsLoading: true,
    arteres06IsLoading: true,
    arteres07IsLoading: true,
    arteres08IsLoading: true,
    formatViewIsLoading: true,
    testimonyViewIsLoading: true,
    bestPointDataList: [],
    arteresDataList: [],
    arteresLayoutDataList: [],
    formatViewDataList: [],
    testimonyViewDataList: [],
  };

  getEntityRegieCount = (regieId) => {
    panneaux
      .entityRegieCount(regieId)
      .then((req) => {
        console.log("req.data", req.data);
        this.setState({ entityCount: req.data.count });
        // setTimeout(() => {
        //   this.setState({ bestPointIsLoading: false });
        // }, 500);
      })
      .catch((error) => {});
  };

  getEntityInProgressRegieCount = (regieId) => {
    let body = {
      regieId: regieId,
      date: moment(currentDate).format("DD/MM/YYYY"),
    };
    panneaux
      .entityReserverListCountByRegieIdAndDate(body)
      .then((req) => {
        console.log("req.data", req.data);
        this.setState({ entityInProgressCount: req.data.count });
        // setTimeout(() => {
        //   this.setState({ bestPointIsLoading: false });
        // }, 500);
      })
      .catch((error) => {});
  };

  getEntityAllReservateRegieCount = (regieId) => {
    reservationLine
      .getEntityRegieId(regieId)
      .then((req) => {
        this.setState({ entityAllReservateCount: req.data.count });
      })
      .catch((error) => {});
  };

  exClientRegieCount = (regieId) => {
    exClient
      .getEntityByRegieId(regieId)
      .then((req) => {
        this.setState({ regieClientCount: req.data.length });
      })
      .catch((error) => {});
  };

  componentDidMount = () => {
    this.getEntityRegieCount(this.props.currentUserInfo.regieId);
    this.getEntityInProgressRegieCount(this.props.currentUserInfo.regieId);
    this.getEntityAllReservateRegieCount(this.props.currentUserInfo.regieId);
    this.exClientRegieCount(this.props.currentUserInfo.regieId);
  };

  render() {
    const {
      entityCount,
      entityInProgressCount,
      entityAllReservateCount,
      regieClientCount,
    } = this.state;
    const FeaturesBox = () => {
      return (
        <section className="features-box">
          <div className="section-countbox">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="stat-content">
                    <div className="row">
                      <div className="col-6 col-md-4 col-sm-6 size-table">
                        <div className="countbox text-center">
                          <div className="count-box">
                            <span>{entityCount}</span>
                          </div>
                          <div className="countbox-content">
                            <p>Espaces d'affichage</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-sm-6 size-table">
                        <div className="countbox text-center">
                          <div className="count-box">
                            <span>{entityCount - entityInProgressCount}</span>
                          </div>
                          <div className="countbox-content">
                            <p>Espaces disponibles</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-sm-6 size-table">
                        <div className="countbox text-center">
                          <div className="count-box">
                            <span>{entityInProgressCount}</span>
                          </div>
                          <div className="countbox-content">
                            <p>Espaces en cours de campagne</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-sm-6 size-table">
                        <div className="countbox text-center">
                          <div className="count-box">
                            <span> {entityAllReservateCount} </span>
                          </div>
                          <div className="countbox-content">
                            <p>Total de campagne</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-sm-6 size-table">
                        <div className="countbox text-center">
                          <div className="count-box">
                            <span> {regieClientCount} </span>
                          </div>
                          <div className="countbox-content">
                            <p>Nombre de clients</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-sm-6 size-table">
                        <div className="countbox text-center">
                          <div className="count-box">
                            <span>{entityAllReservateCount}</span>
                          </div>
                          <div className="countbox-content">
                            <p>Nombre total de campagnes</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12">
                      <Link
                        to="/partner/content/panneau"
                        className="lg-btn bg-blue d-flex align-items-center"
                      >
                        <svg
                          class="icon-32"
                          width="54"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.33 16.5928H4.0293"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                          <path
                            d="M13.1406 6.90042H19.4413"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.72629 6.84625C8.72629 5.5506 7.66813 4.5 6.36314 4.5C5.05816 4.5 4 5.5506 4 6.84625C4 8.14191 5.05816 9.19251 6.36314 9.19251C7.66813 9.19251 8.72629 8.14191 8.72629 6.84625Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20.0002 16.5538C20.0002 15.2581 18.9429 14.2075 17.6379 14.2075C16.3321 14.2075 15.2739 15.2581 15.2739 16.5538C15.2739 17.8494 16.3321 18.9 17.6379 18.9C18.9429 18.9 20.0002 17.8494 20.0002 16.5538Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                        </svg>
                        <div>
                          Gérer mes espaces{" "}
                          <span>
                            Assurer la gestion de vos espaces en un clic
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-12">
                      <Link
                        to="/partner/content/reservation/panneau"
                        className="lg-btn bg-blue d-flex align-items-center"
                      >
                        <svg
                          class="icon-32"
                          width="54"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.7161 16.2234H8.49609"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M15.7161 12.0369H8.49609"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M11.2521 7.86011H8.49707"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.909 2.74976C15.909 2.74976 8.23198 2.75376 8.21998 2.75376C5.45998 2.77076 3.75098 4.58676 3.75098 7.35676V16.5528C3.75098 19.3368 5.47298 21.1598 8.25698 21.1598C8.25698 21.1598 15.933 21.1568 15.946 21.1568C18.706 21.1398 20.416 19.3228 20.416 16.5528V7.35676C20.416 4.57276 18.693 2.74976 15.909 2.74976Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        <div>
                          Campagnes en cours{" "}
                          <span>Découvrez toutes vos campagnes en cours</span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    };

    return (
      <>
        <div className="admin-search-engine">
          <Hero props={this.props} />
          <FeaturesBox />
          {/* <div className="section-1">
            <div className="container-fluid">
              <div className="block-title-box"></div>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.userInfoReducer;
  return {
    currentUserInfo: addedItemsTmp.addedUserInfos,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addUserInfos: (item) => {
      dispatch(addUserInfos(item));
    },
    removeUserInfos: (item) => {
      dispatch(removeUserInfos(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
