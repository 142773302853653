import React, { Component } from "react";
import {
  Tooltip,
  Popover,
  Whisper,
  Button,
  ButtonToolbar,
  SelectPicker,
  Table,
  Pagination,
  Loader,
  Input,
  InputGroup,
  CheckPicker,
} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import emailjs from "@emailjs/browser";
import { Row, Col, Image, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";

// img
// import shap1 from '../../../assets/images/shapes/01.png'
// import shap2 from '../../../assets/images/shapes/02.png'
// import shap3 from '../../../assets/images/shapes/03.png'
// import shap4 from '../../../assets/images/shapes/04.png'
// import shap5 from '../../../assets/images/shapes/05.png'
// import shap6 from '../../../assets/images/shapes/06.png'

// services importation
import account from "../../../restApiService/account";
import role from "../../../restApiService/role";
import auth from "../../../restApiService/auth";
import regie from "../../../restApiService/regie";

class customer_list extends Component {
  state = {
    item: [],
    regieList: [],
    regieSelectList: [],
    entityData: [],
    entityDataTmp: [],
    selectEntityData: [],
    roleList: [],
    roleSelectList: [],
    actionType: "",
    selectEntityID: 0,
    show: false,
    show1: false,
    show2: false,
    show3: false,
    espaceDataIsLoading: true,
    pageNumber: 1,
    limit: 20,
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  };

  handleChangeLimit = (dataKey) => {
    this.setState({ pageNumber: 1, limit: dataKey });
  };

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  handleClose1 = () => this.setState({ show1: false });
  handleShow1 = () => this.setState({ show1: true, actionType: "add" });

  handleClose2 = () => this.setState({ show2: false });
  // const handleShow2 = () => setShow2(true);

  handleClose3 = () => this.setState({ show3: false });

  getRoleListData = () => {
    role
      .getEntityList()
      .then((response) => {
        this.setState({
          roleList: response.data,
          roleSelectList: response.data.map((item) => ({
            label: item.title,
            value: item.id,
          })),
        });
      })
      .catch((error) => console.log("first"));
  };

  getEntityData = () => {
    this.setState({ espaceDataIsLoading: true });
    account
      .getAccountList()
      .then((response) => {
        this.setState({
          entityData: response.data,
          entityDataTmp: response.data,
        });
        this.setState({ espaceDataIsLoading: false });
      })
      .catch((error) => console.log("first"));
  };

  getRegieData = () => {
    regie
      .getEntityList()
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          regieList: response.data,
          regieSelectList: response.data.map((item) => ({
            label: item.title,
            value: item.id,
          })),
        });
      })
      .catch((error) => console.log("error"));
  };

  sendEmail(body) {
    emailjs
      .send("service_4u97vhv", "template_s0j5669", body, "PTs8PLzHh25JCWT8F")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  saveHandledData = async () => {
    let item = { ...this.state.item };
    const promiseVar = toast.loading("Traitement en cours...");
    if (this.state.actionType === "add") {
      item["civility"] = "M";
      item["roleId"] = 1;

      let toSend = {
        name: item.firstname + " " + item.lastname,
        one_time_login_url: "http://localhost:8004/auth/sign-in",
        email: item.email,
        password: "7Ky7A44*1VCv",
      };

      await auth
        .signUp(item)
        .then((response) => {
          this.componentDidMount();
          this.sendEmail(toSend);
          this.setState({
            selectEntityData: this.state.entityDataTmp.filter(
              (items) => items.UserId === item.UserId
            ),
            makeAction: false,
            actionType: "",
            show1: false,
          });
          toast.update(promiseVar, {
            render: "Opération éffectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) =>
          toast.update(promiseVar, {
            render: "Une erreur est susvenue",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        );
    } else {
      delete item["password"];
      delete item["createdAt"];
      delete item["updatedAt"];

      account
        .updateAccount(item.id, item)
        .then((response) => {
          this.componentDidMount();
          this.setState({
            selectEntityData: item,
            show2: false,
            actionType: "",
          });
          toast.update(promiseVar, {
            render: "Opération éffectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) =>
          toast.update(promiseVar, {
            render: "Une erreur est susvenue",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        );
    }
  };

  selectEntity = (action, item) => {
    console.log("item", item);
    if (action === "read") {
      this.setState({
        selectEntityData: item,
        makeAction: false,
        actionType: "",
      });
    } else {
      this.setState({ item: item, show2: true, actionType: "update" });

      setTimeout(() => {
        console.log("item text", item);
      }, 200);
    }
  };

  handleOpenPwdModal = (id) =>
    this.setState({ show3: true, selectEntityID: id });

  updatedUserPassword = () => {
    let item = { ...this.state.item };
    const promiseVar = toast.loading("Traitement en cours...");
    let passwordData = {
      new_password: item.new_password,
    };
    auth
      .updateUserPasswordByAdmin(this.state.selectEntityID, passwordData)
      .then((req) => {
        this.handleClose3();
        toast.update(promiseVar, {
          render: "Opération éffectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  handleSearch = (event) => {
    console.log("event.target.value", event.target.value);
    console.log("this.state.entityDataTmp", this.state.entityDataTmp);
    if (event.target.value.length >= 2) {
      this.setState({
        entityData: this.state.entityDataTmp.filter(
          (item) =>
            item?.firstname
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            item?.lastname
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            // item?.phone
            //   .toLowerCase()
            //   .includes(event.target.value.toLowerCase()) ||
            item?.email
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            item?.country?.title
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
        ),
      });
    }
    if (event.target.value.length === 0) {
      this.setState({
        entityData: this.state.entityDataTmp,
      });
    }
  };

  handleSearchByRole = (event) => {
    this.setState({
      entityData: [],
    });
    if (event.length >= 1) {
      this.setState({
        entityData: this.state.entityData.filter((person) =>
          event.includes(person.roleId)
        ),
      });
    }
    if (event.length === 0) {
      this.setState({
        entityData: this.state.entityDataTmp,
      });
    }
  };

  handleSearchByRegie = (event) => {
    this.setState({
      entityData: [],
    });
    if (event.length >= 1) {
      this.setState({
        entityData: this.state.entityData.filter((person) =>
          event.includes(person.regieId)
        ),
      });
    }
    if (event.length === 0) {
      this.setState({
        entityData: this.state.entityDataTmp,
      });
    }
  };

  removeItem = (id) => {
    const promiseVar = toast.loading("Traitement en cours...");
    account
      .deleteAccount(id)
      .then((response) => {
        this.componentDidMount();
        // this.setState({ selectEntityData: this.state.entityData[0] })
        toast.update(promiseVar, {
          render: "Opération éffectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) =>
        toast.update(promiseVar, {
          render: "Une erreur est susvenue",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      );
  };

  componentDidMount() {
    this.getEntityData();
    this.getRoleListData();
    this.getRegieData();
  }

  render() {
    const { Column, HeaderCell, Cell } = Table;
    const {
      item,
      entityData,
      selectEntityData,
      roleList,
      roleSelectList,
      espaceDataIsLoading,
      regieSelectList,
      pageNumber,
      limit,
    } = this.state;
    const data = entityData.filter((v, i) => {
      const start = limit * (pageNumber - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    const triggerRef = React.createRef();
    const close = () => triggerRef.current.close();
    const pwdTooltip = (
      <Tooltip>
        <i>Modifier le mot de passe</i>
      </Tooltip>
    );
    const delSpeaker = (
      <Popover
        title={
          "Vous êtes sûr de supprimer " +
          selectEntityData.firstname +
          " " +
          selectEntityData.lastname +
          " ?"
        }
      >
        <p className="text-right">
          <button className="ent-btn ent-btn-default mr-1" onClick={close}>
            Non
          </button>
          <button
            className="ent-btn ent-btn-default bg-red-l"
            onClick={() => {
              this.removeItem(item.id ?? selectEntityData.id);
              close();
            }}
          >
            Supprimer
          </button>
        </p>
      </Popover>
    );
    return (
      <>
        <div>
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">
                      Liste des clients ({entityData.length}){" "}
                    </h4>
                  </div>
                  <div>
                    <button
                      // className="text-center btn-primary btn-icon mt-lg-0 mt-md-0 mt-3"
                      className="ent-btn add-btn ent-btn-default ext-btn mt-lg-0 mt-md-0 mt-3"
                      onClick={() => this.handleShow1("add")}
                    >
                      <i className="btn-inner">
                        <svg
                          width="25"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                      </i>
                      <span>Ajouter un client</span>
                    </button>
                    <Modal show={this.state.show1} onHide={this.handleClose1}>
                      <Modal.Header closeButton>
                        <Modal.Title>Nouveau client</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group className="mb-3">
                          <Form.Label>Nom</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastname"
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Prénom</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstname"
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Contact</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone"
                            autocomplete="new-password"
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Role</Form.Label>
                          <select
                            className="form-control"
                            name="roleId"
                            onChange={this.handleChange}
                          >
                            <option>Sélectionnez...</option>
                            {this.state.roleList.map((item, index) => (
                              <option value={item.id}> {item.title}</option>
                            ))}
                          </select>
                        </Form.Group>
                        {Number(item?.roleId) === 2 && (
                          <Form.Group className="mb-3">
                            <Form.Label>Regie publicitaire</Form.Label>
                            <select
                              className="form-control"
                              name="regieId"
                              value={item?.regieId}
                              onChange={this.handleChange}
                            >
                              <option>Sélectionnez...</option>
                              {this.state.regieList?.map((item, index) => (
                                <option value={item.id}> {item.title} </option>
                              ))}
                            </select>
                          </Form.Group>
                        )}
                        <Form.Group className="mb-3">
                          <Form.Label>Mot de passe</Form.Label>
                          <Form.Control
                            type="password"
                            name="password"
                            autocomplete="new-password"
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        {/* <Form.Group controlId="formBasicPassword">
                          <Form.Label>status</Form.Label>
                        </Form.Group> */}
                        {/* <Form.Select
                          aria-label="Default select example"
                          className="mb-3"
                        >
                          <option></option>
                          <option value="first">Activé</option>
                          <option value="second">Bloqué</option>
                        </Form.Select> */}
                        <button
                          className=" ent-btn add-btn ent-btn-default ext-btn w-100 h-60"
                          onClick={this.saveHandledData}
                        >
                          Créer
                        </button>{" "}
                        {/* <Button variant="danger" onClick={this.handleClose1}>
                          Annuler
                        </Button> */}
                      </Modal.Body>
                    </Modal>
                    <Modal show={this.state.show2} onHide={this.handleClose2}>
                      <Modal.Header closeButton>
                        <Modal.Title>Editier les infos du client</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group className="mb-3">
                          <Form.Label>Nom</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastname"
                            value={item.lastname}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Prénom</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstname"
                            value={item.firstname}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            value={item.email}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Contact</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone"
                            value={item.phone}
                            autocomplete="new-password"
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Role </Form.Label>
                          <select
                            className="form-control"
                            name="roleId"
                            value={item?.roleId}
                            onChange={this.handleChange}
                          >
                            <option>Sélectionnez...</option>
                            {this.state.roleList.map((item, index) => (
                              <option value={item.id}> {item.title}</option>
                            ))}
                          </select>
                        </Form.Group>
                        {Number(item?.roleId) === 2 && (
                          <Form.Group className="mb-3">
                            <Form.Label>Regie publicitaire</Form.Label>
                            <select
                              className="form-control"
                              name="regieId"
                              value={item?.regieId}
                              onChange={this.handleChange}
                            >
                              <option>Sélectionnez...</option>
                              {this.state.regieList?.map((item, index) => (
                                <option value={item.id}> {item.title} </option>
                              ))}
                            </select>
                          </Form.Group>
                        )}
                        {/* <Form.Group controlId="formBasicPassword">
                          <Form.Label>status</Form.Label>
                        </Form.Group> */}
                        {/* <Form.Select
                          aria-label="Default select example"
                          className="mb-3"
                          value={item.lastname}
                        >
                          <option></option>
                          <option value="first">Activé</option>
                          <option value="second">Bloqué</option>
                        </Form.Select> */}
                        <button
                          className=" ent-btn add-btn ent-btn-default ext-btn w-100 h-60"
                          onClick={this.saveHandledData}
                        >
                          Modifier
                        </button>
                      </Modal.Body>
                    </Modal>
                    <Modal show={this.state.show3} onHide={this.handleClose3}>
                      <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="text-center">
                          <svg
                            width="164"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.334 2.75H7.665C4.644 2.75 2.75 4.889 2.75 7.916V16.084C2.75 19.111 4.635 21.25 7.665 21.25H16.333C19.364 21.25 21.25 19.111 21.25 16.084V7.916C21.25 4.889 19.364 2.75 16.334 2.75Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.6889 11.9999C10.6889 13.0229 9.85986 13.8519 8.83686 13.8519C7.81386 13.8519 6.98486 13.0229 6.98486 11.9999C6.98486 10.9769 7.81386 10.1479 8.83686 10.1479H8.83986C9.86086 10.1489 10.6889 10.9779 10.6889 11.9999Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10.6919 12H17.0099V13.852"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14.1816 13.852V12"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <Form.Group
                            className="mb-4 mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>
                              Entrez le nouveau mot de passe
                            </Form.Label>
                            <Form.Control
                              type="password"
                              name="new_password"
                              autocomplete="new-password"
                              onChange={this.handleChange}
                            />
                          </Form.Group>

                          <button
                            className="formBasicPassword ent-btn add-btn ent-btn-default ext-btn w-100 h-60"
                            onClick={this.updatedUserPassword}
                          >
                            Modifier le mot de passe
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                  <div className="filter-box">
                    <div className="row">
                      <div className="col-md-6">
                        <InputGroup
                          size="lg"
                          style={{ margin: 10 }}
                          onChange={this.handleSearch}
                        >
                          <Input placeHolder="Trier par Nom, Prénom, Email, Pays..." />
                          <InputGroup.Addon>
                            <SearchIcon />
                          </InputGroup.Addon>
                        </InputGroup>
                      </div>
                      <div className="col-md-3">
                        <CheckPicker
                          placeholder="Trier pas role..."
                          data={roleSelectList}
                          onChange={(e) => this.handleSearchByRole(e)}
                          // style={{ width: 224 }}
                        />
                      </div>
                      <div className="col-md-3">
                        <CheckPicker
                          placeholder="Trier pas régie..."
                          data={regieSelectList}
                          onChange={(e) => this.handleSearchByRegie(e)}
                          // style={{ width: 224 }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <Table
                      height={520}
                      data={data}
                      loading={espaceDataIsLoading}
                    >
                      <Column width={230} fixed>
                        <HeaderCell>Nom</HeaderCell>
                        <Cell>
                          {(rowData) =>
                            rowData?.firstname + " " + rowData?.lastname
                          }
                        </Cell>
                      </Column>

                      <Column width={250}>
                        <HeaderCell>Email</HeaderCell>
                        <Cell dataKey="email" />
                      </Column>

                      <Column width={150}>
                        <HeaderCell>Contact</HeaderCell>
                        <Cell dataKey="phone" />
                      </Column>

                      <Column width={150}>
                        <HeaderCell>Pays</HeaderCell>
                        <Cell>{(rowData) => rowData.country?.title}</Cell>
                      </Column>

                      <Column width={50} flexGrow={1}>
                        <HeaderCell>Role</HeaderCell>
                        <Cell>{(rowData) => rowData.role?.title}</Cell>
                      </Column>

                      <Column width={150} flexGrow={1}>
                        <HeaderCell>Création</HeaderCell>
                        <Cell>
                          {(rowData) => (
                            <Moment format="DD/MM/YYYY">
                              {rowData.createdAt}
                            </Moment>
                          )}
                        </Cell>
                      </Column>

                      <Column width={200} flexGrow={1}>
                        <HeaderCell></HeaderCell>
                        <Cell>
                          {(rowData) => (
                            <div className="flex align-items-center list-user-action">
                              <button
                                className="btn btn-sm btn-icon"
                                onClick={() => {
                                  this.selectEntity("update", rowData);
                                }}
                              >
                                <svg
                                  width="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                  <path
                                    d="M15.1655 4.60254L19.7315 9.16854"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>
                              </button>{" "}
                              <Whisper
                                placement="top"
                                trigger="hover"
                                controlId="control-id-hover-enterable"
                                speaker={pwdTooltip}
                                enterable
                              >
                                <button
                                  className="btn btn-sm btn-icon"
                                  onClick={() =>
                                    this.handleOpenPwdModal(rowData.id)
                                  }
                                >
                                  <svg
                                    width="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M16.334 2.75H7.665C4.644 2.75 2.75 4.889 2.75 7.916V16.084C2.75 19.111 4.635 21.25 7.665 21.25H16.333C19.364 21.25 21.25 19.111 21.25 16.084V7.916C21.25 4.889 19.364 2.75 16.334 2.75Z"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M10.6889 11.9999C10.6889 13.0229 9.85986 13.8519 8.83686 13.8519C7.81386 13.8519 6.98486 13.0229 6.98486 11.9999C6.98486 10.9769 7.81386 10.1479 8.83686 10.1479H8.83986C9.86086 10.1489 10.6889 10.9779 10.6889 11.9999Z"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M10.6919 12H17.0099V13.852"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M14.1816 13.852V12"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              </Whisper>{" "}
                              <Whisper
                                placement="top"
                                trigger="click"
                                ref={triggerRef}
                                controlId="control-id-hover-enterable"
                                speaker={delSpeaker}
                                enterable
                              >
                                <button
                                  className="btn btn-sm btn-icon"
                                  onClick={() =>
                                    this.selectEntity("read", rowData)
                                  }
                                >
                                  <svg
                                    width="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    stroke="currentColor"
                                  >
                                    <path
                                      d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M20.708 6.23975H3.75"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </button>
                              </Whisper>
                            </div>
                          )}
                        </Cell>
                      </Column>
                    </Table>
                    <div style={{ padding: 20 }}>
                      <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={["total", "-", "limit", "|", "pager", "skip"]}
                        total={entityData.length}
                        limitOptions={[10, 30, 50]}
                        limit={limit}
                        activePage={pageNumber}
                        onChangePage={(e) => this.setState({ pageNumber: e })}
                        onChangeLimit={this.handleChangeLimit}
                      />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default customer_list;
