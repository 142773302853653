import axios from "axios";
import config from "./config";
const prefixPoint = "carousel_layout";
export default {
  setEntity: (data) => {
    return axios.post(
      `${config.URL_END_POINT}/${prefixPoint}/`,
      data,
      config.headersConfig
    );
  },
  getEntityList: () => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/list`,
      config.headersConfig
    );
  },
  getEntityId: (id) => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/${id}`,
      config.headersConfig
    );
  },
  getEntityBySlug: (slug) => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/slug/${slug}`,
      config.headersConfig
    );
  },
  updateEntityId: (id, data) => {
    return axios.put(
      `${config.URL_END_POINT}/${prefixPoint}/update/${id}`,
      data
    );
  },
  deleteEntityId: (id) => {
    return axios.delete(`${config.URL_END_POINT}/${prefixPoint}/delete/${id}`);
  },
};
