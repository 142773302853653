import axios from "axios";
import config from "./config";
const prefixPoint = "reference";
export default {
  setEntity: (data) => {
    return axios.post(`${config.URL_END_POINT}/${prefixPoint}/`, data);
  },
  getEntityList: () => {
    return axios.get(`${config.URL_END_POINT}/${prefixPoint}/list`);
  },
  getEntityId: (id) => {
    return axios.get(`${config.URL_END_POINT}/${prefixPoint}/${id}`);
  },
  getEntityBySlug: (slug) => {
    return axios.get(`${config.URL_END_POINT}/${prefixPoint}/slug/${slug}`);
  },
  updateEntityId: (id) => {
    return axios.put(`${config.URL_END_POINT}/${prefixPoint}/${id}`);
  },
  deleteEntityId: (id) => {
    return axios.delete(`${config.URL_END_POINT}/${prefixPoint}/${id}`);
  },
};
