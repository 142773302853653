import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
// loader
import Loader from "../../components/Loader";

//headerstyle1
import HeaderStyle1 from "../../components/partials/dashboard/HeaderStyle/partner-header-style-1";

//footer
import Footer from "../../components/partials/dashboard/FooterStyle/partner-footer-style-2";

//default
import HorizontalRouter from "../../router/partner-router";

// store
import {
  NavbarstyleAction,
  getDirMode,
  getcustomizerMode,
  getcustomizerprimaryMode,
  getcustomizerinfoMode,
  SchemeDirAction,
  ColorCustomizerAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "../../store/setting/setting";

import { connect } from "react-redux";

const mapStateToProps = (state) => {
  console.log("state", state);
  let addedItemsTmp = state.cartReducer;
  let addedWishItemsTmp = state.wishReducer;
  return {
    items: addedItemsTmp.addedItems,
    wishItems: addedWishItemsTmp.addedWishItems,
    darkMode: getDarkMode(state),
    customizerMode: getcustomizerMode(state),
    cololrinfomode: getcustomizerinfoMode(state),
    colorprimarymode: getcustomizerprimaryMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
      ColorCustomizerAction,
    },
    dispatch
  ),
});

const Horizontal = (props) => {
  useEffect(() => {
    //   darkmode
    const colorMode = sessionStorage.getItem("color-mode");
    if (colorMode === null) {
      props.ModeAction(props.darkMode);
    } else {
      props.ModeAction(colorMode);
    }
    // colocustomizermode
    const colorcustomizerMode = sessionStorage.getItem("color-customizer-mode");
    const colorcustomizerinfoMode = sessionStorage.getItem(
      "colorcustominfo-mode"
    );
    const colorcustomizerprimaryMode = sessionStorage.getItem(
      "colorcustomprimary-mode"
    );
    if (colorcustomizerMode === null) {
      props.ColorCustomizerAction(
        props.customizerMode,
        props.cololrinfomode,
        props.colorprimarymode
      );
      document.documentElement.style.setProperty(
        "--bs-info",
        props.cololrinfomode
      );
    } else {
      props.ColorCustomizerAction(
        colorcustomizerMode,
        colorcustomizerinfoMode,
        colorcustomizerprimaryMode
      );
      document.documentElement.style.setProperty(
        "--bs-info",
        colorcustomizerinfoMode
      );
    }
    // rtlmode
    const rtlMode = sessionStorage.getItem("rtl-mode");
    if (rtlMode === null) {
      props.SchemeDirAction(props.schemeDirMode);
    } else {
      props.SchemeDirAction(rtlMode);
    }
  });
  return (
    <>
      <Loader />
      <main className="main-content partner-side">
        <HeaderStyle1
          history={props}
          items={props.items}
          wishItems={props.wishItems}
        />
        <HorizontalRouter history={props} />
        <Footer />
      </main>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Horizontal);
