const componentItem = (props) => {
  return (
    <>
      <div className="entityControl">
        {props.actionType !== "" && (
          <button
            className="ent-btn ent-btn-default back-btn"
            onClick={props.selectPreviousEntity}
          >
            Précédent
          </button>
        )}
        {props.actionType != 5 && (
          <button
            className="ent-btn add-btn ent-btn-default ext-btn blue-btn"
            // disabled={props.valueFieldIsEmpty ? true : false}
            onClick={props.selectNextEntity}
          >
            Continuer
          </button>
        )}
        {props.actionType == 5 && (
          <button
            className="ent-btn add-btn ent-btn-default ext-btn red-btn"
            onClick={props.saveHandledData}
          >
            Enregister
          </button>
        )}
      </div>
    </>
  );
};

export default componentItem;
