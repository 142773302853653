import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment";
import { Drawer, CheckboxGroup, Checkbox, Loader } from "rsuite";
import controllable from "react-controllables";
import { Row, Col, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchForm from "../../../components/partials/dashboard/SearchFormStyle/search-form-style-1";

import ville from "../../../restApiService/ville";
import artere from "../../../restApiService/artere";
import commune from "../../../restApiService/commune";
import format from "../../../restApiService/format";
import type_artere from "../../../restApiService/type-artere";
import modele from "../../../restApiService/modele";
import reference from "../../../restApiService/reference";
import searchRequest from "../../../restApiService/searchRequest";
import "../../../assets/css/searchresults.css";
controllable(["center", "zoom", "markers"]);

const SideDataList = React.lazy(() => import("./components/sideDataList"));
const MapViewer = React.lazy(() => import("./components/mapViewer"));

class search_results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      villeData: [],
      villeListData: [],
      artereData: [],
      communeData: [],
      communeListData: [],
      formatData: [],
      formatListData: [],
      typeArtereListData: [],
      modeleListData: [],
      referenceListData: [],
      espaceDataIsLoading: true,
      espaceDataList: [],
      espaceDataListTmp: [],
      requestEspaceDataListTmp: [],
      espaceDataListForMap: [],
      searchData: [],
      filterboxIsOpen: false,
      searchFormboxIsOpen: false,
      searchType: "",
      title: "",
      afterLoop: false,
      showMap: false,
      MapLoading: false,
      oneViewSelected: false,
      oneViewSelectedId: 0,
      defaultlat: 0,
      defaultlng: 0,
      alreadySearch: false,
    };
  }

  handleChange = (name, value) => {
    let searchData = { ...this.state.searchData };
    console.log(name);
    console.log(value);

    if (name === "villeId") {
      if (searchData.villeId) {
        let find = searchData.villeId.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.villeId.splice(find, 1);
        } else {
          console.log("searchData.villeId", searchData.villeId.length);
          searchData["villeId"].push(value);
        }
      } else {
        searchData["villeId"] = [value];
      }
    }

    if (name === "communeId") {
      if (searchData.communeId) {
        let find = searchData.communeId.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.communeId.splice(find, 1);
        } else {
          console.log("searchData.communeId", searchData.communeId.length);
          searchData["communeId"].push(value);
        }
      } else {
        searchData["communeId"] = [value];
      }
    }

    if (name === "typeArtereId") {
      if (searchData.typeArtereId) {
        let find = searchData.typeArtereId.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.typeArtereId.splice(find, 1);
        } else {
          console.log(
            "searchData.typeArtereId",
            searchData.typeArtereId.length
          );
          searchData["typeArtereId"].push(value);
        }
      } else {
        searchData["typeArtereId"] = [value];
      }
    }

    if (name === "formatId") {
      if (searchData.formatId) {
        let find = searchData.formatId.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.formatId.splice(find, 1);
        } else {
          console.log("searchData.formatId", searchData.formatId.length);
          searchData["formatId"].push(value);
        }
      } else {
        searchData["formatId"] = [value];
      }
    }

    if (name === "eclairage") {
      if (searchData.eclairage) {
        let find = searchData.eclairage.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.eclairage.splice(find, 1);
        } else {
          console.log("searchData.eclairage", searchData.eclairage.length);
          searchData["eclairage"].push(value);
        }
      } else {
        searchData["eclairage"] = [value];
      }
    }

    if (name === "modeleId") {
      if (searchData.modeleId) {
        let find = searchData.modeleId.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.modeleId.splice(find, 1);
        } else {
          console.log("searchData.modeleId", searchData.modeleId.length);
          searchData["modeleId"].push(value);
        }
      } else {
        searchData["modeleId"] = [value];
      }
    }

    if (name === "positionAxe") {
      if (searchData.positionAxe) {
        let find = searchData.positionAxe.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.positionAxe.splice(find, 1);
        } else {
          console.log("searchData.positionAxe", searchData.positionAxe.length);
          searchData["positionAxe"].push(value);
        }
      } else {
        searchData["positionAxe"] = [value];
      }
    }

    if (name === "rate") {
      if (searchData.rate) {
        let find = searchData.rate.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.rate.splice(find, 1);
        } else {
          console.log("searchData.rate", searchData.rate.length);
          searchData["rate"].push(value);
        }
      } else {
        searchData["rate"] = [value];
      }
    }

    if (name === "referenceId") {
      if (searchData.referenceId) {
        let find = searchData.referenceId.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.referenceId.splice(find, 1);
        } else {
          console.log("searchData.referenceId", searchData.referenceId.length);
          searchData["referenceId"].push(value);
        }
      } else {
        searchData["referenceId"] = [value];
      }
    }

    console.log("searchData", searchData);
    this.SearchRequestAnnexe(searchData);
    this.setState({ searchData });
  };

  OneViewSelected = (espaceId) => {
    console.log(espaceId);
    this.setState({ MapLoading: true, oneViewSelectedId: espaceId });
    let espaceDataListTmp = [...this.state.espaceDataListTmp];

    this.setState({ espaceDataListForMap: espaceDataListTmp });

    setTimeout(() => {
      let filteredList = this.state.espaceDataListForMap.filter(
        (item) => item.id === espaceId
      );
      this.setState({
        oneViewSelected: true,
        espaceDataListForMap: filteredList,
        defaultlat: Number(filteredList[0].geolatitude),
        defaultlng: Number(filteredList[0].geolongitude),
      });
    }, 400);
    setTimeout(() => {
      this.setState({ MapLoading: false });
    }, 500);
  };

  ResetOneViewSelected = () => {
    this.setState({
      MapLoading: true,
    });
    setTimeout(() => {
      this.setState({
        oneViewSelectedId: 0,
        MapLoading: false,
        oneViewSelected: false,
        espaceDataListForMap: this.state.espaceDataListTmp,
        defaultlat: Number(this.state.espaceDataListTmp[0].geolatitude),
        defaultlng: Number(this.state.espaceDataListTmp[0].geolongitude),
      });
    }, 500);
  };

  handleChangeDate = (event) => {
    let searchData = { ...this.state.searchData };
    if (event != null) {
      const startDate = moment(event[0]);
      const endDate = moment(event[1]);
      searchData["start"] = new Date(event[0]).getTime();
      searchData["end"] = new Date(event[1]).getTime();
      console.log(searchData);
      let range = endDate.diff(startDate, "days");
      searchData["range"] = range;
      searchData["durationTmp"] = range;
      localStorage.setItem("duration", range);
      this.setState({ searchData });
      setTimeout(() => {
        if (range <= 16) {
          toast.info(
            "Choisissez une période supérieure à 15 jours et inférieure à 1 an",
            {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }, 500);
    }
  };

  SearchRequest = (data) => {
    let searchData = { ...this.state.searchData };
    if (
      localStorage.getItem("end") &&
      localStorage.getItem("range") &&
      localStorage.getItem("start")
    ) {
      searchData["end"] = localStorage.getItem("end");
      searchData["range"] = localStorage.getItem("range");
      searchData["start"] = localStorage.getItem("start");
      this.setState({ searchData });
    }

    searchRequest
      .panneauxSearchEngine(searchData)
      .then((req) => {
        let filterDataToRemoveNoEspaceLngLat = req.data.filter(
          (item) =>
            isNaN(Number(item.geolatitude)) === false &&
            isNaN(Number(item.geolongitude)) === false
        );

        if (filterDataToRemoveNoEspaceLngLat.length > 0) {
          this.setState({
            espaceDataList: filterDataToRemoveNoEspaceLngLat,
            espaceDataListTmp: filterDataToRemoveNoEspaceLngLat,
            espaceDataListForMap: filterDataToRemoveNoEspaceLngLat,
            defaultlat: Number(filterDataToRemoveNoEspaceLngLat[0].geolatitude),
            defaultlng: Number(
              filterDataToRemoveNoEspaceLngLat[0].geolongitude
            ),
          });
          setTimeout(() => {
            this.setState({
              showMap: true,
            });
          }, 1500);
        }

        // setTimeout(() => {

        // }, 500)
        setTimeout(() => {
          this.setState({
            afterLoop:
              filterDataToRemoveNoEspaceLngLat.length === 0 ? true : false,
            espaceDataIsLoading: false,
          });
        }, 1500);
        setTimeout(() => {
          this.setState({ MapLoading: false });
        }, 2000);
      })
      .catch((error) => {});
  };

  AddSearchRequestAnnexeData = () => {
    this.setState({
      espaceDataIsLoading: true,
      showMap: false,
      MapLoading: false,
      espaceDataList: this.state.requestEspaceDataListTmp,
      espaceDataListTmp: this.state.requestEspaceDataListTmp,
      espaceDataListForMap: this.state.requestEspaceDataListTmp,
      defaultlat: Number(this.state.requestEspaceDataListTmp[0].geolatitude),
      defaultlng: Number(this.state.requestEspaceDataListTmp[0].geolongitude),
    });

    setTimeout(() => {
      this.setState({
        filterboxIsOpen: false,
        showMap: true,
        afterLoop:
          this.state.requestEspaceDataListTmp.length === 0 ? true : false,
        espaceDataIsLoading: false,
      });
    }, 1500);
    setTimeout(() => {
      this.setState({ MapLoading: false });
    }, 2000);
  };

  SearchRequestAnnexe = (data) => {
    const filtered = Object.entries(data).reduce(
      (a, [k, v]) => (v.length === 0 ? a : ((a[k] = v), a)),
      {}
    );
    searchRequest
      .panneauxSearchEngine(filtered)
      .then((req) => {
        let filterDataToRemoveNoEspaceLngLat = req.data.filter(
          (item) =>
            isNaN(Number(item.geolatitude)) === false &&
            isNaN(Number(item.geolongitude)) === false
        );

        if (filterDataToRemoveNoEspaceLngLat.length > 0) {
          this.setState({
            requestEspaceDataListTmp: filterDataToRemoveNoEspaceLngLat,
          });
        } else {
          this.setState({
            requestEspaceDataListTmp: [],
          });
        }
      })
      .catch((error) => {});
  };

  getVilleData = (slug) => {
    ville
      .getEntityBySlug(slug)
      .then((rep) => {
        this.setState({
          villeData: rep.data[0],
          title: rep.data[0].title,
          searchType: "Ville",
        });
        let searchData = { ...this.state.searchData };
        searchData["villeId"] = [rep.data[0].id];
        this.setState({ searchData });
        this.SearchRequest(searchData);
      })
      .catch((error) => {});
  };

  getArtereData = (slug) => {
    artere
      .getEntityBySlug(slug)
      .then((rep) => {
        this.setState({
          artereData: rep.data[0],
          title: rep.data[0].title,
          searchType: "Artère",
        });

        let searchData = { ...this.state.searchData };
        searchData["artereId"] = [rep.data[0].id];
        this.setState({ searchData });
        this.SearchRequest(searchData);
      })
      .catch((error) => {});
  };

  getCommuneData = (slug) => {
    commune
      .getEntityBySlug(slug)
      .then((rep) => {
        this.setState({
          communeData: rep.data[0],
          title: rep.data[0].title,
          searchType: "Commune",
        });

        let searchData = { ...this.state.searchData };
        searchData["communeId"] = [rep.data[0].id];
        this.setState({ searchData });
        this.SearchRequest(searchData);
      })
      .catch((error) => {});
  };

  getFormatData = (slug) => {
    format
      .getEntityBySlug(slug)
      .then((rep) => {
        this.setState({
          formatData: rep.data[0],
          title: rep.data[0].title,
          searchType: "Format",
        });

        let searchData = { ...this.state.searchData };
        searchData["formatId"] = [rep.data[0].id];
        this.setState({ searchData });
        this.SearchRequest(searchData);
      })
      .catch((error) => {});
  };

  searchEnginepoint(target, slug) {
    if (target === "city") {
      this.getVilleData(slug);
    }
    if (target === "artere") {
      this.getArtereData(slug);
    }
    if (target === "commune") {
      this.getCommuneData(slug);
    }
    if (target === "format") {
      this.getFormatData(slug);
    }
  }

  getVilleList = () => {
    ville
      .getEntityList()
      .then((rep) => {
        this.setState({
          villeListData: rep.data,
        });
      })
      .catch((error) => {});
  };

  getCommuneList = () => {
    commune
      .getEntityList()
      .then((rep) => {
        this.setState({
          communeListData: rep.data,
        });
      })
      .catch((error) => {});
  };

  getTypeArtereList = () => {
    type_artere
      .getEntityList()
      .then((rep) => {
        this.setState({
          typeArtereListData: rep.data,
        });
      })
      .catch((error) => {});
  };

  getFormatList = () => {
    format
      .getEntityList()
      .then((rep) => {
        this.setState({
          formatListData: rep.data,
        });
      })
      .catch((error) => {});
  };

  getModeleList = () => {
    modele
      .getEntityList()
      .then((rep) => {
        this.setState({
          modeleListData: rep.data,
        });
      })
      .catch((error) => {});
  };

  getReferenceList = () => {
    reference
      .getEntityList()
      .then((rep) => {
        this.setState({
          referenceListData: rep.data,
        });
      })
      .catch((error) => {});
  };

  handleStartSearch = () => {
    this.setState({ espaceDataIsLoading: true, MapLoading: true });
    let searchData = { ...this.state.searchData };

    localStorage.setItem("end", searchData.end);
    localStorage.setItem("range", searchData.range);
    localStorage.setItem("start", searchData.start);
    localStorage.setItem("duration", searchData.durationTmp);
    this.SearchRequest(searchData);
    this.setState({ alreadySearch: true });
  };

  componentDidMount() {
    this.getVilleList();
    this.getCommuneList();
    this.getTypeArtereList();
    this.getFormatList();
    this.getModeleList();
    this.getReferenceList();
    this.setState({ MapLoading: true });
    this.searchEnginepoint(
      this.props.match.params.target,
      this.props.match.params.slug
    );

    if (localStorage.getItem("duration")) {
      this.setState({ alreadySearch: true });
    }
  }

  //   <PanneauxItem />
  render() {
    let {
      filterboxIsOpen,
      searchFormboxIsOpen,
      espaceDataIsLoading,
      espaceDataList,
      espaceDataListForMap,
      requestEspaceDataListTmp,
      showMap,
      defaultlat,
      defaultlng,
      oneViewSelected,
      MapLoading,
      oneViewSelectedId,
      afterLoop,
      searchType,
      title,
    } = this.state;

    return (
      <>
        <section className="login-content">
          <Row className="m-0 bg-white">
            <Col md="7">
              {afterLoop ? (
                <Row className="justify-content-center">
                  <Col md="12">
                    <div className="text-center pt-5">
                      <h2 className="text-left">
                        <svg
                          width="64"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.81409 20.4368H19.1971C20.7791 20.4368 21.7721 18.7267 20.9861 17.3527L13.8001 4.78775C13.0091 3.40475 11.0151 3.40375 10.2231 4.78675L3.02509 17.3518C2.23909 18.7258 3.23109 20.4368 4.81409 20.4368Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.0024 13.4147V10.3147"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.995 16.5H12.005"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </h2>
                      <h2>OUPS!</h2>
                      <h3 className="text-left">Nous sommes désolés</h3>
                      <p className="text-left">
                        Aucun espace ne correspond à votre recherche
                      </p>
                      <p className="text-left">
                        Essayez de modifier vos données pour découvrir d'autres
                        espaces.
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row className="justify-content-center">
                  <Col md="12">
                    {searchType && (
                      <h3 className="search-title p-2">
                        <strong>{searchType} : </strong> {title}
                      </h3>
                    )}
                  </Col>
                  <Col md="12">
                    <div className="result-header-warn">
                      <svg
                        width="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.81409 20.4368H19.1971C20.7791 20.4368 21.7721 18.7267 20.9861 17.3527L13.8001 4.78775C13.0091 3.40475 11.0151 3.40375 10.2231 4.78675L3.02509 17.3518C2.23909 18.7258 3.23109 20.4368 4.81409 20.4368Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.0024 13.4147V10.3147"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.995 16.5H12.005"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className="text-left">
                        Vos resultats de recherches sont actualisés toutes les 2
                        minutes
                      </p>
                    </div>
                  </Col>
                  <Col md="12" className="result-header-o">
                    <div className="result-header p-sticky">
                      <div>
                        <p className="text-left">
                          Plus de {espaceDataList.length} espaces disponibles
                        </p>
                        <span className="text-left">
                          Classement des résultats
                        </span>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          className="mr-1"
                          onClick={() =>
                            this.setState({ searchFormboxIsOpen: true })
                          }
                        >
                          <svg
                            width="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="11.7669"
                              cy="11.7666"
                              r="8.98856"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18.0186 18.4851L21.5426 22"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <button
                          className="mr-1"
                          onClick={() =>
                            this.setState({ filterboxIsOpen: true })
                          }
                        >
                          <svg
                            width="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.33 16.5928H4.0293"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.1406 6.90042H19.4413"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8.72629 6.84625C8.72629 5.5506 7.66813 4.5 6.36314 4.5C5.05816 4.5 4 5.5506 4 6.84625C4 8.14191 5.05816 9.19251 6.36314 9.19251C7.66813 9.19251 8.72629 8.14191 8.72629 6.84625Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M20.0002 16.5538C20.0002 15.2581 18.9429 14.2075 17.6379 14.2075C16.3321 14.2075 15.2739 15.2581 15.2739 16.5538C15.2739 17.8494 16.3321 18.9 17.6379 18.9C18.9429 18.9 20.0002 17.8494 20.0002 16.5538Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>{" "}
                          Filtres
                        </button>
                        {oneViewSelected && (
                          <button
                            className="mr-1"
                            onClick={this.ResetOneViewSelected}
                          >
                            <i class="fas fa-redo-alt"></i>
                          </button>
                        )}

                        {/* <button
                        className="mr-1 active-layout"
                        onClick={() => this.setState({ filterboxIsOpen: true })}
                      >
                        <i class="fas fa-list"></i>
                      </button> */}
                        {/* <button
                        onClick={() => this.setState({ filterboxIsOpen: true })}
                      >
                        <i class="fas fa-th"></i>
                      </button> */}
                      </div>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="result-boby">
                      <SideDataList
                        espaceDataIsLoading={espaceDataIsLoading}
                        espaceDataList={espaceDataList}
                        OneViewSelected={this.OneViewSelected}
                        oneViewSelectedId={oneViewSelectedId}
                        alreadySearch={this.state.alreadySearch}
                        handleStartSearch={this.handleStartSearch}
                        handleChangeDate={this.handleChangeDate}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            <Col md="5" className="p-0 h-950 p-sticky bg-grey overflow-hidden">
              {MapLoading ? (
                <div className="map-loader">
                  <Loader center vertical content="loading" size="lg" />
                </div>
              ) : (
                <MapViewer
                  showMap={showMap}
                  defaultlat={defaultlat}
                  defaultlng={defaultlng}
                  espaceDataList={espaceDataListForMap}
                />
              )}
            </Col>
          </Row>
        </section>
        {/* search form drawer */}
        <Drawer
          size={"xs"}
          placement={"top"}
          open={searchFormboxIsOpen}
          onClose={() => this.setState({ searchFormboxIsOpen: false })}
          className="search-form-drawer"
        >
          <Drawer.Body>
            <div className="search-form-drawer-box">
              <SearchForm props={this.props} />
            </div>
          </Drawer.Body>
        </Drawer>
        <Drawer
          backdrop={"static"}
          open={filterboxIsOpen}
          onClose={() => this.setState({ filterboxIsOpen: false })}
        >
          <Drawer.Header>
            <Drawer.Title>Filtres</Drawer.Title>
            <Drawer.Actions>
              {requestEspaceDataListTmp.length === 0 ? (
                <Button appearance="warn" className="bg-yellow">
                  0 espaces
                </Button>
              ) : (
                <Button
                  onClick={this.AddSearchRequestAnnexeData}
                  appearance="warn"
                  className="bg-yellow"
                >
                  {requestEspaceDataListTmp.length > 1
                    ? "Afficher les " +
                      requestEspaceDataListTmp.length +
                      " espaces"
                    : "Afficher l'espace"}
                </Button>
              )}
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            {this.props.match.params.target !== "city" && (
              <div className="filter-box">
                <p>Villes</p>
                <CheckboxGroup inline name="checkboxList">
                  {this.state.villeListData.map((item) => (
                    <Checkbox
                      name="villeId"
                      onChange={(e) => this.handleChange("villeId", e)}
                      value={item.id}
                    >
                      {item.title}
                    </Checkbox>
                  ))}
                </CheckboxGroup>
              </div>
            )}
            {this.props.match.params.target !== "commune" && (
              <div className="filter-box">
                <p>Commune</p>
                <CheckboxGroup inline name="checkboxList">
                  {this.state.communeListData.map((item) => (
                    <Checkbox
                      name="communeId"
                      onChange={(e) => this.handleChange("communeId", e)}
                      value={item.id}
                    >
                      {item.title}
                    </Checkbox>
                  ))}
                </CheckboxGroup>
              </div>
            )}
            <div className="filter-box">
              <p>Type d’artère</p>
              <CheckboxGroup inline name="checkboxList">
                {this.state.typeArtereListData.map((item) => (
                  <Checkbox
                    name="typeArtereId"
                    onChange={(e) => this.handleChange("typeArtereId", e)}
                    value={item.id}
                  >
                    {item.title}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </div>
            {this.props.match.params.target !== "format" && (
              <div className="filter-box">
                {" "}
                <p>Format</p>
                {this.state.formatListData.map((item) => (
                  <Checkbox
                    name="formatId"
                    onChange={(e) => this.handleChange("formatId", e)}
                    value={item.id}
                  >
                    {item.title}
                  </Checkbox>
                ))}
              </div>
            )}
            <div className="filter-box">
              {" "}
              <p>Eclairage</p>
              <CheckboxGroup inline name="checkboxList">
                <Checkbox
                  name="eclairage"
                  onChange={(e) => this.handleChange("eclairage", e)}
                  value="Eclairé"
                >
                  Eclairé
                </Checkbox>
                <Checkbox
                  name="eclairage"
                  onChange={(e) => this.handleChange("eclairage", e)}
                  value="Non Eclairé"
                >
                  Non Eclairé
                </Checkbox>
              </CheckboxGroup>
            </div>
            <div className="filter-box">
              <p>Modèle</p>
              {this.state.modeleListData.map((item) => (
                <Checkbox
                  name="modeleId"
                  onChange={(e) => this.handleChange("modeleId", e)}
                  value={item.id}
                >
                  {item.title}
                </Checkbox>
              ))}
            </div>
            <div className="filter-box">
              <p>Position</p>
              <CheckboxGroup inline name="checkboxList">
                <Checkbox
                  name="positionAxe"
                  onChange={(e) => this.handleChange("positionAxe", e)}
                  value="Dans le sens"
                >
                  Dans le sens
                </Checkbox>
                <Checkbox
                  name="positionAxe"
                  onChange={(e) => this.handleChange("positionAxe", e)}
                  value="À contre sens"
                >
                  À contre sens
                </Checkbox>
              </CheckboxGroup>
            </div>
            <div className="filter-box">
              <p>Notes</p>
              <CheckboxGroup inline name="checkboxList">
                <Checkbox
                  name="rate"
                  onChange={(e) => this.handleChange("rate", e)}
                  value="5"
                >
                  5 étoiles
                </Checkbox>
                <Checkbox
                  name="rate"
                  onChange={(e) => this.handleChange("rate", e)}
                  value="4"
                >
                  4 étoiles
                </Checkbox>
                <Checkbox
                  name="rate"
                  onChange={(e) => this.handleChange("rate", e)}
                  value="3"
                >
                  3 étoiles
                </Checkbox>
                <Checkbox
                  name="rate"
                  onChange={(e) => this.handleChange("rate", e)}
                  value="2"
                >
                  2 étoiles
                </Checkbox>
                <Checkbox
                  name="rate"
                  onChange={(e) => this.handleChange("rate", e)}
                  value="1"
                >
                  1 étoile
                </Checkbox>
              </CheckboxGroup>
            </div>
            <div className="filter-box">
              {" "}
              <p>Point de réference</p>
              {this.state.referenceListData.map((item) => (
                <Checkbox
                  name="referenceId"
                  onChange={(e) => this.handleChange("referenceId", e)}
                  value={item.id}
                >
                  {item.title}
                </Checkbox>
              ))}
            </div>
          </Drawer.Body>
        </Drawer>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

export default search_results;
