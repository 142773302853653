import { ADD_TO_WISH, REMOVE_WISH_ITEM } from './action-types/cart-actions'

//add cart action
export const addToWish = (item) => {
  return {
    type: ADD_TO_WISH,
    item,
  }
}
//remove item action
export const removeWishItem = (item) => {
  return {
    type: REMOVE_WISH_ITEM,
    item,
  }
}
