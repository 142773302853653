import React from "react";
import Index from "../views/dashboard/index";
import { Switch, Route } from "react-router-dom";
//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";
// routes import
import Account from "../views/admin/account";
import Arteres from "../views/admin/arteres";
import Communes from "../views/admin/communes";
import Formats from "../views/admin/formats";
import Modele from "../views/admin/modele";
import TypeArtere from "../views/admin/types-arteres";
import Villes from "../views/admin/villes";
import Countries from "../views/admin/country";
import Reference from "../views/admin/references";
import Slider from "../views/admin/slider";
import Presse from "../views/admin/presse";
import PanneauList from "../views/admin/panneau-list";
import PanneauAdd from "../views/admin/panneau-add";
import PanneauEdit from "../views/admin/panneau-edit";
import PanneauDetail from "../views/admin/panneau-list";
import Reservation from "../views/admin/reservation";
import ReservationDetail from "../views/admin/reservation-detail";
import Regies from "../views/admin/regies";
import UserList from "../views/admin/user/user-list";
import Temoignages from "../views/admin/temoignages";
import Carriere from "../views/admin/carriere";
import Partenariat from "../views/admin/partenariat";
//admin
import Admin from "../views/dashboard/admin/admin";

const DefaultRouter = () => {
  const routePrefix = "/admin";
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Switch>
          <Route path="/admin" exact component={Index} />
          <Route path="/admin/dashboard" exact component={Index} />
          <Route path="/dashboard/admin/admin" exact component={Admin} />
          <Route
            path={routePrefix + "/user/account"}
            exact
            component={Account}
          />
          <Route
            path={routePrefix + "/layout/arteres"}
            exact
            component={Arteres}
          />
          <Route
            path={routePrefix + "/term/commune"}
            exact
            component={Communes}
          />
          <Route
            path={routePrefix + "/term/format"}
            exact
            component={Formats}
          />
          <Route path={routePrefix + "/term/modele"} exact component={Modele} />
          <Route
            path={routePrefix + "/term/type-artere"}
            exact
            component={TypeArtere}
          />
          <Route path={routePrefix + "/term/ville"} exact component={Villes} />
          <Route
            path={routePrefix + "/term/country"}
            exact
            component={Countries}
          />
          <Route
            path={routePrefix + "/term/reference"}
            exact
            component={Reference}
          />
          <Route
            path={routePrefix + "/layout/slider"}
            exact
            component={Slider}
          />
          <Route
            path={routePrefix + "/content/presse"}
            exact
            component={Presse}
          />
          <Route
            path={routePrefix + "/content/panneau"}
            exact
            component={PanneauList}
          />
          <Route
            path={routePrefix + "/content/panneau/add"}
            exact
            component={PanneauAdd}
          />
          <Route
            path={routePrefix + "/content/panneau/edit/:slug"}
            exact
            component={PanneauEdit}
          />
          <Route
            path={routePrefix + "/content/panneau/detail/:slug"}
            exact
            component={PanneauDetail}
          />
          <Route
            path={routePrefix + "/content/reservation/panneau"}
            exact
            component={Reservation}
          />
          <Route
            path={routePrefix + "/content/reservation/detail/:reservationId"}
            exact
            component={ReservationDetail}
          />
          <Route
            path={routePrefix + "/content/regie"}
            exact
            component={Regies}
          />
          <Route
            path={routePrefix + "/utilisateur"}
            exact
            component={UserList}
          />
          <Route
            path={routePrefix + "/content/temoignage"}
            exact
            component={Temoignages}
          />
          <Route path={routePrefix + "/carriere"} exact component={Carriere} />
          <Route
            path={routePrefix + "/partenariat"}
            exact
            component={Partenariat}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default DefaultRouter;
