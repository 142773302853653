import React from "react";
import logo from "../../../assets/images/nymba-logo-o.png";
const Logo = (props) => {
  return (
    <>
      <img
        className={`${props.color === true ? "text-primary" : ""}`}
        src={logo}
        alt="logo"
      />
    </>
  );
};

export default Logo;
