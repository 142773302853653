const componentItem = (props) => {
  console.log("item", props.selectEntityData);
  return (
    <>
      <div className="entityList">
        <div className="entityListScrollbar">
          {props.entityData.map((item, idx) => (
            <button
              className={
                props.selectEntityData === item.id
                  ? "SingleEntityButton selected"
                  : "SingleEntityButton"
              }
              onClick={() => props.selectEntity(item.id)}
            >
              <div class=" SingleEntity" key={idx}>
                <div class="entAvatar">
                  <span>{item.id}</span>
                </div>
                <div class="SingleEntityName">
                  <h3> {item.title} </h3>
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default componentItem;
