import React, { Component, Suspense } from "react";
//router
import { Switch, Route } from "react-router";
// import Index from '../views/dashboard/index'
import Index from "../views/partner-interface";
import PanneauxAdd from "../views/partner-interface/panneau-add";
import PanneauxList from "../views/partner-interface/panneau-list";
import PanneauxEdit from "../views/partner-interface/panneau-edit";
import Customer from "../views/partner-interface/customer";
import Reservation from "../views/partner-interface/reservation";
import ReservationPending from "../views/partner-interface/reservation-pending";
import ReservationDetail from "../views/partner-interface/reservation-detail";
import Search_results from "../views/partner-interface/search_results/search_results";
import Search_results_by_entity from "../views/partner-interface/search_results/search_results_by_entity";
import Single_espace from "../views/partner-interface/single_espace/single_espace";
import Cart from "../views/partner-interface/cart/cart";
import CheckoutComplete from "../views/partner-interface/checkout-complete/checkout-complete";
import WishList from "../views/partner-interface/wishList/wishList";
import UserReservations from "../views/partner-interface/user_reservations/user_reservations";
import SavedCart from "../views/partner-interface/saved_cart/saved_cart";
import Account from "../views/partner-interface/account/account";

class HorizontalRouter extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.history.history.location.pathname !==
      prevProps.history.location.pathname
    ) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }

    // setTimeout(() => {
    // }, 2000)
    // window.addEventListener('scroll', () => {
    //   if (window.scrollY === 0) {
    //     window.scrollTo({ top: 0, behavior: 'smooth' })
    //   }
    // })
  }
  render() {
    return (
      // <div>
      //   <Index />
      // </div>
      <>
        <Suspense fallback={"loading..."}>
          <Switch>
            <Route path="/partner" exact component={Index}></Route>
            <Route
              path="/partner/content/panneau"
              exact
              component={PanneauxList}
            />
            <Route
              path="/partner/content/panneau/add"
              exact
              component={PanneauxAdd}
            />
            <Route
              path="/partner/content/panneau/edit/:slug"
              exact
              component={PanneauxEdit}
            />
            <Route path="/partner/client" exact component={Customer} />
            <Route
              path="/partner/content/reservation/panneau"
              exact
              component={Reservation}
            />
            <Route
              path="/partner/reservation/pending"
              exact
              component={ReservationPending}
            />
            <Route
              path="/partner/content/reservation/detail/:reservationId"
              exact
              component={ReservationDetail}
            />
            <Route
              path="/partner/search-results/:data"
              exact
              component={Search_results}
            ></Route>
            <Route
              path="/partner/espaces/:target/:slug"
              exact
              component={Search_results_by_entity}
            ></Route>
            <Route
              path="/partner/espaces/:slug"
              exact
              component={Single_espace}
            ></Route>
            <Route path="/partner/mon-panier" exact component={Cart}></Route>
            <Route
              path="/partner/checkout/complete"
              exact
              component={CheckoutComplete}
            ></Route>
            <Route path="/partner/favoris" exact component={WishList}></Route>
            <Route
              path="/partner/reservations"
              exact
              component={UserReservations}
            ></Route>
            <Route
              path="/partner/paniers-sauvegardes"
              exact
              component={SavedCart}
            ></Route>
            <Route
              path="/partner/account/info-personelles"
              exact
              component={Account}
            ></Route>
          </Switch>
        </Suspense>
      </>
    );
  }
}

export default HorizontalRouter;
