import React, { Component } from "react";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";
import Logo from "../../../components/partials/components/logo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// RestApi service import
import auth from "../../../restApiService/auth";

// img
import facebook from "../../../assets/images/brands/fb.svg";
import google from "../../../assets/images/brands/gm.svg";
import instagram from "../../../assets/images/brands/im.svg";
import linkedin from "../../../assets/images/brands/li.svg";
import auth1 from "../../../assets/images/auth/01.png";
import {
  addUserInfos,
  removeUserInfos,
} from "../../../store/actions/userActions";

class SignIn extends Component {
  state = {
    item: [],
    handleLoginLoading: false,
  };

  handleChange = (event) => {
    console.log(event.target.name);
    let item = { ...this.state.item };
    item[event.target.name] = event.target.value;
    this.setState({ item });
  };

  handleLogin = () => {
    const promiseVar = toast.loading("Traitement en cours...");
    this.setState({ handleLoginLoading: true });
    let item = { ...this.state.item };

    if (item.new_password === item.copy_password) {
      auth
        .resetUserPassword(this.props.match.params.userId, item)
        .then((response) => {
          toast.update(promiseVar, {
            render: "Opération effectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setTimeout(() => {
            this.setState({ handleLoginLoading: false });
            window.location.href = "/#/auth/sign-in";
          }, 2000);
        })
        .catch((error) => {
          toast.update(promiseVar, {
            render: "Informations incorrectes",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          this.setState({ handleLoginLoading: false });
        });
    } else {
      toast.update(promiseVar, {
        render: "Informations incorrectes",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      this.setState({ handleLoginLoading: false });
    }
  };

  render() {
    console.log("this.props", this.props);
    const { handleLoginLoading } = this.state;
    return (
      <>
        <section className="login-content">
          <Row className="m-0 align-items-center bg-white vh-100">
            <Col md="6">
              <Row className="justify-content-center">
                <Col md="12">
                  <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                    <Card.Body>
                      <Link
                        to="/app"
                        className="navbar-brand login d-flex align-items-center mb-3"
                      >
                        <Logo />
                      </Link>
                      <h2 className="mb-3">Réinitialiser votre mot de passe</h2>

                      <Form>
                        <Row>
                          <Col lg="12">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="email" className="">
                                Nouveau mot de passe
                              </Form.Label>
                              <Form.Control
                                type="password"
                                className=""
                                id="new_password"
                                name="new_password"
                                onChange={this.handleChange}
                                placeholder=" "
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="12" className="mb-3">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="password" className="">
                                Ressaisissez le mot de passe
                              </Form.Label>
                              <Form.Control
                                type="password"
                                className=""
                                id="copy_password"
                                name="copy_password"
                                onChange={this.handleChange}
                                aria-describedby="password"
                                placeholder=" "
                              />
                              <Form.Control.Feedback tooltip type="invalid">
                                Please provide a valid zip.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <div className="d-flex justify-content-center">
                          {handleLoginLoading ? (
                            <Button variant="btn btn-yellow w-100" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Loading...
                            </Button>
                          ) : (
                            <Button
                              onClick={this.handleLogin}
                              type="button"
                              variant="btn btn-yellow w-100"
                            >
                              Réinitialiser
                            </Button>
                          )}
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col
              md="6"
              className="d-md-block d-none bg-yellow p-0 mt-n1 vh-100 overflow-hidden"
            >
              <Image
                // src={auth1}
                src="https://nymba-files.s3.us-west-2.amazonaws.com/Photo%202.jpg"
                className="Image-fluid gradient-main animated-scaleX"
                alt="images"
              />
            </Col>
          </Row>
        </section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.userInfoReducer;
  return {
    items: addedItemsTmp.addedUserInfos,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addUserInfos: (item) => {
      dispatch(addUserInfos(item));
    },
    removeUserInfos: (item) => {
      dispatch(removeUserInfos(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
