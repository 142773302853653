import React, { Component } from "react";
import { Row, Col, Modal, Form, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageHeader from "../../../components/partials/dashboard/HeaderStyle/partner-page-header";
import Card from "../../../components/Card";

class slider extends Component {
  state = {};

  render() {
    return (
      <div>
        <PageHeader
          coloredText="Politique"
          normalText="de confidentialite"
          bgColor="#313131"
          markerColor="#eaba42"
        />
        <div class="content pb-5">
          <div class="container"></div>
        </div>
      </div>
    );
  }
}

export default slider;
