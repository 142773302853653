import React, { Component } from "react";
import Slider from "react-slick";
import SearchForm from "../SearchFormStyle/search-form-style-1";
import Loader from "../../components/loader";
import SkeletonStyle05 from "../../components/skeletonLoading/skeleton-style-05";
import HeroImg from "../../../../assets/images/pages/np-default-ban.jpg";

class hero extends Component {
  state = {
    isLoading: true,
  };
  componentWillMount() {}

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }

  render() {
    const settings = {
      fade: true,
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      lazyLoad: true,
      autoplaySpeed: 3000,
    };
    const { isLoading } = this.state;
    return (
      <>
        <div className="hero-section">
          {isLoading ? (
            <SkeletonStyle05 />
          ) : (
            <>
              <div className="overlay">&nbsp;</div>
              <Slider {...settings}>
                <div>
                  <img alt="" typeof="foaf:Image" src={HeroImg} />
                </div>
              </Slider>
              <div class="container">
                <div class="wel-box">
                  <h1>
                    Avec <span>Nymba Partner</span>,
                    <br /> gérez vos espaces <br />
                    d'affichage en un clic !
                  </h1>
                  <p className="mt-2">
                    Entrez vos espaces d'affichage et booster vos ventes
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default hero;
