import Dropzone from "react-dropzone";
import { Row, Col, Form, Button } from "react-bootstrap";
const componentItem = (props) => {
  return (
    <>
      <div className="update-form">
        <div className="entityCardAddForm">
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Titre</p>
            <p class="entityInfoDetails">
              <input
                className="form-control"
                name="title"
                value={props.item?.title}
                onChange={props.handleChange}
              />
            </p>
          </div>
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Date de publication</p>
            <p class="entityInfoDetails">
              <input
                className="form-control"
                name="date"
                type="date"
                value={props.item?.date}
                onChange={props.handleChange}
              />
            </p>
          </div>
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Lien de l'article</p>
            <p class="entityInfoDetails">
              <input
                className="form-control"
                name="lien"
                type="text"
                value={props.item?.lien}
                onChange={props.handleChange}
              />
            </p>
          </div>
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Image d'illustration</p>
            <p class="entityInfoDetails">
              <Dropzone onDrop={props.onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzonebox">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <p>
                        Faites glisser et déposez des fichiers ici, ou cliquez
                        pour sélectionner des fichiers.
                      </p>
                    </div>
                    <aside>
                      <ul>{props.files}</ul>
                    </aside>
                  </section>
                )}
              </Dropzone>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default componentItem;
