import axios from "axios";
import config from "./config";

export default {
  createFrontLinkData: (data) => {
    return axios.post(
      `${config.URL_END_POINT}/link_data/`,
      data,
      config.headersConfig
    );
  },
  getLinkData: (regieId, searchCode) => {
    return axios.get(
      `${config.URL_END_POINT}/link_data/${regieId}/${searchCode}`,
      config.headersConfig
    );
  },
  getLinkDataByRegieId: (regieId) => {
    return axios.get(
      `${config.URL_END_POINT}/link_data/list/${regieId}`,
      config.headersConfig
    );
  },
  updateLinkData: (searchCode, data) => {
    return axios.put(
      `${config.URL_END_POINT}/link_data/update/${searchCode}`,
      data
    );
  },
};
