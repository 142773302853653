import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/images/logo-np.png";
const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="footer-body">
          <div className="left-panel">
            <img src={logo} alt="logo" />
            <div className="text-footerr">
              <p>
                La plateforme Nymba est une solution de digitalisation du
                processus d’achat d’espace d’affichage urbain. Nymba facilite le
                processus d’achat d’espace et permet de choisir avec plus de
                pertinence les espaces d’affichage pour des campagnes mieux
                ciblées.
                <br /> Nymba utilise les données de trafic pour déterminer avec
                précision l’impact de chaque espace d’affichage. Nymba est une
                propriété de la régie Varley Halls.{" "}
              </p>
            </div>
          </div>
          <div className="center-panel">
            <h3>A PROPOS</h3>
            <ul className="left-panel list-inline mb-0 p-0">
              <li className="list-inline-item">
                <Link to="/dashboard/extra/privacy-policy">
                  Qui sommes-nous
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/dashboard/extra/terms-of-service">
                  Carrières chez Nymba
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/dashboard/extra/terms-of-service">
                  Conditions générales d’utilisation
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/dashboard/extra/terms-of-service">
                  Devenir partenaire
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/dashboard/extra/terms-of-service">Coin presse</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/dashboard/extra/terms-of-service">
                  Mentions légales
                </Link>
              </li>
            </ul>
          </div>
          <div className="right-panel">
            <h3>CONTACTS</h3>
            <ul className="left-panel list-inline mb-0 p-0">
              <li className="list-inline-item">
                Abidjan, Riviera Triangle, Immeuble Waouh 2ème étage
              </li>
              <li className="list-inline-item">(+225) 07 08 53 55 78</li>
              <li className="list-inline-item">infos@origin7pub.com</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center region-copyright">
        Copyright 2020 - Une propriété de la régie VarleyHalls
      </div>
    </footer>
  );
};

export default Footer;
