import React, { Component } from "react";
import Moment from "react-moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FsLightbox from "fslightbox-react";
import { Row, Col, Modal, Form, Button, Image } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { Lightbox } from "react-modal-image";
import Card from "../../../components/Card";

// services importation
import sliders from "../../../restApiService/slider";
import country from "../../../restApiService/country";

class slider extends Component {
  state = {
    item: [],
    countryList: [],
    entityDataTmp: [],
    entityData: [],
    selectEntityData: [],
    files: [],
    fileTmp: [],
    files_o: [],
    makeAction: false,
    actionType: "",
    searchTerm: "",
    show: false,
    show2: false,
    showFsLightbox: false,
    openImgViewer: false,
  };

  handleClose = () => this.setState({ show: false });
  handleClose2 = () => this.setState({ show2: false });
  handleShow = () =>
    this.setState({ fileTmp: [], show: true, actionType: "add" });

  onDrop = (files) => {
    this.setState({
      files_o: files,
      files: files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  };

  toggleLightbox = (item) => {
    this.setState({
      selectEntityData: item,
      openImgViewer: !this.state.openImgViewer,
    });
  };

  getEntityData = () => {
    sliders
      .getEntityList()
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          entityData: response.data,
          entityDataTmp: response.data,
        });
      })
      .catch((error) => console.log("first"));
  };

  selectEntity = (action, item) => {
    console.log(item);
    if (action === "read") {
      this.setState({
        selectEntityData: item,
        makeAction: false,
        actionType: "",
      });
    } else {
      this.setState({
        item: item,
        files: [item.file],
        show2: true,
        actionType: "update",
      });
    }
  };

  saveHandledData = () => {
    let item = { ...this.state.item };
    const promiseVar = toast.loading("Traitement en cours...");
    console.log(item);
    if (this.state.actionType === "add") {
      const formData = new FormData();
      formData.append("files", this.state.files_o[0]);
      formData.append("countryId", item.countryId);
      sliders
        .setEntity(formData)
        .then((response) => {
          this.componentDidMount();
          this.handleClose();
          toast.update(promiseVar, {
            render: "Opération éffectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) =>
          toast.update(promiseVar, {
            render: "Une erreur est susvenue",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        );
    } else {
      delete item["createdAt"];
      delete item["updatedAt"];
      const formData = new FormData();
      formData.append("files", this.state.files_o[0]);
      formData.append("countryId", item.countryId);
      sliders
        .updateEntityId(item.id, formData)
        .then((response) => {
          this.componentDidMount();
          this.handleClose2();
          toast.update(promiseVar, {
            render: "Opération éffectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) =>
          toast.update(promiseVar, {
            render: "Une erreur est susvenue",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        );
    }
  };

  removeItem = (id) => {
    const promiseVar = toast.loading("Traitement en cours...");
    sliders
      .deleteEntityId(id)
      .then((response) => {
        this.componentDidMount();
        // this.setState({ selectEntityData: this.state.entityData[0] })
        toast.update(promiseVar, {
          render: "Opération éffectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) =>
        toast.update(promiseVar, {
          render: "Une erreur est susvenue",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      );
  };

  getCountryData = () => {
    country
      .getEntityList()
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          countryList: response.data,
        });
      })
      .catch((error) => console.log("error"));
  };

  componentDidMount() {
    this.getEntityData();
    this.getCountryData();
  }

  render() {
    const {
      show,
      show2,
      entityData,
      selectEntityData,
      item,
      makeAction,
      actionType,
      showFsLightbox,
      FsLightboxData,
    } = this.state;

    const files = this.state.files.map((file) => (
      <li key={file.name}>
        <div className="thumb">
          <div className="thumbInner">
            <img
              src={file.preview ?? file}
              className="thumbInner-img"
              // Revoke data uri after image is loaded
              onLoad={() => {
                URL.revokeObjectURL(file.preview ?? file);
              }}
              alt=""
            />
          </div>
          {file.name} - {file.size} {file.size && "bytes"}
        </div>
      </li>
    ));
    return (
      <>
        <div>
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">
                      Liste des slides ({entityData.length}){" "}
                    </h4>
                  </div>
                  <div>
                    <button
                      className="ent-btn add-btn ent-btn-default ext-btn mt-lg-0 mt-md-0 mt-3"
                      onClick={this.handleShow}
                    >
                      <i className="btn-inner">
                        <svg
                          width="25"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                      </i>
                      <span>Ajouter un slide</span>
                    </button>
                    <Modal show={show} onHide={this.handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Ajouter un slide</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Pays</Form.Label>
                          <select
                            className="form-control"
                            name="countryId"
                            value={this.state.item?.countryId}
                            onChange={this.handleChange}
                          >
                            <option>Sélectionnez...</option>
                            {this.state.countryList?.map((item, index) => (
                              <option value={item.id}> {item.title} </option>
                            ))}
                          </select>
                          <Dropzone onDrop={this.onDrop}>
                            {({ getRootProps, getInputProps }) => (
                              <section className="dropzonebox">
                                <div
                                  {...getRootProps({ className: "dropzone" })}
                                >
                                  <input {...getInputProps()} />
                                  <p>
                                    Faites glisser et déposez des fichiers ici,
                                    ou cliquez pour sélectionner des fichiers.
                                  </p>
                                </div>
                                <aside>
                                  <ul>{files}</ul>
                                </aside>
                              </section>
                            )}
                          </Dropzone>
                        </Form.Group>
                        <Button
                          variant="primary"
                          onClick={this.saveHandledData}
                        >
                          Enregistrer
                        </Button>{" "}
                        <Button variant="danger" onClick={this.handleClose}>
                          Annuler
                        </Button>
                      </Modal.Body>
                    </Modal>

                    <Modal show={show2} onHide={this.handleClose2}>
                      <Modal.Header closeButton>
                        <Modal.Title>Editer un slide</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Pays</Form.Label>
                          <select
                            className="form-control"
                            name="countryId"
                            value={this.state.item?.countryId}
                            onChange={this.handleChange}
                          >
                            <option>Sélectionnez...</option>
                            {this.state.countryList?.map((item, index) => (
                              <option value={item.id}> {item.title} </option>
                            ))}
                          </select>

                          <Dropzone onDrop={this.onDrop}>
                            {({ getRootProps, getInputProps }) => (
                              <section className="dropzonebox">
                                <div
                                  {...getRootProps({ className: "dropzone" })}
                                >
                                  <input {...getInputProps()} />
                                  <p>
                                    Faites glisser et déposez des fichiers ici,
                                    ou cliquez pour sélectionner des fichiers.
                                  </p>
                                </div>
                                <aside>
                                  <ul>{files}</ul>
                                </aside>
                              </section>
                            )}
                          </Dropzone>
                        </Form.Group>
                        <div>
                          <div className="text-start mt-2 me-2">
                            <Button onClick={this.saveHandledData}>
                              Enregistrer
                            </Button>{" "}
                            <Button
                              variant="danger"
                              onClick={this.handleClose2}
                            >
                              Annuler
                            </Button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                  <div className="table-responsive">
                    <table
                      id="user-list-table"
                      className="table table-striped"
                      role="grid"
                      data-toggle="data-table"
                    >
                      <thead>
                        <tr className="ligth">
                          <th></th>
                          <th>Pays</th>
                          <th>Création</th>
                          <th min-width="100px">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {entityData.map((item, idx) => (
                          <tr key={idx}>
                            <td className="text-center img-l-box">
                              <button
                                onClick={() => this.toggleLightbox(item)}
                                className="nostyle"
                              >
                                <Image
                                  className="bg-soft-primary rounded img-fluid avatar-80-o me-3"
                                  src={item.file}
                                  alt="profile"
                                />
                              </button>
                            </td>
                            <td>{item?.country?.title}</td>
                            <td>
                              <Moment format="DD/MM/YYYY">
                                {item.createdAt}
                              </Moment>
                            </td>
                            <td>
                              <div className="flex align-items-center list-user-action">
                                <button
                                  className="btn btn-sm btn-icon"
                                  onClick={() =>
                                    this.selectEntity("update", item)
                                  }
                                >
                                  <span className="btn-inner">
                                    <svg
                                      width="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M15.1655 4.60254L19.7315 9.16854"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </span>
                                </button>{" "}
                                <button
                                  className="btn btn-sm btn-icon"
                                  onClick={() => this.removeItem(item.id)}
                                >
                                  <span className="btn-inner">
                                    <svg
                                      width="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      stroke="currentColor"
                                    >
                                      <path
                                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M20.708 6.23975H3.75"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </span>
                                </button>{" "}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        {this.state.openImgViewer && (
          <Lightbox
            medium={this.state.selectEntityData?.file}
            large={this.state.selectEntityData?.file}
            alt="Nymba image viewer"
            onClose={this.toggleLightbox}
          />
        )}
      </>
    );
  }
}

export default slider;
