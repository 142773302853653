export default {
  data: [
    { id: 298, title: 'Aeroport' },
    { id: 279, title: 'Aucun ' },
    { id: 302, title: 'Banque' },
    { id: 295, title: 'Bureaux et Administration' },
    { id: 284, title: 'Centre commercial' },
    { id: 294, title: 'Commerces' },
    { id: 283, title: 'Echangeur' },
    { id: 293, title: 'Eglise' },
    { id: 289, title: 'Etablissement scolaire' },
    { id: 297, title: 'Food and Drink' },
    { id: 304, title: 'Garage' },
    { id: 292, title: 'Gare' },
    { id: 275, title: 'Hôpital' },
    { id: 301, title: 'Hôtel' },
    { id: 300, title: 'Industrie' },
    { id: 290, title: 'Marché' },
    { id: 291, title: 'Mosquée' },
    { id: 303, title: 'Péage' },
    { id: 273, title: 'Pharmacie' },
    { id: 305, title: 'Police ou Gendarmerie' },
    { id: 287, title: 'Pont' },
    { id: 272, title: 'Rond point' },
    { id: 306, title: 'Stade' },
    { id: 10, title: 'Station' },
    { id: 299, title: 'Université' },
  ],
}
