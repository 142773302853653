export default {
  data: [
    { id: 7, title: 'Classic' },
    { id: 8, title: 'Trivision' },
    { id: 9, title: 'Façade murale' },
    { id: 10, title: 'Déroulant' },
    { id: 11, title: "Château d'eau" },
    { id: 12, title: 'Rooftop' },
    { id: 13, title: 'Super sign' },
  ],
}
