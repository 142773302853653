import React, { Component } from "react";
import { Row, Col, Modal, Form, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageHeader from "../../../components/partials/dashboard/HeaderStyle/partner-page-header";
import Card from "../../../components/Card";

import presse from "../../../restApiService/presse";

class slider extends Component {
  state = { entityData: [] };

  entityDataList = () => {
    presse
      .getEntityList()
      .then((req) => this.setState({ entityData: req.data }))
      .catch((err) => {});
  };

  componentDidMount() {
    this.entityDataList();
  }

  render() {
    const { entityData } = this.state;
    return (
      <div>
        <PageHeader
          coloredText="Coin"
          normalText="presse"
          bgColor="#313131"
          markerColor="#eaba42"
        />
        <div class="content pb-5">
          <div class="container">
            <div className="row">
              {entityData.map((item, index) => (
                <div className="col-md-4">
                  <a href={item.lien} target="_BLANK">
                    <div className="blog">
                      <div className="blog-image">
                        <img
                          src={item.image}
                          alt=""
                          className="responsive-image"
                        />
                      </div>
                      <div className="blog-content">
                        <p>{item.title}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default slider;
