export default {
  data: [
    { id: 7, title: 'Autoroute' },
    { id: 14, title: 'Avenue' },
    { id: 8, title: 'Boulevard' },
    { id: 11, title: 'Route départementale' },
    { id: 9, title: 'Route nationale' },
    { id: 13, title: 'Route principale' },
    { id: 10, title: 'Route secondaire' },
    { id: 12, title: 'Rue' },
  ],
}
