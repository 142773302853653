const componentItem = (props) => {
  return (
    <>
      <div className="update-form">
        <div className="entityCardAddForm mt-4">
          <div className="row">
            <div className="col-md-6">
              <div class="form-item">
                <label>Regie publicitaire</label>
                <select
                  className="form-control"
                  name="regieId"
                  value={props.item?.regieId}
                  onChange={props.handleChange}
                >
                  <option>Sélectionnez...</option>
                  {props.regieList?.map((item, index) => (
                    <option value={item.id}> {item.title} </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Code de l'espace</label>
                <input
                  className="form-control"
                  name="code"
                  value={props.item?.code}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-item">
                <label>Type d'espace d'affichage</label>
                <select
                  className="form-control"
                  name="type_espace"
                  value={props.item?.type_espace}
                  onChange={props.handleChange}
                >
                  <option>Sélectionnez...</option>
                  <option value="panneau">Panneau</option>
                  <option value="Façade-murale">Façade murale</option>
                  <option value="rooftop">Rooftop</option>
                  <option value="Chateau-deau">Château d'eau</option>
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-item">
                <label>Format</label>
                <select
                  className="form-control"
                  name="formatId"
                  value={props.item?.formatId}
                  onChange={props.handleChange}
                >
                  <option>Sélectionnez...</option>
                  {props.formatList?.map((item, index) => (
                    <option value={item.id}> {item.title} </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-item">
                <label>Modèle</label>
                <select
                  className="form-control"
                  name="modeleId"
                  value={props.item?.modeleId}
                  onChange={props.handleChange}
                >
                  <option>Sélectionnez...</option>
                  {props.modeleList?.map((item, index) => (
                    <option value={item.id}> {item.title} </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Echairage</label>

                <select
                  className="form-control"
                  name="eclairage"
                  value={props.item?.eclairage}
                  onChange={props.handleChange}
                >
                  <option>Sélectionnez... </option>
                  <option value="eclairé">Eclairé</option>
                  <option value="non éclairé">Non éclairé</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Qualité du panneau</label>
                <select
                  class="form-control border-0 small"
                  name="qualite"
                  value={props.item?.qualite}
                  onChange={props.handleChange}
                >
                  <option>Sélectionnez...</option>
                  <option value="bonne">Bonne</option>
                  <option value="excellente">Excellente</option>
                  <option value="mauvaise">Mauvaise</option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div class="form-item">
                <label>Description</label>
                <textarea
                  class="form-control"
                  name="description"
                  onChange={props.handleChange}
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default componentItem;
