import React, { Component } from 'react'
import Item from './skeletonShema/skeletonLoading01'

export default class skeletonLoading extends Component {
  componentDidMount() {}
  render() {
    return (
      <>
        <Item />
      </>
    )
  }
}
