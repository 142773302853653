import React, { Component } from 'react'
import { connect } from 'react-redux'
import Moment from 'react-moment'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import { PaginatedList } from 'react-paginated-list'
import PaginationList from 'react-pagination-list'
import {
  Drawer,
  CheckboxGroup,
  Checkbox,
  Popover,
  Whisper,
  ButtonToolbar,
  Loader,
  Modal,
} from 'rsuite'

import GoogleMapReact from 'google-map-react'
import controllable from 'react-controllables'
import { StickyContainer, Sticky } from 'react-sticky'
import { Row, Col, Image, Form, Button, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SearchForm from '../../../components/partials/dashboard/SearchFormStyle/search-form-style-1'
import SkeletonLoading from '../../../components/partials/components/skeletonLoading/skeleton-style-01'
// import Loader from '../../../components/partials/components/loader'
import PanneauxItem from '../../../components/partials/components/panneauxItem'

import ville from '../../../restApiService/ville'
import artere from '../../../restApiService/artere'
import commune from '../../../restApiService/commune'
import format from '../../../restApiService/format'
import type_artere from '../../../restApiService/type-artere'
import modele from '../../../restApiService/modele'
import reference from '../../../restApiService/reference'
import searchRequest from '../../../restApiService/searchRequest'
import cartCheckout from '../../../restApiService/cartCheckout'
// service importation
import { addToCart, removeItem } from '../../../store/actions/cartActions'
import '../../../assets/css/searchresults.css'
import ListItem from 'rsuite/esm/List/ListItem'
controllable(['center', 'zoom', 'markers'])

const currentUserInfo = JSON.parse(localStorage.getItem('userInfo'))

const SideDataList = React.lazy(() => import('./components/sideDataList'))
const MapViewer = React.lazy(() => import('./components/mapViewer'))

class user_reservations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reservationData: [],
      selectedItem: [],
      villeData: [],
      villeListData: [],
      artereData: [],
      communeData: [],
      communeListData: [],
      formatData: [],
      formatListData: [],
      typeArtereListData: [],
      modeleListData: [],
      referenceListData: [],
      espaceDataIsLoading: true,
      espaceDataList: [],
      espaceDataListTmp: [],
      requestEspaceDataListTmp: [],
      espaceDataListForMap: [],
      searchData: [],
      filterboxIsOpen: false,
      searchFormboxIsOpen: false,
      searchType: '',
      title: '',
      afterLoop: false,
      showMap: false,
      MapLoading: false,
      oneViewSelected: false,
      oneViewSelectedId: 0,
      defaultlat: 0,
      defaultlng: 0,
      alreadySearch: false,
      open: false,
    }
  }

  handleChange = (name, value) => {
    let searchData = { ...this.state.searchData }
    console.log(name)
    console.log(value)

    if (name === 'villeId') {
      if (searchData.villeId) {
        let find = searchData.villeId.map((e) => e).indexOf(value)
        if (find > -1) {
          searchData.villeId.splice(find, 1)
        } else {
          console.log('searchData.villeId', searchData.villeId.length)
          searchData['villeId'].push(value)
        }
      } else {
        searchData['villeId'] = [value]
      }
    }

    if (name === 'communeId') {
      if (searchData.communeId) {
        let find = searchData.communeId.map((e) => e).indexOf(value)
        if (find > -1) {
          searchData.communeId.splice(find, 1)
        } else {
          console.log('searchData.communeId', searchData.communeId.length)
          searchData['communeId'].push(value)
        }
      } else {
        searchData['communeId'] = [value]
      }
    }

    if (name === 'typeArtereId') {
      if (searchData.typeArtereId) {
        let find = searchData.typeArtereId.map((e) => e).indexOf(value)
        if (find > -1) {
          searchData.typeArtereId.splice(find, 1)
        } else {
          console.log('searchData.typeArtereId', searchData.typeArtereId.length)
          searchData['typeArtereId'].push(value)
        }
      } else {
        searchData['typeArtereId'] = [value]
      }
    }

    if (name === 'formatId') {
      if (searchData.formatId) {
        let find = searchData.formatId.map((e) => e).indexOf(value)
        if (find > -1) {
          searchData.formatId.splice(find, 1)
        } else {
          console.log('searchData.formatId', searchData.formatId.length)
          searchData['formatId'].push(value)
        }
      } else {
        searchData['formatId'] = [value]
      }
    }

    if (name === 'eclairage') {
      if (searchData.eclairage) {
        let find = searchData.eclairage.map((e) => e).indexOf(value)
        if (find > -1) {
          searchData.eclairage.splice(find, 1)
        } else {
          console.log('searchData.eclairage', searchData.eclairage.length)
          searchData['eclairage'].push(value)
        }
      } else {
        searchData['eclairage'] = [value]
      }
    }

    if (name === 'modeleId') {
      if (searchData.modeleId) {
        let find = searchData.modeleId.map((e) => e).indexOf(value)
        if (find > -1) {
          searchData.modeleId.splice(find, 1)
        } else {
          console.log('searchData.modeleId', searchData.modeleId.length)
          searchData['modeleId'].push(value)
        }
      } else {
        searchData['modeleId'] = [value]
      }
    }

    if (name === 'positionAxe') {
      if (searchData.positionAxe) {
        let find = searchData.positionAxe.map((e) => e).indexOf(value)
        if (find > -1) {
          searchData.positionAxe.splice(find, 1)
        } else {
          console.log('searchData.positionAxe', searchData.positionAxe.length)
          searchData['positionAxe'].push(value)
        }
      } else {
        searchData['positionAxe'] = [value]
      }
    }

    if (name === 'rate') {
      if (searchData.rate) {
        let find = searchData.rate.map((e) => e).indexOf(value)
        if (find > -1) {
          searchData.rate.splice(find, 1)
        } else {
          console.log('searchData.rate', searchData.rate.length)
          searchData['rate'].push(value)
        }
      } else {
        searchData['rate'] = [value]
      }
    }

    if (name === 'referenceId') {
      if (searchData.referenceId) {
        let find = searchData.referenceId.map((e) => e).indexOf(value)
        if (find > -1) {
          searchData.referenceId.splice(find, 1)
        } else {
          console.log('searchData.referenceId', searchData.referenceId.length)
          searchData['referenceId'].push(value)
        }
      } else {
        searchData['referenceId'] = [value]
      }
    }

    console.log('searchData', searchData)
    this.SearchRequestAnnexe(searchData)
    this.setState({ searchData })
  }

  OneViewSelected = (espaces) => {
    this.setState({ MapLoading: true })
    // let espaceDataListTmp = [...this.state.espaceDataListTmp]

    this.setState({ espaceDataListForMap: espaces })

    setTimeout(() => {
      let filteredList = this.state.espaceDataListForMap
      this.setState({
        oneViewSelected: true,
        espaceDataListForMap: filteredList,
        defaultlat: Number(filteredList[0].panneaux.geolatitude),
        defaultlng: Number(filteredList[0].panneaux.geolongitude),
        showMap: true,
      })
    }, 400)
    setTimeout(() => {
      this.setState({ MapLoading: false })
    }, 600)
  }

  getUserReservation = (id) => {
    cartCheckout
      .getUserSavedCart(id)
      .then((rep) => {
        this.setState({
          reservationData: rep.data,
          espaceDataIsLoading: false,
        })
      })
      .catch((error) => {})
  }

  deleteUserReservation = (id) => {
    cartCheckout
      .delUserCart(id)
      .then((rep) => {
        this.componentDidMount()
      })
      .catch((error) => {})
  }

  handleClick = () => {
    let item = { ...this.state.selectedItem }
    console.log('this.state.selectedItem', this.state.selectedItem)
    localStorage.setItem('cpgne', item.campagne)
    localStorage.setItem('end', item.periodeEnd)
    localStorage.setItem('start', item.periodeStart)
    localStorage.setItem('params', item.params)
    localStorage.setItem('duration', item.temps_reservation)
    localStorage.setItem('range', item.temps_reservation)
    if (this.props.items.length === 0) {
      for (let i = 0; i < JSON.parse(item.items).length; i++) {
        this.props.addToCart(JSON.parse(item.items)[i])
      }
    } else {
      for (let i = 0; i < this.props.items.length; i++) {
        this.props.removeItem(this.props.items[i])
        if (i == this.props.items.length - 1) {
          for (let i = 0; i < JSON.parse(item.items).length; i++) {
            this.props.addToCart(JSON.parse(item.items)[i])
          }
        }
      }
    }

    this.setState({ open: false })

    // props.addToCart(id)
  }

  componentDidMount() {
    if (currentUserInfo) {
      this.setState({ MapLoading: true })
      this.getUserReservation(currentUserInfo.id)
    } else {
      this.props.history.push('/app')
    }
  }

  componentDidUpdate(prevProps) {}

  //   <PanneauxItem />
  render() {
    let {
      filterboxIsOpen,
      searchFormboxIsOpen,
      espaceDataIsLoading,
      espaceDataList,
      espaceDataListForMap,
      requestEspaceDataListTmp,
      showMap,
      defaultlat,
      defaultlng,
      oneViewSelected,
      MapLoading,
      oneViewSelectedId,
      afterLoop,
      searchType,
      title,
      reservationData,
    } = this.state

    const toggleModal = (selectedItem) => {
      this.setState({ open: !this.state.open, selectedItem: selectedItem })
    }

    return (
      <>
        <section className="login-content">
          <Row className="m-0 bg-white">
            <Col md="7">
              {afterLoop ? (
                <Row className="justify-content-center">
                  <Col md="12">
                    <div className="text-center pt-5">
                      <h2 className="text-left">
                        <svg
                          width="64"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.81409 20.4368H19.1971C20.7791 20.4368 21.7721 18.7267 20.9861 17.3527L13.8001 4.78775C13.0091 3.40475 11.0151 3.40375 10.2231 4.78675L3.02509 17.3518C2.23909 18.7258 3.23109 20.4368 4.81409 20.4368Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.0024 13.4147V10.3147"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.995 16.5H12.005"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </h2>
                      <h2>OUPS!</h2>
                      <h3 className="text-left">Nous sommes désolés</h3>
                      <p className="text-left">
                        Aucun espace ne correspond à votre recherche
                      </p>
                      <p className="text-left">
                        Essayez de modifier vos données pour découvrir d'autres
                        espaces.
                      </p>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="text-center result-header button justify-content-center pt-5 w-100">
                      <button
                        className="mr-1"
                        onClick={() =>
                          this.setState({ searchFormboxIsOpen: true })
                        }
                      >
                        <svg
                          width="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="11.7669"
                            cy="11.7666"
                            r="8.98856"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.0186 18.4851L21.5426 22"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>{' '}
                        faire une nouvelle recherche
                      </button>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row className="justify-content-center">
                  <Col md="12" className="result-header-o mb-5">
                    <div className="result-header p-sticky">
                      <div>
                        <h3 className="text-left search-title p-2">
                          <strong>Mes paniers sauvegardés </strong>
                        </h3>
                      </div>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="result-boby">
                      <SideDataList
                        espaceDataIsLoading={espaceDataIsLoading}
                        espaceDataList={reservationData}
                        OneViewSelected={this.OneViewSelected}
                        oneViewSelectedId={this.state.oneViewSelectedId}
                        alreadySearch={this.state.alreadySearch}
                        handleStartSearch={this.handleStartSearch}
                        handleChangeDate={this.handleChangeDate}
                        deleteUserReservation={this.deleteUserReservation}
                        toggleModal={toggleModal}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            <Col md="5" className="p-0 h-950 p-sticky bg-grey overflow-hidden">
              {/* {MapLoading ? (
                <div className="map-loader">
                  <Loader center vertical content="loading" size="lg" />
                </div>
              ) : (
                <MapViewer
                  showMap={showMap}
                  defaultlat={defaultlat}
                  defaultlng={defaultlng}
                  espaceDataList={espaceDataListForMap}
                />
              )} */}
              <MapViewer
                showMap={showMap}
                defaultlat={defaultlat}
                defaultlng={defaultlng}
                espaceDataList={espaceDataListForMap}
              />
            </Col>
          </Row>
        </section>

        <Modal
          backdrop="static"
          role="alertdialog"
          open={this.state.open}
          onClose={toggleModal}
          size="xs"
        >
          <Modal.Body>
            <p>
              Cette action va réinitialiser votre panier ainsi et modifiée les
              paramètres de recherches
            </p>
            <p>Êtes-vous sur de vouloir continuer ?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.handleClick}
              appearance="subtle"
              className="home-button mr-1"
            >
              Oui
            </Button>
            <Button
              onClick={toggleModal}
              appearance="subtle"
              className="home-button button remove"
            >
              Non
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.cartReducer
  let notMatch = state.cartReducer.notMatch
  return {
    items: addedItemsTmp.addedItems,
    notMatch: notMatch,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id) => {
      dispatch(addToCart(id))
    },
    removeItem: (id) => {
      dispatch(removeItem(id))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(user_reservations)
