import React, { Component } from "react";
import { Row, Col, Image, Form, Button, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import emailjs from "@emailjs/browser";
import Card from "../../../components/Card";
import Logo from "../../../components/partials/components/logo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// RestApi service import
import auth from "../../../restApiService/auth";

// // img
// import auth2 from '../../../assets/images/auth/02.png'
class Recoverpw extends Component {
  state = {
    item: [],
    handleLoginLoading: false,
    confirmBox: false,
  };

  handleChange = (event) => {
    console.log(event.target.name);
    let item = { ...this.state.item };
    item[event.target.name] = event.target.value;
    this.setState({ item });
  };

  sendEmail(body) {
    emailjs
      .send("service_mzxjtr5", "template_so8uy18", body, "6Gkd-TDHtNaaSPr7D")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  handleLogin = () => {
    const promiseVar = toast.loading(
      "Recherche des information du client en cours..."
    );
    this.setState({ handleLoginLoading: true });
    const { item } = this.state;
    auth
      .requestResetPassword(item)
      .then((response) => {
        toast.update(promiseVar, {
          render:
            "Votre demande a été envoyé avec succès. Un e-mail avec des instructions pour réinitialiser votre mot de passe vous a été envoyé",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        let toSend = {
          subject: "Réservation effectuée avec succès sur Nymba",
          email: response.data.data?.email,
          data:
            "<p>Bonjour/Bonsoir," +
            response.data.data.firstname +
            " " +
            response.data.data.lastname +
            "</p>" +
            "<p>Vous avez demandé à réinitialiser votre mot de passe.</p>" +
            "<p> Veuillez cliquer sur le lien ci-dessous pour réinitialiser votre mot de passe</p>" +
            "<a href=" +
            response.data.link +
            '>Réinitialiser le mot de passe</a>";',
        };
        this.sendEmail(toSend);
        setTimeout(() => {
          this.setState({ handleLoginLoading: false, confirmBox: true });
          // this.props.history.push("/auth/confirm-mail");
        }, 2000);
      })
      .catch((error) => {
        toast.update(promiseVar, {
          render: "Informations incorrectes",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        this.setState({ handleLoginLoading: false });
      });
  };

  render() {
    const { handleLoginLoading, confirmBox, item } = this.state;
    return (
      <>
        <section className="login-content">
          <Row className="m-0 align-items-center bg-white vh-100">
            <Col
              md="6"
              className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden"
            >
              <Image
                src="https://nymba-files.s3.us-west-2.amazonaws.com/Photo%202.jpg"
                className="Image-fluid gradient-main animated-scaleX"
                alt="images"
              />
            </Col>
            <Col md="6" className="p-0">
              <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                <Card.Body>
                  <Link
                    to="/app"
                    className="navbar-brand login d-flex align-items-center mb-3"
                  >
                    <Logo />
                  </Link>
                  {!confirmBox && (
                    <div>
                      <h2 className="mb-2">Mot de passe oublié</h2>
                      <p className="mb-4">
                        Entrez votre adresse e-mail et nous vous enverrons un
                        e-mail avec des instructions pour réinitialiser votre
                        mot de passe.
                      </p>
                      <Form>
                        <Row>
                          <Col lg="12" className="col-lg-12">
                            <Form.Group className="floating-label">
                              <Form.Label
                                htmlFor="email"
                                className="form-label"
                              >
                                Email
                              </Form.Label>
                              <Form.Control
                                type="email"
                                className="form-control mb-4"
                                id="email"
                                name="email"
                                onChange={this.handleChange}
                                aria-describedby="email"
                                placeholder=" "
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        {handleLoginLoading ? (
                          <Button variant="btn btn-yellow w-100" disabled>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                            Envoie en cours...
                          </Button>
                        ) : (
                          <Button
                            onClick={this.handleLogin}
                            type="button"
                            variant="btn btn-yellow w-100"
                          >
                            Envoyer le mot de passe par e-mail
                          </Button>
                        )}
                      </Form>
                    </div>
                  )}
                  {confirmBox && (
                    <div>
                      <h2 className="mb-0">Success !</h2>
                      <p className="cnf-mail mb-1">
                        Un e-mail a été envoyé à <strong>{item.email}</strong>.
                        Veuillez rechercher un e-mail de la société et cliquez
                        sur le lien inclus pour réinitialiser votre mot de
                        passe.
                      </p>
                      <div className="d-inline-block w-100">
                        <Link to="/" className="text-underline mt-3">
                          Revenir à l'accueil
                        </Link>
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>
        <ToastContainer
          position="top-center"
          autoClose={8000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

export default Recoverpw;
