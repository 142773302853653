export default {
  // URL_SOCKET_END_POINT: "https://livo-backend.eu-gb.cf.appdomain.cloud/",
  // URL_SOCKET_END_POINT: 'http://192.168.1.4:3000',
  // URL_END_POINT: 'https://livo-backend.eu-gb.cf.appdomain.cloud/api',
  URL_END_POINT:
    "https://nymba-backend.10xwjkhwwi30.eu-gb.codeengine.appdomain.cloud/api",
  // URL_END_POINT: 'http://192.168.254.56:3000/api',
  headersConfig: {
    headers: {
      countryId: 1,
    },
  },
};
