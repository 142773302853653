import Dropzone from "react-dropzone";
import { Row, Col, Form, Button } from "react-bootstrap";
const componentItem = (props) => {
  return (
    <>
      <div className="update-form">
        <div className="entityCardAddForm">
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Nom complet</p>
            <p class="entityInfoDetails">
              <input
                className="form-control"
                name="nom_prenom"
                value={props.item?.nom_prenom}
                onChange={props.handleChange}
              />
            </p>
          </div>
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Fonction</p>
            <p class="entityInfoDetails">
              <input
                className="form-control"
                name="fonction_temoignage"
                value={props.item?.fonction_temoignage}
                onChange={props.handleChange}
              />
            </p>
          </div>
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Temoignage</p>
            <p class="entityInfoDetails">
              <textarea
                row="6"
                className="form-control"
                name="message"
                value={props.item?.message}
                onChange={props.handleChange}
              />
            </p>
          </div>
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Image de couverture</p>
            <p class="entityInfoDetails">
              <Dropzone onDrop={props.onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzonebox">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <p>
                        Faites glisser et déposez des fichiers ici, ou cliquez
                        pour sélectionner des fichiers.
                      </p>
                    </div>
                    <aside>
                      <ul>{props.files}</ul>
                    </aside>
                  </section>
                )}
              </Dropzone>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default componentItem;
