import React from "react";

//router
import { Switch, Route } from "react-router";
//layoutpages
import ProtectedRoute from "./ProtectedRoute";
import Default from "../layouts/dashboard/default";
import CustomerInterface from "../layouts/customer-interface";
import PartnerInterface from "../layouts/partner-interface";
import ExternalInterface from "../layouts/external-interface";
import Search_results from "../layouts/dashboard/space-search";
import Simple from "../layouts/dashboard/simple";

const IndexRouters = (props) => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={CustomerInterface}></Route>
        {/* <Route path="/dashboard" component={Default}></Route> */}
        {/* <Route path="/admin" component={Default}></Route> */}
        <Route path="/app" component={CustomerInterface}></Route>
        {/* <Route path="/partner" component={PartnerInterface}></Route> */}
        <Route path="/search-engine" component={ExternalInterface}></Route>
        <Route path="/auth" component={Simple}></Route>
        <Route path="/errors" component={Simple}></Route>
        {/* <Route path="/search-engine" component={Search_results}></Route> */}
        <ProtectedRoute path="/partner" role={2} component={PartnerInterface} />
        <ProtectedRoute path="/admin" role={1} component={Default} />
      </Switch>
    </>
  );
};

export default IndexRouters;
