import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SkeletonLoading02 from "../../../components/partials/components/skeletonLoading/skeleton-style-02";
import PanneauxItemStyle01 from "../../../components/partials/components/panneauxItem-style-01";
// RestApi service import
import panneaux from "../../../restApiService/panneaux";
// picture importation
import img01 from "../../../assets/images/icons/checked-img.png";
import img02 from "../../../assets/images/icons/messenger.png";

class checkoutComplete extends Component {
  state = {
    bestPointIsLoading: true,
    bestPointDataList: [],
  };

  getBestPointDataList = () => {
    panneaux
      .getBestPanneauxByRate()
      .then((rep) => {
        this.setState({ bestPointDataList: rep.data.slice(0, 10) });
        setTimeout(() => {
          this.setState({ bestPointIsLoading: false });
        }, 500);
      })
      .catch((error) => {});
  };

  renderArrows01 = () => {
    return (
      <div className="slider-arrow">
        <button
          className="arrow-btn prev"
          onClick={() => this.slider01.slickPrev()}
        >
          <svg
            width="32"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5 19L8.5 12L15.5 5"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button
          className="arrow-btn next"
          onClick={() => this.slider01.slickNext()}
        >
          <svg
            width="32"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 5L15.5 12L8.5 19"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    );
  };

  componentDidMount() {
    this.getBestPointDataList();
  }

  render() {
    const { bestPointIsLoading } = this.state;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      lazyLoad: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        <div id="top"></div>
        <div className="end-of-checkout">
          <div className="end-of-content text-center">
            <img alt="" src={img01} />
            <p>Félicitations, vos panneaux ont été réservés !</p>
            <p>
              <Link
                className="btn btn-new-o"
                to="/partner/content/reservation/panneau"
              >
                Lancer une nouvelle campagne
              </Link>
            </p>
            <div className="eof-footer">
              <div className="container">
                <div className="row">
                  <div className="col-md-2">
                    <img alt="" src={img02} />
                  </div>

                  <div className="col-md-10">
                    <p className="main-text">
                      Avez-vous besoin d'une création graphique?
                    </p>
                    <p className="sup-text">
                      Appelez le
                      <strong> (+225) 07 08 53 55 78 </strong>
                      ou contactez notre service client
                      <strong> crea@nymba.ci </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="section-1">
              <div className="container-fluid">
                <div className="block-title-box">
                  <h2 class="block-title">
                    Les meilleurs emplacements du moment en Côte d'Ivoire
                  </h2>
                  {this.renderArrows01()}
                </div>

                {bestPointIsLoading ? (
                  <SkeletonLoading02 />
                ) : (
                  <Slider ref={(c) => (this.slider01 = c)} {...settings}>
                    {this.state.bestPointDataList.map((item, index) => (
                      <div>
                        <PanneauxItemStyle01 data={item} />
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default checkoutComplete;
