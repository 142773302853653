import React, { Component, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import AdminSearchResults from "../views/customer-interface/search_results/admin_search_results";
import ExtSearchResults from "../views/partner-interface/ext_search_results";
import Cart from "../views/customer-interface/cart/cart";

const SimpleRouter = () => {
  return (
    <>
      <Suspense fallback={"loading..."}>
        <Switch>
          <Route exact path="/search-engine/" component={AdminSearchResults} />
          <Route
            exact
            path="/search-engine/results/:data"
            component={AdminSearchResults}
          />
          <Route
            exact
            path="/search-engine/:regie/:data"
            component={ExtSearchResults}
          />
          {/* <Route exact path="/search-engine/mon-panier" component={Cart} /> */}
        </Switch>
      </Suspense>
    </>
  );
};

export default SimpleRouter;
