import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import Header from "../../components/partials/dashboard/HeaderStyle/header-style-4";
//SimpleRouter
import SimpleRouter from "../../router/space-search-router";

// store
import {
  NavbarstyleAction,
  getDirMode,
  getcustomizerMode,
  getcustomizerprimaryMode,
  getcustomizerinfoMode,
  SchemeDirAction,
  ColorCustomizerAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "../../store/setting/setting";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  let addedItemsTmp = state.cartReducer;
  let addedWishItemsTmp = state.wishReducer;
  return {
    items: addedItemsTmp.addedItems,
    wishItems: addedWishItemsTmp.addedWishItems,
    darkMode: getDarkMode(state),
    customizerMode: getcustomizerMode(state),
    cololrinfomode: getcustomizerinfoMode(state),
    colorprimarymode: getcustomizerprimaryMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
      ColorCustomizerAction,
    },
    dispatch
  ),
});

const Simple = (props) => {
  useEffect(() => {
    //   darkmode
    const colorMode = sessionStorage.getItem("color-mode");
    if (colorMode === null) {
      props.ModeAction(props.darkMode);
    } else {
      props.ModeAction(colorMode);
    }

    // colocustomizermode
    const colorcustomizerMode = sessionStorage.getItem("color-customizer-mode");
    const colorcustomizerinfoMode = sessionStorage.getItem(
      "colorcustominfo-mode"
    );
    const colorcustomizerprimaryMode = sessionStorage.getItem(
      "colorcustomprimary-mode"
    );
    if (colorcustomizerMode === null) {
      props.ColorCustomizerAction(
        props.customizerMode,
        props.cololrinfomode,
        props.colorprimarymode
      );
      document.documentElement.style.setProperty(
        "--bs-info",
        props.cololrinfomode
      );
    } else {
      props.ColorCustomizerAction(
        colorcustomizerMode,
        colorcustomizerinfoMode,
        colorcustomizerprimaryMode
      );
      document.documentElement.style.setProperty(
        "--bs-info",
        colorcustomizerinfoMode
      );
    }
    // rtlmode
    const rtlMode = sessionStorage.getItem("rtl-mode");
    if (rtlMode === null) {
      props.SchemeDirAction(props.schemeDirMode);
    } else {
      props.SchemeDirAction(rtlMode);
    }
  });

  return (
    <>
      <div id="loading"></div>
      <main className="main-content bg--grey admin-side">
        <div className="position-relative">
          <Header
            history={props}
            items={props?.items}
            wishItems={props?.wishItems}
          />
        </div>
        <div>
          <SimpleRouter history={props} />
        </div>
        {/* <Footer /> */}
      </main>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Simple);
