import React from "react";
import { Link } from "react-router-dom";

const PanneauxItem = (props) => {
  return (
    <div class="content-commune-home">
      <div class="img-commune-home">
        <Link to={"/app/espaces/" + props.data?.slug}>
          <img
            alt=""
            typeof="foaf:Image"
            src={props.data?.storages[0]?.image}
          />
        </Link>
      </div>
      <div class="title-link-commune-home">
        <div class="title-commune-home">
          <span>&nbsp;</span>
          <Link to={"/app/espaces/city/" + props.data?.ville?.slug}>
            {props.data?.ville?.title}
          </Link>
        </div>
        <div class="link-commune-home">
          <Link to={"/app/espaces/" + props.data?.slug}>Voir</Link>
        </div>
      </div>
    </div>
  );
};

export default PanneauxItem;
