const componentItem = (props) => {
  return (
    <>
      <div className="update-form">
        <div className="entityCardAddForm mt-4">
          <div className="row">
            <div className="col-md-4">
              <div class="form-item">
                <label>Pays</label>
                <select
                  className="form-control"
                  name="countryId"
                  value={props.item?.countryId}
                  onChange={props.handleChange}
                >
                  <option>Sélectionnez...</option>
                  {props.countryList?.map((item, index) => (
                    <option value={item.id}> {item.title} </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-item">
                <label>Ville</label>
                <select
                  className="form-control"
                  name="villeId"
                  value={props.item?.villeId}
                  onChange={props.handleChange}
                >
                  <option>Sélectionnez...</option>
                  {props.villeList?.map((item, index) => (
                    <option value={item.id}> {item.title} </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-item">
                <label>Commune</label>
                <select
                  className="form-control"
                  name="communeId"
                  value={props.item?.communeId}
                  onChange={props.handleChange}
                >
                  <option>Sélectionnez...</option>
                  {props.communeList?.map((item, index) => (
                    <option value={item.id}> {item.title} </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Longitude</label>
                <input
                  className="form-control"
                  name="geolongitude"
                  value={props.item?.geolongitude}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Latitude</label>
                <input
                  className="form-control"
                  name="geolatitude"
                  value={props.item?.geolatitude}
                  onChange={props.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default componentItem;
