export default {
  data: [
    {
      id: 579,
      extClientId: 34,
      panneauxId: 62,
      date_reservation: '09/06/2020 - 30/06/2020',
      start: 1591660800,
      end: 1593475200,
      prix: 150000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Près de la Pharmacie St Come & Damien',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.970583',
      geolatitude_panneaux: '5.372545',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8646.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 16,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        'Situé  sur la I89 en bordure de voie pas loin de la Pharmacie saint Côme et Damien.\nImplanté dans une zone à forte fréquentation, il est particulièrement efficace pour toucher les automobilistes et les nombreux habitants de la zone.',
      code: 'C 001',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8645.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8646.JPG',
      image_4_panneaux: '"[294,273]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1148,
      extClientId: 106,
      panneauxId: 59,
      date_reservation: '10/07/2021 - 08/08/2021',
      start: 1625871600,
      end: 1628377200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Retour Abidjan mall',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.975655',
      geolatitude_panneaux: '5.357548',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8640.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'À contre sens',
      positionAxe: 'Avant un croisement de rue',
      description:
        "En bordure de la route de Bonoumin à 150m d’André Malraux, ce panneau 4X3 est implanté dans une zone à forte fréquentation, il est particulièrement efficace pour toucher les automobilistes de retour  d'Abidjan Mall ou de la palmeraie. ",
      code: 'Coc 15 Bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8641.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8640.JPG',
      image_4_panneaux: '[10,289]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1149,
      extClientId: 106,
      panneauxId: 60,
      date_reservation: '10/07/2021 - 08/08/2021',
      start: 1625871600,
      end: 1628377200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux:
        'Route Eglise Ste Cecile& Abidjan Mall ; face Abidjan Mall ',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9670021',
      geolatitude_panneaux: '5.3652287',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Après un croisement de rue',
      description:
        "Situé sur la I73 dans le quartier de Bonoumin et pas très loin du centre commercial Abidjan Mall.\nCet emplacement offre aux automobilistes et aux riverains de la zone l'occasion de voir les message affichés sur ce panneau..\n",
      code: 'Cocody 16 ',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_4_panneaux: '[294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1150,
      extClientId: 106,
      panneauxId: 61,
      date_reservation: '10/07/2021 - 08/08/2021',
      start: 1625871600,
      end: 1628377200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Fait face à face Abidjan Mall',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9670021',
      geolatitude_panneaux: '5.3652287',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8644.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé sur la I73 dans le quartier de Bonoumin, route de l'église Ste Cecile et d'Abidjan Mall. cet emplacement offre a ce panneau d'avoir une excellente visibilité.\n",
      code: 'Cocody 16 bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8642.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8644.JPG',
      image_4_panneaux: '"[294,293,284]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1151,
      extClientId: 106,
      panneauxId: 62,
      date_reservation: '10/07/2021 - 08/08/2021',
      start: 1625871600,
      end: 1628377200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Près de la Pharmacie St Come & Damien',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.970583',
      geolatitude_panneaux: '5.372545',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8646.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 16,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        'Situé  sur la I89 en bordure de voie pas loin de la Pharmacie saint Côme et Damien.\nImplanté dans une zone à forte fréquentation, il est particulièrement efficace pour toucher les automobilistes et les nombreux habitants de la zone.',
      code: 'C 001',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8645.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8646.JPG',
      image_4_panneaux: '"[294,273]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1152,
      extClientId: 106,
      panneauxId: 65,
      date_reservation: '10/07/2021 - 08/08/2021',
      start: 1625871600,
      end: 1628377200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'bonne',
      eclairage_panneaux: 'non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.974108',
      geolatitude_panneaux: '5.384569',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      type_espace_panneaux: 'panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 39,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé juste après le Feu tricolore à l'entrée du Centre commercial de la Djibi, Cet emplacement, permet à cet espace 4X3 de toucher aussi bien les automobilistes que les nombreux clients et riverains de la zone du Centre Commercial.",
      code: 'coc 01',
      rate: 4,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/djibi3.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      image_4_panneaux: '[284,294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1153,
      extClientId: 106,
      panneauxId: 66,
      date_reservation: '10/07/2021 - 08/08/2021',
      start: 1625871600,
      end: 1628377200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.947316',
      geolatitude_panneaux: '5.385340',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8655.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 16,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé juste après le Feu tricolore à l'entrée du Centre commercial de la Djibi, Cet emplacement, Positionné juste devant l'entrée du centre commercial et dans le sens de la route  il permet de toucher  tout le trafic dans les deux sens et aussi les clients du Centre Commercial. ",
      code: 'CO 02',
      rate: 4,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8655.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8655.JPG',
      image_4_panneaux: '[284,294,295]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1154,
      extClientId: 106,
      panneauxId: 67,
      date_reservation: '10/07/2021 - 08/08/2021',
      start: 1625871600,
      end: 1628377200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller la Pharmacie saint Come et Damien',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9703768',
      geolatitude_panneaux: '5.3719826',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8648.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 16,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'À contre sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé sur la I89 en bordure de voie pas loin de la Pharmacie saint Come et Damien. Sur l'étroite et très fréquentée I89, ce panneau  offre une visibilité optimale au trafic automobile en direction de la riviera 2.",
      code: 'COC 20',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8648.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8648.JPG',
      image_4_panneaux: '"[273,294,295]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1155,
      extClientId: 106,
      panneauxId: 71,
      date_reservation: '10/07/2021 - 08/08/2021',
      start: 1625871600,
      end: 1628377200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: "Classic Cocody Route d'Attoban",
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Attoban avant station shell sens aller riviera 2',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.987482',
      geolatitude_panneaux: '5.385516',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8662.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 17,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 33,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé sur la L23 route d'Attoban avant station Shell dans le  sens aller vers la  Riviera 2. Ce panneau  4x3 offre une excellente visibilité aux automobilistes fréquentant cet axe.",
      code: 'Idy001',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/Capture228.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8662.JPG',
      image_4_panneaux:
        '"\\"https://nymba-files.s3.us-west-2.amazonaws.com/Capture228.JPG\\""',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1156,
      extClientId: 106,
      panneauxId: 69,
      date_reservation: '10/07/2021 - 08/08/2021',
      start: 1625871600,
      end: 1628377200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.974310',
      geolatitude_panneaux: '5.385427',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8656.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 16,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'À contre sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Positionné juste après l'entrée du centre commercial de la Djibi, Son emplacement en fait un panneau très visible. La très haute fréquentation de la voie et du centre commercial de la Djibi lui donne de toucher les automobilistes et habitués de la zone.",
      code: 'Djibi 01',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8657.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8656.JPG',
      image_4_panneaux: '[284,295]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1157,
      extClientId: 106,
      panneauxId: 74,
      date_reservation: '10/07/2021 - 08/08/2021',
      start: 1625871600,
      end: 1628377200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: "Classic Cocody Route d'Attoban",
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Attoban avant station shell sens aller Riviera2',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.987418',
      geolatitude_panneaux: '5.385572',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8666.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 19,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 33,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé sur la L23 route d'Attoban avant station Shell dans le  sens aller vers la Riviera. Cette voie hyper fréquentée est soumise à d'énormes ralentissements ce qui accroit les occasions de vue sur les messages affichés sur ce panneau.",
      code: 'CO 005',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8667.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8666.JPG',
      image_4_panneaux: '"\\"[295,294]\\""',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1158,
      extClientId: 107,
      panneauxId: 640,
      date_reservation: '08/07/2021 - 31/07/2021',
      start: 1625702400,
      end: 1627689600,
      prix: 100000,
      reservationId: null,
      nom_panneaux:
        'Classic Yamoussoukro Boulevard Felix Houphouët-Boigny (Yamoussoukro) 2fh',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Abidjan ',
      reference_panneaux: '',
      geolongitude_panneaux: '-5.274250',
      geolatitude_panneaux: '6.816134',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/Yakro%205%20_%20Carrefour%20station%20Shell%20et%20H%C3%B4tel%20le%20Bonheur%2Cface%20%C3%A0%20la%20gare%20march%C3%A9%20R%C3%A9sidence%20FHB%20%2802%29.jpg',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 8,
      id_modeleIndex: 7,
      id_nom_atereIndex: 35,
      id_formatIndex: 15,
      id_villeIndex: 13,
      id_communeIndex: 24,
      id_reference: 279,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Sur la  grande artère',
      description:
        'Situé au carrefour Shell et Hôtel le Bonheur, ce panneau 4x3 est idéal pour toucher les automobilistes et les habitants de la zone en face à la gare ,du marché et aussi des alentours de le résidence FHB.',
      code: 'Yakro 5',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/Yakro%205%20_%20Carrefour%20station%20Shell%20et%20H%C3%B4tel%20le%20Bonheur%2Cface%20%C3%A0%20la%20gare%20march%C3%A9%20R%C3%A9sidence%20FHB%20%2802%29.jpg',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/Yakro%205%20_%20Carrefour%20station%20Shell%20et%20H%C3%B4tel%20le%20Bonheur%2Cface%20%C3%A0%20la%20gare%20march%C3%A9%20R%C3%A9sidence%20FHB%20%2802%29.jpg',
      image_4_panneaux: '"[302,294,10]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1164,
      extClientId: 53,
      panneauxId: 852,
      date_reservation: '01/01/2022 - 31/01/2022',
      start: 1640995200,
      end: 1643587200,
      prix: 100000,
      reservationId: null,
      nom_panneaux:
        "Classic Treichville Boulevard Valery Giscard d'Estaing (VGE) 50Za",
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Palais des sports ',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.012752',
      geolatitude_panneaux: '5.302791',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/AlSHANA%2031.PNG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'oui',
      id_regisIndex: 14,
      id_type_atereIndex: 8,
      id_modeleIndex: 7,
      id_nom_atereIndex: 38,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 17,
      id_reference: 279,
      positionPanneau: 'À contre sens',
      positionAxe: 'Sur la  grande artère',
      description:
        'Sur le VGE près du restaurant chez Jacko, ce panneau 4x3 offre une excellente visibilité des messages affichés. Sa position sur cette grande artère lui permet de toucher autant les automobilistes en direction du Plateau que les nombreux riverains de la zone.\n',
      code: 'TR 1 Bis',
      rate: 4,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/AlSHANA%2031.PNG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/AlSHANA%2031.PNG',
      image_4_panneaux: '"[294,295]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1165,
      extClientId: 53,
      panneauxId: 382,
      date_reservation: '01/01/2022 - 31/01/2022',
      start: 1640995200,
      end: 1643587200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Treichville Artère communale  16fg',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller école régionale',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.002110',
      geolatitude_panneaux: '5.306901',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/NJcom6.PNG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 38,
      id_type_atereIndex: 14,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 17,
      id_reference: 279,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Après un croisement de rue',
      description:
        "\nSitué à l'avenue 16 de la commune de Treichville et adossé au mur de l'école régionale, ce panneau est aux alentours du collège saint jean. Son excellent positionnement lui donne d'avoir une très bonne lisibilité des messages qu'il affiche. \n",
      code: 'TREICH 01',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/NJcom6.PNG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/NJcom6.PNG',
      image_4_panneaux: '[289,294,297]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1166,
      extClientId: 53,
      panneauxId: 59,
      date_reservation: '01/06/2022 - 30/06/2022',
      start: 1654041600,
      end: 1656547200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Retour Abidjan mall',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.975655',
      geolatitude_panneaux: '5.357548',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8640.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'À contre sens',
      positionAxe: 'Avant un croisement de rue',
      description:
        "En bordure de la route de Bonoumin à 150m d’André Malraux, ce panneau 4X3 est implanté dans une zone à forte fréquentation, il est particulièrement efficace pour toucher les automobilistes de retour  d'Abidjan Mall ou de la palmeraie. ",
      code: 'Coc 15 Bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8641.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8640.JPG',
      image_4_panneaux: '[10,289]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1167,
      extClientId: 66,
      panneauxId: 62,
      date_reservation: '01/07/2022 - 31/07/2022',
      start: 1656633600,
      end: 1659225600,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Près de la Pharmacie St Come & Damien',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.970583',
      geolatitude_panneaux: '5.372545',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8646.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 16,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        'Situé  sur la I89 en bordure de voie pas loin de la Pharmacie saint Côme et Damien.\nImplanté dans une zone à forte fréquentation, il est particulièrement efficace pour toucher les automobilistes et les nombreux habitants de la zone.',
      code: 'C 001',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8645.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8646.JPG',
      image_4_panneaux: '"[294,273]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1168,
      extClientId: 66,
      panneauxId: 1093,
      date_reservation: '01/07/2022 - 31/07/2022',
      start: 1656633600,
      end: 1659225600,
      prix: 100000,
      reservationId: null,
      nom_panneaux: "Classic Koumassi Boulevard Valery Giscard d'Estaing  22XD",
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Koumassi',
      reference_panneaux: '',
      geolongitude_panneaux: ' -3.969710',
      geolatitude_panneaux: '5.285645',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/KO%20%204.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'oui',
      id_regisIndex: 14,
      id_type_atereIndex: 8,
      id_modeleIndex: 7,
      id_nom_atereIndex: 41,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 14,
      id_reference: 279,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Sur la  grande artère',
      description:
        'Situé sur le VGE, ce panneau 4x3 offre une excellente exposition aux messages affichés aux automobilistes en direction du grand carrefour de Koumassi.',
      code: 'KO 4',
      rate: 5,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/KO%20%204.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/KO%20%204.JPG',
      image_4_panneaux: '[295]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1169,
      extClientId: 66,
      panneauxId: 1092,
      date_reservation: '01/07/2022 - 31/07/2022',
      start: 1656633600,
      end: 1659225600,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Yopougon Artère communale  51CK',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Niangon',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.087647',
      geolatitude_panneaux: '5.323343,',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2047.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 279,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé sur l'axe du rond-point de l'église St Pierre Terminus 27, ce panneau est excellent pour toucher les cibles et les automobilistes. Il offre une visibilité optimale sur les messages affichés.",
      code: 'Yop 47',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2047.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2047.JPG',
      image_4_panneaux: '[294,272]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1170,
      extClientId: 125,
      panneauxId: 60,
      date_reservation: '08/06/2022 - 26/07/2022',
      start: 1654646400,
      end: 1658793600,
      prix: 200000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux:
        'Route Eglise Ste Cecile& Abidjan Mall ; face Abidjan Mall ',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9670021',
      geolatitude_panneaux: '5.3652287',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Après un croisement de rue',
      description:
        "Situé sur la I73 dans le quartier de Bonoumin et pas très loin du centre commercial Abidjan Mall.\nCet emplacement offre aux automobilistes et aux riverains de la zone l'occasion de voir les message affichés sur ce panneau..\n",
      code: 'Cocody 16 ',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_4_panneaux: '[294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1171,
      extClientId: 125,
      panneauxId: 61,
      date_reservation: '08/06/2022 - 26/07/2022',
      start: 1654646400,
      end: 1658793600,
      prix: 200000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Fait face à face Abidjan Mall',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9670021',
      geolatitude_panneaux: '5.3652287',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8644.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé sur la I73 dans le quartier de Bonoumin, route de l'église Ste Cecile et d'Abidjan Mall. cet emplacement offre a ce panneau d'avoir une excellente visibilité.\n",
      code: 'Cocody 16 bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8642.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8644.JPG',
      image_4_panneaux: '"[294,293,284]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1172,
      extClientId: 125,
      panneauxId: 65,
      date_reservation: '08/06/2022 - 26/07/2022',
      start: 1654646400,
      end: 1658793600,
      prix: 200000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'bonne',
      eclairage_panneaux: 'non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.974108',
      geolatitude_panneaux: '5.384569',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      type_espace_panneaux: 'panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 39,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé juste après le Feu tricolore à l'entrée du Centre commercial de la Djibi, Cet emplacement, permet à cet espace 4X3 de toucher aussi bien les automobilistes que les nombreux clients et riverains de la zone du Centre Commercial.",
      code: 'coc 01',
      rate: 4,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/djibi3.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      image_4_panneaux: '[284,294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1180,
      extClientId: 125,
      panneauxId: 66,
      date_reservation: '14/06/2022 - 01/08/2022',
      start: 1655164800,
      end: 1659312000,
      prix: 200000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.947316',
      geolatitude_panneaux: '5.385340',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8655.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 16,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé juste après le Feu tricolore à l'entrée du Centre commercial de la Djibi, Cet emplacement, Positionné juste devant l'entrée du centre commercial et dans le sens de la route  il permet de toucher  tout le trafic dans les deux sens et aussi les clients du Centre Commercial. ",
      code: 'CO 02',
      rate: 4,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8655.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8655.JPG',
      image_4_panneaux: '[284,294,295]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1181,
      extClientId: 125,
      panneauxId: 67,
      date_reservation: '14/06/2022 - 01/08/2022',
      start: 1655164800,
      end: 1659312000,
      prix: 200000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller la Pharmacie saint Come et Damien',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9703768',
      geolatitude_panneaux: '5.3719826',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8648.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 16,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'À contre sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé sur la I89 en bordure de voie pas loin de la Pharmacie saint Come et Damien. Sur l'étroite et très fréquentée I89, ce panneau  offre une visibilité optimale au trafic automobile en direction de la riviera 2.",
      code: 'COC 20',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8648.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8648.JPG',
      image_4_panneaux: '"[273,294,295]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1182,
      extClientId: 125,
      panneauxId: 68,
      date_reservation: '14/06/2022 - 01/08/2022',
      start: 1655164800,
      end: 1659312000,
      prix: 200000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '5.384569',
      geolatitude_panneaux: '-3.974108',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8653.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 39,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'À contre sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Positionné juste après l'entrée du centre commercial de la Djibi, Son emplacement en fait un panneau très visible. La très haute fréquentation de la voie et du centre commercial de la Djibi lui donne un excellent score en terne d'occasion de vue.",
      code: 'coc 02',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8654.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8653.JPG',
      image_4_panneaux:
        '"https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8654.JPG"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1183,
      extClientId: 125,
      panneauxId: 65,
      date_reservation: '01/06/2022 - 26/07/2022',
      start: 1654041600,
      end: 1658793600,
      prix: 200000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'bonne',
      eclairage_panneaux: 'non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.974108',
      geolatitude_panneaux: '5.384569',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      type_espace_panneaux: 'panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 39,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé juste après le Feu tricolore à l'entrée du Centre commercial de la Djibi, Cet emplacement, permet à cet espace 4X3 de toucher aussi bien les automobilistes que les nombreux clients et riverains de la zone du Centre Commercial.",
      code: 'coc 01',
      rate: 4,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/djibi3.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      image_4_panneaux: '[284,294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1184,
      extClientId: 125,
      panneauxId: 60,
      date_reservation: '01/06/2022 - 26/07/2022',
      start: 1654041600,
      end: 1658793600,
      prix: 200000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux:
        'Route Eglise Ste Cecile& Abidjan Mall ; face Abidjan Mall ',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9670021',
      geolatitude_panneaux: '5.3652287',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Après un croisement de rue',
      description:
        "Situé sur la I73 dans le quartier de Bonoumin et pas très loin du centre commercial Abidjan Mall.\nCet emplacement offre aux automobilistes et aux riverains de la zone l'occasion de voir les message affichés sur ce panneau..\n",
      code: 'Cocody 16 ',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_4_panneaux: '[294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1185,
      extClientId: 125,
      panneauxId: 65,
      date_reservation: '08/06/2022 - 26/07/2022',
      start: 1654646400,
      end: 1658793600,
      prix: 200000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'bonne',
      eclairage_panneaux: 'non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.974108',
      geolatitude_panneaux: '5.384569',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      type_espace_panneaux: 'panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 39,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé juste après le Feu tricolore à l'entrée du Centre commercial de la Djibi, Cet emplacement, permet à cet espace 4X3 de toucher aussi bien les automobilistes que les nombreux clients et riverains de la zone du Centre Commercial.",
      code: 'coc 01',
      rate: 4,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/djibi3.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      image_4_panneaux: '[284,294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1186,
      extClientId: 125,
      panneauxId: 60,
      date_reservation: '08/06/2022 - 26/07/2022',
      start: 1654646400,
      end: 1658793600,
      prix: 200000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux:
        'Route Eglise Ste Cecile& Abidjan Mall ; face Abidjan Mall ',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9670021',
      geolatitude_panneaux: '5.3652287',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Après un croisement de rue',
      description:
        "Situé sur la I73 dans le quartier de Bonoumin et pas très loin du centre commercial Abidjan Mall.\nCet emplacement offre aux automobilistes et aux riverains de la zone l'occasion de voir les message affichés sur ce panneau..\n",
      code: 'Cocody 16 ',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_4_panneaux: '[294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1187,
      extClientId: 125,
      panneauxId: 65,
      date_reservation: '08/06/2022 - 25/07/2022',
      start: 1654646400,
      end: 1658707200,
      prix: 200000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'bonne',
      eclairage_panneaux: 'non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.974108',
      geolatitude_panneaux: '5.384569',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      type_espace_panneaux: 'panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 39,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé juste après le Feu tricolore à l'entrée du Centre commercial de la Djibi, Cet emplacement, permet à cet espace 4X3 de toucher aussi bien les automobilistes que les nombreux clients et riverains de la zone du Centre Commercial.",
      code: 'coc 01',
      rate: 4,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/djibi3.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      image_4_panneaux: '[284,294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1188,
      extClientId: 125,
      panneauxId: 60,
      date_reservation: '08/06/2022 - 25/07/2022',
      start: 1654646400,
      end: 1658707200,
      prix: 200000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux:
        'Route Eglise Ste Cecile& Abidjan Mall ; face Abidjan Mall ',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9670021',
      geolatitude_panneaux: '5.3652287',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Après un croisement de rue',
      description:
        "Situé sur la I73 dans le quartier de Bonoumin et pas très loin du centre commercial Abidjan Mall.\nCet emplacement offre aux automobilistes et aux riverains de la zone l'occasion de voir les message affichés sur ce panneau..\n",
      code: 'Cocody 16 ',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_4_panneaux: '[294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1193,
      extClientId: 125,
      panneauxId: 65,
      date_reservation: '29/06/2022 - 31/07/2022',
      start: 1656460800,
      end: 1659225600,
      prix: 180000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'bonne',
      eclairage_panneaux: 'non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.974108',
      geolatitude_panneaux: '5.384569',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      type_espace_panneaux: 'panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 39,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé juste après le Feu tricolore à l'entrée du Centre commercial de la Djibi, Cet emplacement, permet à cet espace 4X3 de toucher aussi bien les automobilistes que les nombreux clients et riverains de la zone du Centre Commercial.",
      code: 'coc 01',
      rate: 4,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/djibi3.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      image_4_panneaux: '[284,294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1194,
      extClientId: 125,
      panneauxId: 60,
      date_reservation: '08/06/2022 - 24/07/2022',
      start: 1654646400,
      end: 1658620800,
      prix: 200000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux:
        'Route Eglise Ste Cecile& Abidjan Mall ; face Abidjan Mall ',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9670021',
      geolatitude_panneaux: '5.3652287',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Après un croisement de rue',
      description:
        "Situé sur la I73 dans le quartier de Bonoumin et pas très loin du centre commercial Abidjan Mall.\nCet emplacement offre aux automobilistes et aux riverains de la zone l'occasion de voir les message affichés sur ce panneau..\n",
      code: 'Cocody 16 ',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_4_panneaux: '[294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1195,
      extClientId: 125,
      panneauxId: 65,
      date_reservation: '06/07/2022 - 29/07/2022',
      start: 1657065600,
      end: 1659052800,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'bonne',
      eclairage_panneaux: 'non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.974108',
      geolatitude_panneaux: '5.384569',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      type_espace_panneaux: 'panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 39,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé juste après le Feu tricolore à l'entrée du Centre commercial de la Djibi, Cet emplacement, permet à cet espace 4X3 de toucher aussi bien les automobilistes que les nombreux clients et riverains de la zone du Centre Commercial.",
      code: 'coc 01',
      rate: 4,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/djibi3.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      image_4_panneaux: '[284,294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1196,
      extClientId: 125,
      panneauxId: 60,
      date_reservation: '06/07/2022 - 29/07/2022',
      start: 1657065600,
      end: 1659052800,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux:
        'Route Eglise Ste Cecile& Abidjan Mall ; face Abidjan Mall ',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9670021',
      geolatitude_panneaux: '5.3652287',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Après un croisement de rue',
      description:
        "Situé sur la I73 dans le quartier de Bonoumin et pas très loin du centre commercial Abidjan Mall.\nCet emplacement offre aux automobilistes et aux riverains de la zone l'occasion de voir les message affichés sur ce panneau..\n",
      code: 'Cocody 16 ',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_4_panneaux: '[294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1197,
      extClientId: 125,
      panneauxId: 60,
      date_reservation: '31/07/2022 - 28/08/2022',
      start: 1659225600,
      end: 1661644800,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux:
        'Route Eglise Ste Cecile& Abidjan Mall ; face Abidjan Mall ',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9670021',
      geolatitude_panneaux: '5.3652287',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Après un croisement de rue',
      description:
        "Situé sur la I73 dans le quartier de Bonoumin et pas très loin du centre commercial Abidjan Mall.\nCet emplacement offre aux automobilistes et aux riverains de la zone l'occasion de voir les message affichés sur ce panneau..\n",
      code: 'Cocody 16 ',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_4_panneaux: '[294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1200,
      extClientId: 125,
      panneauxId: 65,
      date_reservation: '08/06/2022 - 24/07/2022',
      start: 1654646400,
      end: 1658620800,
      prix: 200000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'bonne',
      eclairage_panneaux: 'non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.974108',
      geolatitude_panneaux: '5.384569',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      type_espace_panneaux: 'panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 39,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé juste après le Feu tricolore à l'entrée du Centre commercial de la Djibi, Cet emplacement, permet à cet espace 4X3 de toucher aussi bien les automobilistes que les nombreux clients et riverains de la zone du Centre Commercial.",
      code: 'coc 01',
      rate: 4,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/djibi3.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      image_4_panneaux: '[284,294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1202,
      extClientId: 70,
      panneauxId: 59,
      date_reservation: '01/09/2022 - 30/09/2022',
      start: 1661990400,
      end: 1664496000,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Retour Abidjan mall',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.975655',
      geolatitude_panneaux: '5.357548',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8640.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'À contre sens',
      positionAxe: 'Avant un croisement de rue',
      description:
        "En bordure de la route de Bonoumin à 150m d’André Malraux, ce panneau 4X3 est implanté dans une zone à forte fréquentation, il est particulièrement efficace pour toucher les automobilistes de retour  d'Abidjan Mall ou de la palmeraie. ",
      code: 'Coc 15 Bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8641.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8640.JPG',
      image_4_panneaux: '[10,289]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1203,
      extClientId: 70,
      panneauxId: 60,
      date_reservation: '01/09/2022 - 30/09/2022',
      start: 1661990400,
      end: 1664496000,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux:
        'Route Eglise Ste Cecile& Abidjan Mall ; face Abidjan Mall ',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9670021',
      geolatitude_panneaux: '5.3652287',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Après un croisement de rue',
      description:
        "Situé sur la I73 dans le quartier de Bonoumin et pas très loin du centre commercial Abidjan Mall.\nCet emplacement offre aux automobilistes et aux riverains de la zone l'occasion de voir les message affichés sur ce panneau..\n",
      code: 'Cocody 16 ',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8643.JPG',
      image_4_panneaux: '[294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1204,
      extClientId: 70,
      panneauxId: 61,
      date_reservation: '01/09/2022 - 30/09/2022',
      start: 1661990400,
      end: 1664496000,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Fait face à face Abidjan Mall',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9670021',
      geolatitude_panneaux: '5.3652287',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8644.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé sur la I73 dans le quartier de Bonoumin, route de l'église Ste Cecile et d'Abidjan Mall. cet emplacement offre a ce panneau d'avoir une excellente visibilité.\n",
      code: 'Cocody 16 bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8642.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8644.JPG',
      image_4_panneaux: '"[294,293,284]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1205,
      extClientId: 70,
      panneauxId: 62,
      date_reservation: '01/09/2022 - 30/09/2022',
      start: 1661990400,
      end: 1664496000,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Près de la Pharmacie St Come & Damien',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.970583',
      geolatitude_panneaux: '5.372545',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8646.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 16,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        'Situé  sur la I89 en bordure de voie pas loin de la Pharmacie saint Côme et Damien.\nImplanté dans une zone à forte fréquentation, il est particulièrement efficace pour toucher les automobilistes et les nombreux habitants de la zone.',
      code: 'C 001',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8645.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8646.JPG',
      image_4_panneaux: '"[294,273]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1206,
      extClientId: 70,
      panneauxId: 65,
      date_reservation: '01/09/2022 - 30/09/2022',
      start: 1661990400,
      end: 1664496000,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'bonne',
      eclairage_panneaux: 'non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.974108',
      geolatitude_panneaux: '5.384569',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      type_espace_panneaux: 'panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 39,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé juste après le Feu tricolore à l'entrée du Centre commercial de la Djibi, Cet emplacement, permet à cet espace 4X3 de toucher aussi bien les automobilistes que les nombreux clients et riverains de la zone du Centre Commercial.",
      code: 'coc 01',
      rate: 4,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/djibi3.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      image_4_panneaux: '[284,294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1207,
      extClientId: 70,
      panneauxId: 59,
      date_reservation: '01/07/2022 - 30/07/2022',
      start: 1656633600,
      end: 1659139200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Retour Abidjan mall',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.975655',
      geolatitude_panneaux: '5.357548',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8640.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'À contre sens',
      positionAxe: 'Avant un croisement de rue',
      description:
        "En bordure de la route de Bonoumin à 150m d’André Malraux, ce panneau 4X3 est implanté dans une zone à forte fréquentation, il est particulièrement efficace pour toucher les automobilistes de retour  d'Abidjan Mall ou de la palmeraie. ",
      code: 'Coc 15 Bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8641.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8640.JPG',
      image_4_panneaux: '[10,289]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1208,
      extClientId: 70,
      panneauxId: 69,
      date_reservation: '01/07/2022 - 30/07/2022',
      start: 1656633600,
      end: 1659139200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.974310',
      geolatitude_panneaux: '5.385427',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8656.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 16,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'À contre sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Positionné juste après l'entrée du centre commercial de la Djibi, Son emplacement en fait un panneau très visible. La très haute fréquentation de la voie et du centre commercial de la Djibi lui donne de toucher les automobilistes et habitués de la zone.",
      code: 'Djibi 01',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8657.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8656.JPG',
      image_4_panneaux: '[284,295]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1209,
      extClientId: 70,
      panneauxId: 71,
      date_reservation: '01/07/2022 - 30/07/2022',
      start: 1656633600,
      end: 1659139200,
      prix: 100000,
      reservationId: null,
      nom_panneaux: "Classic Cocody Route d'Attoban",
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Attoban avant station shell sens aller riviera 2',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.987482',
      geolatitude_panneaux: '5.385516',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8662.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 17,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 33,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé sur la L23 route d'Attoban avant station Shell dans le  sens aller vers la  Riviera 2. Ce panneau  4x3 offre une excellente visibilité aux automobilistes fréquentant cet axe.",
      code: 'Idy001',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/Capture228.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8662.JPG',
      image_4_panneaux:
        '"\\"https://nymba-files.s3.us-west-2.amazonaws.com/Capture228.JPG\\""',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1210,
      extClientId: 70,
      panneauxId: 72,
      date_reservation: '01/07/2022 - 30/07/2022',
      start: 1656633600,
      end: 1659139200,
      prix: 150000,
      reservationId: null,
      nom_panneaux: "Classic Cocody Route d'Attoban",
      qualite_panneaux: 'bonne',
      eclairage_panneaux: 'non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux:
        'Attoban avant station shell sens aller  7eme tranche- gauche',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.987482',
      geolatitude_panneaux: '5.385516',
      prix_minimum: '80000',
      prix_mensuel: '150000',
      prix_trimestriel: '450000',
      prix_semestriel: '900000',
      prix_annuel: '1800000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8663.JPG',
      type_espace_panneaux: 'panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 17,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 33,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'a contre sens',
      positionAxe: 'dans le quartier',
      description:
        "Situé sur la L23 route d'Attoban avant station Shell dans le  sens aller vers la 7ème tranche. Ce panneau  est à gauche de cette voie hyper fréquentée. La L23 est sujette à d'énormes ralentissements et offre un excellent score en ODV estimée à 15000.",
      code: 'Idyl002',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8664.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8663.JPG',
      image_4_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8664.JPG',
      production: '',
      prix_achat_minimum: '',
      prix_achat_mois: '',
    },
    {
      id: 1211,
      extClientId: 88,
      panneauxId: 59,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Retour Abidjan mall',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.975655',
      geolatitude_panneaux: '5.357548',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8640.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'À contre sens',
      positionAxe: 'Avant un croisement de rue',
      description:
        "En bordure de la route de Bonoumin à 150m d’André Malraux, ce panneau 4X3 est implanté dans une zone à forte fréquentation, il est particulièrement efficace pour toucher les automobilistes de retour  d'Abidjan Mall ou de la palmeraie. ",
      code: 'Coc 15 Bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8641.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8640.JPG',
      image_4_panneaux: '[10,289]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1212,
      extClientId: 88,
      panneauxId: 61,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Fait face à face Abidjan Mall',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.9670021',
      geolatitude_panneaux: '5.3652287',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8644.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 22,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé sur la I73 dans le quartier de Bonoumin, route de l'église Ste Cecile et d'Abidjan Mall. cet emplacement offre a ce panneau d'avoir une excellente visibilité.\n",
      code: 'Cocody 16 bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8642.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8644.JPG',
      image_4_panneaux: '"[294,293,284]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1213,
      extClientId: 88,
      panneauxId: 62,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: 100000,
      reservationId: null,
      nom_panneaux: 'Classic Cocody Route de Bonoumin',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Près de la Pharmacie St Come & Damien',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.970583',
      geolatitude_panneaux: '5.372545',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8646.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 16,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 31,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        'Situé  sur la I89 en bordure de voie pas loin de la Pharmacie saint Côme et Damien.\nImplanté dans une zone à forte fréquentation, il est particulièrement efficace pour toucher les automobilistes et les nombreux habitants de la zone.',
      code: 'C 001',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8645.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8646.JPG',
      image_4_panneaux: '"[294,273]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1214,
      extClientId: 55,
      panneauxId: 65,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: 100000,
      reservationId: 491,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'bonne',
      eclairage_panneaux: 'non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.974108',
      geolatitude_panneaux: '5.384569',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      type_espace_panneaux: 'panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 39,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé juste après le Feu tricolore à l'entrée du Centre commercial de la Djibi, Cet emplacement, permet à cet espace 4X3 de toucher aussi bien les automobilistes que les nombreux clients et riverains de la zone du Centre Commercial.",
      code: 'coc 01',
      rate: 4,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/djibi3.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8652.JPG',
      image_4_panneaux: '[284,294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1215,
      extClientId: 55,
      panneauxId: 69,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: 100000,
      reservationId: 491,
      nom_panneaux: 'Classic Cocody Voie Djibi',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Entrée Centre Commercial La Djibi',
      reference_panneaux: '',
      geolongitude_panneaux: '-3.974310',
      geolatitude_panneaux: '5.385427',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8656.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 16,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 32,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 11,
      id_reference: 10,
      positionPanneau: 'À contre sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Positionné juste après l'entrée du centre commercial de la Djibi, Son emplacement en fait un panneau très visible. La très haute fréquentation de la voie et du centre commercial de la Djibi lui donne de toucher les automobilistes et habitués de la zone.",
      code: 'Djibi 01',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8657.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_8656.JPG',
      image_4_panneaux: '[284,295]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1216,
      extClientId: 55,
      panneauxId: 210,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 492,
      nom_panneaux: 'Classic Yopougon Artère communale  50qD',
      qualite_panneaux: 'Mauvaise',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Sens aller terminus 27 par la pharmacie Sideci',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.083554',
      geolatitude_panneaux: '5.318813',
      prix_minimum: '80000',
      prix_mensuel: '120000',
      prix_trimestriel: '360000',
      prix_semestriel: '720000',
      prix_annuel: '1440000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0180.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'non',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 10,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé aux alentours de la cité Sideci- IGES dans la commune de Yop ( Koweit). Ce panneau est en plein cœur de la cité GEM . Il donne dos au terminus des bus Sotra n° 27 et face à la circulation automobile venant de la grande artère partant de bel air jusqu'au Koweit. La grande fréquentation de cet axe fait de cet emplacement un excellent choix pour les campagnes de masse. ",
      code: 'yop 6Bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0177.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0180.JPG',
      image_4_panneaux:
        '"https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0177.JPG"',
      production: '',
      prix_achat_minimum: '',
      prix_achat_mois: '',
    },
    {
      id: 1217,
      extClientId: 55,
      panneauxId: 454,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 492,
      nom_panneaux: 'Classic Yopougon Artère communale  63XD',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Complexe Sportif Yopougon',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.060447',
      geolatitude_panneaux: '5.327599',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0598.jpg',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 13,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 279,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Sur la  grande artère',
      description:
        'Positionné près de la pharmacie CHIGATA, ce panneau 4x3 grâce à son excellente visibilité touche les cibles  et les riverains de la zone.\n',
      code: 'YOP 43',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0599.jpg',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0598.jpg',
      image_4_panneaux: '"[295,273,290,297,294]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1218,
      extClientId: 55,
      panneauxId: 455,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 492,
      nom_panneaux: 'Classic Yopougon Artère communale  51Za',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller kOWËT',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.060447',
      geolatitude_panneaux: '5.327599',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2043%20BIS.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 13,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 279,
      positionPanneau: 'À contre sens',
      positionAxe: 'Sur la  grande artère',
      description:
        "Situé près de la pharmacie CHIGATA face carrefour sapeurs-pompiers, ce panneau 4x3  touche les automobilistes et les habitués de la zone grâce à la très bonne visibilité qu'il offre sur les messages affichés.\n\n",
      code: 'YOP 43 Bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0601.jpg',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0600.jpg',
      image_4_panneaux: '[273,295,294,297]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1219,
      extClientId: 55,
      panneauxId: 456,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 492,
      nom_panneaux: 'Classic Yopougon Artère communale  81jn',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Nouveau quartier',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.059333',
      geolatitude_panneaux: '5.345553',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0112.jpg',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 279,
      positionPanneau: 'À contre sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé après le nouveau quartier Sicogi, non loin de l'école St Louis, ce panneau 4x3 est idéal pour toucher les cibles. Sa position et l'excellente lisibilité des messages affichés lui permettent de toucher les riverains de la zone.\n",
      code: 'YO 45 bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0113.jpg',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0112.jpg',
      image_4_panneaux: '[294,289]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1220,
      extClientId: 55,
      panneauxId: 457,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 492,
      nom_panneaux: 'Classic Yopougon Artère communale  51yF',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Bel Air',
      reference_panneaux: '',
      geolongitude_panneaux: '5.345553',
      geolatitude_panneaux: '-4.059333',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2045.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 279,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé après le nouveau quartier Sicogi, non loin de l'école St Louis, ce panneau 4x3  touche les automobilistes et les habitués de la zone grâce à la très bonne visibilité qu'il offre sur les messages affichés.\n\n",
      code: 'YO 45 ',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2045.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0114.jpg',
      image_4_panneaux: '[289,294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1221,
      extClientId: 55,
      panneauxId: 458,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 492,
      nom_panneaux: 'Classic Yopougon Artère communale  81zg',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Bel Air',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.059333',
      geolatitude_panneaux: '5.345553',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0114.jpg',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 279,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé après le nouveau quartier Sicogi, non loin de l'école St Louis,ce panneau 4x3 est particulièrement efficace pour toucher les automobilistes en direction de Bel air grâce à la très bonne visibilité qu'il offre sur les messages affichés.\n",
      code: 'Yop 45 ',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0115.jpg',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0114.jpg',
      image_4_panneaux: '"[273,294,289]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1222,
      extClientId: 55,
      panneauxId: 459,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 492,
      nom_panneaux: 'Classic Yopougon Artère communale  4ja',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Pharmacie bel air',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.069345',
      geolatitude_panneaux: '5.346688',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2055%20BIS.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 279,
      positionPanneau: 'À contre sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Positionné sur la rue princesse au niveau de la boutique Danistore, ce panneau 4x3  touche les automobilistes et les habitués de la zone grâce à la très bonne visibilité qu'il offre sur les messages affichés.\n",
      code: 'YOP 55 Bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2055%20BIS.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0606.jpg',
      image_4_panneaux: '[294,297,273]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1223,
      extClientId: 55,
      panneauxId: 460,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 492,
      nom_panneaux: 'Classic Yopougon Artère communale  53kJ',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Pharmacie Kenya',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.069345',
      geolatitude_panneaux: '5.346688',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2055.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 10,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 279,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Aur une route secondaire',
      description:
        "Situé sur la rue princesse au niveau de la boutique Danistore, ce panneau 4x3  touche les automobilistes et les habitués de la zone grâce à la très bonne visibilité qu'il offre sur les messages affichés.\n",
      code: 'YOP 55 ',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2055.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0604.jpg',
      image_4_panneaux: '"[294,297]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1224,
      extClientId: 55,
      panneauxId: 461,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 492,
      nom_panneaux: 'Classic Yopougon Artère communale  17LD',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller rond point gesco',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.098987',
      geolatitude_panneaux: '5.358301',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0639.jpg',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 13,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 279,
      positionPanneau: 'À contre sens',
      positionAxe: 'Sur la  grande artère',
      description:
        'Situé au 2ème Virage du rond point de Gesco, ce panneau 4x3 offre une excellente visibilité des messages affichés. Sa position lui permet de toucher aussi bien les automobilistes que les  riverains de la zone.',
      code: 'YOP 57 Bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0639.jpg',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0639.jpg',
      image_4_panneaux: '[294,272]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1225,
      extClientId: 55,
      panneauxId: 463,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 492,
      nom_panneaux: 'Classic Yopougon Artère communale  2Rb',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller rond point gandhi',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.056809',
      geolatitude_panneaux: '5.323641',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2049%20B.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 13,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 279,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Sur la  grande artère',
      description:
        "Situé après le collège St Augustin face à l'école Gandhi, ce panneau 4x3 est idéal pour toucher les automobilistes et les riverains de la zone. Son positionnement en bordure de voie le rend particulièrement visible.\n",
      code: 'YOP 49',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2049%20B.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2049%20BIS.JPG',
      image_4_panneaux: '"[294,289]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1226,
      extClientId: 55,
      panneauxId: 462,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 492,
      nom_panneaux: 'Classic Yopougon Artère communale  2Gn',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Chu Yopougon',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.098987',
      geolatitude_panneaux: '5.358301',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2057.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 13,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 279,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Sur la  grande artère',
      description:
        "Situé à l'Ananeraie, au 2ème Virage rond point Gesco,. Ce panneau 4x3 est particulièrement bien placé pour offrir dans ce virage une excellente visibilité  aux automobilistes et aux riverains de la zone.\n",
      code: 'YOP 57 ',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2057.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2057.JPG',
      image_4_panneaux: '"\\"[294]\\""',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1227,
      extClientId: 55,
      panneauxId: 464,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 492,
      nom_panneaux: 'Classic Yopougon Artère communale  2eX',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Koweit',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.056809',
      geolatitude_panneaux: '5.323641',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2049%20BIS.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 13,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 279,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Sur la  grande artère',
      description:
        "Situé après le collège St Augustin face à l'école Gandhi, ce panneau 4x3 est idéal pour toucher les automobilistes et les riverains de la zone. Son positionnement en bordure de voie le rend particulièrement visible.\n",
      code: 'YOP 49 Bis',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2049%20BIS.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0155.jpg',
      image_4_panneaux: '[289,294]',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1228,
      extClientId: 55,
      panneauxId: 465,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 493,
      nom_panneaux: 'Classic Yopougon Artère communale  85it',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Chu Yopougon',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.086544',
      geolatitude_panneaux: '5.335352',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0634.jpg',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'oui',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 13,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 279,
      positionPanneau: 'Dans le sens',
      positionAxe: 'Sur la  grande artère',
      description:
        "Situé au carrefour de Port-Bouet 2, ce panneau 4x3 est particulièrement efficace pour toucher les automobilistes en direction du CHU de Yop grâce à la très bonne visibilité qu'il offre sur les messages affichés.",
      code: ' YOP 10 Bis',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0635.jpg',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0634.jpg',
      image_4_panneaux: '"[294,292,300]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1229,
      extClientId: 55,
      panneauxId: 466,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 493,
      nom_panneaux: 'Classic Yopougon Artère communale  58fh',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Siporex',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.086544',
      geolatitude_panneaux: '5.335352',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2010.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'non',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 13,
      id_modeleIndex: 7,
      id_nom_atereIndex: 39,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 12,
      id_reference: 279,
      positionPanneau: 'À contre sens',
      positionAxe: 'Sur la  grande artère',
      description:
        'Ce panneau  est implanté au carrefour de Port-Bouet 2 . Il est idéal pour toucher les cibles en direction de Siporex . Sa position et son excellente visibilité lui permettent de toucher les riverains de la zone.',
      code: 'YOP 10 ',
      rate: 2,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/YOP%2010.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/IMG_0632.jpg',
      image_4_panneaux: '"[294]"',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
    {
      id: 1230,
      extClientId: 55,
      panneauxId: 467,
      date_reservation: '01/08/2022 - 31/08/2022',
      start: 1659312000,
      end: 1661904000,
      prix: null,
      reservationId: 493,
      nom_panneaux: 'Classic Plateau Boulevard de la Paix 16Rb',
      qualite_panneaux: 'Bonne',
      eclairage_panneaux: 'Non éclairé',
      statut_panneaux: 'DISPONIBLE',
      sens_panneaux: 'Aller Pont FHB',
      reference_panneaux: '',
      geolongitude_panneaux: '-4.026313',
      geolatitude_panneaux: '5.322252',
      prix_minimum: '80000',
      prix_mensuel: '100000',
      prix_trimestriel: '300000',
      prix_semestriel: '600000',
      prix_annuel: '1200000',
      image_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/PL%201.JPG',
      type_espace_panneaux: 'Panneau',
      critere_1_panneaux: 'oui',
      critere_2_panneaux: 'oui',
      critere_3_panneaux: 'non',
      critere_4_panneaux: 'oui',
      critere_5_panneaux: 'non',
      id_regisIndex: 14,
      id_type_atereIndex: 8,
      id_modeleIndex: 7,
      id_nom_atereIndex: 54,
      id_formatIndex: 15,
      id_villeIndex: 12,
      id_communeIndex: 21,
      id_reference: 279,
      positionPanneau: 'À contre sens',
      positionAxe: 'Sur la  grande artère',
      description:
        'Ce panneau 4x3 est positionné sur le Boulevard de la Paix au niveau du dépôt de Sitarail du Plateau, Son  excellent emplacement lui permet de toucher tous les automobiles en direction du Plateau.',
      code: 'PL 1',
      rate: 3,
      image_2_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/PL%201.JPG',
      image_3_panneaux:
        'https://nymba-files.s3.us-west-2.amazonaws.com/PL%201.JPG',
      image_4_panneaux: '"\\"[295,292,300]\\""',
      production: '22420',
      prix_achat_minimum: '60000',
      prix_achat_mois: '80000',
    },
  ],
}
