const componentItem = (props) => {
  return (
    <>
      <div className="update-form">
        <div className="entityCardAddForm mt-4">
          <div class="row">
            <div class="form-group col-lg-12">
              <label for="">
                L'espace d'affichage est-il situé sur une autoroute, un
                boulevard ou une voie départementale ?
              </label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="oui"
                  name="critere_1"
                  id="critere1-1"
                  onChange={props.handleChange}
                />
                <label class="form-check-label" for="critere1-1">
                  Oui
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="non"
                  name="critere_1"
                  id="critere1-2"
                  onChange={props.handleChange}
                />
                <label class="form-check-label" for="critere1-2">
                  Non
                </label>
              </div>
            </div>

            <div class="form-group col-lg-12">
              <label for="">
                L'espace d'affichage a-t-il un mobilier de qualité
              </label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="oui"
                  name="critere_2"
                  id="critere2-1"
                  onChange={props.handleChange}
                />
                <label class="form-check-label" for="critere2-1">
                  Oui
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="non"
                  name="critere_2"
                  id="critere2-2"
                  onChange={props.handleChange}
                />
                <label class="form-check-label" for="critere2-2">
                  Non
                </label>
              </div>
            </div>

            <div class="form-group col-lg-12">
              <label for="">
                L'espace d'affichage est-il situé dans le sens de la route ?
              </label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="oui"
                  name="critere_3"
                  id="critere3-1"
                  onChange={props.handleChange}
                />
                <label class="form-check-label" for="critere3-1">
                  Oui
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="non"
                  name="critere_3"
                  id="critere3-2"
                  onChange={props.handleChange}
                />
                <label class="form-check-label" for="critere1-2">
                  Non
                </label>
              </div>
            </div>

            <div class="form-group col-lg-12">
              <label for="">L'espace d'affichage est-il bien visible ?</label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="oui"
                  name="critere_4"
                  id="critere4-1"
                  onChange={props.handleChange}
                />
                <label class="form-check-label" for="critere4-1">
                  Oui
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="non"
                  name="critere_4"
                  id="critere4-2"
                  onChange={props.handleChange}
                />
                <label class="form-check-label" for="critere4-2">
                  Non
                </label>
              </div>
            </div>

            <div class="form-group col-lg-12">
              <label for="">
                Le trafic journalier de la voie de circulation où est situé
                l'espace d'affichage est-il supérieur ou égal á 20 000 ?
              </label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="oui"
                  name="critere_5"
                  id="critere5-1"
                  onChange={props.handleChange}
                />
                <label class="form-check-label" for="critere5-1">
                  Oui
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value="non"
                  name="critere_5"
                  id="critere5-2"
                  onChange={props.handleChange}
                />
                <label class="form-check-label" for="critere5-2">
                  Non
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default componentItem;
