import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Loader } from "rsuite";
import { ToastContainer, toast } from "react-toastify";
import controllable from "react-controllables";
import { Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import "../../../assets/css/searchresults.css";

// service importation
import { addToCart, removeItem } from "../../../store/actions/cartActions";
import cartCheckout from "../../../restApiService/cartCheckout";
import cartCheckoutLine from "../../../restApiService/cartCheckoutLine";
// end of service importation

controllable(["center", "zoom", "markers"]);

const Header = React.lazy(() => import("./components/actionHeader"));
const EmptyCart = React.lazy(() => import("./components/emptyCart"));
const SideDataList = React.lazy(() => import("./components/sideDataList"));
// const Summary = React.lazy(() => import('./components/summary'))
// const MapViewer = React.lazy(() => import('./components/mapViewer'))

// get connected user info
const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));
class WishList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartSummary: [],
      espaceDataIsLoading: false,
      espaceDataList: [],
      espaceDataListTmp: [],
      requestEspaceDataListTmp: [],
      espaceDataListForMap: [],
      filterboxIsOpen: false,
      searchFormboxIsOpen: false,
      title: "",
      campagne: "",
      afterLoop: false,
      showMap: false,
      mapViewer: false,
      MapLoading: false,
      handleLoading: false,
      oneViewSelected: false,
      prod_option: false,
      addExtra: false,
      oneViewSelectedId: 0,
      defaultlat: 0,
      defaultlng: 0,
      taux_frequentation: 0,
      sousTotal: 0,
      extra: 0,
      tva: 0,
      tsp: 0,
      odp: 0,
      tm: 0,
      totalTtc: 0,
      alreadySearch: false,
    };
  }

  handleClickToRemove = (item) => {
    this.props.removeItem(item);
  };

  OneViewSelected = (espaceId) => {
    console.log(espaceId);
    this.setState({ MapLoading: true, oneViewSelectedId: espaceId });
    let espaceDataListTmp = [...this.state.espaceDataListTmp];

    this.setState({ espaceDataListForMap: espaceDataListTmp });

    setTimeout(() => {
      let filteredList = this.state.espaceDataListForMap.filter(
        (item) => item.id === espaceId
      );
      this.setState({
        oneViewSelected: true,
        espaceDataListForMap: filteredList,
        defaultlat: Number(filteredList[0].geolatitude),
        defaultlng: Number(filteredList[0].geolongitude),
      });
    }, 400);
    setTimeout(() => {
      this.setState({ MapLoading: false });
    }, 500);
  };

  ResetOneViewSelected = () => {
    this.setState({
      mapViewer: false,
    });
  };

  addExtraProd = () => {
    let cartSummary = { ...this.state.cartSummary };
    this.setState({ addExtra: !this.state.addExtra });
    console.log("cartSummary", cartSummary);
    console.log("this.state.totalTtc", this.state.totalTtc);
    if (this.state.addExtra) {
      let newTotal = this.state.totalTtc - this.state.extra;
      cartSummary["total"] = newTotal;
      this.setState({ totalTtc: newTotal });
    } else {
      let newTotal = this.state.totalTtc + this.state.extra;
      cartSummary["total"] = newTotal;
      this.setState({ totalTtc: newTotal });
    }
    this.setState({ cartSummary });
  };

  generateCartSummary = (cart, prod_option) => {
    let HT = 0;
    let TVA = 0;
    let TTC = 0;
    let TSP = 0;
    let ODP = 0;
    let total_ODP = 0;
    let total_TM = 0;
    let TM = 0;
    let prix = 0;
    let sous_total = 0;
    let total_format = 0;
    let prixSearch_0 = 0;
    let total = 0;
    let items = [];
    let total_extra = 0;
    let total_extra_o = 0;
    let extra = 0;
    let emailItems = [];
    let eclairage_panneaux = 0;
    let frais_transport_total = 0;
    let cartSummary;
    let taux;
    let arteresOk = [];

    for (var i = 0; i < cart.length; i++) {
      let item = cart[i];
      console.log("item", item);
      //LIst des product à joindre à l'Email
      let tmpEmailItems = {
        nom_panneaux: item.title,
        code: item.code,
        localite: item.ville.title,
      };

      emailItems.push(tmpEmailItems);

      console.log("item", item);

      const prix = parseInt(item.prixCalculate);
      console.log("prix", prix);
      console.log("prix_min", item.prixCalculate);
      sous_total += item.prixCalculate * 1;
      const formatsize = item.format.slug;
      const formats = formatsize.length;
      const formatsufixe = formatsize.slice(-2);
      const format = formats - 1;

      prixSearch_0 = item.prixCalculate;

      const formatInt = formatsize.slice(0, format);

      total_format += formatInt * 1;

      //calcule du montant TTC

      if (item.commune.title === "Plateau") {
        localStorage.setItem("Plateau", i.toString());
      } else {
      }

      HT = sous_total;
      TVA = sous_total * 0.18;
      TTC = sous_total + TVA;
      TSP = TTC * 0.03;

      const cTaxe = localStorage.getItem("Plateau");

      if (
        item.commune.title === "Plateau" &&
        item.eclairage === "Non éclairé"
      ) {
        ODP = 4000 * formatInt * 1;
        TM = 1000 * formatInt * 1;
      } else if (
        item.commune.title === "Plateau" &&
        item.eclairage === "non éclairé"
      ) {
        ODP = 4000 * formatInt * 1;
        TM = 1000 * formatInt * 1;
      } else if (
        item.commune.title === "Plateau" &&
        item.eclairage === "Eclairé"
      ) {
        ODP = 4000 * formatInt * 1;
        TM = 3000 * formatInt * 1;
      } else if (
        item.commune.title === "Plateau" &&
        item.eclairage === "éclairé"
      ) {
        ODP = 4000 * formatInt * 1;
        TM = 3000 * formatInt * 1;
      }

      if (item.commune.title != "Plateau" && item.eclairage === "Non éclairé") {
        ODP = 1000 * formatInt * 1;
        TM = 1000 * formatInt * 1;
      } else if (
        item.commune.title != "Plateau" &&
        item.eclairage === "non éclairé"
      ) {
        ODP = 1000 * formatInt * 1;
        TM = 1000 * formatInt * 1;
      } else if (
        item.commune.title != "Plateau" &&
        item.eclairage === "Eclairé"
      ) {
        ODP = 1000 * formatInt * 1;
        TM = 3000 * formatInt * 1;
      } else if (
        item.commune.title != "Plateau" &&
        item.eclairage === "éclairé"
      ) {
        ODP = 1000 * formatInt * 1;
        TM = 3000 * formatInt * 1;
      }

      console.log(total_TM);

      total_ODP += ODP * 1;
      total_TM += TM * 1;

      let options = "";

      options = item.format.title;
      extra = item.production;

      console.log("coute de production", extra);
      console.log("coute de production", item);

      total_extra_o += extra * 1;

      //frais_transport_total += 0 * 1;
      frais_transport_total += item.ville.frais_transport * 1;

      console.log("optiopn", Option);
      console.log("option prod", prod_option);

      if (prod_option === true) {
        total_extra = total_extra_o * 1;
      } else total_extra = 0;

      // generate frequentation count
      if (arteresOk.length > 0) {
        for (var j = 0; j < arteresOk.length; j++) {
          if (arteresOk[j] !== item.artere.title) {
            if (isNaN(Number(item.artere.frequentation)) === false) {
              this.setState({
                taux_frequentation:
                  this.state.taux_frequentation +
                  Number(item.artere.frequentation),
              });
              arteresOk.push(item.artere.title);
            }
          }
        }
      } else {
        if (isNaN(Number(item.artere.frequentation)) === false) {
          console.log("item.artere.title", item.artere.title);
          console.log(
            "item.artere.frequentation",
            Number(item.artere.frequentation)
          );
          console.log("taux", taux);
          this.setState({
            taux_frequentation:
              Number(this.state.taux_frequentation) +
              Number(item.artere.frequentation),
          });
          // taux += Number(item.artere.frequentation) * 1
          arteresOk.push(item.artere.title);
        }
      }

      let sous_total_0 = sous_total;
      let total_extra_o_0 = total_extra_o;
      let TTC_0 = TTC;
      let TVA_0 = TVA;
      let TSP_0 = TSP;
      let total_ODP_0 = total_ODP;
      let total_extra_0 = total_extra;
      let frais_transport_total_0 = isNaN(frais_transport_total)
        ? 0
        : frais_transport_total;
      let total_TM_0 = total_TM;
      let addExtraValue = this.state.addExtra ? total_extra_o_0 : 0;

      let total_0 =
        sous_total_0 +
        TVA_0 +
        TSP_0 +
        total_ODP_0 +
        total_TM_0 +
        frais_transport_total_0 +
        addExtraValue;

      this.setState({ totalTtc: total_0, extra: total_extra_o_0 });

      cartSummary = {
        sous_total: sous_total_0,
        TVA: TVA_0,
        TSP: TSP_0,
        total_ODP: total_ODP_0,
        total_TM: total_TM_0,
        frais_transport_total: frais_transport_total_0,
        total_extra_o: total_extra_o_0,
        total: total_0,
        taux_frequentation: this.state.taux_frequentation,
      };
    }

    console.log("cartSummary : ", cartSummary);
    this.setState({ cartSummary: cartSummary });
    // return cartSummary
  };

  showMapViewer = () => {
    this.setState({ mapViewer: true, MapLoading: true });
    setTimeout(() => {
      this.setState({ MapLoading: false, showMap: true });
    }, 1000);
  };

  restoreCartParams = () => {
    let searchParams = localStorage.getItem("params");
    this.props.history.push("/app/search-results/" + searchParams);
  };

  cartCheckout = () => {
    const { taux_frequentation, sousTotal, tva, tsp, odp, tm, totalTtc } =
      this.state;
    if (currentUserInfo) {
      this.setState({ handleLoading: true });
      localStorage.removeItem("reservedItem");

      let Total_cart = {
        nom_campagne: localStorage.getItem("cpgne"),
        id_client: currentUserInfo?.id,
        sousTotal: sousTotal,
        tva: tva,
        tsp: tsp,
        odp: odp,
        tm: tm,
        totalTtc: totalTtc,
        date_reservation: this.periodeStart + " - " + this.periodeEnd,
        prix_extra: this.total_extra,
        transport: this.frais_transport_total,
        espace_reserve: this.emailItems,
        etat: 0,
      };

      cartCheckout
        .setReservation(Total_cart)
        .then((req) => {
          let cartitem = this.props.items;

          for (var i = 0; i < cartitem.length; i++) {
            let itemInCart = cartitem[i];

            var total_cartiem = {
              panneauxId: itemInCart.id,
              date_reservation: localStorage.start + " - " + localStorage.end,
              start: localStorage.start
                ? moment(localStorage.start, "DD/MM/YYYY").format("X")
                : moment().format("X"),
              end: localStorage.end
                ? moment(localStorage.end, "DD/MM/YYYY").format("X")
                : moment().format("X"),
              prix: itemInCart.prixCalculate,
              reservationId: req.id,
            };

            //   store each reservation line
            cartCheckoutLine
              .setReservationLine(total_cartiem)
              .then((req) => {
                // remove item from cart
                this.handleClickToRemove(cartitem);
              })
              .catch((error) => {});

            if (i == cartitem.length - 1) {
              this.setState({ handleLoading: false });
              this.props.history.push("/checkout/complete");
            }
          }
        })
        .catch((error) => {
          this.setState({ handleLoading: false });
        });
    } else {
      localStorage.setItem("checkout", "ad6476a36ec35fdd4d9f");
      this.props.history.push("/auth/sign-in");
    }
  };

  SelectLink() {
    this.extLink = "";

    var items = [
      2, 4, 9, 16, 17, 22, 34, 37, 50, 51, 53, 54, 58, 60, 63, 81, 84, 85, 91,
      94,
    ];
    var aZ = [
      "Fj",
      "aG",
      "qd",
      "kJ",
      "ur",
      "wU",
      "NJ",
      "Oy",
      "Bx",
      "qB",
      "qT",
      "jq",
      "ri",
      "eX",
      "Za",
      "fh",
      "qD",
      "SH",
      "CK",
      "eK",
      "Of",
      "Gn",
      "Fo",
      "Ti",
      "GP",
      "GJ",
      "dU",
      "LD",
      "Zl",
      "jh",
      "Fy",
      "Dz",
      "Ri",
      "cl",
      "lc",
      "VI",
      "BY",
      "VZ",
      "LM",
      "Wn",
      "BE",
      "NB",
      "Rb",
      "Rv",
      "je",
      "ka",
      "Ad",
      "VM",
      "lq",
      "xp",
      "XD",
      "QX",
      "FN",
      "iq",
      "xu",
      "EQ",
      "Kf",
      "qU",
      "gS",
      "zW",
      "ZI",
      "rI",
      "nx",
      "IJ",
      "Cm",
      "Ry",
      "WI",
      "wM",
      "Lm",
      "DK",
      "Gp",
      "Ej",
      "it",
      "jk",
      "Xn",
      "NO",
      "SO",
      "hR",
      "Yu",
      "PT",
      "Bz",
      "uP",
      "Km",
      "vJ",
      "ja",
      "my",
      "aa",
      "jN",
      "fg",
      "jn",
      "Qo",
      "yq",
      "FK",
      "Qa",
      "Np",
      "wp",
      "Yr",
      "yF",
      "dF",
      "zg",
    ];
    var seg01 = items[Math.floor(Math.random() * items.length)];
    var seg02 = aZ[Math.floor(Math.random() * aZ.length)];
    var seg03 = aZ[Math.floor(Math.random() * aZ.length)];
    var seg04 = aZ[Math.floor(Math.random() * aZ.length)];
    var unicode = seg01 + "" + seg02 + "" + seg03 + "" + seg04;

    this.linkParams = JSON.parse(localStorage.getItem("searchParams"));

    this.loadingLink = true;

    let cartitem = this.props.items;

    for (var i = 0; i < cartitem.length; i++) {
      // let itemInCart = JSON.parse(cartitem[i]);

      let linkData = {
        code: unicode,
        items: JSON.stringify(cartitem),
        start: localStorage.start
          ? moment(localStorage.periodeStart, "DD/MM/YYYY").format("X")
          : moment().format("X"),
        end: localStorage.end
          ? moment(localStorage.periodeEnd, "DD/MM/YYYY").format("X")
          : moment().format("X"),
        periodeStart: localStorage.getItem("periodeStart"),
        periodeEnd: localStorage.getItem("periodeEnd"),
        temps_reservation: localStorage.getItem("temps_reservation"),
        id_user: this.user_id,
      };

      // this.completeCheckoutService.createFrontLinkData(linkData)
      // .subscribe((data: any) => {

      // })
    }

    setTimeout(() => {
      this.loadingLink = false;
      this.extLink = unicode;
    }, 4000);
  }

  handleStartSearch = () => {
    let searchData = { ...this.state.searchData };

    localStorage.setItem("end", searchData.end);
    localStorage.setItem("range", searchData.range);
    localStorage.setItem("start", searchData.start);

    this.SearchRequest(searchData);
    this.setState({ alreadySearch: true });
  };

  handleChangeDate = (event) => {
    let searchData = { ...this.state.searchData };
    if (event != null) {
      const startDate = moment(event[0]);
      const endDate = moment(event[1]);
      searchData["start"] = new Date(event[0]).getTime();
      searchData["end"] = new Date(event[1]).getTime();
      console.log(searchData);
      let range = endDate.diff(startDate, "days");
      searchData["range"] = range;
      localStorage.setItem("duration", range);
      this.setState({ searchData });
      setTimeout(() => {
        if (range <= 16) {
          toast.info(
            "Choisissez une période supérieure à 15 jours et inférieure à 1 an",
            {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }, 500);
    }
  };

  componentDidMount() {
    this.setState({
      MapLoading: true,
      campagne: localStorage.getItem("cpgne")
        ? localStorage.getItem("cpgne")
        : "",
    });

    this.props.items.length > 0 &&
      this.generateCartSummary(this.props.items, this.state.prod_option);

    if (localStorage.getItem("duration")) {
      this.setState({ alreadySearch: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      // this.setState({ MapLoading: true })
      // this.setState({ searchFormboxIsOpen: false, espaceDataIsLoading: true })

      this.props.items.length > 0 &&
        this.generateCartSummary(this.props.items, this.state.prod_option);
    }
  }

  //   <PanneauxItem />
  render() {
    let {
      filterboxIsOpen,
      searchFormboxIsOpen,
      espaceDataIsLoading,
      espaceDataList,
      espaceDataListForMap,
      requestEspaceDataListTmp,
      showMap,
      mapViewer,
      defaultlat,
      defaultlng,
      oneViewSelected,
      MapLoading,
      oneViewSelectedId,
      afterLoop,
      title,
      cartSummary,
      taux_frequentation,
      campagne,
    } = this.state;

    console.log("items", this.props);

    return (
      <>
        <section className="login-content">
          <Row className="m-0 bg-white">
            {!mapViewer && (
              <>
                <Col md={"12"}>
                  {this.props.items.length === 0 ? (
                    <Row className="justify-content-center align-content-center h-100vh">
                      <EmptyCart history={this.props.history} />
                    </Row>
                  ) : (
                    <Row className="justify-content-center">
                      <Col md="12">
                        <h3 className="search-title p-2">
                          <strong>Mes coups de coeur </strong>
                        </h3>
                      </Col>
                      <Col md="12">
                        <div className="result-header-warn">
                          <svg
                            width="46"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.81409 20.4368H19.1971C20.7791 20.4368 21.7721 18.7267 20.9861 17.3527L13.8001 4.78775C13.0091 3.40475 11.0151 3.40375 10.2231 4.78675L3.02509 17.3518C2.23909 18.7258 3.23109 20.4368 4.81409 20.4368Z"
                              stroke="#eaba3a"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.0024 13.4147V10.3147"
                              stroke="#eaba3a"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.995 16.5H12.005"
                              stroke="#eaba3a"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p className="m-l-2 text-left">
                            Les coûts de reservation de vos{" "}
                            <strong>coups de coeur</strong> sont relatifs à la
                            campagne en cours
                          </p>
                        </div>
                      </Col>
                      <Col md="12" className="result-header-o">
                        <Header
                          items={this.props.items}
                          showMapViewer={this.showMapViewer}
                          taux_frequentation={taux_frequentation}
                          restoreCartParams={this.restoreCartParams}
                        />
                      </Col>
                      <Col md="12">
                        <div className="result-boby">
                          <SideDataList
                            espaceDataIsLoading={espaceDataIsLoading}
                            espaceDataList={this.props.items}
                            OneViewSelected={this.OneViewSelected}
                            oneViewSelectedId={this.state.oneViewSelectedId}
                            alreadySearch={this.state.alreadySearch}
                            handleStartSearch={this.handleStartSearch}
                            handleChangeDate={this.handleChangeDate}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
              </>
            )}
          </Row>
        </section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("state", state);
  let addedItemsTmp = state.wishReducer;
  return {
    items: addedItemsTmp.addedWishItems,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id) => {
      dispatch(addToCart(id));
    },
    removeItem: (id) => {
      dispatch(removeItem(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WishList);
