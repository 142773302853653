import axios from "axios";
import config from "./config";

export default {
  signUp: (data) => {
    // console.log(data);
    return axios.post(`${config.URL_END_POINT}/auth/signup`, data);
  },
  signIn: (data) => {
    // console.log(data);
    return axios.post(`${config.URL_END_POINT}/auth/signin`, data);
  },
  requestResetPassword: (data) => {
    return axios.post(
      `${config.URL_END_POINT}/auth/requestResetPassword`,
      data
    );
  },
  resetPassword: (id) => {
    return axios.post(`${config.URL_END_POINT}/auth/resetPassword`);
  },
  userInfoById: (id) => {
    return axios.get(`${config.URL_END_POINT}/account/${id}`);
  },
  updateUserPassword: (id, data) => {
    return axios.put(`${config.URL_END_POINT}/account/${id}/pwd/update`, data);
  },
  resetUserPassword: (id, data) => {
    return axios.put(`${config.URL_END_POINT}/account/${id}/reset/pwd`, data);
  },
  updateUserPasswordByAdmin: (id, data) => {
    return axios.put(
      `${config.URL_END_POINT}/account/${id}/pwd/admin/update`,
      data
    );
  },
  updateUserAccount: (id, data) => {
    return axios.put(`${config.URL_END_POINT}/account/update/${id}`, data);
  },
};
