import {
  ADD_TO_CART,
  REMOVE_ITEM,
  ADD_TO_WISH,
  REMOVE_WISH_ITEM,
} from './action-types/cart-actions'

//add cart action
export const addToCart = (item) => {
  return {
    type: ADD_TO_CART,
    item,
  }
}

//remove item action
export const removeItem = (item) => {
  return {
    type: REMOVE_ITEM,
    item,
  }
}
