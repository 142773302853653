import React, { Component } from "react";

export default class Pager extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { totalPages, currentPage } = this.props;
    return (
      <ul className="styles_PaginationListPage">
        <li className="styles_PaginationPrev">
          <button
            onClick={this.props.PaginationPrev}
            disabled={currentPage == 0 ? true : false}
          >
            <i className="feather icon-chevron-left"></i>
          </button>
        </li>
        <li className="styles_PaginationItem">
          {currentPage + 1} / {totalPages}
        </li>
        <li className="styles_PaginationNext">
          <button
            onClick={this.props.PaginationNext}
            disabled={totalPages - currentPage == 1 ? true : false}
          >
            <i className="feather icon-chevron-right"></i>
          </button>
        </li>
      </ul>
    );
  }
}
