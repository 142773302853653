export default {
  data: [
    {
      id: 1128,
      title: "Classic Abengourou Artère communale  58GP",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "allant à l'hotel de ville",
      reference: "",
      geolongitude: "45°30'19.3\"N",
      geolatitude: "73°34'23.7\"W ",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABENGOUROU.%20Centre%20ville%20carrefour%20jeunesse%20sens%20aller%20entr%C3%A9e%20de%20l%27Hotel%20de%20ville..jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 43,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Avant un croisement de rue",
      description:
        "ABENGOUROU. Centre ville carrefour jeunesse sens aller entrée de l'Hotel de ville.",
      code: "abengourou",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABENGOUROU.%20Centre%20ville%20carrefour%20jeunesse%20sens%20aller%20entr%C3%A9e%20de%20l%27Hotel%20de%20ville..jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABENGOUROU.%20Centre%20ville%20carrefour%20jeunesse%20sens%20aller%20entr%C3%A9e%20de%20l%27Hotel%20de%20ville..jpg",
      image_4: "[284]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abengourou",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1134,
      title: "Classic Abidjan Autoroute d'Abobo 17jn",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4: '"[299]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1140,
      title: "Classic Abidjan Autoroute d'Abobo 17lc",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4: '"\\"\\\\\\"\\\\\\\\\\\\\\"[299]\\\\\\\\\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1148,
      title: "Classic Abidjan Autoroute d'Abobo 2qT",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1147,
      title: "Classic Abidjan Autoroute d'Abobo 37Bx",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1138,
      title: "Classic Abidjan Autoroute d'Abobo 51Dz",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4: '"\\"[299]\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1142,
      title: "Classic Abidjan Autoroute d'Abobo 51wU",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1137,
      title: "Classic Abidjan Autoroute d'Abobo 53jq",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"[299]\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1144,
      title: "Classic Abidjan Autoroute d'Abobo 54lc",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1145,
      title: "Classic Abidjan Autoroute d'Abobo 54lq",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1139,
      title: "Classic Abidjan Autoroute d'Abobo 54SH",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"[299]\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1146,
      title: "Classic Abidjan Autoroute d'Abobo 58aa",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1143,
      title: "Classic Abidjan Autoroute d'Abobo 63VM",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"[299]\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1135,
      title: "Classic Abidjan Autoroute d'Abobo 84lq",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4: "[299]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1136,
      title: "Classic Abidjan Autoroute d'Abobo 94jk",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4: '"\\"\\\\\\"[299]\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1141,
      title: "Classic Abidjan Autoroute d'Abobo 9Wn",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "de le sens d adjame abobo",
      reference: "",
      geolongitude: "5.396060",
      geolatitude: "-4.021121.",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "situé sur une grande voix où le trafique de la  circulation est élévé  ainsi qu avec beaucoup de reverain conte tenue de l universite",
      code: "ADJAME DEV-45 UNIVERSITE ABOBO-ADJAME SENS ALLER ABOBO (4)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20DEV-45%20UNIVERSITE%20ABOBO-ADJAME%20SENS%20ALLER%20ABOBO.jpeg",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1132,
      title: "Classic Abobo Artère communale  22XD",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "5.433105",
      geolatitude: "-4.016236",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%20DEV-06%20APRES%20LYCEE%20MODERNE%20COTE%20DROIT%20DIR%20PHARMACIE%20ST%20FRANCOIS%20XAVIER.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 10,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 12,
      communeId: 13,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Dans le quartier",
      description: "",
      code: "ABOBO DEV-06 APRES LYCEE MODERNE COTE DROIT DIR PHARMACIE ST FRANCOIS XAVIER",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%20DEV-06%20APRES%20LYCEE%20MODERNE%20COTE%20DROIT%20DIR%20PHARMACIE%20ST%20FRANCOIS%20XAVIER.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%20DEV-06%20APRES%20LYCEE%20MODERNE%20COTE%20DROIT%20DIR%20PHARMACIE%20ST%20FRANCOIS%20XAVIER.jpeg",
      image_4: "[293]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 10,
      nom_type_atere: "Route secondaire",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 13,
      slug: "Abobo",
    },
    {
      id: 1154,
      title: "Classic Abobo Artère communale  34XD",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "aller angré,face au rond point samanké",
      reference: "",
      geolongitude: "5414865",
      geolatitude: "-4006590",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%2003.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 12,
      communeId: 13,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "",
      code: "abobo03-carrefour chefferie aboboté",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%2003.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%2003.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 13,
      slug: "Abobo",
    },
    {
      id: 1153,
      title: "Classic Abobo Artère communale  60LM",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "aller angré,face au rond point samanké",
      reference: "",
      geolongitude: "5414865",
      geolatitude: "-4006590",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%2003.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 12,
      communeId: 13,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "",
      code: "abobo03-carrefour chefferie aboboté",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%2003.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%2003.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 13,
      slug: "Abobo",
    },
    {
      id: 1156,
      title: "Classic Abobo Artère communale  81VZ",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens retour zone industrielle,face n'dotré",
      reference: "",
      geolongitude: "5.443177",
      geolatitude: "-4.069537",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%2005%20PK18.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 12,
      communeId: 13,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description: "",
      code: "ABOBO 05-PK18-n'dotré",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%2005%20PK18.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%2005%20PK18.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 13,
      slug: "Abobo",
    },
    {
      id: 1151,
      title: "Classic Abobo Artère et Voie de l'intérieur 34GP",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens retour d Allepe",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/abobo%202.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 9,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 12,
      communeId: 13,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "situé dans le sens retour d Allepe",
      code: "abobo 02-belleville",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/abobo%202.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/abobo%202.PNG",
      image_4: '""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 9,
      nom_type_atere: "Route nationale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 13,
      slug: "Abobo",
    },
    {
      id: 1150,
      title: "Classic Abobo Artère et Voie de l'intérieur 50LM",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens aller d Allepe",
      reference: "",
      geolongitude: "5.434625",
      geolatitude: "-3.988288",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 12,
      communeId: 13,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "ABOBO belleville,caerefoureau et forêt,sens aller ALLEPE ,face Abobo baoulè",
      code: "ABOBO 01-Abobo belleville(GEK-CI)",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%2001.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%2001.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 13,
      slug: "Abobo",
    },
    {
      id: 1152,
      title: "Classic Abobo Artère et Voie de l'intérieur 94cl",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens retour d Allepe",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/abobo%202.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 9,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 12,
      communeId: 13,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "situé dans le sens retour d Allepe",
      code: "abobo 02-belleville",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/abobo%202.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/abobo%202.PNG",
      image_4: '"\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 9,
      nom_type_atere: "Route nationale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 13,
      slug: "Abobo",
    },
    {
      id: 1113,
      title: "Classic Aboisso Artère communale  22jk",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Aller au marché",
      reference: "",
      geolongitude: " 3°12'32.2\"W",
      geolatitude: "5°28'06.4\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 31,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Avant un croisement de rue",
      description:
        "Situé au marché, face a la gare. Non loin du super marché Bon Prix",
      code: "Aboisso",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"[292,291,290]\\\\\\\\\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Aboisso",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1121,
      title: "Classic Aboisso Artère communale  22QX",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Allant dans ",
      reference: "",
      geolongitude: "3°12'32.2\"W",
      geolatitude: "5°28'02.7\"N ",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20Pharmacie%20de%20la%20BIA.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 31,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Aur une route secondaire",
      description:
        "Situé a quelques 50metre de du marché fètai, en face de la pharmacie BIA",
      code: "Aboisso",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20Pharmacie%20de%20la%20BIA.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20Pharmacie%20de%20la%20BIA.jpg",
      image_4: "[284,297,273]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Aboisso",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1119,
      title: "Classic Aboisso Artère communale  2SH",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Aller au marché",
      reference: "",
      geolongitude: " 3°12'32.2\"W",
      geolatitude: "5°28'06.4\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 31,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Avant un croisement de rue",
      description:
        "Situé au marché, face a la gare. Non loin du super marché Bon Prix",
      code: "Aboisso",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_4: '"[292,291,290]"',
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Aboisso",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1112,
      title: "Classic Aboisso Artère communale  4Rb",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Aller au marché",
      reference: "",
      geolongitude: " 3°12'32.2\"W",
      geolatitude: "5°28'06.4\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 31,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Avant un croisement de rue",
      description:
        "Situé au marché, face a la gare. Non loin du super marché Bon Prix",
      code: "Aboisso",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_4: '"\\"[292,291,290]\\""',
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Aboisso",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1123,
      title: "Classic Aboisso Artère communale  53Fy",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "allant à Ayamé",
      reference: "",
      geolongitude: "3°11'45.4\"W",
      geolatitude: "5°28'42.7\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20Sortie%20par%20Ayam%C3%A9%20route%20du%20lyc%C3%A9e%20moderne%20face%20centre%20ville.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 31,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Dans le quartier",
      description:
        "ABOISSO. Sortie par Ayamé route du lycée moderne face centre ville",
      code: null,
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20Sortie%20par%20Ayam%C3%A9%20route%20du%20lyc%C3%A9e%20moderne%20face%20centre%20ville.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20Sortie%20par%20Ayam%C3%A9%20route%20du%20lyc%C3%A9e%20moderne%20face%20centre%20ville.jpg",
      image_4: "[293,301]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Aboisso",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1117,
      title: "Classic Aboisso Artère communale  53Rb",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Aller au marché",
      reference: "",
      geolongitude: " 3°12'32.2\"W",
      geolatitude: "5°28'06.4\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 31,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Avant un croisement de rue",
      description:
        "Situé au marché, face a la gare. Non loin du super marché Bon Prix",
      code: "Aboisso",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_4: '"\\"\\\\\\"[292,291,290]\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Aboisso",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1114,
      title: "Classic Aboisso Artère communale  54qd",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Aller au marché",
      reference: "",
      geolongitude: " 3°12'32.2\"W",
      geolatitude: "5°28'06.4\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 31,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Avant un croisement de rue",
      description:
        "Situé au marché, face a la gare. Non loin du super marché Bon Prix",
      code: "Aboisso",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"[292,291,290]\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Aboisso",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1120,
      title: "Classic Aboisso Artère communale  58Rv",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Allant dans ",
      reference: "",
      geolongitude: "3°12'32.2\"W",
      geolatitude: "5°28'02.7\"N ",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20Pharmacie%20de%20la%20BIA.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 31,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Aur une route secondaire",
      description:
        "Situé a quelques 50metre de du marché fètai, en face de la pharmacie BIA",
      code: "Aboisso",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20Pharmacie%20de%20la%20BIA.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20Pharmacie%20de%20la%20BIA.jpg",
      image_4: '"[284,297,273]"',
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Aboisso",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1111,
      title: "Classic Aboisso Artère communale  58zg",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Entrant dans la v",
      reference: "",
      geolongitude: "3°13'01.7\"W",
      geolatitude: "5°28'20.5\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20Entr%C3%A9e%20ville%20pr%C3%A9s%20du%20village%20SOS%20face%20Bonoua.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 10,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 31,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Aur une route secondaire",
      description:
        "a l'entrée de de la ville d'aboisso, non loin de l'espace Alimahou un endroit dédié à l'élevage\nPrès du village SOS",
      code: "Aboisso",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20Entr%C3%A9e%20ville%20pr%C3%A9s%20du%20village%20SOS%20face%20Bonoua.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20Entr%C3%A9e%20ville%20pr%C3%A9s%20du%20village%20SOS%20face%20Bonoua.jpg",
      image_4: "[279]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 10,
      nom_type_atere: "Route secondaire",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Aboisso",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1110,
      title: "Classic Aboisso Artère communale  81Cm",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "allant à Bouna",
      reference: "",
      geolongitude: "3°31'06.1\"W",
      geolatitude: "5°17'05.1\"N ",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20Carrefour%20de%20Samo%20sens%20aller%20Bonoua.JPG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 31,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Avant un croisement de rue",
      description:
        "ABOISSO. Carrefour de Samo sens aller Bonoua, à quelques centaine de mètre du collège situé a la sortie de Bouna.",
      code: "Aboisso",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20Carrefour%20de%20Samo%20sens%20aller%20Bonoua.JPG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20Carrefour%20de%20Samo%20sens%20aller%20Bonoua.JPG",
      image_4: "[292,290]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Aboisso",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1118,
      title: "Classic Aboisso Artère communale  81Fj",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Aller au marché",
      reference: "",
      geolongitude: " 3°12'32.2\"W",
      geolatitude: "5°28'06.4\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 31,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Avant un croisement de rue",
      description:
        "Situé au marché, face a la gare. Non loin du super marché Bon Prix",
      code: "Aboisso",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_4: "[292,291,290]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Aboisso",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1115,
      title: "Classic Aboisso Artère communale  84PT",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Aller au marché",
      reference: "",
      geolongitude: " 3°12'32.2\"W",
      geolatitude: "5°28'06.4\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 31,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Avant un croisement de rue",
      description:
        "Situé au marché, face a la gare. Non loin du super marché Bon Prix",
      code: "Aboisso",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"[292,291,290]\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Aboisso",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1116,
      title: "Classic Aboisso Artère communale  94Xn",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Aller au marché",
      reference: "",
      geolongitude: " 3°12'32.2\"W",
      geolatitude: "5°28'06.4\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 31,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Avant un croisement de rue",
      description:
        "Situé au marché, face a la gare. Non loin du super marché Bon Prix",
      code: "Aboisso",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20March%C3%A9%20face%20%C3%A0%20la%20gare.jpg",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"[292,291,290]\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\',
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Aboisso",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1122,
      title: "Classic Aboisso Artère et Voie de l'intérieur 50SO",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "A proximité de NOE",
      reference: "",
      geolongitude: " 3°12'14.4\"W",
      geolatitude: "5°28'33.6\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20Sortie%20aboisso%20pres%20des%20champs%20de%20bananiers%20face%20NOE.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 11,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 31,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "ABOISSO. Sortie aboisso près des champs de bananiers face NOE",
      code: null,
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20Sortie%20aboisso%20pres%20des%20champs%20de%20bananiers%20face%20NOE.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOISSO.%20Sortie%20aboisso%20pres%20des%20champs%20de%20bananiers%20face%20NOE.jpeg",
      image_4: "[300,10]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 11,
      nom_type_atere: "Route départementale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Aboisso",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1157,
      title: "Classic Adjamé Autoroute d'Abobo 34Wn",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "abobo sens aller face adjamé",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20macaci.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 22,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "",
      code: "ADJAME macaci",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20macaci.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20macaci.PNG",
      image_4: "[294,304]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 22,
      slug: "Adjamé",
    },
    {
      id: 1094,
      title: "Classic Adjamé Autoroute d'Abobo 53LD",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "autoroute d'abobo ,apres filtisac , direction adjame",
      reference: "",
      geolongitude: "5.376174",
      geolatitude: "-4.026661",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/pmabo90-autorouted%27abobo%2Capresfiltisac%2Cdirectionadjame_optimized_optimized%20-%20Copie.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 59,
      typeAtereId: 9,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 22,
      referenceId: 279,
      positionPanneau: "",
      positionAxe: "Sur la  grande artère",
      description: "",
      code: "pmabo 90-autoroute d'abobo ,apres filtisac , direction adjame",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/pmabo90-autorouted%27abobo%2Capresfiltisac%2Cdirectionadjame_optimized_optimized%20-%20Copie.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/pmabo90-autorouted%27abobo%2Capresfiltisac%2Cdirectionadjame_optimized_optimized%20-%20Copie.jpg",
      image_4: '""',
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 59,
      nom_regis: "Pyramide Média",
      id_type_atere: 9,
      nom_type_atere: "Route nationale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 22,
      slug: "Adjamé",
    },
    {
      id: 1158,
      title: "Classic Adjamé Autoroute d'Abobo 54Of",
      qualite: "Bonne",
      eclairage: "Eclairé",
      statut: "INDISPONIBLE",
      sens: "adjamé sens retour face macaci",
      reference: "",
      geolongitude: "5.377354",
      geolatitude: "-4.026038",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20macaci%2002.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 22,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "        Adjamé, Macaci voie express Abobo, sens retour Abobo, face Macaci",
      code: "ADJAME macaci-02",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20macaci%2002.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADJAME%20macaci%2002.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 22,
      slug: "Adjamé",
    },
    {
      id: 1097,
      title: "Classic Adzopé Artère communale  17Ri",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "rond point",
      reference: "",
      geolongitude: "3°51'51.1\"W",
      geolatitude: "6°06'23.8\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADZOPE.%20Centre%20ville%20pres%20du%20centre%20d%27enseignement%20Technique.JPG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 47,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Après un croisement de rue",
      description: null,
      code: "ADZOPE.",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADZOPE.%20Centre%20ville%20pres%20du%20centre%20d%27enseignement%20Technique.JPG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADZOPE.%20Centre%20ville%20pres%20du%20centre%20d%27enseignement%20Technique.JPG",
      image_4: "null",
      production: "",
      prix_achat_minimum: " 60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Adzopé",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1099,
      title: "Classic Adzopé Artère communale  2hR",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "arrivé à Adzopé",
      reference: "",
      geolongitude: "3°52'29.3\"W",
      geolatitude: "6°04'52.1\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADZOPE.%20Entr%C3%A9e%20ville%20par%20abidjan%20face%20au%20corridor.JPG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 9,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 47,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "ADZOPE. Entrée ville par abidjan face au corridor",
      code: "ADZOPE",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADZOPE.%20Entr%C3%A9e%20ville%20par%20abidjan%20face%20au%20corridor.JPG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADZOPE.%20Entr%C3%A9e%20ville%20par%20abidjan%20face%20au%20corridor.JPG",
      image_4: '"null"',
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 9,
      nom_type_atere: "Route nationale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Adzopé",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1098,
      title: "Classic Adzopé Artère communale  34Rb",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "rond point",
      reference: "",
      geolongitude: "3°51'51.1\"W",
      geolatitude: "6°06'23.8\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADZOPE.%20Centre%20ville%20pres%20du%20centre%20d%27enseignement%20Technique.JPG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 47,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Après un croisement de rue",
      description: null,
      code: "ADZOPE.",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADZOPE.%20Centre%20ville%20pres%20du%20centre%20d%27enseignement%20Technique.JPG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADZOPE.%20Centre%20ville%20pres%20du%20centre%20d%27enseignement%20Technique.JPG",
      image_4: '"null"',
      production: "",
      prix_achat_minimum: " 60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Adzopé",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1096,
      title: "Classic Adzopé Artère et Voie de l'intérieur 53QX",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Aller Akoupé",
      reference: "",
      geolongitude: "-3.856591",
      geolatitude: "6.102270",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADZOPE.%20Carrefour%20de%20la%20gare%20routier%20pres%20du%20stade.JPG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 47,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Aur une route secondaire",
      description: "ADZOPE. Carrefour de la gare routier pres du stade",
      code: "ADZOPE",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADZOPE.%20Carrefour%20de%20la%20gare%20routier%20pres%20du%20stade.JPG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ADZOPE.%20Carrefour%20de%20la%20gare%20routier%20pres%20du%20stade.JPG",
      image_4: "[273,306,297]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Adzopé",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1100,
      title: "Classic Agboville Artère communale  9FN",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "En plein rond  point",
      reference: "",
      geolongitude: "-3.9491918999999998",
      geolatitude: "5.3654085",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/AGBOVILLE.%20Centre%20ville%20au%20rond%20point%20de%20l%27Agneby%20%20%C3%A0%20l%27entr%C3%A9e%20de%20l%27%C3%A9glise%20catholique%20St%20Jean%20Marie%20Vianney%20%283%29.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 46,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Après un croisement de rue",
      description:
        "AGBOVILLE. Centre ville au rond point de l'Agneby  à l'entrée de l'église catholique St Jean Marie Vianney (3)",
      code: "agboville",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/AGBOVILLE.%20Centre%20ville%20au%20rond%20point%20de%20l%27Agneby%20%20%C3%A0%20l%27entr%C3%A9e%20de%20l%27%C3%A9glise%20catholique%20St%20Jean%20Marie%20Vianney%20%283%29.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/AGBOVILLE.%20Centre%20ville%20au%20rond%20point%20de%20l%27Agneby%20%20%C3%A0%20l%27entr%C3%A9e%20de%20l%27%C3%A9glise%20catholique%20St%20Jean%20Marie%20Vianney%20%283%29.jpg",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Agboville",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1101,
      title: "Classic Agboville Artère et Voie de l'intérieur 34SO",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "entrant dans la ville",
      reference: "",
      geolongitude: "4°11'46.0\"W",
      geolatitude: "5°54'53.2\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/AGBOVILLE.%20Entr%C3%A9e%20ville%20par%20Abidjan%20avant%20le%20corridor%20face%20corridor%20%281%29.JPG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 9,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 46,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Aur une route secondaire",
      description:
        "AGBOVILLE. Entrée ville par Abidjan avant le corridor face corridor (1)",
      code: "Agboville",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/AGBOVILLE.%20Entr%C3%A9e%20ville%20par%20Abidjan%20avant%20le%20corridor%20face%20corridor%20%281%29.JPG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/AGBOVILLE.%20Entr%C3%A9e%20ville%20par%20Abidjan%20avant%20le%20corridor%20face%20corridor%20%281%29.JPG",
      image_4: "[304,291]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 9,
      nom_type_atere: "Route nationale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Agboville",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1102,
      title: "Classic Agboville Artère et Voie de l'intérieur 53PT",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "entrant dans la ville",
      reference: "",
      geolongitude: "4°11'46.0\"W",
      geolatitude: "5°54'53.2\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/AGBOVILLE.%20Entr%C3%A9e%20ville%20par%20Abidjan%20avant%20le%20corridor%20face%20corridor%20%281%29.JPG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 9,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 46,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Aur une route secondaire",
      description:
        "AGBOVILLE. Entrée ville par Abidjan avant le corridor face corridor (1)",
      code: "Agboville",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/AGBOVILLE.%20Entr%C3%A9e%20ville%20par%20Abidjan%20avant%20le%20corridor%20face%20corridor%20%281%29.JPG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/AGBOVILLE.%20Entr%C3%A9e%20ville%20par%20Abidjan%20avant%20le%20corridor%20face%20corridor%20%281%29.JPG",
      image_4: '"[304,291]"',
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 9,
      nom_type_atere: "Route nationale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Agboville",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1155,
      title: "Classic Anyama Artère communale  81Rv",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens retour angré,face au rond point samanké",
      reference: "",
      geolongitude: "5414865",
      geolatitude: "-4006590",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO04.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 12,
      communeId: 25,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: null,
      code: "abobo04-carrefour chefferie aboboté",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO04.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO04.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 41,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 25,
      slug: "Anyama",
    },
    {
      id: 1172,
      title: "Classic Anyama Autoroute d'Abobo 50Za",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ANYAMA%20entree-01.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 53,
      formatId: 15,
      villeId: 12,
      communeId: 25,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "         Anyama, Entrée ville avant la \u000b\u000b\u000b                                                                                                                                                                                gare des Gbaka, face Anyama",
      code: "ANYAMA entree-01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ANYAMA%20entree-01.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ANYAMA%20entree-01.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 41,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 25,
      slug: "Anyama",
    },
    {
      id: 1189,
      title: "Classic Assinie Artère et Voie de l'intérieur 17VZ",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens sortir d Assinie",
      reference: "",
      geolongitude: "5.171812",
      geolatitude: "-3.474971",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/ASSINIE%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 21,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description: "Assinie, entrée ville face à Assinie",
      code: "ASSINIE 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ASSINIE%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ASSINIE%2001.PNG",
      image_4: "[279]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Assinie",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1188,
      title: "Classic Assinie Artère et Voie de l'intérieur 58Ad",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens sortir d Assinie",
      reference: "",
      geolongitude: "5.171812",
      geolatitude: "-3.474971",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/ASSINIE%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 21,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description: "Assinie, entrée ville face à Assinie",
      code: "ASSINIE 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ASSINIE%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ASSINIE%2001.PNG",
      image_4: '"[279]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Assinie",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1190,
      title: "Classic Attingué  Artère et Voie de l'intérieur 2NB",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens aller sur yamoussokro",
      reference: "",
      geolongitude: "5.409664",
      geolatitude: "-4.155890",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ATTINGUE%20-01.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 42,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "PK 24 Nouvelle Zone industrielle, Terre plein autoroute du nord, sens aller Yamoussoukro, face Abidjan\n",
      code: "ATTINGUE PK24",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ATTINGUE%20-01.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ATTINGUE%20-01.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Attingué ",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1192,
      title: "Classic Attingué  Artère et Voie de l'intérieur 51QX",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens retour de yamoussokro",
      reference: "",
      geolongitude: "5.409151",
      geolatitude: "-4156491",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ATTINGUE%20-02.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 42,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "PK 24 Nouvelle Zone industrielle, Terre plein autoroute du nord, sens retour Yamoussoukro, face Brasivoire\n",
      code: "ATTINGUE -02- PK24",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ATTINGUE%20-02.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ATTINGUE%20-02.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Attingué ",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1191,
      title: "Classic Attingué  Artère et Voie de l'intérieur 9it",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens retour de yamoussokro",
      reference: "",
      geolongitude: "5.409151",
      geolatitude: "-4156491",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ATTINGUE%20-02.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 42,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "PK 24 Nouvelle Zone industrielle, Terre plein autoroute du nord, sens retour Yamoussoukro, face Brasivoire\n",
      code: "ATTINGUE -02- PK24",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ATTINGUE%20-02.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ATTINGUE%20-02.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Attingué ",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1173,
      title: "Classic Bassam Artère et Voie de l'intérieur 51Np",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BASSAM%20entree%20par%20l%20ancienne%20voir.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 20,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description: "",
      code: "BASSAM entree -01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BASSAM%20entree%20par%20l%20ancienne%20voir.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BASSAM%20entree%20par%20l%20ancienne%20voir.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bassam",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1174,
      title: "Classic Bassam Artère et Voie de l'intérieur 81Za",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BASSAM%20entree%20par%20l%20ancienne%20voir.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 20,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description: "",
      code: "BASSAM entree -01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BASSAM%20entree%20par%20l%20ancienne%20voir.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BASSAM%20entree%20par%20l%20ancienne%20voir.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bassam",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1198,
      title: "Classic Bondoukou Artère et Voie de l'intérieur 94jk",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "8.057706",
      geolatitude: "-2813729",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BONDOUKOU%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 45,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Bondoukou, Sortie Ville, Axe \u000b                                                                                                                                                                                                                                                                                                                    v                                 Bouna près du Cafop, face Bouna",
      code: "BONDOUKOU01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BONDOUKOU%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BONDOUKOU%2001.PNG",
      image_4: "[295,294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bondoukou",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1124,
      title: "Classic Bonoua Artère communale  4wU",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: " Proximité ",
      reference: "",
      geolongitude: "3°44'05.8\"W",
      geolatitude: "5°11'42.8\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BONOUA.%20Entr%C3%A9e%20de%20la%20ville%20face%20BASSAM.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 37,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "BONOUA. Entrée de la ville face BASSAM",
      code: "Bonoua",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BONOUA.%20Entr%C3%A9e%20de%20la%20ville%20face%20BASSAM.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BONOUA.%20Entr%C3%A9e%20de%20la%20ville%20face%20BASSAM.jpg",
      image_4: "[297,291,304]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bonoua",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1204,
      title: "Classic Bouaflé Artère et Voie de l'intérieur 17XD",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.985484",
      geolatitude: "-5.752369",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAFLE%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 50,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Bouaflé centre ville, sens aller stade muicipal, face rond point",
      code: "BOUAFLE 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAFLE%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAFLE%2001.PNG",
      image_4: '""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaflé",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1207,
      title: "Classic Bouaflé Artère et Voie de l'intérieur 4lc",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.985484",
      geolatitude: "-5.752369",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAFLE%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 50,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Bouaflé centre ville, sens aller stade muicipal, face rond point",
      code: "BOUAFLE 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAFLE%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAFLE%2001.PNG",
      image_4: '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaflé",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1206,
      title: "Classic Bouaflé Artère et Voie de l'intérieur 58aG",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.985484",
      geolatitude: "-5.752369",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAFLE%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 50,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Bouaflé centre ville, sens aller stade muicipal, face rond point",
      code: "BOUAFLE 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAFLE%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAFLE%2001.PNG",
      image_4: '"\\"\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaflé",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1205,
      title: "Classic Bouaflé Artère et Voie de l'intérieur 81cl",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.985484",
      geolatitude: "-5.752369",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAFLE%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 50,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Bouaflé centre ville, sens aller stade muicipal, face rond point",
      code: "BOUAFLE 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAFLE%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAFLE%2001.PNG",
      image_4: '"\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaflé",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1213,
      title: "Classic Bouaké Artère communale  16aG",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.678575",
      geolatitude: "-5.025689",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2003.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 10,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 14,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Dans le quartier",
      description:
        "Bouaké rue du poulet chaud, sens retour Air France 3 corridor sud, \u000bface Air France 3",
      code: "BOUAKE-03",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2003.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2003.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 10,
      nom_type_atere: "Route secondaire",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaké",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1212,
      title: "Classic Bouaké Artère communale  34LM",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.678575",
      geolatitude: "-5.025689",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2003.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 10,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 14,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Dans le quartier",
      description:
        "Bouaké rue du poulet chaud, sens retour Air France 3 corridor sud, \u000bface Air France 3",
      code: "BOUAKE-03",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2003.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2003.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 10,
      nom_type_atere: "Route secondaire",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaké",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1210,
      title: "Classic Bouaké Artère communale  4Wn",
      qualite: "Bonne",
      eclairage: "",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.678607",
      geolatitude: "-5.025809",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2002.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 14,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Dans le quartier",
      description:
        "Bouaké rue du poulet chaud, sens aller Air France 3 corridor sud, face carrefour poulet chaud",
      code: "BOUAKE -02",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2002.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2002.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaké",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1211,
      title: "Classic Bouaké Artère communale  81ri",
      qualite: "Bonne",
      eclairage: "",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.678607",
      geolatitude: "-5.025809",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2002.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 14,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Dans le quartier",
      description:
        "Bouaké rue du poulet chaud, sens aller Air France 3 corridor sud, face carrefour poulet chaud",
      code: "BOUAKE -02",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2002.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2002.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaké",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1208,
      title: "Classic Bouaké Artère et Voie de l'intérieur 37aG",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.684316",
      geolatitude: "-5.025674",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 14,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Bouaké quartier commerce axe Brobo, face rond point Centre Culturel Jacque AKA",
      code: "BOUAKE -01",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2001.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2001.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaké",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1214,
      title: "Classic Bouaké Artère et Voie de l'intérieur 54rI",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.690415",
      geolatitude: "-5.039048",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2004%20PNG.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 14,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "Bouaké Lycée Moderne Djibo Soungalo, face Haoungnouanssou",
      code: "BOUAKE -04",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2004%20PNG.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2004%20PNG.PNG",
      image_4: "[294,289]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaké",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1219,
      title: "Classic Bouaké Artère et Voie de l'intérieur 58Lm",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.719019",
      geolatitude: "-5.049932",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2006.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 11,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 14,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Bouaké zone industrielle entrée de l'usine Gonfreville, face au feu Résidence Soro",
      code: "BOUAKE -06",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2006.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2006.PNG",
      image_4: '"[294,300]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 11,
      nom_type_atere: "Route départementale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaké",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1209,
      title: "Classic Bouaké Artère et Voie de l'intérieur 63Fj",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.684316",
      geolatitude: "-5.025674",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 14,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Bouaké quartier commerce axe Brobo, face rond point Centre Culturel Jacque AKA",
      code: "BOUAKE -01",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2001.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2001.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaké",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1217,
      title: "Classic Bouaké Artère et Voie de l'intérieur 81aa",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.647996",
      geolatitude: "-5.027577",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2005.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 11,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 14,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Bouaké entrée ville Abidjan par déviation, air France 3, face corridor",
      code: "BOUAKE -05",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2005.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2005.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 11,
      nom_type_atere: "Route départementale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaké",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1218,
      title: "Classic Bouaké Artère et Voie de l'intérieur 84fh",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.719019",
      geolatitude: "-5.049932",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2006.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 11,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 14,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Bouaké zone industrielle entrée de l'usine Gonfreville, face au feu Résidence Soro",
      code: "BOUAKE -06",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2006.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2006.PNG",
      image_4: "[294,300]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 11,
      nom_type_atere: "Route départementale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaké",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1216,
      title: "Classic Bouaké Artère et Voie de l'intérieur 84je",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.647996",
      geolatitude: "-5.027577",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2005.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 11,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 14,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Bouaké entrée ville Abidjan par déviation, air France 3, face corridor",
      code: "BOUAKE -05",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2005.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2005.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 11,
      nom_type_atere: "Route départementale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaké",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1215,
      title: "Classic Bouaké Artère et Voie de l'intérieur 84qD",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.690415",
      geolatitude: "-5.039048",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2004%20PNG.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 14,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "Bouaké Lycée Moderne Djibo Soungalo, face Haoungnouanssou",
      code: "BOUAKE -04",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2004%20PNG.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/BOUAKE%2004%20PNG.PNG",
      image_4: '"[294,289]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Bouaké",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1185,
      title: "Classic Dabou Artère et Voie de l'intérieur 51PT",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "entree de dabou",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/DABOU%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 39,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "Dabou entrée ville carrefour  grande scierie, face Abidjan",
      code: "DABOU 01",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/DABOU%2001.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/DABOU%2001.PNG",
      image_4: '"[294,300]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Dabou",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1184,
      title: "Classic Dabou Artère et Voie de l'intérieur 54jn",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "entree de dabou",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/DABOU%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 39,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "Dabou entrée ville carrefour  grande scierie, face Abidjan",
      code: "DABOU 01",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/DABOU%2001.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/DABOU%2001.PNG",
      image_4: "[294,300]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Dabou",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1187,
      title: "Classic Dabou Artère et Voie de l'intérieur 81Cm",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "5.324916",
      geolatitude: "-4.351634",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/DABOU%2002.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 39,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "         Dabou entrée ville, carrefour \u000b\u000b                                                                                                                                                                                                                                                                                                                         grande Scierie, face Dabou",
      code: "DABOU 02",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/DABOU%2002.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/DABOU%2002.PNG",
      image_4: "[300]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Dabou",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1186,
      title: "Classic Dabou Artère et Voie de l'intérieur 91NO",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "entree de dabou",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/DABOU%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 39,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "Dabou entrée ville carrefour  grande scierie, face Abidjan",
      code: "DABOU 01",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/DABOU%2001.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/DABOU%2001.PNG",
      image_4: '"\\"[294,300]\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Dabou",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1202,
      title: "Classic Daloa Artère communale  84Ri",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.892023",
      geolatitude: "-6.435591",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/DALOA-02.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 10,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 18,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Dans le quartier",
      description:
        "Daloa Tazibo 2, clôture de la cour secondaire protestante, face Université",
      code: "DALOA 02 tazibo",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/DALOA-02.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/DALOA-02.PNG",
      image_4: "[294,299]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 10,
      nom_type_atere: "Route secondaire",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Daloa",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1201,
      title: "Classic Daloa Artère et Voie de l'intérieur 16Ti",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "face au coridor",
      reference: "",
      geolongitude: "6.875291",
      geolatitude: "-6.491161",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/DALOA-01.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 11,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 18,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Daloa, Axe Duékoué-Man soleil 2 avant le corridor, face corridor",
      code: "DALOA-01",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/DALOA-01.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/DALOA-01.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 11,
      nom_type_atere: "Route départementale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Daloa",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1203,
      title: "Classic Daloa Artère et Voie de l'intérieur 9VM",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.887000",
      geolatitude: "-6.451323",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/DALOA%2003.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 10,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 18,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Dans le quartier",
      description:
        "Daloa axe Man, près du Collège Suisse, face au village des Lépreux",
      code: "DALOA 03",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/DALOA%2003.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/DALOA%2003.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 10,
      nom_type_atere: "Route secondaire",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Daloa",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1127,
      title: "Classic Daoukro Artère et Voie de l'intérieur 60SH",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "allant de le sns de la capitale( Abidjan )",
      reference: "",
      geolongitude: "3°57'31.0\"W",
      geolatitude: "7°04'10.0\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/DAOUKRO.%20Rond%20point%20Sortie%20ville%20pour%20Abidjan.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 51,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "DAOUKRO. Rond point Sortie ville pour Abidjan",
      code: null,
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/DAOUKRO.%20Rond%20point%20Sortie%20ville%20pour%20Abidjan.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/DAOUKRO.%20Rond%20point%20Sortie%20ville%20pour%20Abidjan.jpg",
      image_4: "[300,299]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Daoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1125,
      title: "Classic Daoukro Artère et Voie de l'intérieur 85SH",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "allant  dans le sens de la grande mosquée",
      reference: "",
      geolongitude: "3°57'27.9\"W",
      geolatitude: "7°04'11.2\"N ",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/SOUBRE.%20Carrefour%20du%20grand%20march%C3%A9.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 51,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Après un croisement de rue",
      description:
        "DAOUKRO. Boulevard Henry Konan Bedie face au feu du commerce",
      code: "Daoukro",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/SOUBRE.%20Carrefour%20du%20grand%20march%C3%A9.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/SOUBRE.%20Carrefour%20du%20grand%20march%C3%A9.jpg",
      image_4: "[290,291,292]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Daoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1126,
      title: "Classic Dimbokro Artère communale  91FK",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "allant vers le collège st exupery ",
      reference: "",
      geolongitude: " 3°57'38.2\"W",
      geolatitude: "7°03'29.7\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/DAOUKRO.%20Rond%20point%20Entr%C3%A9e%20ville%20par%20Abidjan.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 35,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Aur une route secondaire",
      description: "DAOUKRO. Rond point Entrée ville par Abidjan",
      code: "Daoukro",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/DAOUKRO.%20Rond%20point%20Entr%C3%A9e%20ville%20par%20Abidjan.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/DAOUKRO.%20Rond%20point%20Entr%C3%A9e%20ville%20par%20Abidjan.jpg",
      image_4: "[301,272]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Dimbokro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1129,
      title: "Classic Divo Artère et Voie de l'intérieur 58rI",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "entrant a divo",
      reference: "",
      geolongitude: "5°21'24.6\"W",
      geolatitude: "5°50'51.2\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/Divo%20entr%C3%A9e%20ville%20par%20Abidjan%20quartier%20millionnaire.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 52,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "Divo entrée ville par Abidjan quartier millionnaire",
      code: "Divo",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/Divo%20entr%C3%A9e%20ville%20par%20Abidjan%20quartier%20millionnaire.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/Divo%20entr%C3%A9e%20ville%20par%20Abidjan%20quartier%20millionnaire.jpg",
      image_4: "[304]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Divo",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1200,
      title: "Classic Gagnoa Artère et Voie de l'intérieur 50Ri",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.147713",
      geolatitude: "-5.949675",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/GAGNOA%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 11,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 19,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Gagnoa, route de Sinfra Babré carrefour hôtel gloria, face Ouragahio",
      code: "GAGNOA 01",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/GAGNOA%2001.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/GAGNOA%2001.PNG",
      image_4: '"[294,301]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 11,
      nom_type_atere: "Route départementale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Gagnoa",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1199,
      title: "Classic Gagnoa Artère et Voie de l'intérieur 63qT",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.147713",
      geolatitude: "-5.949675",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/GAGNOA%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 11,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 19,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Gagnoa, route de Sinfra Babré carrefour hôtel gloria, face Ouragahio",
      code: "GAGNOA 01",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/GAGNOA%2001.PNG",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/GAGNOA%2001.PNG",
      image_4: "[294,301]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 11,
      nom_type_atere: "Route départementale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Gagnoa",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1180,
      title: "Classic Jacqueville Artère et Voie de l'intérieur 16Rv",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens entree sur jacqueville",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2002.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 40,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "au centre ville au virage , face a  la  residence Yace",
      code: "JACQUEVILLE 02",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2002.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2002.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Jacqueville",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1179,
      title: "Classic Jacqueville Artère et Voie de l'intérieur 2Rv",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "SENS entree jacqueville",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 40,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Jaqueville, Carrefour Jacqueville,    sens aller Jacqueville, face Station",
      code: "JACQUEVILLE 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Jacqueville",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1175,
      title: "Classic Jacqueville Artère et Voie de l'intérieur 4SH",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "SENS entree jacqueville",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 40,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Jaqueville, Carrefour Jacqueville,    sens aller Jacqueville, face Station",
      code: "JACQUEVILLE 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      image_4: '""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Jacqueville",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1181,
      title: "Classic Jacqueville Artère et Voie de l'intérieur 50DK",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens entree sur jacqueville",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2002.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 40,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "au centre ville au virage , face a  la  residence Yace",
      code: "JACQUEVILLE 02",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2002.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2002.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Jacqueville",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1182,
      title: "Classic Jacqueville Artère et Voie de l'intérieur 50Wn",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "entree jacque ville",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2003.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 40,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "Jacqueville, clôture du lycée professionnel, face corridor",
      code: "JACQUEVILLE 03",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2003.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2003.PNG",
      image_4: "[289]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Jacqueville",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1176,
      title: "Classic Jacqueville Artère et Voie de l'intérieur 60SO",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "SENS entree jacqueville",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 40,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Jaqueville, Carrefour Jacqueville,    sens aller Jacqueville, face Station",
      code: "JACQUEVILLE 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      image_4: '"\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Jacqueville",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1183,
      title: "Classic Jacqueville Artère et Voie de l'intérieur 81eX",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "entree jacque ville",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2003.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 40,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "Jacqueville, clôture du lycée professionnel, face corridor",
      code: "JACQUEVILLE 03",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2003.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2003.PNG",
      image_4: '"[289]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Jacqueville",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1177,
      title: "Classic Jacqueville Artère et Voie de l'intérieur 91FK",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "SENS entree jacqueville",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 40,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Jaqueville, Carrefour Jacqueville,    sens aller Jacqueville, face Station",
      code: "JACQUEVILLE 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      image_4: '"\\"\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Jacqueville",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1178,
      title: "Classic Jacqueville Artère et Voie de l'intérieur 91ur",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "SENS entree jacqueville",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 40,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Jaqueville, Carrefour Jacqueville,    sens aller Jacqueville, face Station",
      code: "JACQUEVILLE 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/JACQUEVILLE%2001.PNG",
      image_4: '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Jacqueville",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1131,
      title: "Classic Marcory Artère et Voie de l'intérieur 51DK",
      qualite: "Bonne",
      eclairage: "Eclairé",
      statut: "INDISPONIBLE",
      sens: "sens retour de l artci",
      reference: "",
      geolongitude: "5.33449",
      geolatitude: "-3973357",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/MARCORY%20CP%20ANOUMABO%20CLOTURE%20ARTCI%20SENS%20ALLER%20VGcMARCORY%20CP%20ANOUMABO%20CLOTURE%20ARTCI%20SENS%20ALLER%20VGEE.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 10,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 12,
      communeId: 16,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Dans le quartier",
      description: "MARCORY CP ANOUMABO CLOTURE ARTCI SENS ALLER VGE",
      code: "MARCORY CP ANOUMABO CLOTURE ARTCI SENS ALLER VGE",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/MARCORY%20CP%20ANOUMABO%20CLOTURE%20ARTCI%20SENS%20ALLER%20VGcMARCORY%20CP%20ANOUMABO%20CLOTURE%20ARTCI%20SENS%20ALLER%20VGEE.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/MARCORY%20CP%20ANOUMABO%20CLOTURE%20ARTCI%20SENS%20ALLER%20VGcMARCORY%20CP%20ANOUMABO%20CLOTURE%20ARTCI%20SENS%20ALLER%20VGEE.jpeg",
      image_4: '"\\"\\""',
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 10,
      nom_type_atere: "Route secondaire",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 16,
      slug: "Marcory",
    },
    {
      id: 1130,
      title: "Classic Marcory Artère et Voie de l'intérieur 84cl",
      qualite: "Bonne",
      eclairage: "Eclairé",
      statut: "INDISPONIBLE",
      sens: "sens retour de l artci",
      reference: "",
      geolongitude: "5.33449",
      geolatitude: "-3973357",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/MARCORY%20CP%20ANOUMABO%20CLOTURE%20ARTCI%20SENS%20ALLER%20VGcMARCORY%20CP%20ANOUMABO%20CLOTURE%20ARTCI%20SENS%20ALLER%20VGEE.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 10,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 12,
      communeId: 16,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Dans le quartier",
      description: "MARCORY CP ANOUMABO CLOTURE ARTCI SENS ALLER VGE",
      code: "MARCORY CP ANOUMABO CLOTURE ARTCI SENS ALLER VGE",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/MARCORY%20CP%20ANOUMABO%20CLOTURE%20ARTCI%20SENS%20ALLER%20VGcMARCORY%20CP%20ANOUMABO%20CLOTURE%20ARTCI%20SENS%20ALLER%20VGEE.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/MARCORY%20CP%20ANOUMABO%20CLOTURE%20ARTCI%20SENS%20ALLER%20VGcMARCORY%20CP%20ANOUMABO%20CLOTURE%20ARTCI%20SENS%20ALLER%20VGEE.jpeg",
      image_4: '""',
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 10,
      nom_type_atere: "Route secondaire",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 16,
      slug: "Marcory",
    },
    {
      id: 1197,
      title: "Classic N'Zuanouan Autoroute du Nord 34ZI",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens retour face peage de sobreko",
      reference: "",
      geolongitude: "6.071001",
      geolatitude: "-4.892669",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/N%27ZUANOUAN%2002PNG.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 14,
      formatId: 15,
      villeId: 65,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "     N’Zuanouan sens retour péage, face pont péage de Singrobo\n",
      code: "N'ZUANOUAN 02",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/N%27ZUANOUAN%2002PNG.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/N%27ZUANOUAN%2002PNG.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 14,
      nom_nom_atere: "Autoroute du Nord",
      frequentation: 103591,
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/autoroute-du-nord-3.jpg",
      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "N'Zuanouan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1195,
      title: "Classic N'Zuanouan Autoroute du Nord 53Bz",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.011129",
      geolatitude: "-4.824117",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/N%27ZUANOUAN%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 14,
      formatId: 15,
      villeId: 65,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "N’Zuanouan sens aller pont péage, face centre ville",
      code: "N'ZUANOUAN 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/N%27ZUANOUAN%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/N%27ZUANOUAN%2001.PNG",
      image_4: "[284]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 14,
      nom_nom_atere: "Autoroute du Nord",
      frequentation: 103591,
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/autoroute-du-nord-3.jpg",
      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "N'Zuanouan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1196,
      title: "Classic N'Zuanouan Autoroute du Nord 60aG",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens retour face peage de sobreko",
      reference: "",
      geolongitude: "6.071001",
      geolatitude: "-4.892669",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/N%27ZUANOUAN%2002PNG.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 14,
      formatId: 15,
      villeId: 65,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "     N’Zuanouan sens retour péage, face pont péage de Singrobo\n",
      code: "N'ZUANOUAN 02",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/N%27ZUANOUAN%2002PNG.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/N%27ZUANOUAN%2002PNG.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 14,
      nom_nom_atere: "Autoroute du Nord",
      frequentation: 103591,
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/autoroute-du-nord-3.jpg",
      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "N'Zuanouan",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1149,
      title: "Classic Port-bouet Artère communale  81fh",
      qualite: "Bonne",
      eclairage: "Eclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "5.443768",
      geolatitude: "-4.055005",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%20DEV-09%20%20PHARMACIE%20SAFIR%20AXE%20CARREFOUR%20AGRIPAC%20-%20CARREFOUR%20N%27DOTRE%20SENS%20N%27DOTRE.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 13,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Aur une route secondaire",
      description: "",
      code: "ABOBO DEV 09",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%20DEV-09%20%20PHARMACIE%20SAFIR%20AXE%20CARREFOUR%20AGRIPAC%20-%20CARREFOUR%20N%27DOTRE%20SENS%20N%27DOTRE.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/ABOBO%20DEV-09%20%20PHARMACIE%20SAFIR%20AXE%20CARREFOUR%20AGRIPAC%20-%20CARREFOUR%20N%27DOTRE%20SENS%20N%27DOTRE.jpeg",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 13,
      nom_type_atere: "Route principale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1133,
      title: "Classic Port-bouet Boulevard de Petit Bassam 22Dz",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "dans le sens de la route en partant vers vridi ",
      reference: "",
      geolongitude: "5.250908",
      geolatitude: "-39978384",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT-BOUET%20DEV-32%20CARREFOUR%20PETIT%20BASSAM%20%20COTE%20DROIT%20DIRECTION%20SIR%20%28FACE%20P%2033%29.jpeg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 8,
      modeleId: 7,
      artereId: 68,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "    situe sur un boulevard ou le trafique est dance et habite par plusieurs riverain",
      code: "PORT-BOUET DEV-32 CARREFOUR PETIT BASSAM  COTE DROIT DIRECTION SIR (FACE P 33) (2)",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT-BOUET%20DEV-32%20CARREFOUR%20PETIT%20BASSAM%20%20COTE%20DROIT%20DIRECTION%20SIR%20%28FACE%20P%2033%29.jpeg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT-BOUET%20DEV-32%20CARREFOUR%20PETIT%20BASSAM%20%20COTE%20DROIT%20DIRECTION%20SIR%20%28FACE%20P%2033%29.jpeg",
      image_4: "[304]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 8,
      nom_type_atere: "Boulevard",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 68,
      nom_nom_atere: "Boulevard de Petit Bassam",
      frequentation: 2000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1171,
      title: "Classic Port-bouet Boulevard du Gabon 4Of",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens aller a koumassi",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-03.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 8,
      modeleId: 7,
      artereId: 56,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "Port-Bouët Akwaba, sens aller Koumassi, face station Shell",
      code: "PORT BOUET akwaba",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-03.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-03.PNG",
      image_4: '"\\"[294]\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 8,
      nom_type_atere: "Boulevard",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 56,
      nom_nom_atere: "Boulevard du Gabon",
      frequentation: 1000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1170,
      title: "Classic Port-bouet Boulevard du Gabon 54wp",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens aller a koumassi",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-03.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 8,
      modeleId: 7,
      artereId: 56,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "Port-Bouët Akwaba, sens aller Koumassi, face station Shell",
      code: "PORT BOUET akwaba",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-03.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-03.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 8,
      nom_type_atere: "Boulevard",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 56,
      nom_nom_atere: "Boulevard du Gabon",
      frequentation: 1000,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1167,
      title: "Classic Port-bouet Route de Bassam 22eK",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens aller sur gonzague",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20PHARE%20-01.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 60,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "                Port-Bouët, Carrefour 43ème BIMA, sens                                                \u000b                                                                                                                                                                                                                                      retour carrefour Phare, face carrefour Phare",
      code: "PORT BOUET phare 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20PHARE%20-01.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20PHARE%20-01.PNG",
      image_4: '""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 60,
      nom_nom_atere: "Route de Bassam",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1161,
      title: "Classic Port-bouet Route de Bassam 2Dz",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens retour de bassam",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-02.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 60,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "             Port-Bouet, Bvd de Bassam, Gonzagueville Ex \u000b                                                                                                                                                                                                                   corridor, sens Retour rond point Anani, \u000bface terminus de Bus",
      code: "PORT BOUET gonzague -02",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-02.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-02.PNG",
      image_4: "[294,272]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 60,
      nom_nom_atere: "Route de Bassam",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1165,
      title: "Classic Port-bouet Route de Bassam 2Yr",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens abidjan bassam",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20jean%20folly-01.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 60,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "           Port-Bouet, Marché Jean-folly,            \u000b                                                                                                                                                                                                                                     sens aller Bassam, face Marché",
      code: "PORT BOUET jean folly-01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20jean%20folly-01.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20jean%20folly-01.PNG",
      image_4: '"[290]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 60,
      nom_nom_atere: "Route de Bassam",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1159,
      title: "Classic Port-bouet Route de Bassam 34Kf",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens bassam abidjan",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20gonzague.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 60,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "               Port-Bouet, Gonzagueville Ex corridor, face \u000b                                                                                                                                                                                                                                                                                                                                                                                   Commissaire de police du 24e Arrondissement",
      code: "PORT BOUET gonzague",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20gonzague.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20gonzague.PNG",
      image_4: "[294,305]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 60,
      nom_nom_atere: "Route de Bassam",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1166,
      title: "Classic Port-bouet Route de Bassam 37Kf",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "dans le sens retour de bassam face a la paroisse st louis",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20jean%20folly%20-02.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 60,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "            Port-Bouet, Marché Jean-folly, sens retour\u000b                                                                                                                                                                                                                                   Bassam, face Paroisse St Louis",
      code: "PORT BOUET jean folly-02",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20jean%20folly%20-02.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20jean%20folly%20-02.PNG",
      image_4: "[275]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 60,
      nom_nom_atere: "Route de Bassam",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1162,
      title: "Classic Port-bouet Route de Bassam 4Yr",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-03%20ADJOUFOU.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 60,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "       Port-Bouët Adjoufou carrefour          \u000b       marché, face entrée marché NUIT",
      code: "PORT BOUET adjoufou-01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-03%20ADJOUFOU.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-03%20ADJOUFOU.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 60,
      nom_nom_atere: "Route de Bassam",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1168,
      title: "Classic Port-bouet Route de Bassam 51iq",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens aller sur gonzague",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20PHARE%20-01.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 60,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "                Port-Bouët, Carrefour 43ème BIMA, sens                                                \u000b                                                                                                                                                                                                                                      retour carrefour Phare, face carrefour Phare",
      code: "PORT BOUET phare 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20PHARE%20-01.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20PHARE%20-01.PNG",
      image_4: '"\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 60,
      nom_nom_atere: "Route de Bassam",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1160,
      title: "Classic Port-bouet Route de Bassam 63XD",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens retour de bassam",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-02.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 60,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "             Port-Bouet, Bvd de Bassam, Gonzagueville Ex \u000b                                                                                                                                                                                                                   corridor, sens Retour rond point Anani, \u000bface terminus de Bus",
      code: "PORT BOUET gonzague -02",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-02.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET-02.PNG",
      image_4: '"[294,272]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 60,
      nom_nom_atere: "Route de Bassam",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1164,
      title: "Classic Port-bouet Route de Bassam 81fh",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens abidjan bassam",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20jean%20folly-01.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 60,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "           Port-Bouet, Marché Jean-folly,            \u000b                                                                                                                                                                                                                                     sens aller Bassam, face Marché",
      code: "PORT BOUET jean folly-01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20jean%20folly-01.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20jean%20folly-01.PNG",
      image_4: "[290]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 60,
      nom_nom_atere: "Route de Bassam",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1163,
      title: "Classic Port-bouet Route de Bassam 81jh",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens retour de gonzague ville",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/port%20bouet%20adjoufou%2004.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 60,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "",
      code: "PORT BOUET gonzague -04",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/port%20bouet%20adjoufou%2004.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/port%20bouet%20adjoufou%2004.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 60,
      nom_nom_atere: "Route de Bassam",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1169,
      title: "Classic Port-bouet Route de Bassam 81VI",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "sens aller sur gonzague",
      reference: "",
      geolongitude: "",
      geolatitude: "",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20PHARE%20-01.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 60,
      formatId: 15,
      villeId: 12,
      communeId: 15,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "                Port-Bouët, Carrefour 43ème BIMA, sens                                                \u000b                                                                                                                                                                                                                                      retour carrefour Phare, face carrefour Phare",
      code: "PORT BOUET phare 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20PHARE%20-01.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/PORT%20BOUET%20PHARE%20-01.PNG",
      image_4: '"\\"\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 60,
      nom_nom_atere: "Route de Bassam",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 15,
      slug: "Port-bouet",
    },
    {
      id: 1220,
      title: "Classic Tiébissou Artère et Voie de l'intérieur 37ri",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.158531",
      geolatitude: "-5.226894",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TIEBISSOU%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 11,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 61,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Tiébissou près de la Gendarmerie, face corridor de Yamoussoukro",
      code: "TIEBISSOU 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TIEBISSOU%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TIEBISSOU%2001.PNG",
      image_4: '"\\"\\\\\\"\\\\\\\\\\\\\\"[294,305]\\\\\\\\\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 11,
      nom_type_atere: "Route départementale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Tiébissou",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1221,
      title: "Classic Tiébissou Artère et Voie de l'intérieur 54qB",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.158531",
      geolatitude: "-5.226894",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TIEBISSOU%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 11,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 61,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Tiébissou près de la Gendarmerie, face corridor de Yamoussoukro",
      code: "TIEBISSOU 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TIEBISSOU%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TIEBISSOU%2001.PNG",
      image_4:
        '"\\"\\\\\\"\\\\\\\\\\\\\\"\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"[294,305]\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"\\\\\\\\\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 11,
      nom_type_atere: "Route départementale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Tiébissou",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1223,
      title: "Classic Tiébissou Artère et Voie de l'intérieur 58je",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.159259",
      geolatitude: "-5.223096",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TIEBISSOU%2002.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 61,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "Tiébisou clôture CHR, face gare routière",
      code: "TIEBISSOU 02",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TIEBISSOU%2002.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TIEBISSOU%2002.PNG",
      image_4: '"[297]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Tiébissou",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1222,
      title: "Classic Tiébissou Artère et Voie de l'intérieur 84Bx",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "7.159259",
      geolatitude: "-5.223096",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TIEBISSOU%2002.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 61,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description: "Tiébisou clôture CHR, face gare routière",
      code: "TIEBISSOU 02",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TIEBISSOU%2002.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TIEBISSOU%2002.PNG",
      image_4: "[297]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Tiébissou",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1194,
      title: "Classic Toumodi Autoroute du Nord 17eK",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "entree toumodi face au deuxieme peage",
      reference: "",
      geolongitude: "6.550088",
      geolatitude: "-5.019562",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/TOUMODI%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 14,
      formatId: 15,
      villeId: 62,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Toumodi, entrée ville par l’autoroute du Nord, face 2ème péage ",
      code: "TOUMODI 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TOUMODI%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TOUMODI%2001.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 14,
      nom_nom_atere: "Autoroute du Nord",
      frequentation: 103591,
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/autoroute-du-nord-3.jpg",
      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Toumodi",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1193,
      title: "Classic Toumodi Autoroute du Nord 4XD",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "entree toumodi face au deuxieme peage",
      reference: "",
      geolongitude: "6.550088",
      geolatitude: "-5.019562",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/TOUMODI%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 14,
      formatId: 15,
      villeId: 62,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Toumodi, entrée ville par l’autoroute du Nord, face 2ème péage ",
      code: "TOUMODI 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TOUMODI%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/TOUMODI%2001.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 14,
      nom_nom_atere: "Autoroute du Nord",
      frequentation: 103591,
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/autoroute-du-nord-3.jpg",
      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Toumodi",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1225,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 17Ry",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.802696",
      geolatitude: "-5.267213",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro centre ville grande voie de Sopim au niveau de ivograin face Fondation FHB",
      code: "YAMOUSSOUKRO 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2001.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1237,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 17XD",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.863534",
      geolatitude: "-5.236724",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2007.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro Millionnaire voie de visitation, sens aller Attiegouakro, face hôtel président",
      code: "YAMOUSSOUKRO -07",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2007.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2007.PNG",
      image_4: "[301]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1230,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 2WI",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.761602",
      geolatitude: "-5.316568",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2003.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Après un croisement de rue",
      description:
        "Yamoussoukro, axe Sinfra, sens retour Baselique, face carrefour mouton (abattoir)",
      code: "YAMOUSSOUKRO -03",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2003.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2003.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1227,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 34Gn",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.752310",
      geolatitude: "-5.320622",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2002.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 11,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro, sens retour Oumé, nouveau marché, face station distribucom",
      code: "YAMOUSSOUKRO 02",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2002.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2002.PNG",
      image_4: '"\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 11,
      nom_type_atere: "Route départementale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1242,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 34QX",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.822829",
      geolatitude: "-5.271428",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2010.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 10,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro Habitat rue des Lacs, sens retour place Jean Paul II, face hôtel Fanion",
      code: "YAMOUSSOUKRO -10",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2010.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2010.PNG",
      image_4: '""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 10,
      nom_type_atere: "Route secondaire",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1231,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 34xp",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.843341",
      geolatitude: "-5.295068",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2004.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro, sens aller Bouaké avant carrefour Morofé, face centre ville",
      code: "YAMOUSSOUKRO -04",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2004.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2004.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1233,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 4DK",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.818294",
      geolatitude: "-5.274052",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2005.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro axe principal, face au feu de la gare centrale",
      code: "YAMOUSSOUKRO -05",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2005.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2005.PNG",
      image_4: "[294,292]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1238,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 50xu",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.820255",
      geolatitude: "-5.250365",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2008.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro Millionnaire, sens retour cité administrative, face rond point château",
      code: "YAMOUSSOUKRO -08",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2008.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2008.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1229,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 51xu",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.761602",
      geolatitude: "-5.316568",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2003.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Après un croisement de rue",
      description:
        "Yamoussoukro, axe Sinfra, sens retour Baselique, face carrefour mouton (abattoir)",
      code: "YAMOUSSOUKRO -03",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2003.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2003.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1228,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 53eX",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.752310",
      geolatitude: "-5.320622",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2002.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 11,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro, sens retour Oumé, nouveau marché, face station distribucom",
      code: "YAMOUSSOUKRO 02",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2002.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2002.PNG",
      image_4: '"\\"\\\\\\"\\\\\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 11,
      nom_type_atere: "Route départementale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1224,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 53Np",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.802696",
      geolatitude: "-5.267213",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2001.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro centre ville grande voie de Sopim au niveau de ivograin face Fondation FHB",
      code: "YAMOUSSOUKRO 01",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2001.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2001.PNG",
      image_4: "[294]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1236,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 54jq",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.812152",
      geolatitude: "-5.265811",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2006.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro axe principal, près de la CIE, face au feu UTB",
      code: "YAMOUSSOUKRO -06",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2006.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2006.PNG",
      image_4: '"[294,292]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1226,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 54uP",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.752310",
      geolatitude: "-5.320622",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2002.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 11,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro, sens retour Oumé, nouveau marché, face station distribucom",
      code: "YAMOUSSOUKRO 02",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2002.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2002.PNG",
      image_4: '""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 11,
      nom_type_atere: "Route départementale",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1232,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 81jh",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.843341",
      geolatitude: "-5.295068",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2004.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro, sens aller Bouaké avant carrefour Morofé, face centre ville",
      code: "YAMOUSSOUKRO -04",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2004.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2004.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1240,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 84kJ",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.818462",
      geolatitude: "-5.276653",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2009.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro rue des lacs, près de la microfinanceBaobab, face au feu du marché CHR",
      code: "YAMOUSSOUKRO -09",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2009.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2009.PNG",
      image_4: "[275]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1234,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 84Yr",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.818294",
      geolatitude: "-5.274052",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2005.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro axe principal, face au feu de la gare centrale",
      code: "YAMOUSSOUKRO -05",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2005.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2005.PNG",
      image_4: '"[294,292]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1235,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 85Ej",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.812152",
      geolatitude: "-5.265811",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2006.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro axe principal, près de la CIE, face au feu UTB",
      code: "YAMOUSSOUKRO -06",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2006.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2006.PNG",
      image_4: "[294,292]",
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1241,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 85Ti",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.818462",
      geolatitude: "-5.276653",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2009.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro rue des lacs, près de la microfinanceBaobab, face au feu du marché CHR",
      code: "YAMOUSSOUKRO -09",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2009.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2009.PNG",
      image_4: '"[275]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1243,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 85VZ",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.822829",
      geolatitude: "-5.271428",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2010.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 10,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro Habitat rue des Lacs, sens retour place Jean Paul II, face hôtel Fanion",
      code: "YAMOUSSOUKRO -10",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2010.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2010.PNG",
      image_4: '"\\"\\""',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 10,
      nom_type_atere: "Route secondaire",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1239,
      title: "Classic Yamoussoukro Artère et Voie de l'intérieur 94yq",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "6.820255",
      geolatitude: "-5.250365",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2008.PNG",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 63,
      typeAtereId: 7,
      modeleId: 7,
      artereId: 36,
      formatId: 15,
      villeId: 13,
      communeId: 24,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        "Yamoussoukro Millionnaire, sens retour cité administrative, face rond point château",
      code: "YAMOUSSOUKRO -08",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2008.PNG",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YAMOUSSOUKRO%2008.PNG",
      image_4: '"[294]"',
      production: "",
      prix_achat_minimum: "80000",
      prix_achat_mois: "100000",
      id_regis: 63,
      nom_regis: "Formation",
      id_type_atere: 7,
      nom_type_atere: "Autoroute",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 36,
      nom_nom_atere: "Artère et Voie de l'intérieur",
      frequentation: 1500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 13,
      nom_ville: "Yamoussoukro",
      frais_transport: "0",
      id_commune: 24,
      slug: "Villes et Communes de l'intérieur",
    },
    {
      id: 1104,
      title: "Classic Yopougon Artère communale  17Fj",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "aller a la pharmacie",
      reference: "",
      geolongitude: "5°16'07.2\"",
      geolatitude: "4°00'54.8\"W",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20Base%20CIE%20avant%20le%20barrage%20sens%20aller%20P%C3%A9tro%20Ivoire.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 12,
      communeId: 12,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Sur la  grande artère",
      description:
        ". Marché du camp militaire sens aller feu de Pharmacie NANKOKO",
      code: "Yopougon",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20Base%20CIE%20avant%20le%20barrage%20sens%20aller%20P%C3%A9tro%20Ivoire.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20Base%20CIE%20avant%20le%20barrage%20sens%20aller%20P%C3%A9tro%20Ivoire.jpg",
      image_4: "[294,289,301,305]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 12,
      slug: "Yopougon",
    },
    {
      id: 1108,
      title: "Classic Yopougon Artère communale  34VI",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Remontant au feu de saguidiba",
      reference: "",
      geolongitude: " 4°03'48.5\"W",
      geolatitude: "5°19'41.0\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20March%C3%A9%20de%20Sideci%20sens%20aller%20feu%20de%20Saguidiba%20apr%C3%A8s%20la%20pharmacie%20Nankoko.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 10,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 12,
      communeId: 12,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Après un croisement de rue",
      description:
        "YOPOUGON. Marché de Sideci sens aller au feu de Saguidiba après la pharmacie Nankoko",
      code: "Yopougon",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20March%C3%A9%20de%20Sideci%20sens%20aller%20feu%20de%20Saguidiba%20apr%C3%A8s%20la%20pharmacie%20Nankoko.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20March%C3%A9%20de%20Sideci%20sens%20aller%20feu%20de%20Saguidiba%20apr%C3%A8s%20la%20pharmacie%20Nankoko.jpg",
      image_4: "[294,304]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 10,
      nom_type_atere: "Route secondaire",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 12,
      slug: "Yopougon",
    },
    {
      id: 1103,
      title: "Classic Yopougon Artère communale  34Yr",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Aller  carrefour policier",
      reference: "",
      geolongitude: "4°00'52.6\"W",
      geolatitude: "5°16'07.5\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20Base%20CIE%20apr%C3%A8s%20le%20barrage%20sens%20retour%20P%C3%A9tro%20Ivoire.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 12,
      communeId: 12,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Après un croisement de rue",
      description:
        "YOPOUGON. Base CIE après le barrage sens retour Pétro Ivoire",
      code: "Yopougon",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20Base%20CIE%20apr%C3%A8s%20le%20barrage%20sens%20retour%20P%C3%A9tro%20Ivoire.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20Base%20CIE%20apr%C3%A8s%20le%20barrage%20sens%20retour%20P%C3%A9tro%20Ivoire.jpg",
      image_4: "[290,272]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 12,
      slug: "Yopougon",
    },
    {
      id: 1107,
      title: "Classic Yopougon Artère communale  4Bx",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Aller  carrefour du grand marché",
      reference: "",
      geolongitude: "4°05'24.1\"W",
      geolatitude: "5°19'04.2\"N ",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20March%C3%A9%20carrefour%20Academie%20face%20au%20march%C3%A9.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 12,
      communeId: 12,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Dans le quartier",
      description: "YOPOUGON. Marché carrefour académie face au grand marché",
      code: "Yopougon",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20March%C3%A9%20carrefour%20Academie%20face%20au%20march%C3%A9.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20March%C3%A9%20carrefour%20Academie%20face%20au%20march%C3%A9.jpg",
      image_4: '"[293,290,292]"',
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 12,
      slug: "Yopougon",
    },
    {
      id: 1106,
      title: "Classic Yopougon Artère communale  51kJ",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "Aller  carrefour du grand marché",
      reference: "",
      geolongitude: "4°05'24.1\"W",
      geolatitude: "5°19'04.2\"N ",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20March%C3%A9%20carrefour%20Academie%20face%20au%20march%C3%A9.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 12,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 12,
      communeId: 12,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Dans le quartier",
      description: "YOPOUGON. Marché carrefour académie face au grand marché",
      code: "Yopougon",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20March%C3%A9%20carrefour%20Academie%20face%20au%20march%C3%A9.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20March%C3%A9%20carrefour%20Academie%20face%20au%20march%C3%A9.jpg",
      image_4: "[293,290,292]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 12,
      nom_type_atere: "Rue",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 12,
      slug: "Yopougon",
    },
    {
      id: 1109,
      title: "Classic Yopougon Artère communale  81Ti",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "allant a la Siporex",
      reference: "",
      geolongitude: " 4°04'27.6\"W",
      geolatitude: "5°21'31.6\"N",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20Sur%20le%202%C3%A8me%20pont%20avant%20le%20barage%20de%20la%20Police.jpg",
      type_espace: "Panneau",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 61,
      typeAtereId: 10,
      modeleId: 7,
      artereId: 39,
      formatId: 15,
      villeId: 12,
      communeId: 12,
      referenceId: 279,
      positionPanneau: "Dans le sens",
      positionAxe: "Aur une route secondaire",
      description:
        "YOPOUGON. Sur le 2ème pont avant le barrage de la Police\nsur l'axe du grand établissement appartenant au Roi Mohamed VI",
      code: "Yopougon",
      rate: 0,
      image_2:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20Sur%20le%202%C3%A8me%20pont%20avant%20le%20barage%20de%20la%20Police.jpg",
      image_3:
        "https://nymba-files.s3.us-west-2.amazonaws.com/YOPOUGON.%20Sur%20le%202%C3%A8me%20pont%20avant%20le%20barage%20de%20la%20Police.jpg",
      image_4: "[294,289,290]",
      production: "",
      prix_achat_minimum: "60000",
      prix_achat_mois: "80000",
      id_regis: 61,
      nom_regis: "Cerise",
      id_type_atere: 10,
      nom_type_atere: "Route secondaire",
      id_modele: 7,
      nom_modele: "Classic",
      id_nom_atere: 39,
      nom_nom_atere: "Artère communale ",
      frequentation: 4500,

      id_format: 15,
      nom_format: "12m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 12,
      slug: "Yopougon",
    },
    {
      id: 1244,
      title: "Façade murale Cocody Autoroute d'Abobo 53ur",
      qualite: "Bonne",
      eclairage: "Non éclairé",
      statut: "INDISPONIBLE",
      sens: "",
      reference: "",
      geolongitude: "-4.0042496",
      geolatitude: "5.3510144",
      prix_minimum: "0",
      prix_mensuel: "0",
      prix_trimestriel: "0",
      prix_semestriel: "0",
      prix_annuel: "0",
      image: "https://nymba-files.s3.us-west-2.amazonaws.com/logo_csp.jpeg",
      type_espace: "Façade-murale",
      critere_1: "non",
      critere_2: "non",
      critere_3: "non",
      critere_4: "non",
      critere_5: "non",
      regieId: 67,
      typeAtereId: 8,
      modeleId: 9,
      artereId: 53,
      formatId: 19,
      villeId: 12,
      communeId: 11,
      referenceId: 279,
      positionPanneau: "À contre sens",
      positionAxe: "Sur la  grande artère",
      description: "",
      code: "CI78",
      rate: 0,
      image_2: "https://nymba-files.s3.us-west-2.amazonaws.com/Location.jpeg",
      image_3: "https://nymba-files.s3.us-west-2.amazonaws.com/logo_csp.jpeg",
      image_4: "[302,284,289]",
      production: "",
      prix_achat_minimum: "500000",
      prix_achat_mois: "1500000",
      id_regis: 67,
      nom_regis: "DUMP PUB",
      id_type_atere: 8,
      nom_type_atere: "Boulevard",
      id_modele: 9,
      nom_modele: "Façade murale",
      id_nom_atere: 53,
      nom_nom_atere: "Autoroute d'Abobo",
      frequentation: 3000,

      id_format: 19,
      nom_format: "64m²",
      id_ville: 12,
      nom_ville: "Abidjan",
      frais_transport: "0",
      id_commune: 11,
      slug: "Cocody",
    },
  ],
};
