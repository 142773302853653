import axios from "axios";
import config from "./config";
const prefixPoint = "reservation_line";
export default {
  getEntityList: (id) => {
    return axios.get(`${config.URL_END_POINT}/${prefixPoint}/${id}/list`);
  },
  getEntityId: (id) => {
    return axios.get(`${config.URL_END_POINT}/${prefixPoint}/${id}`);
  },
  getEntityRegieId: (regieId) => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/panneaux/regie/${regieId}`
    );
  },
  getEntityBySlug: (slug) => {
    return axios.get(`${config.URL_END_POINT}/${prefixPoint}/slug/${slug}`);
  },
  updateEntityId: (id) => {
    return axios.put(`${config.URL_END_POINT}/${prefixPoint}/${id}`);
  },
  deleteEntityId: (id) => {
    return axios.delete(`${config.URL_END_POINT}/${prefixPoint}/${id}`);
  },
};
