import axios from "axios";
import config from "./config";

export default {
  panneauxSearchEngine: (data) => {
    return axios.post(
      `${config.URL_END_POINT}/panneaux/search`,
      data,
      config.headersConfig
    );
  },
  panneauxSearchEngineForRegie: (data) => {
    return axios.post(
      `${config.URL_END_POINT}/panneaux/regie/search`,
      data,
      config.headersConfig
    );
  },
};
