import React, { Component } from 'react'

class loader extends Component {
  componentWillMount() {}

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  shouldComponentUpdate(nextProps, nextState) {}

  componentWillUpdate(nextProps, nextState) {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="loader-box">
        <div class="pin"></div>
        <div class="pulse"></div>
      </div>
    )
  }
}

loader.propTypes = {}

export default loader
