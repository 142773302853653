import { CheckPicker } from "rsuite";

const componentItem = (props) => {
  return (
    <>
      <div className="update-form">
        <div className="entityCardAddForm mt-4">
          <div className="row">
            <div className="col-md-6">
              <div class="form-item">
                <label>Artère</label>
                <select
                  className="form-control"
                  name="artereId"
                  value={props.item?.artereId}
                  onChange={props.handleChange}
                >
                  <option>Sélectionnez...</option>
                  {props.artereList?.map((item, index) => (
                    <option value={item.id}> {item.title} </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Sens</label>
                <input
                  className="form-control"
                  name="sens"
                  value={props.item?.sens}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Position de l'axe</label>
                <select
                  class="form-control"
                  name="positionAxe"
                  value={props.item?.positionAxe}
                  onChange={props.handleChange}
                >
                  <option>Sélectionnez...</option>
                  <option value="Dans le quartier">Dans un quartier</option>
                  <option value="Sur la  grande artère">
                    Sur une grande artère
                  </option>
                  <option value="Après un croisement de rue">
                    Après un croisement de rue
                  </option>
                  <option value="Avant un croisement de rue">
                    Avant un croisement de rue
                  </option>
                  <option value="Aur une route secondaire">
                    Sur une route secondaire
                  </option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Position du panneau</label>
                <select
                  className="form-control"
                  name="positionPanneau"
                  value={props.item?.positionPanneau}
                  onChange={props.handleChange}
                >
                  <option>Sélectionnez...</option>
                  <option value="'Dans le sens'">
                    Dans le sens de la route
                  </option>
                  <option value="À contre sens">À contre sens</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Type d'artère</label>
                <select
                  className="form-control"
                  name="typeAtereId"
                  value={props.item?.typeAtereId}
                  onChange={props.handleChange}
                >
                  <option>Sélectionnez...</option>
                  {props.typeArtereList?.map((item, index) => (
                    <option value={item.id}> {item.title} </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Points de reférences</label>
                <CheckPicker
                  placeholder="Sélectionnez les points de reférence..."
                  data={props?.referenceList}
                  onChange={(e) => props.handleList("references", e)}
                  style={{ width: 224 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default componentItem;
