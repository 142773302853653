export default {
  data: [
    {
      id: 39,
      title: 'Artère communale ',
      frequentation: 4500,
      image: null,
    },
    {
      id: 36,
      title: "Artère et Voie de l'intérieur",
      frequentation: 1500,
      image: null,
    },
    {
      id: 53,
      title: "Autoroute d'Abobo",
      frequentation: 3000,
      image: null,
    },
    {
      id: 14,
      title: 'Autoroute du Nord',
      frequentation: 103591,
      image:
        'https://nymba-files.s3.us-west-2.amazonaws.com/autoroute-du-nord-3.jpg',
    },
    {
      id: 67,
      title: 'Avenue Boga Doudou',
      frequentation: 1000,
      image: null,
    },
    {
      id: 52,
      title: 'Avenue de Marcory',
      frequentation: 2000,
      image: null,
    },
    {
      id: 16,
      title: 'Boulevard  Latrille',
      frequentation: 3500,
      image:
        'https://nymba-files.s3.us-west-2.amazonaws.com/blv%20Latrille.jpg',
    },
    {
      id: 64,
      title: 'Boulevard Abrogoua',
      frequentation: 1000,
      image: null,
    },
    {
      id: 61,
      title: 'Boulevard Antanarivo',
      frequentation: 2000,
      image: null,
    },
    {
      id: 66,
      title: 'Boulevard Arsène Ursher Assouan',
      frequentation: 1000,
      image: null,
    },
    {
      id: 42,
      title: 'Boulevard C2',
      frequentation: 3000,
      image: 'https://nymba-files.s3.us-west-2.amazonaws.com/st%20jacques.jpg',
    },
    {
      id: 46,
      title: 'Boulevard Carde',
      frequentation: 3000,
      image:
        'https://nymba-files.s3.us-west-2.amazonaws.com/Blv%20Carde-%20Plateau.jpg',
    },
    {
      id: 17,
      title: 'Boulevard de France',
      frequentation: 70000,
      image:
        'https://nymba-files.s3.us-west-2.amazonaws.com/Blv%20de%20France%20APRES%2002.jpg',
    },
    {
      id: 71,
      title: "Boulevard de l'Ouest",
      frequentation: 2000,
      image: null,
    },
    {
      id: 65,
      title: "Boulevard de l'université",
      frequentation: 1000,
      image: null,
    },
    {
      id: 54,
      title: 'Boulevard de la Paix',
      frequentation: 2500,
      image: null,
    },
    {
      id: 47,
      title: 'Boulevard de la République',
      frequentation: 3000,
      image: null,
    },
    {
      id: 34,
      title: 'Boulevard de Marseille',
      frequentation: 350000,
      image:
        'https://nymba-files.s3.us-west-2.amazonaws.com/blv%20marseille%20%282%29.jpg',
    },
    {
      id: 68,
      title: 'Boulevard de Petit Bassam',
      frequentation: 2000,
      image: null,
    },
    {
      id: 57,
      title: 'Boulevard de Vridi',
      frequentation: 2000,
      image: null,
    },
    {
      id: 73,
      title: 'Boulevard du Caire',
      frequentation: 2000,
      image: null,
    },
    {
      id: 70,
      title: 'Boulevard du Cameroun',
      frequentation: 2000,
      image: null,
    },
    {
      id: 62,
      title: 'Boulevard du Canal',
      frequentation: 1000,
      image: null,
    },
    {
      id: 56,
      title: 'Boulevard du Gabon',
      frequentation: 1000,
      image: null,
    },
    {
      id: 35,
      title: 'Boulevard Felix Houphouët-Boigny (Yamoussoukro)',
      frequentation: 500000,
      image: 'https://nymba-files.s3.us-west-2.amazonaws.com/yamoussoukro1.jpg',
    },
    {
      id: 18,
      title: 'Boulevard Lagunaire',
      frequentation: 1500000,
      image: 'https://nymba-files.s3.us-west-2.amazonaws.com/Lagunaire.jpg',
    },
    {
      id: 19,
      title: 'Boulevard Mitterand',
      frequentation: 51108,
      image:
        'https://nymba-files.s3.us-west-2.amazonaws.com/Photo%20%281%29.jpg',
    },
    {
      id: 48,
      title: 'Boulevard Pont Général De Gaulle',
      frequentation: 3000,
      image: null,
    },
    {
      id: 72,
      title: 'Boulevard Usher Assouan',
      frequentation: 2000,
      image: null,
    },
    {
      id: 41,
      title: "Boulevard Valery Giscard d'Estaing ",
      frequentation: 103591,
      image: null,
    },
    {
      id: 38,
      title: "Boulevard Valery Giscard d'Estaing (VGE)",
      frequentation: 51108,
      image: 'https://nymba-files.s3.us-west-2.amazonaws.com/Photo%202.jpg',
    },
    {
      id: 40,
      title: 'La nationale A1',
      frequentation: 103591,
      image: null,
    },
    {
      id: 45,
      title: 'La nationale A3',
      frequentation: 5000,
      image: null,
    },
    {
      id: 49,
      title: 'Pont Henri Konan Bedie',
      frequentation: 3000,
      image: 'https://nymba-files.s3.us-west-2.amazonaws.com/Pont%20HKB.jpg',
    },
    {
      id: 33,
      title: "Route d'Attoban",
      frequentation: 25000,
      image: null,
    },
    {
      id: 60,
      title: 'Route de Bassam',
      frequentation: 1500,
      image: null,
    },
    {
      id: 31,
      title: 'Route de Bonoumin',
      frequentation: 5000,
      image: null,
    },
    {
      id: 21,
      title: "Route de l'Aéroport",
      frequentation: 7150,
      image: null,
    },
    {
      id: 69,
      title: 'Route de la Prison Civile',
      frequentation: 2000,
      image: null,
    },
    {
      id: 59,
      title: 'Route du Zoo',
      frequentation: 2000,
      image: null,
    },
    {
      id: 58,
      title: 'Rue des Jardins',
      frequentation: 1500,
      image: null,
    },
    {
      id: 75,
      title: 'Rue du 7 décembre',
      frequentation: 2000,
      image: null,
    },
    {
      id: 63,
      title: 'Rue du Canal',
      frequentation: 1000,
      image: null,
    },
    {
      id: 32,
      title: 'Voie de la Djibi',
      frequentation: 30000,
      image: null,
    },
    {
      id: 55,
      title: 'Voie Express Abijan- Bassam',
      frequentation: 2000,
      image: null,
    },
  ],
}
