import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { addUserInfos, removeUserInfos } from "../store/actions/userActions";
// const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));
// const roleId = currentUserInfo?.roleId;
const ProtectedRoute = ({
  currentUserInfo,
  component: Comp,
  role,
  path,
  ...rest
}) => {
  const protecting = () => {
    // console.log(currentUserInfo);
    let access = false;
    if (currentUserInfo != null) {
      if (
        Number(currentUserInfo.roleId) === 2 &&
        window.location.href.includes("partner")
      ) {
        access = true;
      } else if (
        [1, 3].includes(Number(currentUserInfo.roleId)) &&
        window.location.href.includes("admin")
      ) {
        access = true;
      } else {
        access = false;
      }
    } else {
      access = false;
    }
    console.log("access", access);
    return access;
  };

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return protecting() === true ? (
          <Comp {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/sign-in",
              state: {
                prevLocation: path,
                error: "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = (state) => {
  let addedItemsTmp = state.userInfoReducer;
  return {
    currentUserInfo: addedItemsTmp.addedUserInfos,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addUserInfos: (item) => {
      dispatch(addUserInfos(item));
    },
    removeUserInfos: (item) => {
      dispatch(removeUserInfos(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
