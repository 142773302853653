import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { PaginatedList } from 'react-paginated-list'
import PaginationList from "react-pagination-list";
import {
  Drawer,
  CheckboxGroup,
  Checkbox,
  Popover,
  Whisper,
  Toolbar,
  Loader,
  Modal,
  // Button,
  Input,
  InputGroup,
} from "rsuite";

import GoogleMapReact from "google-map-react";
import controllable from "react-controllables";
import { StickyContainer, Sticky } from "react-sticky";
import { Row, Col, Image, Form, Button, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import SearchForm from "../../../components/partials/dashboard/SearchFormStyle/search-form-style-1";
import SkeletonLoading from "../../../components/partials/components/skeletonLoading/skeleton-style-01";
// import Loader from '../../../components/partials/components/loader'
import PanneauxItem from "../../../components/partials/components/panneauxItem";

import ville from "../../../restApiService/ville";
import linkData from "../../../restApiService/linkData";
import artere from "../../../restApiService/artere";
import commune from "../../../restApiService/commune";
import format from "../../../restApiService/format";
import type_artere from "../../../restApiService/type-artere";
import modele from "../../../restApiService/modele";
import reference from "../../../restApiService/reference";
import searchRequest from "../../../restApiService/searchRequest";

// component importation
// import SideDataList from './components/sideDataList'
// import MapViewer from './components/mapViewer'

import "../../../assets/css/searchresults.css";
import ListItem from "rsuite/esm/List/ListItem";

const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));

controllable(["center", "zoom", "markers"]);
const K_MARGIN_TOP = 30;
const K_MARGIN_RIGHT = 30;
const K_MARGIN_BOTTOM = 30;
const K_MARGIN_LEFT = 30;

const SideDataList = React.lazy(() => import("./components/sideDataList"));
const MapViewer = React.lazy(() => import("./components/mapViewer"));

const K_HOVER_DISTANCE = 30;

const decipher = (salt) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return (encoded) =>
    encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
};

class search_results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      villeData: [],
      villeListData: [],
      artereData: [],
      communeData: [],
      communeListData: [],
      formatData: [],
      formatListData: [],
      typeArtereListData: [],
      modeleListData: [],
      referenceListData: [],
      espaceDataIsLoading: true,
      espaceDataList: [],
      espaceDataListTmp: [],
      requestEspaceDataListTmp: [],
      espaceDataListForMap: [],
      searchData: [],
      filterboxIsOpen: false,
      searchFormboxIsOpen: false,
      searchType: "",
      title: "",
      afterLoop: false,
      showMap: false,
      MapLoading: false,
      oneViewSelected: false,
      oneViewSelectedId: 0,
      defaultlat: 0,
      defaultlng: 0,
      alreadySearch: true,
      extLink: "",
      savingCart: false,
      loadingLink: false,
    };
  }

  handleChange = (name, value) => {
    let searchData = { ...this.state.searchData };
    console.log(name);
    console.log(value);

    if (name === "villeId") {
      if (searchData.villeId) {
        let find = searchData.villeId.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.villeId.splice(find, 1);
        } else {
          console.log("searchData.villeId", searchData.villeId.length);
          searchData["villeId"].push(value);
        }
      } else {
        searchData["villeId"] = [value];
      }
    }

    if (name === "communeId") {
      if (searchData.communeId) {
        let find = searchData.communeId.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.communeId.splice(find, 1);
        } else {
          console.log("searchData.communeId", searchData.communeId.length);
          searchData["communeId"].push(value);
        }
      } else {
        searchData["communeId"] = [value];
      }
    }

    if (name === "typeArtereId") {
      if (searchData.typeArtereId) {
        let find = searchData.typeArtereId.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.typeArtereId.splice(find, 1);
        } else {
          console.log(
            "searchData.typeArtereId",
            searchData.typeArtereId.length
          );
          searchData["typeArtereId"].push(value);
        }
      } else {
        searchData["typeArtereId"] = [value];
      }
    }

    if (name === "formatId") {
      if (searchData.formatId) {
        let find = searchData.formatId.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.formatId.splice(find, 1);
        } else {
          console.log("searchData.formatId", searchData.formatId.length);
          searchData["formatId"].push(value);
        }
      } else {
        searchData["formatId"] = [value];
      }
    }

    if (name === "eclairage") {
      if (searchData.eclairage) {
        let find = searchData.eclairage.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.eclairage.splice(find, 1);
        } else {
          console.log("searchData.eclairage", searchData.eclairage.length);
          searchData["eclairage"].push(value);
        }
      } else {
        searchData["eclairage"] = [value];
      }
    }

    if (name === "modeleId") {
      if (searchData.modeleId) {
        let find = searchData.modeleId.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.modeleId.splice(find, 1);
        } else {
          console.log("searchData.modeleId", searchData.modeleId.length);
          searchData["modeleId"].push(value);
        }
      } else {
        searchData["modeleId"] = [value];
      }
    }

    if (name === "positionAxe") {
      if (searchData.positionAxe) {
        let find = searchData.positionAxe.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.positionAxe.splice(find, 1);
        } else {
          console.log("searchData.positionAxe", searchData.positionAxe.length);
          searchData["positionAxe"].push(value);
        }
      } else {
        searchData["positionAxe"] = [value];
      }
    }

    if (name === "rate") {
      if (searchData.rate) {
        let find = searchData.rate.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.rate.splice(find, 1);
        } else {
          console.log("searchData.rate", searchData.rate.length);
          searchData["rate"].push(value);
        }
      } else {
        searchData["rate"] = [value];
      }
    }

    if (name === "referenceId") {
      if (searchData.referenceId) {
        let find = searchData.referenceId.map((e) => e).indexOf(value);
        if (find > -1) {
          searchData.referenceId.splice(find, 1);
        } else {
          console.log("searchData.referenceId", searchData.referenceId.length);
          searchData["referenceId"].push(value);
        }
      } else {
        searchData["referenceId"] = [value];
      }
    }

    console.log("searchData", searchData);
    this.SearchRequestAnnexe(searchData);
    this.setState({ searchData });
  };

  OneViewSelected = (espaceId) => {
    console.log(espaceId);
    this.setState({ MapLoading: true, oneViewSelectedId: espaceId });
    let espaceDataListTmp = [...this.state.espaceDataListTmp];

    this.setState({ espaceDataListForMap: espaceDataListTmp });

    setTimeout(() => {
      let filteredList = this.state.espaceDataListForMap.filter(
        (item) => item.id === espaceId
      );
      this.setState({
        oneViewSelected: true,
        espaceDataListForMap: filteredList,
        defaultlat: Number(filteredList[0].geolatitude),
        defaultlng: Number(filteredList[0].geolongitude),
      });
    }, 400);
    setTimeout(() => {
      this.setState({ MapLoading: false });
    }, 500);
  };

  ResetOneViewSelected = () => {
    this.setState({
      MapLoading: true,
    });
    setTimeout(() => {
      this.setState({
        oneViewSelectedId: 0,
        MapLoading: false,
        oneViewSelected: false,
        espaceDataListForMap: this.state.espaceDataListTmp,
        defaultlat: Number(this.state.espaceDataListTmp[0].geolatitude),
        defaultlng: Number(this.state.espaceDataListTmp[0].geolongitude),
      });
    }, 500);
  };

  handleChangeDate = (event) => {
    let searchData = { ...this.state.searchData };
    if (event != null) {
      const startDate = moment(event[0]);
      const endDate = moment(event[1]);
      searchData["start"] = new Date(event[0]).getTime();
      searchData["end"] = new Date(event[1]).getTime();
      console.log(searchData);
      let range = endDate.diff(startDate, "days");
      searchData["range"] = range;
      localStorage.setItem("duration", range);
      this.setState({ searchData });
      setTimeout(() => {
        if (range <= 16) {
          toast.info(
            "Choisissez une période supérieure à 15 jours et inférieure à 1 an",
            {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }, 500);
    }
  };

  SearchRequest = (data) => {
    searchRequest
      .panneauxSearchEngineForRegie(data)
      .then((req) => {
        let filterDataToRemoveNoEspaceLngLat = req.data.filter(
          (item) =>
            isNaN(Number(item.geolatitude)) === false &&
            isNaN(Number(item.geolongitude)) === false
        );

        if (filterDataToRemoveNoEspaceLngLat.length > 0) {
          this.setState({
            espaceDataList: filterDataToRemoveNoEspaceLngLat,
            espaceDataListTmp: filterDataToRemoveNoEspaceLngLat,
            espaceDataListForMap: filterDataToRemoveNoEspaceLngLat,
            defaultlat: Number(filterDataToRemoveNoEspaceLngLat[0].geolatitude),
            defaultlng: Number(
              filterDataToRemoveNoEspaceLngLat[0].geolongitude
            ),
          });
          setTimeout(() => {
            this.setState({
              showMap: true,
            });
          }, 1200);
          setTimeout(() => {
            this.setState({ MapLoading: false });
          }, 1000);
        }

        // setTimeout(() => {

        // }, 500)
        setTimeout(() => {
          this.setState({
            afterLoop:
              filterDataToRemoveNoEspaceLngLat.length === 0 ? true : false,
            espaceDataIsLoading: false,
          });
        }, 1000);
        // setTimeout(() => {
        //   this.setState({ MapLoading: false })
        // }, 2000)
      })
      .catch((error) => {});
  };

  AddSearchRequestAnnexeData = () => {
    this.setState({
      espaceDataIsLoading: true,
      showMap: false,
      MapLoading: false,
      espaceDataList: this.state.requestEspaceDataListTmp,
      espaceDataListTmp: this.state.requestEspaceDataListTmp,
      espaceDataListForMap: this.state.requestEspaceDataListTmp,
      defaultlat: Number(this.state.requestEspaceDataListTmp[0].geolatitude),
      defaultlng: Number(this.state.requestEspaceDataListTmp[0].geolongitude),
    });

    setTimeout(() => {
      this.setState({
        filterboxIsOpen: false,
        showMap: true,
        afterLoop:
          this.state.requestEspaceDataListTmp.length === 0 ? true : false,
        espaceDataIsLoading: false,
      });
    }, 1500);
    setTimeout(() => {
      this.setState({ MapLoading: false });
    }, 2000);
  };

  SearchRequestAnnexe = (data) => {
    const filtered = Object.entries(data).reduce(
      (a, [k, v]) => (v.length === 0 ? a : ((a[k] = v), a)),
      {}
    );
    searchRequest
      .panneauxSearchEngineForRegie(filtered)
      .then((req) => {
        let filterDataToRemoveNoEspaceLngLat = req.data.filter(
          (item) =>
            isNaN(Number(item.geolatitude)) === false &&
            isNaN(Number(item.geolongitude)) === false
        );

        if (filterDataToRemoveNoEspaceLngLat.length > 0) {
          this.setState({
            requestEspaceDataListTmp: filterDataToRemoveNoEspaceLngLat,
          });
        } else {
          this.setState({
            requestEspaceDataListTmp: [],
          });
        }
      })
      .catch((error) => {});
  };

  getVilleData = (slug) => {
    ville
      .getEntityBySlug(slug)
      .then((rep) => {
        this.setState({
          villeData: rep.data[0],
          title: rep.data[0].title,
          searchType: "Ville",
        });
        let searchData = { ...this.state.searchData };
        searchData["villeId"] = [rep.data[0].id];
        this.setState({ searchData });
        this.SearchRequest(searchData);
      })
      .catch((error) => {});
  };

  getArtereData = (slug) => {
    artere
      .getEntityBySlug(slug)
      .then((rep) => {
        this.setState({
          artereData: rep.data[0],
          title: rep.data[0].title,
          searchType: "Artère",
        });

        let searchData = { ...this.state.searchData };
        searchData["artereId"] = [rep.data[0].id];
        this.setState({ searchData });
        this.SearchRequest(searchData);
      })
      .catch((error) => {});
  };

  getCommuneData = (slug) => {
    commune
      .getEntityBySlug(slug)
      .then((rep) => {
        this.setState({
          communeData: rep.data[0],
          title: rep.data[0].title,
          searchType: "Commune",
        });

        let searchData = { ...this.state.searchData };
        searchData["communeId"] = [rep.data[0].id];
        this.setState({ searchData });
        this.SearchRequest(searchData);
      })
      .catch((error) => {});
  };

  getFormatData = (slug) => {
    format
      .getEntityBySlug(slug)
      .then((rep) => {
        this.setState({
          formatData: rep.data[0],
          title: rep.data[0].title,
          searchType: "Format",
        });

        let searchData = { ...this.state.searchData };
        searchData["formatId"] = [rep.data[0].id];
        this.setState({ searchData });
        this.SearchRequest(searchData);
      })
      .catch((error) => {});
  };

  searchEnginepoint(target, slug) {
    if (target === "city") {
      this.getVilleData(slug);
    }
    if (target === "artere") {
      this.getArtereData(slug);
    }
    if (target === "commune") {
      this.getCommuneData(slug);
    }
    if (target === "format") {
      this.getFormatData(slug);
    }
  }

  getVilleList = () => {
    ville
      .getEntityList()
      .then((rep) => {
        this.setState({
          villeListData: rep.data,
        });
      })
      .catch((error) => {});
  };

  getCommuneList = () => {
    commune
      .getEntityList()
      .then((rep) => {
        this.setState({
          communeListData: rep.data,
        });
      })
      .catch((error) => {});
  };

  getTypeArtereList = () => {
    type_artere
      .getEntityList()
      .then((rep) => {
        this.setState({
          typeArtereListData: rep.data,
        });
      })
      .catch((error) => {});
  };

  getFormatList = () => {
    format
      .getEntityList()
      .then((rep) => {
        this.setState({
          formatListData: rep.data,
        });
      })
      .catch((error) => {});
  };

  getModeleList = () => {
    modele
      .getEntityList()
      .then((rep) => {
        this.setState({
          modeleListData: rep.data,
        });
      })
      .catch((error) => {});
  };

  getReferenceList = () => {
    reference
      .getEntityList()
      .then((rep) => {
        this.setState({
          referenceListData: rep.data,
        });
      })
      .catch((error) => {});
  };

  handleStartSearch = () => {
    let searchData = { ...this.state.searchData };

    localStorage.setItem("end", searchData.end);
    localStorage.setItem("range", searchData.range);
    localStorage.setItem("start", searchData.start);

    this.SearchRequest(searchData);
    this.setState({ alreadySearch: true });
  };

  getAutoGeneratedPassword = (length) => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let genPass = "";

    for (let i = 0, n = charset.length; i < length; ++i) {
      genPass += charset.charAt(Math.floor(Math.random() * n));
    }

    return genPass;
  };

  SaveSearchParamsAndGenerateExtLink = () => {
    // const alpha = "abcdefghijklmnopqrstuvwxyz";
    // const calpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    // const num = "1234567890";
    // const options = [alpha, alpha, alpha, calpha, calpha, num, num];
    // let opt, choose;
    // let pass = "";
    // for (let i = 0; i < 8; i++) {
    //   opt = Math.floor(Math.random() * options?.length);
    //   choose = Math.floor(Math.random() * options[opt]?.length);
    //   pass = pass + options[opt][choose];
    //   options.splice(opt, 1);
    // }

    // const replaceSpaceByDash = pass;

    this.setState({
      extLink: "",
      loadingLink: true,
    });

    let link_Data = {
      code: this.getAutoGeneratedPassword(10),
      userId: currentUserInfo.id,
      params: this.props.match.params.data,
      used: 0,
    };

    linkData
      .createFrontLinkData(link_Data)
      .then((data) => {
        this.setState({
          savingCart: true,
          extLink:
            "localhost:8004/search-engine/" +
            currentUserInfo.regie?.slug +
            "/" +
            this.getAutoGeneratedPassword(10),
          loadingLink: false,
        });
      })
      .catch((error) => {
        this.setState({ savingCart: true, extLink: "", loadingLink: false });
      });
  };

  handleClose = () => {
    this.setState({ savingCart: false });
  };

  componentDidMount() {
    // this.getVilleList();
    // this.getCommuneList();
    // this.getTypeArtereList();
    // this.getFormatList();
    // this.getModeleList();
    // this.getReferenceList();
    this.setState({ MapLoading: true });
    //To decipher, you need to create a decipher and use it:
    console.log(this.props.match.params.data);
    const myDecipher = decipher("mySecretSalt");
    const stringData = myDecipher(this.props.match.params.data); // --> 'the secret string'
    this.setState({ searchData: JSON.parse(stringData) });
    localStorage.setItem("start", JSON.parse(stringData)?.start);
    localStorage.setItem("end", JSON.parse(stringData)?.end);
    localStorage.setItem("cpgne", "Inconnu");
    this.SearchRequest(JSON.parse(stringData));

    if (localStorage.getItem("duration")) {
      this.setState({ alreadySearch: true });
    }
  }

  //   <PanneauxItem />
  render() {
    let {
      filterboxIsOpen,
      searchFormboxIsOpen,
      espaceDataIsLoading,
      espaceDataList,
      espaceDataListForMap,
      requestEspaceDataListTmp,
      showMap,
      defaultlat,
      defaultlng,
      oneViewSelected,
      MapLoading,
      oneViewSelectedId,
      afterLoop,
      searchType,
      title,
    } = this.state;

    return (
      <>
        <section className="login-content">
          <Row className="m-0 bg-white">
            <Col md="7">
              {afterLoop ? (
                <Row className="justify-content-center">
                  <Col md="12">
                    <div className="text-center pt-5">
                      <h2 className="text-left">
                        <svg
                          width="64"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.81409 20.4368H19.1971C20.7791 20.4368 21.7721 18.7267 20.9861 17.3527L13.8001 4.78775C13.0091 3.40475 11.0151 3.40375 10.2231 4.78675L3.02509 17.3518C2.23909 18.7258 3.23109 20.4368 4.81409 20.4368Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.0024 13.4147V10.3147"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.995 16.5H12.005"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </h2>
                      <h2>OUPS!</h2>
                      <h3 className="text-left">Nous sommes désolés</h3>
                      <p className="text-left">
                        Aucun espace ne correspond à votre recherche
                      </p>
                      <p className="text-left">
                        Essayez de modifier vos données pour découvrir d'autres
                        espaces.
                      </p>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="text-center result-header button justify-content-center pt-5 w-100">
                      <button
                        className="mr-1"
                        onClick={() =>
                          this.setState({ searchFormboxIsOpen: true })
                        }
                      >
                        <svg
                          width="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="11.7669"
                            cy="11.7666"
                            r="8.98856"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.0186 18.4851L21.5426 22"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>{" "}
                        faire une nouvelle recherche
                      </button>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row className="justify-content-center">
                  <Col md="12">
                    {searchType && (
                      <h3 className="search-title p-2">
                        <strong>{searchType} : </strong> {title}
                      </h3>
                    )}
                  </Col>
                  <Col md="12">
                    <div className="result-header-warn">
                      <svg
                        width="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.81409 20.4368H19.1971C20.7791 20.4368 21.7721 18.7267 20.9861 17.3527L13.8001 4.78775C13.0091 3.40475 11.0151 3.40375 10.2231 4.78675L3.02509 17.3518C2.23909 18.7258 3.23109 20.4368 4.81409 20.4368Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.0024 13.4147V10.3147"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.995 16.5H12.005"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className="text-left">
                        Vos resultats de recherches sont actualisés toutes les 2
                        minutes
                      </p>
                    </div>
                  </Col>
                  <Col md="12" className="result-header-o">
                    <div className="result-header p-sticky">
                      <div>
                        <p className="text-left">
                          Plus de {espaceDataList.length} espaces disponibles du{" "}
                          <strong className="text-red">
                            <Moment format="DD/MM/YYYY">
                              {this.state.searchData.start}
                            </Moment>
                          </strong>{" "}
                          au{" "}
                          <strong className="text-red">
                            <Moment format="DD/MM/YYYY">
                              {this.state.searchData.end}
                            </Moment>
                          </strong>
                        </p>
                        <span className="text-left">
                          Classement des résultats
                        </span>
                      </div>
                      <div>
                        {oneViewSelected && (
                          <button
                            className="mr-1"
                            onClick={this.ResetOneViewSelected}
                          >
                            <i class="fas fa-redo-alt"></i>
                          </button>
                        )}

                        {/* <button
                        className="mr-1 active-layout"
                        onClick={() => this.setState({ filterboxIsOpen: true })}
                      >
                        <i class="fas fa-list"></i>
                      </button> */}
                        {/* <button
                        onClick={() => this.setState({ filterboxIsOpen: true })}
                      >
                        <i class="fas fa-th"></i>
                      </button> */}
                      </div>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="result-boby">
                      <SideDataList
                        espaceDataIsLoading={espaceDataIsLoading}
                        espaceDataList={espaceDataList}
                        OneViewSelected={this.OneViewSelected}
                        oneViewSelectedId={this.state.oneViewSelectedId}
                        alreadySearch={this.state.alreadySearch}
                        handleStartSearch={this.handleStartSearch}
                        handleChangeDate={this.handleChangeDate}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            <Col md="5" className="p-0 h-950 p-sticky bg-grey overflow-hidden">
              {MapLoading ? (
                <div className="map-loader">
                  <Loader center vertical content="loading" size="lg" />
                </div>
              ) : (
                <MapViewer
                  showMap={showMap}
                  defaultlat={defaultlat}
                  defaultlng={defaultlng}
                  espaceDataList={espaceDataListForMap}
                />
              )}
            </Col>
          </Row>
        </section>
        {/* search form drawer */}
        <Drawer
          size={"xs"}
          placement={"top"}
          open={searchFormboxIsOpen}
          onClose={() => this.setState({ searchFormboxIsOpen: false })}
          className="search-form-drawer"
        >
          <Drawer.Body>
            <div className="search-form-drawer-box">
              <SearchForm props={this.props} />
            </div>
          </Drawer.Body>
        </Drawer>
        <Drawer
          backdrop={"static"}
          open={filterboxIsOpen}
          onClose={() => this.setState({ filterboxIsOpen: false })}
        >
          <Drawer.Header>
            <Drawer.Title>Filtres</Drawer.Title>
            <Drawer.Actions>
              {requestEspaceDataListTmp.length === 0 ? (
                <Button appearance="warn" className="bg-yellow">
                  0 espaces
                </Button>
              ) : (
                <Button
                  onClick={this.AddSearchRequestAnnexeData}
                  appearance="warn"
                  className="bg-yellow"
                >
                  {requestEspaceDataListTmp.length > 1
                    ? "Afficher les " +
                      requestEspaceDataListTmp.length +
                      " espaces"
                    : "Afficher l'espace"}
                </Button>
              )}
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            <div className="filter-box">
              <p>Villes </p>

              {this.state.villeListData.map((item) => (
                <Checkbox
                  name="villeId"
                  onChange={(e) => this.handleChange("villeId", e)}
                  value={item.id}
                  checked={
                    this.state.searchData?.villeId === undefined
                      ? false
                      : this.state.searchData.villeId.indexOf(item.id) === -1
                      ? false
                      : true
                  }
                >
                  {item.title}
                </Checkbox>
              ))}
            </div>

            <div className="filter-box">
              <p>Commune</p>

              {this.state.communeListData.map((item) => (
                <Checkbox
                  name="communeId"
                  onChange={(e) => this.handleChange("communeId", e)}
                  value={item.id}
                  checked={
                    this.state.searchData?.communeId === undefined
                      ? false
                      : this.state.searchData?.communeId?.indexOf(item.id) ===
                        -1
                      ? false
                      : true
                  }
                >
                  {item.title}
                </Checkbox>
              ))}
            </div>

            <div className="filter-box">
              <p>Type d’artère</p>

              {this.state.typeArtereListData.map((item) => (
                <Checkbox
                  name="typeArtereId"
                  onChange={(e) => this.handleChange("typeArtereId", e)}
                  value={item.id}
                  checked={
                    this.state.searchData?.typeArtereId === undefined
                      ? false
                      : this.state.searchData?.typeArtereId?.indexOf(
                          item.id
                        ) === -1
                      ? false
                      : true
                  }
                >
                  {item.title}
                </Checkbox>
              ))}
            </div>

            <div className="filter-box">
              {" "}
              <p>Format</p>
              {this.state.formatListData.map((item) => (
                <Checkbox
                  name="formatId"
                  onChange={(e) => this.handleChange("formatId", e)}
                  value={item.id}
                  checked={
                    this.state.searchData?.formatId === undefined
                      ? false
                      : this.state.searchData?.formatId?.indexOf(item.id) === -1
                      ? false
                      : true
                  }
                >
                  {item.title}
                </Checkbox>
              ))}
            </div>

            <div className="filter-box">
              {" "}
              <p>Eclairage</p>
              <Checkbox
                name="eclairage"
                onChange={(e) => this.handleChange("eclairage", e)}
                value="Eclairé"
              >
                Eclairé
              </Checkbox>
              <Checkbox
                name="eclairage"
                onChange={(e) => this.handleChange("eclairage", e)}
                value="Non Eclairé"
              >
                Non Eclairé
              </Checkbox>
            </div>
            <div className="filter-box">
              <p>Modèle</p>

              {this.state.modeleListData.map((item) => (
                <Checkbox
                  name="modeleId"
                  onChange={(e) => this.handleChange("modeleId", e)}
                  value={item.id}
                  checked={
                    this.state.searchData?.modeleId === undefined
                      ? false
                      : this.state.searchData?.modeleId?.indexOf(item.id) === -1
                      ? false
                      : true
                  }
                >
                  {item.title}
                </Checkbox>
              ))}
            </div>
            <div className="filter-box">
              <p>Position</p>
              <CheckboxGroup inline name="checkboxList">
                <Checkbox
                  name="positionAxe"
                  onChange={(e) => this.handleChange("positionAxe", e)}
                  value="Dans le sens"
                >
                  Dans le sens
                </Checkbox>
                <Checkbox
                  name="positionAxe"
                  onChange={(e) => this.handleChange("positionAxe", e)}
                  value="À contre sens"
                >
                  À contre sens
                </Checkbox>
              </CheckboxGroup>
            </div>
            <div className="filter-box">
              <p>Notes</p>
              <CheckboxGroup inline name="checkboxList">
                <Checkbox
                  name="rate"
                  onChange={(e) => this.handleChange("rate", e)}
                  value="5"
                  checked={
                    this.state.searchData?.rate === undefined
                      ? false
                      : this.state.searchData?.rate?.indexOf("5") === -1
                      ? false
                      : true
                  }
                >
                  5 étoiles
                </Checkbox>
                <Checkbox
                  name="rate"
                  onChange={(e) => this.handleChange("rate", e)}
                  value="4"
                  checked={
                    this.state.searchData?.rate === undefined
                      ? false
                      : this.state.searchData?.rate?.indexOf("4") === -1
                      ? false
                      : true
                  }
                >
                  4 étoiles
                </Checkbox>
                <Checkbox
                  name="rate"
                  onChange={(e) => this.handleChange("rate", e)}
                  value="3"
                  checked={
                    this.state.searchData?.rate === undefined
                      ? false
                      : this.state.searchData?.rate?.indexOf("3") === -1
                      ? false
                      : true
                  }
                >
                  3 étoiles
                </Checkbox>
                <Checkbox
                  name="rate"
                  onChange={(e) => this.handleChange("rate", e)}
                  value="2"
                  checked={
                    this.state.searchData?.rate === undefined
                      ? false
                      : this.state.searchData?.rate?.indexOf("2") === -1
                      ? false
                      : true
                  }
                >
                  2 étoiles
                </Checkbox>
                <Checkbox
                  name="rate"
                  onChange={(e) => this.handleChange("rate", e)}
                  value="1"
                  checked={
                    this.state.searchData?.rate === undefined
                      ? false
                      : this.state.searchData?.rate?.indexOf("1") === -1
                      ? false
                      : true
                  }
                >
                  1 étoile
                </Checkbox>
              </CheckboxGroup>
            </div>
            <div className="filter-box">
              {" "}
              <p>Point de réference</p>
              {this.state.referenceListData.map((item) => (
                <Checkbox
                  name="referenceId"
                  onChange={(e) => this.handleChange("referenceId", e)}
                  value={item.id}
                >
                  {item.title}
                </Checkbox>
              ))}
            </div>
          </Drawer.Body>
        </Drawer>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        {espaceDataIsLoading && (
          <div className="search-loader-box">
            <div className="box">
              <Loader center vertical content="loading" size="lg" />
            </div>
          </div>
        )}
        <Modal open={this.state.savingCart} onClose={this.handleClose}>
          <Modal.Header>
            <Modal.Title>Vos disponibilités ont été exporté</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup
              size="lg"
              placeholder="Large"
              inside
              style={{
                marginBottom: 10,
              }}
            >
              <Input
                className="form-control input-monospace input-sm color-bg-subtle"
                placeholder={this.state.extLink}
                readonly=""
              />
              <InputGroup.Button
                onClick={() => {
                  navigator.clipboard.writeText(this.state.extLink);
                }}
              >
                <svg
                  aria-hidden="true"
                  height="16"
                  viewBox="0 0 16 16"
                  version="1.1"
                  width="16"
                  data-view-component="true"
                  class="octicon octicon-copy js-clipboard-copy-icon d-inline-block"
                >
                  <path
                    fill-rule="evenodd"
                    d="M0 6.75C0 5.784.784 5 1.75 5h1.5a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 00.25-.25v-1.5a.75.75 0 011.5 0v1.5A1.75 1.75 0 019.25 16h-7.5A1.75 1.75 0 010 14.25v-7.5z"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M5 1.75C5 .784 5.784 0 6.75 0h7.5C15.216 0 16 .784 16 1.75v7.5A1.75 1.75 0 0114.25 11h-7.5A1.75 1.75 0 015 9.25v-7.5zm1.75-.25a.25.25 0 00-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 00.25-.25v-7.5a.25.25 0 00-.25-.25h-7.5z"
                  ></path>
                </svg>
                <svg
                  aria-hidden="true"
                  height="16"
                  viewBox="0 0 16 16"
                  version="1.1"
                  width="16"
                  data-view-component="true"
                  class="octicon octicon-check js-clipboard-check-icon color-fg-success d-inline-block d-sm-none"
                >
                  <path
                    fill-rule="evenodd"
                    d="M13.78 4.22a.75.75 0 010 1.06l-7.25 7.25a.75.75 0 01-1.06 0L2.22 9.28a.75.75 0 011.06-1.06L6 10.94l6.72-6.72a.75.75 0 011.06 0z"
                  ></path>
                </svg>
              </InputGroup.Button>
            </InputGroup>
            <p>
              Partagez votre panier en utilisant l’URL web.{" "}
              <a href={this.state.extLink} target="_blank">
                Ouvrir le lien
              </a>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose} appearance="subtle">
              Fermer
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default search_results;
