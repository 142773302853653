import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Loader, Modal, Button, Input, InputGroup } from "rsuite";
import CheckIcon from "@rsuite/icons/Check";
import controllable from "react-controllables";
import { Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import "../../../assets/css/searchresults.css";

// service importation
import { addToCart, removeItem } from "../../../store/actions/cartActions";
import extClient from "../../../restApiService/extClient";
import extClientReservation from "../../../restApiService/extClientReservation";
import linkData from "../../../restApiService/linkData";
import cartCheckout from "../../../restApiService/cartCheckout";
import cartCheckoutLine from "../../../restApiService/cartCheckoutLine";
// end of service importation

controllable(["center", "zoom", "markers"]);

const Header = React.lazy(() => import("./components/actionHeader"));
const EmptyCart = React.lazy(() => import("./components/emptyCart"));
const SideDataList = React.lazy(() => import("./components/sideDataList"));
const Summary = React.lazy(() => import("./components/summary"));
const MapViewer = React.lazy(() => import("./components/mapViewer"));

// get connected user info
const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));
class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartSummary: [],
      clientList: [],
      new_client_item: [],
      clientId: 0,
      espaceDataIsLoading: false,
      espaceDataList: [],
      espaceDataListTmp: [],
      requestEspaceDataListTmp: [],
      espaceDataListForMap: [],
      filterboxIsOpen: false,
      searchFormboxIsOpen: false,
      title: "",
      campagne: "",
      extLink: "",
      notLogged: false,
      savingCart: false,
      afterLoop: false,
      showMap: false,
      mapViewer: false,
      MapLoading: false,
      handleLoading: false,
      oneViewSelected: false,
      prod_option: false,
      addExtra: false,
      valueFieldIsEmpty: true,
      oneViewSelectedId: 0,
      defaultlat: 0,
      defaultlng: 0,
      taux_frequentation: 0,
      sousTotal: 0,
      extra: 0,
      tva: 0,
      tsp: 0,
      odp: 0,
      tm: 0,
      totalTtc: 0,
      option: 0,
    };
  }

  fieldIsEmptyFunction = (lines) => {
    // Check if each item of the object is not empty
    let new_client_item = { ...this.state.new_client_item };
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (
        new_client_item[line] === null ||
        new_client_item[line] === undefined ||
        new_client_item[line] === ""
      ) {
        this.setState({ valueFieldIsEmpty: true });
        break;
      } else {
        this.setState({ valueFieldIsEmpty: false });
      }
    }
  };

  handleClickToRemove = (item) => {
    this.props.removeItem(item);
  };

  OneViewSelected = (espaceId) => {
    console.log(espaceId);
    this.setState({ MapLoading: true, oneViewSelectedId: espaceId });
    let espaceDataListTmp = [...this.state.espaceDataListTmp];

    this.setState({ espaceDataListForMap: espaceDataListTmp });

    setTimeout(() => {
      let filteredList = this.state.espaceDataListForMap.filter(
        (item) => item.id === espaceId
      );
      this.setState({
        oneViewSelected: true,
        espaceDataListForMap: filteredList,
        defaultlat: Number(filteredList[0].geolatitude),
        defaultlng: Number(filteredList[0].geolongitude),
      });
    }, 400);
    setTimeout(() => {
      this.setState({ MapLoading: false });
    }, 500);
  };

  ResetOneViewSelected = () => {
    this.setState({
      mapViewer: false,
    });
  };

  handleClose = () => {
    this.setState({ savingCart: false });
  };

  addExtraProd = () => {
    let cartSummary = { ...this.state.cartSummary };
    this.setState({ addExtra: !this.state.addExtra });
    console.log("cartSummary", cartSummary);
    console.log("this.state.totalTtc", this.state.totalTtc);
    if (this.state.addExtra) {
      let newTotal = this.state.totalTtc - this.state.extra;
      cartSummary["total"] = newTotal;
      this.setState({ totalTtc: newTotal });
    } else {
      let newTotal = this.state.totalTtc + this.state.extra;
      cartSummary["total"] = newTotal;
      this.setState({ totalTtc: newTotal });
    }
    this.setState({ cartSummary });
  };

  generateCartSummary = (cart, prod_option) => {
    let HT = 0;
    let TVA = 0;
    let TTC = 0;
    let TSP = 0;
    let ODP = 0;
    let total_ODP = 0;
    let total_TM = 0;
    let TM = 0;
    let prix = 0;
    let sous_total = 0;
    let total_format = 0;
    let prixSearch_0 = 0;
    let total = 0;
    let items = [];
    let total_extra = 0;
    let total_extra_o = 0;
    let extra = 0;
    let emailItems = [];
    let eclairage_panneaux = 0;
    let frais_transport_total = 0;
    let cartSummary;
    let taux;
    let arteresOk = [];

    for (var i = 0; i < cart.length; i++) {
      let item = cart[i];
      console.log("item", item);
      //LIst des product à joindre à l'Email
      let tmpEmailItems = {
        nom_panneaux: item.title,
        code: item.code,
        localite: item.ville.title,
      };

      emailItems.push(tmpEmailItems);

      console.log("item", item);

      const prix = parseInt(item.prixCalculate);
      console.log("prix", prix);
      console.log("prix_min", item.prixCalculate);
      sous_total += item.prixCalculate * 1;
      const formatsize = item.format.slug;
      const formats = formatsize.length;
      const formatsufixe = formatsize.slice(-2);
      const format = formats - 1;

      prixSearch_0 = item.prixCalculate;

      const formatInt = formatsize.slice(0, format);

      total_format += formatInt * 1;

      //calcule du montant TTC

      if (item.commune.title === "Plateau") {
        localStorage.setItem("Plateau", i.toString());
      } else {
      }

      HT = sous_total;
      TVA = sous_total * 0.18;
      TTC = sous_total + TVA;
      TSP = TTC * 0.03;

      const cTaxe = localStorage.getItem("Plateau");

      if (
        item.commune.title === "Plateau" &&
        item.eclairage === "Non éclairé"
      ) {
        ODP = 4000 * formatInt * 1;
        TM = 1000 * formatInt * 1;
      } else if (
        item.commune.title === "Plateau" &&
        item.eclairage === "non éclairé"
      ) {
        ODP = 4000 * formatInt * 1;
        TM = 1000 * formatInt * 1;
      } else if (
        item.commune.title === "Plateau" &&
        item.eclairage === "Eclairé"
      ) {
        ODP = 4000 * formatInt * 1;
        TM = 3000 * formatInt * 1;
      } else if (
        item.commune.title === "Plateau" &&
        item.eclairage === "éclairé"
      ) {
        ODP = 4000 * formatInt * 1;
        TM = 3000 * formatInt * 1;
      }

      if (item.commune.title != "Plateau" && item.eclairage === "Non éclairé") {
        ODP = 1000 * formatInt * 1;
        TM = 1000 * formatInt * 1;
      } else if (
        item.commune.title != "Plateau" &&
        item.eclairage === "non éclairé"
      ) {
        ODP = 1000 * formatInt * 1;
        TM = 1000 * formatInt * 1;
      } else if (
        item.commune.title != "Plateau" &&
        item.eclairage === "Eclairé"
      ) {
        ODP = 1000 * formatInt * 1;
        TM = 3000 * formatInt * 1;
      } else if (
        item.commune.title != "Plateau" &&
        item.eclairage === "éclairé"
      ) {
        ODP = 1000 * formatInt * 1;
        TM = 3000 * formatInt * 1;
      }

      console.log(total_TM);

      total_ODP += ODP * 1;
      total_TM += TM * 1;

      let options = "";

      options = item.format.title;
      extra = item.production;

      console.log("coute de production", extra);
      console.log("coute de production", item);

      total_extra_o += extra * 1;

      //frais_transport_total += 0 * 1;
      frais_transport_total += item.ville.frais_transport * 1;

      console.log("optiopn", Option);
      console.log("option prod", prod_option);

      if (prod_option === true) {
        total_extra = total_extra_o * 1;
      } else total_extra = 0;

      // generate frequentation count
      if (arteresOk.length > 0) {
        for (var j = 0; j < arteresOk.length; j++) {
          if (arteresOk[j] !== item.artere.title) {
            if (isNaN(Number(item.artere.frequentation)) === false) {
              this.setState({
                taux_frequentation:
                  this.state.taux_frequentation +
                  Number(item.artere.frequentation),
              });
              arteresOk.push(item.artere.title);
            }
          }
        }
      } else {
        if (isNaN(Number(item.artere.frequentation)) === false) {
          console.log("item.artere.title", item.artere.title);
          console.log(
            "item.artere.frequentation",
            Number(item.artere.frequentation)
          );
          console.log("taux", taux);
          this.setState({
            taux_frequentation:
              Number(this.state.taux_frequentation) +
              Number(item.artere.frequentation),
          });
          // taux += Number(item.artere.frequentation) * 1
          arteresOk.push(item.artere.title);
        }
      }

      let sous_total_0 = sous_total;
      let total_extra_o_0 = total_extra_o;
      let TTC_0 = TTC;
      let TVA_0 = TVA;
      let TSP_0 = TSP;
      let total_ODP_0 = total_ODP;
      let total_extra_0 = total_extra;
      let frais_transport_total_0 = isNaN(frais_transport_total)
        ? 0
        : frais_transport_total;
      let total_TM_0 = total_TM;
      let addExtraValue = this.state.addExtra ? total_extra_o_0 : 0;

      let total_0 =
        sous_total_0 +
        TVA_0 +
        TSP_0 +
        total_ODP_0 +
        total_TM_0 +
        frais_transport_total_0 +
        addExtraValue;

      this.setState({
        totalTtc: total_0,
        extra: total_extra_o_0,
        taux_frequentation: frais_transport_total_0,
        sousTotal: sous_total_0,
        tva: TVA_0,
        tsp: TSP_0,
        odp: total_ODP_0,
        tm: total_TM_0,
      });

      cartSummary = {
        sous_total: sous_total_0,
        TVA: TVA_0,
        TSP: TSP_0,
        total_ODP: total_ODP_0,
        total_TM: total_TM_0,
        frais_transport_total: frais_transport_total_0,
        total_extra_o: total_extra_o_0,
        total: total_0,
        taux_frequentation: this.state.taux_frequentation,
      };
    }

    console.log("cartSummary : ", cartSummary);
    this.setState({ cartSummary: cartSummary });
    // return cartSummary
  };

  showMapViewer = () => {
    this.setState({ mapViewer: true, MapLoading: true });
    setTimeout(() => {
      this.setState({ MapLoading: false, showMap: true });
    }, 1000);
  };

  restoreCartParams = () => {
    let searchParams = localStorage.getItem("params");
    this.props.history.push("/partner/search-results/" + searchParams);
  };

  cartCheckout = () => {
    const { sousTotal, tva, tsp, odp, tm, totalTtc } = this.state;
    if (currentUserInfo) {
      this.setState({ handleLoading: true });
      localStorage.removeItem("reservedItem");

      if (this.state.option == 2) {
        this.setRegieClient();
      }

      let Total_cart = {
        nom_campagne: localStorage.getItem("cpgne"),
        userId: currentUserInfo?.id,
        sousTotal: sousTotal,
        tva: tva,
        tsp: tsp,
        odp: odp,
        tm: tm,
        totalTtc: totalTtc,
        date_reservation:
          moment(parseInt(localStorage.start)).format("DD/MM/YYYY") +
          " - " +
          moment(parseInt(localStorage.end)).format("DD/MM/YYYY"),
        prix_extra: this.total_extra,
        transport: this.frais_transport_total,
        espace_reserve: this.emailItems,
        etat: 0,
      };

      cartCheckout
        .setReservation(Total_cart)
        .then((req) => {
          let client_reservation = {
            extClientId: this.state.clientId,
            reservationId: req.data.data.id,
          };
          this.setExtClientReservation(client_reservation);

          let cartitem = this.props.items;
          for (var i = 0; i < cartitem.length; i++) {
            let itemInCart = cartitem[i];

            var total_cartiem = {
              panneauxId: itemInCart.id,
              date_reservation:
                moment(parseInt(localStorage.start)).format("DD/MM/YYYY") +
                " - " +
                moment(parseInt(localStorage.end)).format("DD/MM/YYYY"),
              start: localStorage.start,
              end: localStorage.end,
              prix: itemInCart.prixCalculate,
              reservationId: req.data.data.id,
            };

            //   store each reservation line
            cartCheckoutLine
              .setReservationLine(total_cartiem)
              .then((req) => {
                // remove item from cart
                this.handleClickToRemove(itemInCart);
              })
              .catch((error) => {});

            if (i == cartitem.length - 1) {
              this.setState({ handleLoading: false });
              this.props.history.push("/partner/checkout/complete");
            }
          }
        })
        .catch((error) => {
          this.setState({ handleLoading: false });
        });
    } else {
      localStorage.setItem("checkout", "ad6476a36ec35fdd4d9f");
      this.props.history.push("/auth/sign-in");
    }
  };

  handleChange = (value) => {
    this.setState({ option: value, valueFieldIsEmpty: true });
  };

  handleChangeClientId = (value) => {
    console.log(value);
    let new_client_item = { ...this.state.new_client_item };
    new_client_item["clientId"] = value;
    this.setState({ new_client_item });
    this.setState({ clientId: value });
    setTimeout(() => {
      this.fieldIsEmptyFunction(["clientId"]);
    }, 300);
  };

  handleChangeClientItem = (event) => {
    console.log(event);
    let new_client_item = { ...this.state.new_client_item };
    new_client_item[event.target.name] = event.target.value;
    this.setState({ new_client_item });
    setTimeout(() => {
      this.fieldIsEmptyFunction(["firstname", "lastname", "phone", "email"]);
    }, 300);
  };

  SaveCartAndGenerateExtLink = () => {
    const campagne = localStorage.getItem("cpgne");
    const transformToLowercase = campagne.toLowerCase();
    const replaceSpaceByDash = transformToLowercase
      .replace(/\s/g, "-")
      .replace(/\(/g, "-")
      .replace(/\)/g, "-")
      .replace(/à/g, "a")
      .replace(/á/g, "a")
      .replace(/â/g, "a")
      .replace(/é/g, "e")
      .replace(/è/g, "e")
      .replace(/ê/g, "e")
      .replace(/ë/g, "e")
      .replace(/î/g, "i")
      .replace(/ï/g, "i")
      .replace(/û/g, "u")
      .replace(/ü/g, "u")
      .replace(/ù/g, "u")
      .replace(/ô/g, "o")
      .replace(/ö/g, "o")
      .replace(/œ/g, "o-e")
      .replace(/'/g, "-");

    this.setState({
      extLink: "",
      linkParams: JSON.parse(localStorage.getItem("searchParams")),
      loadingLink: true,
    });

    let linkData = {
      campagne: localStorage.getItem("cpgne"),
      items: localStorage.getItem("tmpCart"),
      start: moment(parseInt(localStorage.start)).format("DD/MM/YYYY"),
      end: moment(parseInt(localStorage.end)).format("DD/MM/YYYY"),
      periodeStart: localStorage.getItem("start"),
      periodeEnd: localStorage.getItem("end"),
      temps_reservation: localStorage.getItem("range"),
      slug: replaceSpaceByDash + "-" + currentUserInfo.id,
      userId: currentUserInfo.id,
      params: localStorage.getItem("params"),
    };

    cartCheckout
      .saveCart(linkData)
      .then((data) => {
        this.setState({
          savingCart: true,
          extLink:
            "https://nymba.ci/#/saved/" +
            replaceSpaceByDash +
            "-" +
            currentUserInfo.id,
          loadingLink: false,
        });
      })
      .catch((error) => {
        this.setState({ savingCart: true, extLink: "", loadingLink: false });
      });
  };

  getRegieClient = () => {
    extClient
      .getEntityByRegieId(currentUserInfo.regieId)
      .then((req) => this.setState({ clientList: req.data }));
  };

  setRegieClient = () => {
    let new_client_item = { ...this.state.new_client_item };
    new_client_item["regieId"] = currentUserInfo.regieId;
    this.setState({ new_client_item });
    extClient
      .setEntity(new_client_item)
      .then((req) => this.setState({ clientId: req.data.data.id }));
  };

  setExtClientReservation = (data) => {
    extClientReservation
      .setEntity(data)
      .then((req) => this.setState({ clientId: req.data.id }));
  };

  updateCampagneName = () => {
    this.setState({
      campagneNameUpdated: !this.state.campagneNameUpdated,
      campagne: localStorage.getItem("cpgne"),
    });
  };

  componentDidMount() {
    localStorage.removeItem("checkout");
    this.getRegieClient();
    this.setState({
      MapLoading: true,
      campagne: localStorage.getItem("cpgne")
        ? localStorage.getItem("cpgne")
        : "",
    });

    this.props.items.length > 0 &&
      this.generateCartSummary(this.props.items, this.state.prod_option);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      // this.setState({ MapLoading: true })
      // this.setState({ searchFormboxIsOpen: false, espaceDataIsLoading: true })

      this.props.items.length > 0 &&
        this.generateCartSummary(this.props.items, this.state.prod_option);
    }
  }

  //   <PanneauxItem />
  render() {
    let {
      filterboxIsOpen,
      searchFormboxIsOpen,
      espaceDataIsLoading,
      espaceDataList,
      espaceDataListForMap,
      requestEspaceDataListTmp,
      showMap,
      mapViewer,
      defaultlat,
      defaultlng,
      oneViewSelected,
      MapLoading,
      oneViewSelectedId,
      afterLoop,
      title,
      cartSummary,
      taux_frequentation,
      campagne,
    } = this.state;

    console.log("items", this.props);

    return (
      <>
        <section className="login-content cart-page">
          <Row className="m-0 bg-white">
            {!mapViewer && (
              <>
                <Col md={this.props.items.length === 0 ? "12" : "7"}>
                  {this.props.items.length === 0 ? (
                    <Row className="justify-content-center align-content-center h-100vh">
                      <EmptyCart history={this.props.history} />
                    </Row>
                  ) : (
                    <Row className="justify-content-center">
                      <Col md="12">
                        {!this.state.campagneNameUpdated && (
                          <h3 className="search-title p-2">
                            <strong>Mon panier : </strong> {campagne}{" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  Modifier le nom de votre campagne
                                </Tooltip>
                              }
                            >
                              <button
                                className="mr-1 edit-campagne-title"
                                onClick={this.updateCampagneName}
                              >
                                <i class="far fa-edit"></i>
                              </button>
                            </OverlayTrigger>
                          </h3>
                        )}
                        {this.state.campagneNameUpdated && (
                          <h3 className="d-flex align-items-center search-title p-2">
                            <strong>Mon panier : </strong>{" "}
                            <InputGroup
                              style={{
                                width: "60%",
                                marginLeft: 10,
                                marginRight: 10,
                              }}
                              onChange={this.handleCampagneField}
                            >
                              <Input value={this.state.campagne} focused />
                              <InputGroup.Button
                                onClick={this.refleshCampagneField}
                              >
                                <CheckIcon />
                              </InputGroup.Button>
                            </InputGroup>{" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Annuler</Tooltip>}
                            >
                              <button
                                className="mr-1 edit-campagne-title"
                                onClick={this.updateCampagneName}
                              >
                                <i class="fas fa-times"></i>
                              </button>
                            </OverlayTrigger>
                          </h3>
                        )}
                      </Col>
                      <Col md="12">
                        <div className="result-header-warn">
                          <svg
                            width="46"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.81409 20.4368H19.1971C20.7791 20.4368 21.7721 18.7267 20.9861 17.3527L13.8001 4.78775C13.0091 3.40475 11.0151 3.40375 10.2231 4.78675L3.02509 17.3518C2.23909 18.7258 3.23109 20.4368 4.81409 20.4368Z"
                              stroke="#eaba3a"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.0024 13.4147V10.3147"
                              stroke="#eaba3a"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.995 16.5H12.005"
                              stroke="#eaba3a"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p className="m-l-2 text-left">
                            Certains panneaux peuvent ne plus être disponibles
                            en se moment même, ne vous inquiètez pas vous pouvez
                            les retrouver dans vos favories pour une prochaine
                            campagne
                          </p>
                        </div>
                      </Col>
                      <Col md="12" className="result-header-o">
                        <Header
                          items={this.props.items}
                          showMapViewer={this.showMapViewer}
                          taux_frequentation={taux_frequentation}
                          restoreCartParams={this.restoreCartParams}
                          SaveCartAndGenerateExtLink={
                            this.SaveCartAndGenerateExtLink
                          }
                        />
                      </Col>
                      <Col md="12">
                        <div className="result-boby">
                          <SideDataList
                            espaceDataIsLoading={espaceDataIsLoading}
                            espaceDataList={this.props.items}
                            OneViewSelected={this.OneViewSelected}
                            oneViewSelectedId={this.state.oneViewSelectedId}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
                {this.props.items.length !== 0 && (
                  <Col
                    md="5"
                    className="p-0 h-950 p-sticky-65 bg-grey overflow-hidden"
                  >
                    <Summary
                      items={this.props.items}
                      option={this.state.option}
                      clientList={this.state.clientList}
                      valueFieldIsEmpty={this.state.valueFieldIsEmpty}
                      handleChange={this.handleChange}
                      handleChangeClientId={this.handleChangeClientId}
                      handleChangeClientItem={this.handleChangeClientItem}
                      handleLoading={this.state.handleLoading}
                      cartCheckout={this.cartCheckout}
                      addExtraProd={this.addExtraProd}
                      taux_frequentation={taux_frequentation}
                      cartSummary={cartSummary}
                      fieldIsEmptyFunction={this.fieldIsEmptyFunction}
                    />
                  </Col>
                )}
              </>
            )}
            {mapViewer && (
              <Col md="12" className="p-0 bg-grey">
                {MapLoading ? (
                  <div className="map-loader">
                    <Loader center vertical content="loading" size="lg" />
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col md="12" className="result-header-o">
                        <Header
                          items={this.props.items}
                          showMapViewer={this.showMapViewer}
                          taux_frequentation={taux_frequentation}
                          ResetOneViewSelected={this.ResetOneViewSelected}
                          showMap={showMap}
                          restoreCartParams={this.restoreCartParams}
                          SaveCartAndGenerateExtLink={
                            this.SaveCartAndGenerateExtLink
                          }
                        />
                      </Col>
                      <Col md="12" className="cart-map-box">
                        <MapViewer
                          showMap={showMap}
                          defaultlat={Number(this.props.items[0]?.geolatitude)}
                          defaultlng={Number(this.props.items[0]?.geolongitude)}
                          espaceDataList={this.props.items}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            )}
          </Row>
        </section>
        <Modal open={this.state.savingCart} onClose={this.handleClose}>
          <Modal.Header>
            <Modal.Title>Votre panier a été sauvegardé</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup
              size="lg"
              placeholder="Large"
              inside
              style={{
                marginBottom: 10,
              }}
            >
              <Input
                className="form-control input-monospace input-sm color-bg-subtle"
                placeholder={this.state.extLink}
                readonly=""
              />
              <InputGroup.Button
                onClick={() => {
                  navigator.clipboard.writeText(this.state.extLink);
                }}
              >
                <svg
                  aria-hidden="true"
                  height="16"
                  viewBox="0 0 16 16"
                  version="1.1"
                  width="16"
                  data-view-component="true"
                  class="octicon octicon-copy js-clipboard-copy-icon d-inline-block"
                >
                  <path
                    fill-rule="evenodd"
                    d="M0 6.75C0 5.784.784 5 1.75 5h1.5a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 00.25-.25v-1.5a.75.75 0 011.5 0v1.5A1.75 1.75 0 019.25 16h-7.5A1.75 1.75 0 010 14.25v-7.5z"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M5 1.75C5 .784 5.784 0 6.75 0h7.5C15.216 0 16 .784 16 1.75v7.5A1.75 1.75 0 0114.25 11h-7.5A1.75 1.75 0 015 9.25v-7.5zm1.75-.25a.25.25 0 00-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 00.25-.25v-7.5a.25.25 0 00-.25-.25h-7.5z"
                  ></path>
                </svg>
                <svg
                  aria-hidden="true"
                  height="16"
                  viewBox="0 0 16 16"
                  version="1.1"
                  width="16"
                  data-view-component="true"
                  class="octicon octicon-check js-clipboard-check-icon color-fg-success d-inline-block d-sm-none"
                >
                  <path
                    fill-rule="evenodd"
                    d="M13.78 4.22a.75.75 0 010 1.06l-7.25 7.25a.75.75 0 01-1.06 0L2.22 9.28a.75.75 0 011.06-1.06L6 10.94l6.72-6.72a.75.75 0 011.06 0z"
                  ></path>
                </svg>
              </InputGroup.Button>
            </InputGroup>
            <p>
              Partagez votre panier en utilisant l’URL web.{" "}
              <a href={this.state.extLink} target="_blank">
                Ouvrir le lien
              </a>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose} appearance="subtle">
              Fermer
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.cartReducer;
  return {
    items: addedItemsTmp.addedItems,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id) => {
      dispatch(addToCart(id));
    },
    removeItem: (id) => {
      dispatch(removeItem(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
