import React, { Component } from "react";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Card from "../../../components/Card";
import Logo from "../../../components/partials/components/logo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// RestApi service import
import auth from "../../../restApiService/auth";

// img
import facebook from "../../../assets/images/brands/fb.svg";
import google from "../../../assets/images/brands/gm.svg";
import instagram from "../../../assets/images/brands/im.svg";
import linkedin from "../../../assets/images/brands/li.svg";
import auth5 from "../../../assets/images/auth/05.png";

class SignUp extends Component {
  state = {
    item: [],
    handleLoginLoading: false,
  };

  handleChange = (event) => {
    console.log(event.target.name);
    let item = { ...this.state.item };
    item[event.target.name] = event.target.value;
    this.setState({ item });
  };

  handleLogin = () => {
    const promiseVar = toast.loading(
      "Recherche des information du client en cours..."
    );
    this.setState({ handleLoginLoading: true });
    const { item } = this.state;
    auth
      .signUp(item)
      .then((response) => {
        localStorage.setItem("userInfo", JSON.stringify(response.data.user));
        toast.update(promiseVar, {
          render: "Votre compte a été créée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          this.setState({ handleLoginLoading: false });
          if (localStorage.getItem("checkout")) {
            window.location.href = "/#/app/mon-panier";
          } else {
            window.location.href = "/#/";
          }
        }, 1500);
      })
      .catch((error) => {
        toast.update(promiseVar, {
          render: "Informations incorrectes",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        this.setState({ handleLoginLoading: false });
      });
  };

  render() {
    const { handleLoginLoading } = this.state;
    return (
      <>
        <section className="login-content">
          <Row className="m-0 align-items-center bg-white vh-100">
            <div className="col-md-6 d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
              <Image
                src="https://nymba-files.s3.us-west-2.amazonaws.com/Photo%202.jpg"
                className="Image-fluid gradient-main animated-scaleX"
                alt="images"
              />
            </div>
            <Col md="6">
              <Row className="justify-content-center">
                <Col md="10">
                  <Card className="card-transparent auth-card-o shadow-none d-flex justify-content-center mb-0">
                    <Card.Body>
                      <Link
                        to="/app"
                        className="navbar-brand login d-flex align-items-center mb-3"
                      >
                        <Logo />
                      </Link>
                      <h2 className="mb-2">Créer un compte</h2>
                      <p className="mb-4">
                        Créez un compte afin de profiter des services Nymba en
                        toute simplicité.
                      </p>
                      <Form>
                        <Row>
                          <Col lg="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="full-name" className="">
                                Nom*
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className=""
                                name="lastname"
                                id="full-name"
                                onChange={this.handleChange}
                                placeholder=" "
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="last-name" className="">
                                Prenom(s)*
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className=""
                                name="firstname"
                                id="last-name"
                                onChange={this.handleChange}
                                placeholder=" "
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="email" className="">
                                Adresse email*
                              </Form.Label>
                              <Form.Control
                                type="email"
                                className=""
                                id="email"
                                name="email"
                                onChange={this.handleChange}
                                placeholder=" "
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="phone" className="">
                                Numéro de téléphone*
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className=""
                                id="phone"
                                name="phone"
                                onChange={this.handleChange}
                                placeholder=" "
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="12">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="phone" className="">
                                Entreprise
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className=""
                                id="entreprise"
                                name="entreprise"
                                onChange={this.handleChange}
                                placeholder=" "
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="password" className="">
                                Mot de passe*
                              </Form.Label>
                              <Form.Control
                                type="password"
                                className=""
                                id="password"
                                name="password"
                                onChange={this.handleChange}
                                placeholder=" "
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="6">
                            <Form.Group className="form-group">
                              <Form.Label
                                htmlFor="confirm-password"
                                className=""
                              >
                                Confirmer mot de passe*
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className=""
                                id="confirm-password"
                                name="password"
                                onChange={this.handleChange}
                                placeholder=" "
                              />
                            </Form.Group>
                          </Col>
                          <Col
                            lg="12"
                            className="d-flex justify-content-center"
                          >
                            <Form.Check className="mb-3 form-check">
                              <Form.Check.Input
                                type="checkbox"
                                id="customCheck1"
                              />
                              <Form.Check.Label htmlFor="customCheck1">
                                J'accepte les termes & conditions
                              </Form.Check.Label>
                            </Form.Check>
                          </Col>
                        </Row>
                        <div className="d-flex justify-content-center">
                          {handleLoginLoading ? (
                            <Button variant="btn btn-yellow w-100" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Inscription...
                            </Button>
                          ) : (
                            <Button
                              onClick={this.handleLogin}
                              type="button"
                              variant="btn btn-yellow w-100"
                            >
                              S'inscrire
                            </Button>
                          )}
                        </div>
                        {/* <p className="text-center my-3">
                        or sign in with other accounts?
                      </p>
                      <div className="d-flex justify-content-center">
                        <ListGroup
                          as="ul"
                          className="list-group-horizontal list-group-flush"
                        >
                          <ListGroup.Item
                            as="li"
                            className="list-group-item border-0 pb-0"
                          >
                            <Link to="#">
                              <Image src={facebook} alt="fb" />
                            </Link>
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            className="list-group-item border-0 pb-0"
                          >
                            <Link to="#">
                              <Image src={google} alt="gm" />
                            </Link>
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            className="list-group-item border-0 pb-0"
                          >
                            <Link to="#">
                              <Image src={instagram} alt="im" />
                            </Link>
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            className="list-group-item border-0 pb-0"
                          >
                            <Link to="#">
                              <Image src={linkedin} alt="li" />
                            </Link>
                          </ListGroup.Item>
                        </ListGroup>
                      </div> */}
                        <p className="mt-3 text-center">
                          Vous avez déjà un compte?
                        </p>
                        <p className="mt-0 text-center">
                          <Link to="/auth/sign-in" className="text-underline">
                            Connectez-vous
                          </Link>
                        </p>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {/* <div className="sign-bg sign-bg-right">
              <svg
                width="280"
                height="230"
                viewBox="0 0 421 359"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.05">
                  <rect
                    x="-15.0845"
                    y="154.773"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 -15.0845 154.773)"
                    fill="#3A57E8"
                  />
                  <rect
                    x="149.47"
                    y="319.328"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(-45 149.47 319.328)"
                    fill="#3A57E8"
                  />
                  <rect
                    x="203.936"
                    y="99.543"
                    width="310.286"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 203.936 99.543)"
                    fill="#3A57E8"
                  />
                  <rect
                    x="204.316"
                    y="-229.172"
                    width="543"
                    height="77.5714"
                    rx="38.7857"
                    transform="rotate(45 204.316 -229.172)"
                    fill="#3A57E8"
                  />
                </g>
              </svg>
            </div> */}
            </Col>
          </Row>
        </section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

export default SignUp;
