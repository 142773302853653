import React, { Component, Suspense } from "react";
//router
import { Switch, Route } from "react-router";
// import Index from '../views/dashboard/index'
import Index from "../views/customer-interface";
import Search_results from "../views/customer-interface/search_results/search_results";
import Search_results_by_entity from "../views/customer-interface/search_results/search_results_by_entity";
import Single_espace from "../views/customer-interface/single_espace/single_espace";
import Cart from "../views/customer-interface/cart/cart";
import CheckoutComplete from "../views/customer-interface/checkout-complete/checkout-complete";
import WishList from "../views/customer-interface/wishList/wishList";
import UserReservations from "../views/customer-interface/user_reservations/user_reservations";
import SavedCart from "../views/customer-interface/saved_cart/saved_cart";
import Account from "../views/customer-interface/account/account";
import About from "../views/customer-interface/about";
import Cgu from "../views/customer-interface/cgu";
import MentionLegale from "../views/customer-interface/mention-legale";
import PolitiqueConfidentialite from "../views/customer-interface/politique-confidentialite";
import Carriere from "../views/customer-interface/carriere";
import DevenirPartenaire from "../views/customer-interface/devenir-partenaire";
import Presse from "../views/customer-interface/presse";

class HorizontalRouter extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.history.history.location.pathname !==
      prevProps.history.location.pathname
    ) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }

    // setTimeout(() => {
    // }, 2000)
    // window.addEventListener('scroll', () => {
    //   if (window.scrollY === 0) {
    //     window.scrollTo({ top: 0, behavior: 'smooth' })
    //   }
    // })
  }
  render() {
    return (
      // <div>
      //   <Index />
      // </div>
      <>
        <Suspense fallback={"loading..."}>
          <Switch>
            <Route path="/" exact component={Index}></Route>
            <Route path="/app" exact component={Index}></Route>
            <Route
              path="/app/search-results/:data"
              exact
              component={Search_results}
            ></Route>
            <Route
              path="/app/espaces/:target/:slug"
              exact
              component={Search_results_by_entity}
            ></Route>
            <Route
              path="/app/espaces/:slug"
              exact
              component={Single_espace}
            ></Route>
            <Route path="/app/mon-panier" exact component={Cart}></Route>
            <Route
              path="/app/checkout/complete"
              exact
              component={CheckoutComplete}
            ></Route>
            <Route path="/app/favoris" exact component={WishList}></Route>
            <Route
              path="/app/reservations"
              exact
              component={UserReservations}
            ></Route>
            <Route
              path="/app/paniers-sauvegardes"
              exact
              component={SavedCart}
            ></Route>
            <Route
              path="/app/account/info-personelles"
              exact
              component={Account}
            ></Route>
            <Route path="/app/qui-somme-nous" exact component={About}></Route>
            <Route path="/app/condition-generale" exact component={Cgu}></Route>
            <Route
              path="/app/mentions-legales"
              exact
              component={MentionLegale}
            ></Route>
            <Route path="/app/carriere" exact component={Carriere}></Route>
            <Route
              path="/app/devenir-partenaire"
              exact
              component={DevenirPartenaire}
            ></Route>
            <Route path="/app/coin-presse" exact component={Presse}></Route>
            {/* <Route
              path="/app/account/info-personelles"
              exact
              component={PolitiqueConfidentialite}
            ></Route> */}
          </Switch>
        </Suspense>
      </>
    );
  }
}

export default HorizontalRouter;
