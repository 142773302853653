import axios from "axios";
import config from "./config";
const prefixPoint = "panneaux";
export default {
  getBestPanneauxByRate: () => {
    return axios.get(
      `${config.URL_END_POINT}/panneaux/list/gte`,
      config.headersConfig
    );
  },
  setEntity: (data) => {
    return axios.post(
      `${config.URL_END_POINT}/${prefixPoint}/`,
      data,
      config.headersConfig
    );
  },
  getEntityList: () => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/list`,
      config.headersConfig
    );
  },
  getEntityDispoCount: () => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/dispo/count`,
      config.headersConfig
    );
  },
  getEntityIndispoCount: () => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/indispo/count`,
      config.headersConfig
    );
  },
  getEntityPaginateShortList: (statut, page) => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/list/paginated?statut=${statut}&page=${page}&size=5`,
      config.headersConfig
    );
  },
  getEntityPaginateList: (statut) => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/${statut}/list`,
      config.headersConfig
    );
  },
  // getEntityPaginateList: (statut, page) => {
  //   return axios.get(
  //     `${config.URL_END_POINT}/${prefixPoint}/list/paginated?statut=${statut}&page=${page}&size=20`,
  //     config.headersConfig
  //   );
  // },
  getEntityRegiePaginateList: (regieId, page) => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/list/paginated/${regieId}?page=${page}&size=20`,
      config.headersConfig
    );
  },
  getEntityId: (id) => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/${id}`,
      config.headersConfig
    );
  },
  getEntityBySlug: (slug) => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/slug/${slug}`,
      config.headersConfig
    );
  },
  updateEntityId: (id) => {
    return axios.put(`${config.URL_END_POINT}/${prefixPoint}/update/${id}`);
  },
  deleteEntityId: (id) => {
    return axios.delete(`${config.URL_END_POINT}/${prefixPoint}/${id}`);
  },
  entityRegieCount: (regieId) => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/regie/count/${regieId}`,
      config.headersConfig
    );
  },
  entityReserverListCountByRegieIdAndDate: (data) => {
    return axios.post(
      `${config.URL_END_POINT}/${prefixPoint}/regie/count`,
      data,
      config.headersConfig
    );
  },
};
