import React, { Component } from "react";
import Moment from "react-moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FsLightbox from "fslightbox-react";
import { Row, Col, Modal, Form, Button, Image } from "react-bootstrap";
import {
  Drawer,
  CheckboxGroup,
  Checkbox,
  Popover,
  Whisper,
  ButtonToolbar,
  Loader,
} from "rsuite";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import PageHeader from "../../../components/partials/dashboard/HeaderStyle/partner-page-header";
import SearchForm from "../../../components/partials/dashboard/SearchFormStyle/search-form-style-1";
import sep from "../../../components/partials/components/number-separator";
import Card from "../../../components/Card";

// services importation
import extClient from "../../../restApiService/extClient";

const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));

class slider extends Component {
  state = {
    item: [],
    entityDataTmp: [],
    FsLightboxData: [],
    entityData: [],
    selectEntityData: [],
    files: [],
    fileTmp: [],
    files_o: [],
    makeAction: false,
    actionType: "",
    searchTerm: "",
    show: false,
    show2: false,
    showFsLightbox: false,
    searchFormboxIsOpen: false,
  };

  handleClose = () => this.setState({ show: false });
  handleClose2 = () => this.setState({ show2: false });
  handleShow = () =>
    this.setState({ fileTmp: [], show: true, actionType: "add" });

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  };

  getEntityData = () => {
    extClient
      .getEntityByRegieId(currentUserInfo.regieId)
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          entityData: response.data,
          entityDataTmp: response.data,
        });
      })
      .catch((error) => console.log("first"));
  };

  updateData = () => {
    let item = { ...this.state.item };
    delete item["createdAt"];
    delete item["updatedAt"];
    extClient
      .updateEntityId(item.id, item)
      .then((response) => {
        this.componentDidMount();
        this.setState({
          searchFormboxIsOpen: false,
        });
      })
      .catch((error) => console.log("first"));
  };

  selectEntity = (item) => {
    console.log("ok");
    this.setState({
      item: item,
      searchFormboxIsOpen: true,
    });
  };

  removeItem = (id) => {
    const promiseVar = toast.loading("Traitement en cours...");
    extClient
      .deleteEntityId(id)
      .then((response) => {
        this.componentDidMount();
        // this.setState({ selectEntityData: this.state.entityData[0] })
        toast.update(promiseVar, {
          render: "Opération éffectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) =>
        toast.update(promiseVar, {
          render: "Une erreur est susvenue",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      );
  };

  componentDidMount() {
    this.getEntityData();
  }

  render() {
    const { entityData, searchFormboxIsOpen } = this.state;

    return (
      <div className="bg-grey-light">
        <PageHeader
          coloredText="Mes"
          normalText="clients"
          bgColor="#13367c"
          markerColor="#d24f3f"
        />
        <div className="container">
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">
                      Liste des clients ({entityData.length}){" "}
                    </h4>
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                  <div className="table-responsive">
                    <table
                      id="user-list-table"
                      className="table table-striped"
                      role="grid"
                      data-toggle="data-table"
                    >
                      <thead>
                        <tr className="ligth">
                          <th className="text-l-box">Nom et prénom</th>
                          <th>Email</th>
                          <th>Téléphone</th>
                          <th>Création</th>
                          <th min-width="100px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {entityData.map((item, idx) => (
                          <tr key={idx}>
                            <td className="text-l-box">
                              {item?.firstname + " " + item?.lastname}
                            </td>
                            <td>{item?.email}</td>
                            <td>{item?.phone}</td>
                            <td>
                              <Moment format="DD/MM/YYYY">
                                {item.createdAt}
                              </Moment>
                            </td>
                            <td>
                              <div className="flex align-items-center list-user-action">
                                <button
                                  className="btn btn-sm btn-icon"
                                  onClick={() => this.selectEntity(item)}
                                >
                                  <span className="btn-inner">
                                    <svg
                                      class="icon-32"
                                      width="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      {" "}
                                      <path
                                        d="M15.7161 16.2234H8.49609"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>{" "}
                                      <path
                                        d="M15.7161 12.0369H8.49609"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>{" "}
                                      <path
                                        d="M11.2521 7.86011H8.49707"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>{" "}
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M15.909 2.74976C15.909 2.74976 8.23198 2.75376 8.21998 2.75376C5.45998 2.77076 3.75098 4.58676 3.75098 7.35676V16.5528C3.75098 19.3368 5.47298 21.1598 8.25698 21.1598C8.25698 21.1598 15.933 21.1568 15.946 21.1568C18.706 21.1398 20.416 19.3228 20.416 16.5528V7.35676C20.416 4.57276 18.693 2.74976 15.909 2.74976Z"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>{" "}
                                    </svg>
                                  </span>
                                </button>{" "}
                                <button
                                  className="btn btn-sm btn-icon"
                                  onClick={() => this.removeItem(item.id)}
                                >
                                  <span className="btn-inner">
                                    <svg
                                      width="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      stroke="currentColor"
                                    >
                                      <path
                                        d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M20.708 6.23975H3.75"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </span>
                                </button>{" "}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>

        <Modal
          show={searchFormboxIsOpen}
          onHide={() => this.setState({ searchFormboxIsOpen: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Modifier</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="row mt-4">
              <div class="field-box col-lg-4">
                <label for="">Nom</label>
                <input
                  type="text"
                  class="form-control bg-light border-0 small mb-4"
                  placeholder=""
                  name="lastname"
                  id="lastname"
                  value={this.state.item.lastname}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div class="field-box col-lg-8">
                <label for="">Prénom</label>
                <input
                  type="text"
                  class="form-control bg-light border-0 small mb-4"
                  placeholder=""
                  name="firstname"
                  id="firstname"
                  value={this.state.item.firstname}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div class="field-box col-lg-12">
                <label for="">Téléphone</label>
                <input
                  type="text"
                  class="form-control bg-light border-0 small mb-4"
                  placeholder=""
                  name="phone"
                  id="phone"
                  value={this.state.item.phone}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div class="field-box col-lg-12">
                <label for="">Email</label>
                <input
                  type="text"
                  class="form-control bg-light border-0 small mb-4"
                  placeholder=""
                  name="email"
                  id="email"
                  value={this.state.item.email}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>
            <Button
              variant="primary"
              className="blue-btn"
              onClick={this.updateData}
            >
              Enregister
            </Button>{" "}
            <Button
              variant="danger"
              onClick={() => this.setState({ searchFormboxIsOpen: false })}
            >
              Cancel
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default slider;
