const componentItem = (props) => {
  return (
    <>
      <div className="update-form">
        <div className="entityCardAddForm">
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Titre</p>
            <p class="entityInfoDetails">
              <input
                className="form-control"
                name="title"
                value={props.item?.title}
                onChange={props.handleChange}
              />
            </p>
          </div>
          <div class="entityCardInfos">
            <p class="entityInfoLabel">Pays</p>
            <p class="entityInfoDetails">
              <select
                className="form-control"
                name="countryId"
                value={props.item?.countryId}
                onChange={props.handleChange}
              >
                <option>Sélectionnez...</option>
                {props.countryList?.map((item, index) => (
                  <option value={item.id}> {item.title} </option>
                ))}
              </select>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default componentItem;
