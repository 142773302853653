const componentItem = (props) => {
  return (
    <>
      <div className="update-form">
        <div className="entityCardAddForm mt-4">
          <div className="row">
            <div className="col-md-6">
              <div class="form-item">
                <label>Montant minimum de reservation</label>
                <input
                  className="form-control"
                  name="prix_minimum"
                  value={props.item?.prix_minimum}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Prix d'achat minimum</label>
                <input
                  className="form-control"
                  name="prix_achat_minimum"
                  value={props.item?.prix_achat_minimum}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Montant de reservation pour 1 mois</label>
                <input
                  className="form-control"
                  name="prix_mensuel"
                  value={props.item?.prix_mensuel}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Prix d'achat pour 1 mois</label>
                <input
                  className="form-control"
                  name="prix_achat_mois"
                  value={props.item?.prix_achat_mois}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Montant de reservation pour 3 mois</label>
                <input
                  className="form-control"
                  name="prix_trimestriel"
                  value={props.item?.prix_trimestriel}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Montant de reservation pour 6 mois</label>
                <input
                  className="form-control"
                  name="prix_semestriel"
                  value={props.item?.prix_semestriel}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Montant de reservation pour 1 an</label>
                <input
                  className="form-control"
                  name="prix_annuel"
                  value={props.item?.prix_annuel}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Cout de production d'affiche</label>
                <input
                  className="form-control"
                  name="production"
                  value={props.item?.production}
                  onChange={props.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default componentItem;
