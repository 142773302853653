import React, { Component } from "react";
import { Navbar, Dropdown, Container } from "react-bootstrap";
import { connect } from "react-redux";
// Container,Dropdown,Button
import { Badge } from "rsuite";
import { Link } from "react-router-dom";
import CustomToggle from "../../../dropdowns";
// mobile-offcanvas
import MobildeOffcanvas from "../../components/mobile-offcanvas";
//Horizontal-nav
import HorizontalNav from "../HeaderStyle/horizontal-nav";

import {
  addUserInfos,
  removeUserInfos,
} from "../../../../store/actions/userActions";
//img
import flag1 from "../../../../assets/images/Flag/flag001.png";
import flag2 from "../../../../assets/images/Flag/flag-02.png";
import flag3 from "../../../../assets/images/Flag/flag-03.png";
import flag4 from "../../../../assets/images/Flag/flag-04.png";
import flag5 from "../../../../assets/images/Flag/flag-05.png";
import flag6 from "../../../../assets/images/Flag/flag-06.png";
import avatars1 from "../../../../assets/images/avatars/01.png";
// logo
import Logo from "../../components/logo";
// const currentUserInfo = JSON.parse(localStorage.getItem('userInfo'))
class HeaderStyle1 extends Component {
  state = {
    bounceIn: false,
    bounceIn02: false,
  };
  logOut = () => {
    localStorage.removeItem("userInfo");
    setTimeout(() => {
      window.location.reload();
    }, 400);
  };

  componentDidUpdate(prevProps) {
    console.log("prevProps", prevProps);
    if (prevProps.items !== this.props.items) {
      console.log("new changes is detect");
      this.setState({ bounceIn: true });
      setTimeout(() => {
        this.setState({ bounceIn: false });
      }, 1000);
    }
    if (prevProps.wishItems !== this.props.wishItems) {
      console.log("new changes is detect");
      this.setState({ bounceIn02: true });
      setTimeout(() => {
        this.setState({ bounceIn02: false });
      }, 1000);
    }
  }

  render() {
    const { currentUserInfo } = this.props;
    // console.log(currentUserInfo);
    return (
      <>
        <div className="top-header-box container-fluid">
          <div className="top-header">
            <p>
              <i class="fa fa-phone" aria-hidden="true"></i> (+225) 07 08 53 55
              78
            </p>
            <div class="follow-us">
              <ul className="left-panel list-inline mb-0 p-0">
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/company/nymba/"
                    target="_blank"
                  >
                    <i class="fab fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://web.facebook.com/Nymba-106906621022811/?ref=page_internal"
                    target="_blank"
                  >
                    <i class="fab fa-facebook-f" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Navbar expand="xl" className="nav iq-navbar p-sticky">
          <Container fluid className="navbar-inner">
            <MobildeOffcanvas />
            <Navbar.Brand as="div" className="col-lg-2 col-lg-3 ">
              <Link to="/" className="d-flex">
                <Logo color={true} />
              </Link>
            </Navbar.Brand>
            {/* <HorizontalNav /> */}
            <Navbar.Toggle aria-controls="navbarSupportedContent">
              <span className="navbar-toggler-icon">
                <span className="navbar-toggler-bar bar1 mt-2"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </span>
            </Navbar.Toggle>
            <Navbar.Collapse className="col-md-2" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {currentUserInfo.length === 0 && (
                  <Dropdown as="li" className="nav-item">
                    <Link
                      className={" anonymous-link nav-link "}
                      to="/auth/sign-in"
                    >
                      <svg
                        width="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.8125 12.0217H3.77148"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.8848 9.10571L15.8128 12.0217L12.8848 14.9377"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.50439 7.38897V6.45597C8.50439 4.42097 10.1534 2.77197 12.1894 2.77197H17.0734C19.1034 2.77197 20.7484 4.41697 20.7484 6.44697V17.587C20.7484 19.622 19.0984 21.272 17.0634 21.272H12.1784C10.1494 21.272 8.50439 19.626 8.50439 17.597V16.655"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                      Se connecter
                    </Link>
                  </Dropdown>
                )}
                {currentUserInfo.length === 0 && (
                  <Dropdown as="li" className="nav-item">
                    <Link
                      className={" anonymous-link nav-link "}
                      to="/auth/sign-up"
                    >
                      <svg
                        width="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.87651 15.2063C6.03251 15.2063 2.74951 15.7873 2.74951 18.1153C2.74951 20.4433 6.01251 21.0453 9.87651 21.0453C13.7215 21.0453 17.0035 20.4633 17.0035 18.1363C17.0035 15.8093 13.7415 15.2063 9.87651 15.2063Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.8766 11.886C12.3996 11.886 14.4446 9.841 14.4446 7.318C14.4446 4.795 12.3996 2.75 9.8766 2.75C7.3546 2.75 5.3096 4.795 5.3096 7.318C5.3006 9.832 7.3306 11.877 9.8456 11.886H9.8766Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19.2036 8.66919V12.6792"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21.2497 10.6741H17.1597"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                      S'inscrire
                    </Link>
                  </Dropdown>
                )}
                <Dropdown as="li" className="nav-item mr-2 m-l-2">
                  <Link to="/app/mon-panier">
                    <Badge
                      content={this.props.items.length}
                      className={this.state.bounceIn ? "bounceIn" : ""}
                    >
                      <svg
                        width="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.75 3.25L4.83 3.61L5.793 15.083C5.87 16.02 6.653 16.739 7.593 16.736H18.502C19.399 16.738 20.16 16.078 20.287 15.19L21.236 8.632C21.342 7.899 20.833 7.219 20.101 7.113C20.037 7.104 5.164 7.099 5.164 7.099"
                          stroke="#eaba3a"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.125 10.7949H16.898"
                          stroke="#eaba3a"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.15435 20.2026C7.45535 20.2026 7.69835 20.4466 7.69835 20.7466C7.69835 21.0476 7.45535 21.2916 7.15435 21.2916C6.85335 21.2916 6.61035 21.0476 6.61035 20.7466C6.61035 20.4466 6.85335 20.2026 7.15435 20.2026Z"
                          fill="currentColor"
                          stroke="#eaba3a"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M18.4346 20.2026C18.7356 20.2026 18.9796 20.4466 18.9796 20.7466C18.9796 21.0476 18.7356 21.2916 18.4346 21.2916C18.1336 21.2916 17.8906 21.0476 17.8906 20.7466C17.8906 20.4466 18.1336 20.2026 18.4346 20.2026Z"
                          fill="currentColor"
                          stroke="#eaba3a"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Badge>
                  </Link>
                </Dropdown>
                <Dropdown as="li" className="nav-item">
                  <Link to="/app/favoris">
                    <Badge
                      content={this.props.wishItems?.length}
                      className={this.state.bounceIn02 ? "bounceIn" : ""}
                    >
                      <svg
                        width="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.87187 11.5983C1.79887 8.24832 3.05287 4.41932 6.56987 3.28632C8.41987 2.68932 10.4619 3.04132 11.9999 4.19832C13.4549 3.07332 15.5719 2.69332 17.4199 3.28632C20.9369 4.41932 22.1989 8.24832 21.1269 11.5983C19.4569 16.9083 11.9999 20.9983 11.9999 20.9983C11.9999 20.9983 4.59787 16.9703 2.87187 11.5983Z"
                          stroke="#eaba3a"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16 6.69995C17.07 7.04595 17.826 8.00095 17.917 9.12195"
                          stroke="#eaba3a"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Badge>
                  </Link>
                </Dropdown>
                {currentUserInfo.length !== 0 && (
                  <Dropdown as="li" className="nav-item m-l-2">
                    <Dropdown.Toggle
                      as={CustomToggle}
                      variant=" nav-link py-0 d-flex align-items-center"
                      href="#"
                      id="navbarDropdown"
                    >
                      <img
                        src={avatars1}
                        alt="User-Profile"
                        className="theme-color-default-img img-fluid avatar avatar-50 avatar-rounded"
                      />
                      <div className="caption ms-3 ">
                        <h6 className="mb-0 caption-title">
                          {currentUserInfo?.firstname +
                            " " +
                            currentUserInfo?.lastname}
                        </h6>
                        {currentUserInfo?.roleId === 1 && (
                          <p className="mb-0 caption-sub-title">
                            Administrateur
                          </p>
                        )}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as="ul" className="dropdown-menu-end">
                      {currentUserInfo?.roleId === 1 && (
                        <Link to="/admin/dashboard" className="dropdown-item">
                          Tableau de bord
                        </Link>
                      )}
                      {currentUserInfo?.roleId === 2 && (
                        <Link to="/partner" className="dropdown-item">
                          Tableau de bord
                        </Link>
                      )}
                      <Link
                        to="/app/account/info-personelles"
                        className="dropdown-item"
                      >
                        Mon compte
                      </Link>
                      <Link to="/app/favoris" className="dropdown-item">
                        Mes coups de coeur
                      </Link>
                      <Link to="/app/reservations" className="dropdown-item">
                        Mes reservations
                      </Link>
                      <Link
                        to="/app/paniers-sauvegardes"
                        className="dropdown-item"
                      >
                        Mes paniers sauvegardés
                      </Link>
                      <Dropdown.Divider />
                      <Dropdown.Item type="button" onClick={this.logOut}>
                        Se déconnecter
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </ul>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.userInfoReducer;
  return {
    currentUserInfo: addedItemsTmp.addedUserInfos,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addUserInfos: (item) => {
      dispatch(addUserInfos(item));
    },
    removeUserInfos: (item) => {
      dispatch(removeUserInfos(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderStyle1);
