import Moment from "react-moment";
import moment from "moment";

const componentItem = (props) => {
  return (
    <>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Titre</p>
        <p class="entityInfoDetails">{props.selectEntityData?.title}</p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Taux de fréquentation</p>
        <p class="entityInfoDetails">{props.selectEntityData?.frequentation}</p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Pays</p>
        <p class="entityInfoDetails">
          {props.selectEntityData?.country?.title}
        </p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Date de création</p>
        <p class="entityInfoDetails">
          <Moment format="DD/MM/YYYY">
            {props.selectEntityData?.createdAt}
          </Moment>
        </p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Date de modification</p>
        <p class="entityInfoDetails">
          <Moment format="DD/MM/YYYY">
            {props.selectEntityData?.updatedAt}
          </Moment>
        </p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Image</p>
        <p class="entityInfoDetails">
          <button onClick={props.toggleLightbox} className="illustration-img">
            <img src={props.selectEntityData?.image} alt="" />
          </button>
        </p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Disposition</p>
      </div>

      <div class="entityCardInfos">
        <p class="entityInfoDetails">
          <ul className="layout-selection-box">
            <li>
              <button
                className={
                  props.layoutItem?.top_left_layout ===
                  props.selectEntityData.id
                    ? "layout-selected-o"
                    : "layout-unselected"
                }
              >
                <img src={props.row1lc1} alt="" />
              </button>
              <p>Position 01</p>
            </li>
            <li>
              <button
                className={
                  props.layoutItem?.top_right_layout_top ===
                  props.selectEntityData.id
                    ? "layout-selected-o"
                    : "layout-unselected"
                }
              >
                <img src={props.row1rc1} alt="" />
              </button>
              <p>Position 02</p>
            </li>
            <li>
              <button
                className={
                  props.layoutItem?.top_right_layout_bottom ===
                  props.selectEntityData.id
                    ? "layout-selected-o"
                    : "layout-unselected"
                }
              >
                <img src={props.row1rc2} alt="" />
              </button>
              <p>Position 03</p>
            </li>
            <li>
              <button
                className={
                  props.layoutItem?.middle_left_layout_top ===
                  props.selectEntityData.id
                    ? "layout-selected-o"
                    : "layout-unselected"
                }
              >
                <img src={props.row2lc1} alt="" />
              </button>
              <p>Position 04</p>
            </li>
            <li>
              <button
                className={
                  props.layoutItem?.middle_left_layout_bottom ===
                  props.selectEntityData.id
                    ? "layout-selected-o"
                    : "layout-unselected"
                }
              >
                <img src={props.row2lc2} alt="" />
              </button>
              <p>Position 05</p>
            </li>
            <li>
              <button
                className={
                  props.layoutItem?.middle_right_layout ===
                  props.selectEntityData.id
                    ? "layout-selected-o"
                    : "layout-unselected"
                }
              >
                <img src={props.row2rc1} alt="" />
              </button>
              <p>Position 06</p>
            </li>
            <li>
              <button
                className={
                  props.layoutItem?.bottom_left_layout ===
                  props.selectEntityData.id
                    ? "layout-selected-o"
                    : "layout-unselected"
                }
              >
                <img src={props.row3lc1} alt="" />
              </button>
              <p>Position 07</p>
            </li>
            <li>
              <button
                className={
                  props.layoutItem?.bottom_right_layout ===
                  props.selectEntityData.id
                    ? "layout-selected-o"
                    : "layout-unselected"
                }
              >
                <img src={props.row3rc1} alt="" />
              </button>
              <p>Position 08</p>
            </li>
          </ul>
        </p>
      </div>
    </>
  );
};

export default componentItem;
