import React, { Component } from 'react'

export default class skeletonLoading extends Component {
  componentDidMount() {}
  render() {
    return (
      <div id="skeleton-03">
        <div class="wrapper">
          <div class="element box item-02-01" data-id="0"></div>
          <div class="element box item-02-02" data-id="1"></div>
          <div class="element circle item-02-03" data-id="2"></div>
          <div class="element box item-02-04" data-id="3"></div>
          <div class="element box item-02-05" data-id="4"></div>
        </div>
      </div>
    )
  }
}
