import React from "react";
import { Link } from "react-router-dom";

const PanneauxItem = (props) => {
  console.log(props.data);
  return (
    <div class="content-type-format-home">
      <div class="img-type-format-home">
        <img
          alt=""
          typeof="foaf:Image"
          src={props.data.panneauxes[0]?.storages[0]?.image}
        />
        <div class="format-type-format-home">
          <Link to={"/app/espaces/format/" + props.data.slug}>
            {props.data.title}
          </Link>
        </div>
      </div>
      <div class="ville-commune-type-format-home">
        <div class="ville-type-format-home">
          <Link
            to={"/app/espaces/city/" + props.data.panneauxes[0]?.ville.slug}
          >
            {props.data.panneauxes[0]?.ville.title}
          </Link>
          ,{" "}
        </div>
        <div class="commune-type-format-home">
          <Link
            to={
              "/app/espaces/commune/" + props.data.panneauxes[0]?.commune.slug
            }
          >
            {props.data.panneauxes[0]?.commune.title}
          </Link>
        </div>
        <div class="etoile-panneaux-o">
          <i class="fa fa-star" aria-hidden="true">
            &nbsp;
          </i>{" "}
          ({props.data.panneauxes[0]?.rate})
        </div>
      </div>
      <div class="cart-type-format-home">
        <i class="fa fa-map-marker" aria-hidden="true"></i>
        <Link
          to={"/app/espaces/artere/" + props.data.panneauxes[0]?.artere.slug}
        >
          {props.data.panneauxes[0]?.artere.title}
        </Link>
      </div>
    </div>
  );
};

export default PanneauxItem;
