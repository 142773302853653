import React, { Component } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from "moment";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";

// component importation
import EntityList from "./component/entityList";
import EntityInfos from "./component/entityInfos";
import Header from "./component/hearder";
import InformationsGenerales from "./component/InformationsGenerales";
import DonneesdeLocalisation from "./component/DonneesdeLocalisation";
import Visibilites from "./component/Visibilites";
import Tarification from "./component/Tarification";
import Images from "./component/Images";
import Evaluations from "./component/Evaluations";

// services importation
import artiste_gender from "../../../restApiService/artere";
import panneaux from "../../../restApiService/panneaux";
import storage from "../../../restApiService/storage";
import regie from "../../../restApiService/regie";
import format from "../../../restApiService/format";
import modele from "../../../restApiService/modele";
import country from "../../../restApiService/country";
import ville from "../../../restApiService/ville";
import commune from "../../../restApiService/commune";
import artere from "../../../restApiService/artere";
import typeArtere from "../../../restApiService/type-artere";
import reference from "../../../restApiService/reference";
import reference_panneaux from "../../../restApiService/reference_panneaux";

// img
import shap1 from "../../../assets/images/shapes/01.png";
import shap2 from "../../../assets/images/shapes/02.png";
import shap3 from "../../../assets/images/shapes/03.png";
import shap4 from "../../../assets/images/shapes/04.png";
import shap5 from "../../../assets/images/shapes/05.png";
import shap6 from "../../../assets/images/shapes/06.png";

class Entity extends Component {
  state = {
    item: [],
    entityDataTmp: [],
    regieList: [],
    formatList: [],
    modeleList: [],
    countryList: [],
    villeList: [],
    communeList: [],
    artereList: [],
    typeArtereList: [],
    referenceList: [],
    references: [],
    storagefile01: "",
    storagefile02: "",
    files: [],
    files_o: [],
    files02: [],
    files02_o: [],
    entityData: [
      { id: 1, title: "Informations générales" },
      { id: 2, title: "Données géographique" },
      { id: 3, title: "Visibilités" },
      { id: 4, title: "Tarification" },
      { id: 5, title: "Images" },
      { id: 6, title: "Notes et évaluations" },
    ],
    selectEntityData: 1,
    makeAction: false,
    fileEdited01: false,
    fileEdited02: false,
    newFile01: false,
    newFile02: false,
    actionType: 1,
    searchTerm: "",
    rate01: 0,
    rate02: 0,
    rate03: 0,
    rate04: 0,
    rate05: 0,
  };

  filesEdited = (value) => {
    if (value === 1) {
      this.setState({
        fileEdited01: !this.state.fileEdited01,
        newFile01: !this.state.newFile01,
      });
    } else {
      this.setState({
        fileEdited02: !this.state.fileEdited02,
        newFile02: !this.state.newFile02,
      });
    }
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  };

  onDrop = (files) => {
    this.setState({
      newFile01: true,
      files_o: files,
      files: files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
  };

  onDrop02 = (files) => {
    this.setState({
      newFile02: true,
      files02_o: files,
      files02: files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
  };

  handleSearch = (event) => {
    // console.log('this.state.searchTerm : ', this.state.searchTerm)
    // this.setState({ searchTerm: event.target.value })

    if (event.target.value.length >= 2) {
      this.setState({
        entityData: this.state.entityDataTmp.filter((item) =>
          item.title.toLowerCase().includes(event.target.value.toLowerCase())
        ),
      });
    }
    if (event.target.value.length === 0) {
      this.setState({
        entityData: this.state.entityDataTmp,
      });
    }
  };

  getEntityData = (slug) => {
    panneaux
      .getEntityBySlug(slug)
      .then((response) => {
        this.setState({
          item: response.data[0],
          storagefile01: response.data[0]?.storages[0],
          storagefile02: response.data[0]?.storages[1],
        });
        setTimeout(() => {
          let item = { ...this.state.item };
          for (
            let i = 0;
            i < response.data[0]?.reference_panneauxes?.length;
            i++
          ) {
            const element = response.data[0]?.reference_panneauxes[i];
            this.setState({
              references: [...this.state.references, element?.referenceId],
            });
          }
          this.setState({ item });
        }, 400);
      })
      .catch((error) => console.log("first"));
  };

  selectEntity = (itemId) => {
    this.setState({
      selectEntityData: itemId,
      makeAction: false,
      actionType: itemId,
    });
  };

  selectPreviousEntity = () => {
    console.log(Number(this.state.actionType) - 1);
    if (Number(this.state.actionType) - 1 !== 0) {
      this.setState({
        selectEntityData: Number(this.state.actionType) - 1,
        makeAction: false,
        actionType: Number(this.state.actionType) - 1,
      });
    } else {
      this.setState({
        selectEntityData: 1,
        makeAction: false,
        actionType: 1,
      });
    }
  };

  selectNextEntity = () => {
    // console.log(Number(this.state.actionType) - 1);
    this.setState({
      selectEntityData: Number(this.state.actionType) + 1,
      makeAction: false,
      actionType: Number(this.state.actionType) + 1,
    });
  };

  addEntity = () => {
    // document.getElementById('create-form').reset()
    this.setState({
      item: null,
      makeAction: true,
      actionType: "add",
    });
  };

  handleList = (name, event) => {
    console.log("event", event);
    let item = { ...this.state.item };
    item[name] = event;
    this.setState({ item });
  };

  setReferencePanneaux = (data) => {
    reference_panneaux.setEntity(data);
  };

  delReferencePanneaux = (id) => {
    reference_panneaux.deleteEntityId(id);
  };

  savePanneauxStorage = (file, fileStorageId) => {
    const formData = new FormData();
    formData.append("files", file);
    formData.append("id", fileStorageId);
    storage
      .updateStorage(fileStorageId, formData)
      .then((response) => {})
      .catch((error) => {});
  };

  saveHandledData = () => {
    const { rate01, rate02, rate03, rate04, rate05 } = this.state;
    let item = { ...this.state.item };
    console.log("item", item);
    if (item.critere_1 == "oui") {
      this.setState({ rate01: 1 });
    } else {
      this.setState({ rate01: 0 });
    }
    if (item.critere_2 == "oui") {
      this.setState({ rate02: 1 });
    } else {
      this.setState({ rate02: 0 });
    }
    if (item.critere_3 == "oui") {
      this.setState({ rate03: 1 });
    } else {
      this.setState({ rate03: 0 });
    }
    if (item.critere_4 == "oui") {
      this.setState({ rate04: 1 });
    } else {
      this.setState({ rate04: 0 });
    }
    if (item.critere_5 == "oui") {
      this.setState({ rate05: 1 });
    } else {
      this.setState({ rate05: 1 });
    }
    setTimeout(() => {
      item["rate"] =
        this.state.rate01 +
        this.state.rate02 +
        this.state.rate03 +
        this.state.rate04 +
        this.state.rate05;
    }, 200);
    delete item["createdAt"];
    delete item["updatedAt"];
    console.log("item data", item);
    const promiseVar = toast.loading("Traitement en cours...");

    panneaux
      .updateEntityId(item.id, item)
      .then((response) => {
        this.componentDidMount();
        if (this.state.newFile01) {
          this.savePanneauxStorage(
            this.state.files_o[0],
            this.state.storagefile01?.id
          );
        }
        if (this.state.newFile02) {
          this.savePanneauxStorage(
            this.state.files02_o[0],
            this.state.storagefile02?.id
          );
        }

        if (this.state.references.length > 0) {
          // delete old reference
          for (let i = 0; i < item.reference_panneauxes.length; i++) {
            const element = item.reference_panneauxes[i];
            this.delReferencePanneaux(element.id);
          }
        }

        if (item.references.length > 0) {
          for (let i = 0; i < item.references.length; i++) {
            const element = item.references[i];
            this.setReferencePanneaux({
              panneauxId: item.id,
              referenceId: element,
            });
          }
        }

        toast.update(promiseVar, {
          render: "Opération éffectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        this.componentDidMount();
      })
      .catch((error) =>
        toast.update(promiseVar, {
          render: "Une erreur est susvenue",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      );
  };

  getRegieData = () => {
    regie
      .getEntityList()
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          regieList: response.data,
        });
      })
      .catch((error) => console.log("error"));
  };

  getFormatData = () => {
    format
      .getEntityList()
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          formatList: response.data,
        });
      })
      .catch((error) => console.log("error"));
  };

  getModeleData = () => {
    modele
      .getEntityList()
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          modeleList: response.data,
        });
      })
      .catch((error) => console.log("error"));
  };

  getCountryData = () => {
    country
      .getEntityList()
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          countryList: response.data,
        });
      })
      .catch((error) => console.log("error"));
  };

  getVilleData = () => {
    ville
      .getEntityList()
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          villeList: response.data,
        });
      })
      .catch((error) => console.log("error"));
  };

  getCommuneData = () => {
    commune
      .getEntityList()
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          communeList: response.data,
        });
      })
      .catch((error) => console.log("error"));
  };

  getArtereData = () => {
    artere
      .getEntityList()
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          artereList: response.data,
        });
      })
      .catch((error) => console.log("error"));
  };

  getTypeArtereData = () => {
    typeArtere
      .getEntityList()
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          typeArtereList: response.data,
        });
      })
      .catch((error) => console.log("error"));
  };

  getReferenceData = () => {
    reference
      .getEntityList()
      .then((response) => {
        console.log("referenceList", response.data);
        this.setState({
          referenceList: response.data.map((item) => ({
            label: item.title,
            value: item.id,
          })),
        });
      })
      .catch((error) => console.log("error"));
  };

  componentDidMount() {
    this.getEntityData(this.props.match.params.slug);
    this.getRegieData();
    this.getFormatData();
    this.getModeleData();
    this.getCountryData();
    this.getVilleData();
    this.getCommuneData();
    this.getArtereData();
    this.getTypeArtereData();
    this.getReferenceData();
  }

  render() {
    const {
      regieList,
      formatList,
      modeleList,
      countryList,
      villeList,
      communeList,
      artereList,
      typeArtereList,
      referenceList,
      references,
      entityData,
      selectEntityData,
      item,
      makeAction,
      actionType,
    } = this.state;

    return (
      <>
        <div>
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Ajoutez un nouvel espace</h4>
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                  <div className="entityBox space-style">
                    <div className="entityListBar">
                      <div className="entityListWrapper">
                        {/* <SearchBar handleSearch={this.handleSearch} /> */}
                        <EntityList
                          selectEntityData={selectEntityData}
                          entityData={entityData}
                          selectEntity={this.selectEntity}
                          removeItem={this.removeItem}
                          shap5={shap5}
                        />
                      </div>
                    </div>
                    <div className="entityBoxWrapper ent-layout">
                      <div className="entityBoxScrollbar">
                        {actionType === 1 && (
                          <InformationsGenerales
                            item={item}
                            regieList={regieList}
                            formatList={formatList}
                            modeleList={modeleList}
                            handleChange={this.handleChange}
                            handleList={this.handleList}
                          />
                        )}
                        {actionType === 2 && (
                          <DonneesdeLocalisation
                            item={item}
                            countryList={countryList}
                            villeList={villeList}
                            communeList={communeList}
                            handleChange={this.handleChange}
                            handleList={this.handleList}
                          />
                        )}
                        {actionType === 3 && (
                          <Visibilites
                            item={item}
                            artereList={artereList}
                            typeArtereList={typeArtereList}
                            referenceList={referenceList}
                            references={references}
                            handleChange={this.handleChange}
                            handleList={this.handleList}
                          />
                        )}
                        {actionType === 4 && (
                          <Tarification
                            item={item}
                            handleChange={this.handleChange}
                          />
                        )}
                        {actionType === 5 && (
                          <Images
                            item={item}
                            onDrop={this.onDrop}
                            onDrop02={this.onDrop02}
                            filesEdited={this.filesEdited}
                            files={this.state.files}
                            storagefile01={this.state.storagefile01}
                            storagefile02={this.state.storagefile02}
                            files_o={this.state.files_o}
                            files02={this.state.files02}
                            files02_o={this.state.files02_o}
                            fileEdited01={this.state.fileEdited01}
                            fileEdited02={this.state.fileEdited02}
                            newFile01={this.state.newFile01}
                            newFile02={this.state.newFile02}
                            handleChange={this.handleChange}
                          />
                        )}
                        {actionType === 6 && (
                          <Evaluations
                            item={item}
                            handleChange={this.handleChange}
                          />
                        )}
                      </div>
                      <Header
                        item={item}
                        removeItem={this.removeItem}
                        actionType={actionType}
                        selectEntityData={selectEntityData}
                        selectEntity={this.selectEntity}
                        selectPreviousEntity={this.selectPreviousEntity}
                        selectNextEntity={this.selectNextEntity}
                        saveHandledData={this.saveHandledData}
                        addEntity={this.addEntity}
                      />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Entity;
