import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "rsuite";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
// logo
import Logo from "../../components/logo";

const HeaderStyle4 = (props) => {
  return (
    <>
      <Navbar
        bg="dark"
        expand="lg"
        variant="dark"
        className="fixed-top iq-navbar"
        aria-label="Main navigation"
      >
        <Container fluid>
          <Link
            to="/dashboard"
            className="navbar-brand d-flex align-items-center"
          >
            <Logo color={true} />
            <h4 className="logo-title text-light ms-3 mb-0">Reservation</h4>
          </Link>
          <Navbar.Toggle
            className="p-0 border-0"
            aria-controls="navbarSideCollapse"
          >
            <span className="navbar-toggler-icon">
              <span className="navbar-toggler-bar bar1 mt-2"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </span>
          </Navbar.Toggle>
          <Navbar.Collapse className="offcanvas-collapse">
            <Nav as="ul" className=" me-auto mb-2 mb-lg-0">
              <Nav.Item as="li">
                <Nav.Link active aria-current="page" href="#"></Nav.Link>
              </Nav.Item>
            </Nav>
            <form className="d-flex">
              <Link className="btn btn-primary" to="/app/mon-panier">
                Finaliser ({props?.items?.length})
              </Link>
              <button className="btn btn-primary" type="submit">
                Revenir à l'interface d'administration
              </button>
            </form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default HeaderStyle4;
