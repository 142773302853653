import axios from "axios";
import config from "./config";
const prefixPoint = "reservation";
export default {
  getEntityList: () => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/list`,
      config.headersConfig
    );
  },
  getEntityRegieList: (id) => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/list/regie/${id}`,
      config.headersConfig
    );
  },
  getEntityId: (id) => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/${id}`,
      config.headersConfig
    );
  },
  getEntityBySlug: (slug) => {
    return axios.get(
      `${config.URL_END_POINT}/${prefixPoint}/slug/${slug}`,
      config.headersConfig
    );
  },
  updateEntityId: (id, data) => {
    return axios.put(
      `${config.URL_END_POINT}/${prefixPoint}/update/${id}`,
      data
    );
  },
  deleteEntityId: (id) => {
    return axios.delete(`${config.URL_END_POINT}/${prefixPoint}/${id}`);
  },
};
