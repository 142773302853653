import Moment from "react-moment";
import moment from "moment";

const componentItem = (props) => {
  return (
    <>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Titre</p>
        <p class="entityInfoDetails">{props.selectEntityData?.title}</p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Ville</p>
        <p class="entityInfoDetails">{props.selectEntityData?.ville?.title}</p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Date de création</p>
        <p class="entityInfoDetails">
          <Moment format="DD/MM/YYYY">
            {props.selectEntityData?.createdAt}
          </Moment>
        </p>
      </div>
      <div class="entityCardInfos">
        <p class="entityInfoLabel">Date de modification</p>
        <p class="entityInfoDetails">
          <Moment format="DD/MM/YYYY">
            {props.selectEntityData?.updatedAt}
          </Moment>
        </p>
      </div>
    </>
  );
};

export default componentItem;
