import React, { Component } from 'react'

export default class skeletonLoading extends Component {
  componentDidMount() {}
  render() {
    return (
      <div id="skeleton">
        <div class="wrapper">
          <div class="element box item-01" data-id="0"></div>
          <div class="element box item-02" data-id="1"></div>
          <div class="element box item-03" data-id="2"></div>
          <div class="element box item-04" data-id="3"></div>
          <div class="element box item-05" data-id="5"></div>
          <div class="element box item-06" data-id="5"></div>
          <div class="element box item-07" data-id="6"></div>
          <div class="element box item-08" data-id="7"></div>
          <div class="element box item-09" data-id="8"></div>
          <div class="element box item-10" data-id="9"></div>
          <div class="element box item-11" data-id="10"></div>
        </div>
      </div>
    )
  }
}
