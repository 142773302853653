import React, { Component, Suspense } from "react";
//router
import { Switch, Route } from "react-router";
// import Index from '../views/dashboard/index'
import Index from "../views/external-interface";
import Search_results from "../views/external-interface/search_results/search_results";
import Cart from "../views/external-interface/cart/cart";
import CheckoutComplete from "../views/external-interface/checkout-complete/checkout-complete";

class HorizontalRouter extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.history.history.location.pathname !==
      prevProps.history.location.pathname
    ) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  }
  render() {
    return (
      <>
        <Suspense fallback={"loading..."}>
          <Switch>
            <Route path="/search-engine" exact component={Index}></Route>
            <Route
              path="/search-engine/:slug/:code"
              exact
              component={Index}
            ></Route>
            <Route
              path="/search-engine/results/:slug/:data"
              exact
              component={Search_results}
            ></Route>
            <Route
              path="/search-engine/mon-panier"
              exact
              component={Cart}
            ></Route>
            <Route
              path="/search-engine/checkout/complete"
              exact
              component={CheckoutComplete}
            ></Route>
          </Switch>
        </Suspense>
      </>
    );
  }
}

export default HorizontalRouter;
