import React from 'react'

const testimomyItem = (props) => {
  return (
    <div class="content-temoignage-box">
      <div class="body-temoignage">
        <p>{props.data.message}</p>
      </div>
      <div class="img-fonct-nom-temoignage">
        <div class="img-temoignage">
          <img alt="" typeof="foaf:Image" src={props.data.photo} />
        </div>
        <div class="nom-temoignage">{props.data.nom_prenom}</div>
        <div class="fonct-temoignage">{props.data.fonction_temoignage} </div>
      </div>
    </div>
  )
}

export default testimomyItem
