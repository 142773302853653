import React, { Component } from "react";
import axios from "axios";
import { DateRangePicker, CheckPicker } from "rsuite";
import Slider from "react-slick";
import { bindActionCreators } from "redux";
import { Button, Nav, Collapse, Navbar, Container } from "react-bootstrap";
import Card from "../../src/components/Card";
import Logo from "../components/partials/components/logo";
import { ScrollSpy } from "bootstrap";
import { Link } from "react-router-dom";

//uiKit
import Accordions from "./uikit/accordion";
import Alerts from "./uikit/alert";
import Badges from "./uikit/badge";
import Breadcrumbs from "./uikit/breadcrumb";
import Buttons from "./uikit/button";
import ButtonGroups from "./uikit/buttons-group";
import Calenders from "./uikit/calender";
import Cards from "./uikit/card";
import Carousels from "./uikit/carousel";
import DropDowns from "./uikit/dropdowns";
import ListGroups from "./uikit/list-group";
import Modals from "./uikit/modal";
import Navbars from "./uikit/navbar";
import Navs from "./uikit/nav";
import OffCanvass from "./uikit/off-canvas";
import Paginations from "./uikit/pagination";
import Popovers from "./uikit/popovers";
import Scrollspys from "./uikit/scrollspy";
import Spinnerss from "./uikit/spinner";
import Toasts from "./uikit/toast";
import Tooltips from "./uikit/tooltip";
import Progresss from "./uikit/progress";
//form
import DisabledForms from "./uikit/disabled-form";
import AFormControls from "./uikit/alternate-form-control";
import Sizings from "./uikit/sizing";
import InputGroups from "./uikit/input-group";
import FloatingLables from "./uikit/floating-lable";
import AFloatingLables from "./uikit/alternate-floating-lable";
import ToggleBtns from "./uikit/toggle-btn";
import Validations from "./uikit/validation";
import Overview from "./uikit/form-overview";

// content
import Typographys from "./uikit/typography";
import Images from "./uikit/image";
import Figures from "./uikit/figure";
import Tables from "./uikit/table";

//img
import topImage from "../assets/images/dashboard/top-image.jpg";
import github from "../assets/images/brands/23.png";

//prism
import "../../node_modules/prismjs/prism";
import "../../node_modules/prismjs/themes/prism-okaidia.css";

// SliderTab
import SliderTab from "../plugins/slider-tabs";

// sidebar-offcanvas
import SidebarOffcanvas from "./uikit/sidebar-offcanvas";
// store
import {
  NavbarstyleAction,
  getDirMode,
  getcustomizerMode,
  getcustomizerprimaryMode,
  getcustomizerinfoMode,
  SchemeDirAction,
  ColorCustomizerAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "../store/setting/setting";
import HorizontalNav from "../components/partials/dashboard/HeaderStyle/horizontal-nav";
import { connect } from "react-redux";

// import fake data files
import fakeArtere from "../fakedata/fake_artere";
import fake_commune from "../fakedata/fake_commune";
import fake_format from "../fakedata/fake_format";
import fake_modele from "../fakedata/fake_modele";
import fake_panneaux from "../fakedata/fake_panneaux";
import fake_reference from "../fakedata/fake_reference";
import fake_regie from "../fakedata/fake_regie";
import fake_reservation from "../fakedata/fake_reservation";
import fake_reservation_line from "../fakedata/fake_reservation_line";
import fake_type_artere from "../fakedata/fake_type_artere";
import fake_user from "../fakedata/fake_user";
import fake_ville from "../fakedata/fake_ville";
import fake_artere from "../fakedata/fake_artere";

const searchData = {
  data: {
    formatId: [15],
    type_espace: ["Panneau"],
    villeId: [12],
    start: "1591660800",
    end: "1593475200",
  },
};

const data = [
  { label: "Eugenia", value: 1 },
  { label: "Lydia", value: 2 },
  { label: "Hal", value: 3 },
  { label: "Hannah", value: 4 },
  { label: "Harriet", value: 5 },
  { label: "Hattie", value: 6 },
  { label: "Hazel", value: 7 },
  { label: "Hilda", value: 8 },
];
// const data = [
//     { label: 'Eugenia', value: 1 },
//     { label: 'Lydia', value: 2 },
//     { label: 'Hal', value: 3 },
//     { label: 'Hannah', value: 4 },
//     { label: 'Harriet', value: 5 },
//     { label: 'Hattie', value: 6 },
//     { label: 'Hazel', value: 7 },
//     { label: 'Hilda', value: 8 },
// ].map((item) => ({ label: item, value: item }))

const mapStateToProps = (state) => {
  return {
    darkMode: getDarkMode(state),
    customizerMode: getcustomizerMode(state),
    cololrinfomode: getcustomizerinfoMode(state),
    colorprimarymode: getcustomizerprimaryMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
      ColorCustomizerAction,
    },
    dispatch
  ),
});
class index extends Component {
  constructor(props) {
    super(props);
  }

  handleSelect(ranges) {
    console.log(ranges);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  }

  handleList(event) {
    console.log("event", event);
  }

  addFakeData = (url, data) => {
    console.log("first");
    console.log("url", url);
    console.log("data", data.data);
    for (let i = 0; i < data.data.length; i++) {
      axios
        .post(url, data.data[i])
        .then((req) => {})
        .catch((error) => {});
    }
  };
  addFakeStorageData = (url, data) => {
    for (let i = 0; i < data.data.length; i++) {
      if (data.data[i].image_3) {
        let storageData = {
          image: data.data[i].image_3,
          panneauxId: data.data[i].id,
        };
        axios
          .post(url, storageData)
          .then((req) => {})
          .catch((error) => {});
      }
    }
  };
  addSlug = (url) => {
    axios
      .get(url)
      .then((req) => {})
      .catch((error) => {});
  };
  fakeSearchRequest = (url, data) => {
    console.log("first");
    console.log("url", url);
    console.log("data", data.data);
    axios
      .post(url, data.data)
      .then((req) => {})
      .catch((error) => {});
  };

  componentDidMount() {}

  render() {
    const selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      lazyLoad: true,
      autoplaySpeed: 3000,
    };
    return (
      <>
        <HorizontalNav />
        <span className="uisheet screen-darken"></span>
        <div
          className="header"
          style={{
            background: `url(${topImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100vh",
            position: "relative",
          }}
        >
          <div className="main-img">
            <div className="container">
              <svg
                width="150"
                viewBox="0 0 55 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="-0.423828"
                  y="34.5762"
                  width="50"
                  height="7.14286"
                  rx="3.57143"
                  transform="rotate(-45 -0.423828 34.5762)"
                  fill="white"
                />
                <rect
                  x="14.7295"
                  y="49.7266"
                  width="50"
                  height="7.14286"
                  rx="3.57143"
                  transform="rotate(-45 14.7295 49.7266)"
                  fill="white"
                />
                <rect
                  x="19.7432"
                  y="29.4902"
                  width="28.5714"
                  height="7.14286"
                  rx="3.57143"
                  transform="rotate(45 19.7432 29.4902)"
                  fill="white"
                />
                <rect
                  x="19.7783"
                  y="-0.779297"
                  width="50"
                  height="7.14286"
                  rx="3.57143"
                  transform="rotate(45 19.7783 -0.779297)"
                  fill="white"
                />
              </svg>
              <h1 className="my-4">{/* <span>Nymba v2</span> */}</h1>

              <div className="d-flex justify-content-center align-items-center mb-3">
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.addFakeData(
                        "http://localhost:3000/api/auth/signup",
                        fake_user
                      )
                    }
                  >
                    <span>Load user</span>
                  </Button>
                </div>
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.addFakeData(
                        "http://localhost:3000/api/regie",
                        fake_regie
                      )
                    }
                  >
                    <span>Load regie</span>
                  </Button>
                </div>
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.addFakeData(
                        "http://localhost:3000/api/ville",
                        fake_ville
                      )
                    }
                  >
                    <span>Load ville</span>
                  </Button>
                </div>
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.addFakeData(
                        "http://localhost:3000/api/commune",
                        fake_commune
                      )
                    }
                  >
                    <span>Load commune</span>
                  </Button>
                </div>
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.addFakeData(
                        "http://localhost:3000/api/format",
                        fake_format
                      )
                    }
                  >
                    <span>Load format</span>
                  </Button>
                </div>
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.addFakeData(
                        "http://localhost:3000/api/modele",
                        fake_modele
                      )
                    }
                  >
                    <span>Load modèle</span>
                  </Button>
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.addFakeData(
                        "http://localhost:3000/api/artere",
                        fake_artere
                      )
                    }
                  >
                    <span>Load artere</span>
                  </Button>
                </div>
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.addFakeData(
                        "http://localhost:3000/api/type_atere",
                        fake_type_artere
                      )
                    }
                  >
                    <span>Load type d'artere</span>
                  </Button>
                </div>
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.addFakeData(
                        "http://localhost:3000/api/reference",
                        fake_reference
                      )
                    }
                  >
                    <span>Load reference</span>
                  </Button>
                </div>
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.addFakeData(
                        "http://localhost:3000/api/panneaux",
                        fake_panneaux
                      )
                    }
                  >
                    <span>Load panneaux {fake_panneaux.length} </span>
                  </Button>
                </div>
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.addFakeData(
                        "http://localhost:3000/api/reservation",
                        fake_reservation
                      )
                    }
                  >
                    <span>Load reservations</span>
                  </Button>
                </div>
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.addFakeData(
                        "http://localhost:3000/api/reservation_line",
                        fake_reservation_line
                      )
                    }
                  >
                    <span>Load reservations item</span>
                  </Button>
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.fakeSearchRequest(
                        "http://localhost:3000/api/panneaux/search",
                        searchData
                      )
                    }
                  >
                    <span>Search request</span>
                  </Button>
                </div>
                <div className="ms-3">
                  <DateRangePicker />
                </div>
                <div className="ms-3">
                  <CheckPicker
                    placeholder="Ou sfjvj sfji"
                    data={data}
                    onChange={this.handleList}
                    style={{ width: 224 }}
                  />
                </div>
              </div>
              <div className="text-center">
                <div className="ms-3">
                  <Slider {...settings}>
                    <div>
                      <h3 className="test">1</h3>
                    </div>
                    <div>
                      <h3 className="test">2</h3>
                    </div>
                    <div>
                      <h3 className="test">3</h3>
                    </div>
                    <div>
                      <h3 className="test">4</h3>
                    </div>
                    <div>
                      <h3 className="test">5</h3>
                    </div>
                    <div>
                      <h3 className="test">6</h3>
                    </div>
                  </Slider>
                </div>
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.addFakeStorageData(
                        "http://localhost:3000/api/import/storage",
                        fake_panneaux
                      )
                    }
                  >
                    <span>Load panneaux image {fake_panneaux.length} </span>
                  </Button>
                </div>
                <div className="ms-3">
                  <Button
                    bsPrefix=" btn btn-light bg-white d-flex"
                    type="button"
                    onClick={() =>
                      this.addSlug(
                        "http://localhost:3000/api/panneaux/list/slug"
                      )
                    }
                  >
                    <span>Load Slug </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(index);
