import React, { Component } from "react";
// Container,Dropdown,Button
import { Link } from "react-router-dom";

class PageHeader extends Component {
  state = {};

  render() {
    const { coloredText, normalText, bgColor, markerColor } = this.props;
    return (
      <>
        <div class="partner-title-section" style={{ background: bgColor }}>
          <div class="container">
            <div class="title-section-title">
              <h1>
                <strong style={{ color: markerColor }}>{coloredText}</strong>{" "}
                {normalText}
              </h1>
            </div>
            {/* <div class="breadcrumbs">
              <div>
                <ul>
                  <li>
                    <Link to="/partner/accueil">Accueil</Link>
                  </li>
                  <li> {">"} </li>
                  <li>Espace</li>
                  <li> {">"} </li>
                  <li> créer </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default PageHeader;
