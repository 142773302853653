import React, { Component } from "react";
import { Link, browserHistory } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
import moment from "moment";
import { Modal, Button, DateRangePicker, CheckPicker } from "rsuite";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ville from "../../../../restApiService/ville";
import format from "../../../../restApiService/format";
import modele from "../../../../restApiService/modele";
import searchRequest from "../../../../restApiService/searchRequest";
import {
  addUserInfos,
  removeUserInfos,
} from "../../../../store/actions/userActions";

const cipher = (salt) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return (text) =>
    text.split("").map(textToChars).map(applySaltToChar).map(byteHex).join("");
};

const { allowedMaxDays } = DateRangePicker;
// const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));

class searchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      villeData: [],
      villeListData: [],
      artereData: [],
      communeData: [],
      communeListData: [],
      formatData: [],
      formatListData: [],
      typeArtereListData: [],
      modeleListData: [],
      referenceListData: [],
      espaceDataIsLoading: true,
      espaceDataList: [],
      espaceDataListTmp: [],
      requestEspaceDataListTmp: [],
      espaceDataListForMap: [],
      searchData: [],
      filterboxIsOpen: false,
      searchFormboxIsOpen: false,
      searchType: "",
      title: "",
      afterLoop: false,
      showMap: false,
      DateError: true,
      DateErrorModal: false,
      MapLoading: false,
      oneViewSelected: false,
      oneViewSelectedId: 0,
      defaultlat: 0,
      defaultlng: 0,
    };
  }

  handleList = (name, event) => {
    let searchData = { ...this.state.searchData };
    if (name === "villeId") {
      searchData["villeId"] = event;
    }
    if (name === "modeleId") {
      searchData["modeleId"] = event;
    }
    if (name === "formatId") {
      searchData["formatId"] = event;
    }
    this.setState({ searchData });
    console.log(searchData);
  };

  handleChangeDate = (event) => {
    let searchData = { ...this.state.searchData };
    if (event != null) {
      const startDate = moment(event[0]);
      const endDate = moment(event[1]);
      searchData["start"] = new Date(event[0]).getTime();
      searchData["end"] = new Date(event[1]).getTime();
      console.log(searchData);
      let range = endDate.diff(startDate, "days");
      searchData["range"] = range;
      localStorage.setItem("duration", range);
      this.setState({ searchData });
      setTimeout(() => {
        if (range <= 16) {
          this.setState({ DateError: true, DateErrorModal: true });
          toast.info(
            "Choisissez une période supérieure à 15 jours et inférieure à 1 an",
            {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        } else {
          this.setState({ DateError: false, DateErrorModal: false });
        }
      }, 500);
    }
  };

  SearchformEngine = (event) => {
    event.preventDefault();
    let searchData = { ...this.state.searchData };
    localStorage.setItem("end", searchData.end);
    localStorage.setItem("duration", searchData.range);
    localStorage.setItem("range", searchData.range);
    localStorage.setItem("start", searchData.start);
    if (window.location.href.includes("/partner/")) {
      searchData["regieId"] = [this.props.currentUserInfo.regieId];
    }
    // To create a cipher
    const myCipher = cipher("mySecretSalt");
    //Then cipher any text:
    myCipher(JSON.stringify(searchData)); // --> "7c606d287b6d6b7a6d7c287b7c7a61666f"
    // save search parameter in localStorage
    localStorage.setItem("params", myCipher(JSON.stringify(searchData)));

    if (window.location.href.includes("/admin/")) {
      // this.props.props.history.push(
      //   "/search-engine/results/" + myCipher(JSON.stringify(searchData))
      // );
      localStorage.setItem("AHFDE", true);
      this.props.props.history.push(
        "/app/search-results/" + myCipher(JSON.stringify(searchData))
      );
    } else if (window.location.href.includes("/partner/")) {
      this.props.props.history.push(
        "/partner/search-results/" + myCipher(JSON.stringify(searchData))
      );
    } else {
      // if (localStorage.getItem("AHFDE")r) {
      localStorage.removeItem("AHFDE");
      // }
      this.props.props.history.push(
        "/app/search-results/" + myCipher(JSON.stringify(searchData))
      );
    }

    // window.location.href =
    //   '/app/search-results/' + myCipher(JSON.stringify(searchData))
  };

  getVilleList = () => {
    ville
      .getEntityList()
      .then((rep) => {
        this.setState({
          villeListData: rep.data.map((item) => ({
            label: item.title,
            value: item.id,
          })),
        });
      })
      .catch((error) => {});
  };

  getFormatList = () => {
    format
      .getEntityList()
      .then((rep) => {
        this.setState({
          formatListData: rep.data.map((item) => ({
            label: item.title,
            value: item.id,
          })),
        });
      })
      .catch((error) => {});
  };

  closeModal = () => {
    this.setState({ DateErrorModal: false });
  };

  getModeleList = () => {
    modele
      .getEntityList()
      .then((rep) => {
        this.setState({
          modeleListData: rep.data.map((item) => ({
            label: item.title,
            value: item.id,
          })),
        });
      })
      .catch((error) => {});
  };
  componentDidMount() {
    // setTimeout(() => {
    //   window.scrollTo({ top: 0, behavior: 'smooth' })
    // }, 2000)

    this.getVilleList();
    this.getFormatList();
    this.getModeleList();
  }

  componentWillReceiveProps(nextProps) {}

  componentWillUpdate(nextProps, nextState) {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  render() {
    let { villeListData, modeleListData, formatListData } = this.state;
    return (
      <>
        <div className="search-form-box">
          <div className="search-form-item">
            <CheckPicker
              placeholder="Où voulez-vous afficher ?"
              data={villeListData}
              onChange={(e) => this.handleList("villeId", e)}
              style={{ width: 224 }}
            />
          </div>
          <div className="search-form-item">
            <CheckPicker
              placeholder="Sur quel type d'espace ?"
              data={modeleListData}
              onChange={(e) => this.handleList("modeleId", e)}
              style={{ width: 224 }}
            />
          </div>
          <div className="search-form-item">
            <CheckPicker
              placeholder="Sur quel format ?"
              data={formatListData}
              onChange={(e) => this.handleList("formatId", e)}
              style={{ width: 224 }}
            />
          </div>
          <div className="search-form-item-date-range not-mobile">
            <DateRangePicker
              placeholder="À quelle période ?"
              placement="bottomEnd"
              disabledDate={allowedMaxDays(365)}
              onChange={this.handleChangeDate}
              format="yyyy-MM-dd"
            />
          </div>
          <div className="search-form-item-date-range mobile">
            <DateRangePicker
              showOneCalendar
              placeholder="À quelle période ?"
              disabledDate={allowedMaxDays(365)}
              onChange={this.handleChangeDate}
              format="yyyy-MM-dd"
            />
          </div>
          <div className="search-form btn-o">
            <button
              className="search-btn"
              onClick={this.SearchformEngine}
              disabled={this.state.DateError ? true : false}
            >
              <svg
                width="32"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="11.7669"
                  cy="11.7666"
                  r="8.98856"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.0186 18.4851L21.5426 22"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

        <Modal open={this.state.DateErrorModal} onClose={this.closeModal}>
          <Modal.Header>
            <Modal.Title>Vous démarrez une campagne publicitaire ?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>
              Choisissez une période supérieure à 15 jours et inférieure à 1 an
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => this.setState({ DateErrorModal: false })}
              appearance="primary"
              className="w-100"
            >
              J'ai compris
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.cartReducer;
  let addedWishItemsTmp = state.wishReducer;
  let addedUserItemsTmp = state.userInfoReducer;
  return {
    currentUserInfo: addedUserItemsTmp.addedUserInfos,
    items: addedItemsTmp.addedItems,
    wish_items: addedWishItemsTmp.addedWishItems,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addUserInfos: (item) => {
      dispatch(addUserInfos(item));
    },
    removeUserInfos: (item) => {
      dispatch(removeUserInfos(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(searchForm);
