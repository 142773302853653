import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Loader, Modal, Button, Input, InputGroup } from "rsuite";
import controllable from "react-controllables";
import { Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import "../../../assets/css/searchresults.css";

// service importation
import { addToCart, removeItem } from "../../../store/actions/cartActions";
import extClient from "../../../restApiService/extClient";
import extClientReservation from "../../../restApiService/extClientReservation";
import linkData from "../../../restApiService/linkData";
import cartCheckout from "../../../restApiService/cartCheckout";
import cartCheckoutLine from "../../../restApiService/cartCheckoutLine";
// end of service importation
import checkicon from "../../../assets/images/icons/checked-o.png";

controllable(["center", "zoom", "markers"]);

const Header = React.lazy(() => import("./components/actionHeader"));
const EmptyCart = React.lazy(() => import("./components/emptyCart"));
const SideDataList = React.lazy(() => import("./components/sideDataList"));
const Summary = React.lazy(() => import("./components/summary"));
const MapViewer = React.lazy(() => import("./components/mapViewer"));

// get connected user info
class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartSummary: [],
      clientList: [],
      new_client_item: [],
      clientId: 0,
      espaceDataIsLoading: false,
      espaceDataList: [],
      espaceDataListTmp: [],
      requestEspaceDataListTmp: [],
      espaceDataListForMap: [],
      filterboxIsOpen: false,
      searchFormboxIsOpen: false,
      title: "",
      campagne: "",
      extLink: "",
      notLogged: false,
      savingCart: false,
      afterLoop: false,
      showMap: false,
      mapViewer: false,
      MapLoading: false,
      handleLoading: false,
      oneViewSelected: false,
      prod_option: false,
      addExtra: false,
      valueFieldIsEmpty: true,
      oneViewSelectedId: 0,
      defaultlat: 0,
      defaultlng: 0,
      taux_frequentation: 0,
      sousTotal: 0,
      extra: 0,
      tva: 0,
      tsp: 0,
      odp: 0,
      tm: 0,
      totalTtc: 0,
      option: 0,
    };
  }

  fieldIsEmptyFunction = (lines) => {};

  handleClickToRemove = (item) => {
    this.props.removeItem(item);
  };

  OneViewSelected = (espaceId) => {
    console.log(espaceId);
    this.setState({ MapLoading: true, oneViewSelectedId: espaceId });
    let espaceDataListTmp = [...this.state.espaceDataListTmp];

    this.setState({ espaceDataListForMap: espaceDataListTmp });

    setTimeout(() => {
      let filteredList = this.state.espaceDataListForMap.filter(
        (item) => item.id === espaceId
      );
      this.setState({
        oneViewSelected: true,
        espaceDataListForMap: filteredList,
        defaultlat: Number(filteredList[0].geolatitude),
        defaultlng: Number(filteredList[0].geolongitude),
      });
    }, 400);
    setTimeout(() => {
      this.setState({ MapLoading: false });
    }, 500);
  };

  ResetOneViewSelected = () => {
    this.setState({
      mapViewer: false,
    });
  };

  handleClose = () => {
    this.setState({ savingCart: false });
  };

  addExtraProd = () => {};

  generateCartSummary = (cart, prod_option) => {};

  showMapViewer = () => {
    this.setState({ mapViewer: true, MapLoading: true });
    setTimeout(() => {
      this.setState({ MapLoading: false, showMap: true });
    }, 1000);
  };

  restoreCartParams = () => {
    this.props.history.goBack();
  };

  cartCheckout = () => {};

  handleChange = (value) => {};

  handleChangeClientId = (value) => {};

  handleChangeClientItem = (event) => {};

  SaveCartAndGenerateExtLink = () => {
    this.setState({
      loadingLink: true,
    });

    let link_Data = {
      chosen: localStorage.getItem("tmpCart"),
      used: 1,
    };

    linkData
      .updateLinkData(localStorage.getItem("code"), link_Data)
      .then((data) => {
        let cartitem = this.props.items;

        for (var i = 0; i < cartitem.length; i++) {
          let itemInCart = cartitem[i];

          this.handleClickToRemove(itemInCart);

          if (i == cartitem.length - 1) {
            this.setState({
              savingCart: true,
              loadingLink: false,
            });
          }
        }
      })
      .catch((error) => {
        this.setState({ savingCart: true, loadingLink: false });
      });
  };

  setExtClientReservation = (data) => {};

  componentDidMount() {
    localStorage.removeItem("checkout");
  }

  componentDidUpdate(prevProps) {}

  render() {
    let {
      espaceDataIsLoading,
      showMap,
      mapViewer,
      MapLoading,
      taux_frequentation,
    } = this.state;

    console.log("items", this.props);

    return (
      <>
        <section className="login-content">
          <Row className="m-0 bg-white">
            {!mapViewer && (
              <>
                <Col md={this.props.items.length === 0 ? "12" : "9"}>
                  {this.props.items.length === 0 ? (
                    <Row className="justify-content-center align-content-center h-100vh">
                      <EmptyCart history={this.props.history} />
                    </Row>
                  ) : (
                    <Row className="justify-content-center">
                      {/* <Col md="12">
                        <h3 className="search-title p-2">
                          <strong>Mon panier : </strong> {campagne}{" "}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Modifier le nom de votre campagne
                              </Tooltip>
                            }
                          >
                            <button className="mr-1 edit-campagne-title">
                              <i class="far fa-edit"></i>
                            </button>
                          </OverlayTrigger>
                        </h3>
                      </Col> */}
                      <Col md="12">
                        <div className="result-header-warn">
                          <svg
                            width="46"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.81409 20.4368H19.1971C20.7791 20.4368 21.7721 18.7267 20.9861 17.3527L13.8001 4.78775C13.0091 3.40475 11.0151 3.40375 10.2231 4.78675L3.02509 17.3518C2.23909 18.7258 3.23109 20.4368 4.81409 20.4368Z"
                              stroke="#eaba3a"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12.0024 13.4147V10.3147"
                              stroke="#eaba3a"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.995 16.5H12.005"
                              stroke="#eaba3a"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p className="m-l-2 text-left">
                            Certains panneaux peuvent ne plus être disponibles
                            en se moment même, ne vous inquiètez pas vous pouvez
                            les retrouver dans vos favories pour une prochaine
                            campagne
                          </p>
                        </div>
                      </Col>
                      <Col md="12" className="result-header-o">
                        <Header
                          items={this.props.items}
                          showMapViewer={this.showMapViewer}
                          taux_frequentation={taux_frequentation}
                          restoreCartParams={this.restoreCartParams}
                          SaveCartAndGenerateExtLink={
                            this.SaveCartAndGenerateExtLink
                          }
                        />
                      </Col>
                      <Col md="12">
                        <div className="result-boby">
                          <SideDataList
                            espaceDataIsLoading={espaceDataIsLoading}
                            espaceDataList={this.props.items}
                            OneViewSelected={this.OneViewSelected}
                            oneViewSelectedId={this.state.oneViewSelectedId}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
                {this.props.items.length !== 0 && (
                  <Col
                    md="3"
                    className="p-0 h-950 p-sticky-65 bg-grey overflow-hidden"
                  >
                    {/* <Summary
                      items={this.props.items}
                      option={this.state.option}
                      clientList={this.state.clientList}
                      valueFieldIsEmpty={this.state.valueFieldIsEmpty}
                      handleChange={this.handleChange}
                      handleChangeClientId={this.handleChangeClientId}
                      handleChangeClientItem={this.handleChangeClientItem}
                      handleLoading={this.state.handleLoading}
                      cartCheckout={this.cartCheckout}
                      addExtraProd={this.addExtraProd}
                      taux_frequentation={taux_frequentation}
                      cartSummary={cartSummary}
                      fieldIsEmptyFunction={this.fieldIsEmptyFunction}
                    /> */}
                  </Col>
                )}
              </>
            )}
            {mapViewer && (
              <Col md="12" className="p-0 bg-grey">
                {MapLoading ? (
                  <div className="map-loader">
                    <Loader center vertical content="loading" size="lg" />
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col md="12" className="result-header-o">
                        <Header
                          items={this.props.items}
                          showMapViewer={this.showMapViewer}
                          taux_frequentation={taux_frequentation}
                          ResetOneViewSelected={this.ResetOneViewSelected}
                          showMap={showMap}
                          restoreCartParams={this.restoreCartParams}
                          SaveCartAndGenerateExtLink={
                            this.SaveCartAndGenerateExtLink
                          }
                        />
                      </Col>
                      <Col md="12" className="cart-map-box">
                        <MapViewer
                          showMap={showMap}
                          defaultlat={Number(this.props.items[0]?.geolatitude)}
                          defaultlng={Number(this.props.items[0]?.geolongitude)}
                          espaceDataList={this.props.items}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            )}
          </Row>
        </section>
        <Modal open={this.state.savingCart}>
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <img src={checkicon} alt="" className="success-icon" />
            <p className="mt-5">
              <strong>Félicitations, vos choix ont été validé!</strong>
            </p>
          </Modal.Body>
        </Modal>
        {this.state.savingCart && (
          <div className="search-loader-box">
            <div className="box"></div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.cartReducer;
  return {
    items: addedItemsTmp.addedItems,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id) => {
      dispatch(addToCart(id));
    },
    removeItem: (id) => {
      dispatch(removeItem(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
