export default {
  data: [
    { id: 13, title: 'Abobo', villeId: 12 },
    { id: 22, title: 'Adjamé', villeId: 12 },
    { id: 25, title: 'Anyama', villeId: 41 },
    { id: 20, title: 'Attécoubé', villeId: 12 },
    { id: 26, title: 'Attingué ', villeId: 42 },
    { id: 27, title: 'Bassam', villeId: 20 },
    { id: 19, title: 'Bingerville', villeId: 12 },
    { id: 11, title: 'Cocody', villeId: 12 },
    { id: 14, title: 'Koumassi', villeId: 12 },
    { id: 16, title: 'Marcory', villeId: 12 },
    { id: 21, title: 'Plateau', villeId: 12 },
    { id: 15, title: 'Port-bouet', villeId: 12 },
    { id: 18, title: 'Songon', villeId: 12 },
    { id: 17, title: 'Treichville', villeId: 12 },
    {
      id: 24,
      title: "Villes et Communes de l'intérieur",
      villeId: 13,
    },
    { id: 12, title: 'Yopougon', villeId: 12 },
  ],
}
