import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Image } from "react-bootstrap";
import moment from "moment";
import {
  Drawer,
  CheckboxGroup,
  Checkbox,
  Popover,
  Whisper,
  Toolbar,
  Loader,
  Modal,
  // Button,
  Input,
  InputGroup,
} from "rsuite";
// import SkeletonLoading from '../../components/skeletonLoading/skeleton-style-01'

// RestApi service import
import panneaux from "../../restApiService/panneaux";
import regie from "../../restApiService/regie";
import linkData from "../../restApiService/linkData";
import reservationLine from "../../restApiService/reservation_line";
import exClient from "../../restApiService/extClient";
import error404 from "../../assets/images/error/unlink.png";
const currentDate = new Date();

class index extends Component {
  state = { entityData: [], espaceDataIsLoading: true, brokeLink: false };

  getEntity = (slug) => {
    regie
      .getEntityBySlug(slug)
      .then((req) => {
        localStorage.setItem("regieTitle", req.data[0].title);
        this.setState({ entityData: req.data });
        this.getSearchParams(req.data[0].id, this.props.match.params.code);
      })
      .catch((error) => {
        this.setState({ espaceDataIsLoading: false, brokeLink: true });
        localStorage.removeItem("regieTitle");
      });
  };

  getSearchParams = (regieId, searchCode) => {
    linkData
      .getLinkData(regieId, searchCode)
      .then((req) => {
        // console.log(req.data[0].params);
        localStorage.setItem("code", req.data[0].code);
        this.props.history.push(
          "/search-engine/results/" +
            this.props.match.params.slug +
            "/" +
            req.data[0].params
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({ espaceDataIsLoading: false, brokeLink: true });
      });
  };

  getEntityInProgressRegieCount = (regieId) => {
    let body = {
      regieId: regieId,
      date: moment(currentDate).format("DD/MM/YYYY"),
    };
    panneaux
      .entityReserverListCountByRegieIdAndDate(body)
      .then((req) => {
        console.log("req.data", req.data);
        this.setState({ entityInProgressCount: req.data.count });
        // setTimeout(() => {
        //   this.setState({ bestPointIsLoading: false });
        // }, 500);
      })
      .catch((error) => {});
  };

  getEntityAllReservateRegieCount = (regieId) => {
    reservationLine
      .getEntityRegieId(regieId)
      .then((req) => {
        this.setState({ entityAllReservateCount: req.data.count });
      })
      .catch((error) => {});
  };

  exClientRegieCount = (regieId) => {
    exClient
      .getEntityByRegieId(regieId)
      .then((req) => {
        this.setState({ regieClientCount: req.data.length });
      })
      .catch((error) => {});
  };

  componentDidMount = () => {
    localStorage.removeItem("regieTitle");
    this.getEntity(this.props.match.params.slug);
  };

  render() {
    const { espaceDataIsLoading, brokeLink } = this.state;

    return (
      <>
        <div className="admin-search-engine">
          {espaceDataIsLoading && (
            <div className="search-loader-box">
              <div className="box">
                <Loader center vertical content="loading..." size="lg" />
              </div>
            </div>
          )}
          {brokeLink && (
            <div className="search-loader-box">
              <div className="box mt-20 text-center">
                <Image src={error404} className="img-fluid mb-4 w-20" alt="" />
                <h2 className="mb-0 mt-4 text-black">
                  Oops! Cette page est introuvable.
                </h2>
                <p className="mt-2 text-black">
                  La page demandée n'existe pas.
                </p>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default index;
