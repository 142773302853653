import React, { Component } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from "moment";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";

// component importation
import SearchBar from "./component/searchBar";
import EntityList from "./component/entityList";
import EntityInfos from "./component/entityInfos";
import Header from "./component/hearder";
import UpdateForm from "./component/updateForm";
import AddForm from "./component/addForm";

// services importation
import country from "../../../restApiService/country";

// img
import shap1 from "../../../assets/images/shapes/01.png";
import shap2 from "../../../assets/images/shapes/02.png";
import shap3 from "../../../assets/images/shapes/03.png";
import shap4 from "../../../assets/images/shapes/04.png";
import shap5 from "../../../assets/images/shapes/05.png";
import shap6 from "../../../assets/images/shapes/06.png";

class Entity extends Component {
  state = {
    item: [],
    entityDataTmp: [],
    entityData: [],
    selectEntityData: [],
    makeAction: false,
    actionType: "",
    searchTerm: "",
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  };

  handleSearch = (event) => {
    // console.log('this.state.searchTerm : ', this.state.searchTerm)
    // this.setState({ searchTerm: event.target.value })

    if (event.target.value.length >= 2) {
      this.setState({
        entityData: this.state.entityDataTmp.filter((item) =>
          item.title.toLowerCase().includes(event.target.value.toLowerCase())
        ),
      });
    }
    if (event.target.value.length === 0) {
      this.setState({
        entityData: this.state.entityDataTmp,
      });
    }
  };

  getEntityData = () => {
    country
      .getEntityList()
      .then((response) => {
        console.log("first", response.data);
        this.setState({
          entityData: response.data,
          entityDataTmp: response.data,
        });
        if (response.data.length > 0) {
          this.setState({ selectEntityData: this.state.entityData[0] });
        }
      })
      .catch((error) => console.log("first"));
  };

  selectEntity = (action, item) => {
    if (action === "read") {
      this.setState({
        selectEntityData: item,
        makeAction: false,
        actionType: "",
      });
    } else {
      this.setState({ item: item, makeAction: true, actionType: "update" });
    }
  };

  addEntity = () => {
    // document.getElementById('create-form').reset()
    this.setState({
      item: null,
      makeAction: true,
      actionType: "add",
    });
  };

  saveHandledData = () => {
    let item = { ...this.state.item };
    const promiseVar = toast.loading("Traitement en cours...");
    if (this.state.actionType === "add") {
      country
        .setEntity(item)
        .then((response) => {
          this.componentDidMount();
          toast.update(promiseVar, {
            render: "Opération éffectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) =>
          toast.update(promiseVar, {
            render: "Une erreur est susvenue",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        );
    } else {
      delete item["createdAt"];
      delete item["updatedAt"];
      country
        .updateEntityId(item.id, item)
        .then((response) => {
          this.componentDidMount();
          toast.update(promiseVar, {
            render: "Opération éffectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) =>
          toast.update(promiseVar, {
            render: "Une erreur est susvenue",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        );
    }
  };

  removeItem = (id) => {
    const promiseVar = toast.loading("Traitement en cours...");
    country
      .deleteEntityId(id)
      .then((response) => {
        this.componentDidMount();
        this.setState({ selectEntityData: this.state.entityData[0] });
        toast.update(promiseVar, {
          render: "Opération éffectuée avec succès",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) =>
        toast.update(promiseVar, {
          render: "Une erreur est susvenue",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      );
  };

  componentDidMount() {
    this.getEntityData();
  }

  render() {
    const { entityData, selectEntityData, item, makeAction, actionType } =
      this.state;

    return (
      <>
        <div>
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Pays ({entityData.length})</h4>
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                  <div className="entityBox">
                    <div className="entityListBar">
                      <div className="entityListWrapper">
                        <SearchBar handleSearch={this.handleSearch} />
                        <EntityList
                          selectEntityData={selectEntityData}
                          entityData={entityData}
                          selectEntity={this.selectEntity}
                          removeItem={this.removeItem}
                          shap5={shap5}
                        />
                      </div>
                    </div>
                    <div className="entityBoxWrapper ent-layout">
                      <Header
                        item={item}
                        removeItem={this.removeItem}
                        actionType={actionType}
                        selectEntityData={selectEntityData}
                        selectEntity={this.selectEntity}
                        saveHandledData={this.saveHandledData}
                        addEntity={this.addEntity}
                      />
                      <div className="entityBoxScrollbar">
                        <div className="entityCardList">
                          {!makeAction && selectEntityData.length !== 0 && (
                            <EntityInfos
                              item={item}
                              selectEntityData={selectEntityData}
                            />
                          )}
                        </div>
                        {actionType === "add" && (
                          <AddForm
                            item={item}
                            handleChange={this.handleChange}
                          />
                        )}
                        {actionType === "update" && (
                          <UpdateForm
                            item={item}
                            handleChange={this.handleChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Entity;
