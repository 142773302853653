import React, { Component } from "react";
import { Row, Col, Modal, Form, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageHeader from "../../../components/partials/dashboard/HeaderStyle/partner-page-header";
import Card from "../../../components/Card";

class slider extends Component {
  state = {};

  render() {
    return (
      <div>
        <PageHeader
          coloredText="Mentions"
          normalText="légales"
          bgColor="#313131"
          markerColor="#eaba42"
        />
        <div class="content pb-5">
          <div class="container">
            <strong>EDITEUR DU SITE</strong>
            <p>
              Le site est édité par la Societe VARLEY HALLS: <br></br>
              Adresse: 23 BP 1714 ABIDJAN 23 <br></br>
              COCODY Riviera Triangle Lot 300- Immeuble WAHOU 2ème étage 2B.{" "}
              <br></br>
              Phone: 07 07 11 19 20 <br></br>
              E-Mail: infos@origin7pub.com <br></br>
            </p>
            <strong>REALISATION</strong>
            <p>
              Jean-Marc BOA <br></br>
              Linkedin :{" "}
              <a
                href="https://www.linkedin.com/in/jean-marc-boa-b5a4a6159/"
                target="_BLANK"
              >
                Cliquez ici
              </a>
            </p>
            <strong>INFORMATIONS INFORMATIQUE ET LIBERTÉS</strong>
            <p>
              Les informations recueillies sont nécessaires pour la prise de
              contact. Elles font l’objet d’un traitement informatique et sont
              destinées à reprendre contact avec vous, elles ne sont pas
              stockées pour traitement ultérieure. En application des articles
              39 et suivants de la loi du 6 janvier 1978 modifiée, vous
              bénéficiez d’un droit d’accès et de rectification aux informations
              qui vous concernent. Si vous souhaitez exercer ce droit et obtenir
              communication des informations vous concernant, veuillez vous
              adresser à : infos@origin7pub.com
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default slider;
