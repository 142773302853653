import React, { Component } from "react";
import { Navbar, Dropdown, Container } from "react-bootstrap";
import { connect } from "react-redux";
// Container,Dropdown,Button
import { Badge } from "rsuite";
import { Link } from "react-router-dom";
import CustomToggle from "../../../dropdowns";
// mobile-offcanvas
import MobildeOffcanvas from "../../components/mobile-offcanvas";
//Horizontal-nav
import HorizontalNav from "./horizontal-nav";

import {
  addUserInfos,
  removeUserInfos,
} from "../../../../store/actions/userActions";
//img
import avatars1 from "../../../../assets/images/avatars/01.png";
import avatars2 from "../../../../assets/images/avatars/avtar_1.png";
import avatars3 from "../../../../assets/images/avatars/avtar_2.png";
import avatars4 from "../../../../assets/images/avatars/avtar_3.png";
import avatars5 from "../../../../assets/images/avatars/avtar_4.png";
import avatars6 from "../../../../assets/images/avatars/avtar_5.png";
// logo
import Logo from "../../components/logo-partner";

import linkData from "../../../../restApiService/linkData";

// const currentUserInfo = JSON.parse(localStorage.getItem("userInfo"));
class HeaderStyle1 extends Component {
  state = {
    bounceIn: false,
    bounceIn02: false,
    reservationData: [],
  };
  logOut = () => {
    localStorage.removeItem("userInfo");
    setTimeout(() => {
      window.location.reload();
    }, 400);
  };

  getRegiePendingReservation = (id) => {
    linkData
      .getLinkDataByRegieId(id)
      .then((rep) => {
        this.setState({
          reservationData: rep.data,
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    this.getRegiePendingReservation(this.props.currentUserInfo.regieId);
  }

  componentDidUpdate(prevProps) {
    console.log("prevProps", prevProps);
    if (prevProps.items !== this.props.items) {
      console.log("new changes is detect");
      this.setState({ bounceIn: true });
      setTimeout(() => {
        this.setState({ bounceIn: false });
      }, 1000);
    }
    if (prevProps.wishItems !== this.props.wishItems) {
      console.log("new changes is detect");
      this.setState({ bounceIn02: true });
      setTimeout(() => {
        this.setState({ bounceIn02: false });
      }, 1000);
    }
  }

  render() {
    const { currentUserInfo } = this.props;
    return (
      <>
        <div className="top-header-box container-fluid">
          <div className="top-header">
            <p>
              <i class="fa fa-phone" aria-hidden="true"></i> (+225) 07 08 53 55
              78
            </p>
            <div class="follow-us">
              <ul className="left-panel list-inline mb-0 p-0">
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/company/nymba/"
                    target="_blank"
                  >
                    <i class="fab fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://web.facebook.com/Nymba-106906621022811/?ref=page_internal"
                    target="_blank"
                  >
                    <i class="fab fa-facebook-f" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Navbar expand="xl" className="nav iq-navbar p-sticky">
          <Container fluid className="navbar-inner">
            <MobildeOffcanvas />
            <Navbar.Brand as="div" className="col-lg-2 col-lg-3 ">
              <Link to="/partner" className="d-flex">
                <Logo color={true} />
              </Link>
            </Navbar.Brand>
            {/* <HorizontalNav /> */}
            <Navbar.Toggle aria-controls="navbarSupportedContent">
              <span className="navbar-toggler-icon">
                <span className="navbar-toggler-bar bar1 mt-2"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </span>
            </Navbar.Toggle>
            <Navbar.Collapse className="col-md-2" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <Dropdown as="li" className="nav-item">
                  <Link
                    className={" anonymous-link nav-link "}
                    to="/partner/content/panneau"
                  >
                    Mes espaces
                  </Link>
                </Dropdown>
                {/* <Dropdown as="li" className="nav-item">
                  <Link
                    className={" anonymous-link nav-link "}
                    to="/partner/content/reservation/panneau"
                  >
                    Mes campagnes
                  </Link>
                </Dropdown> */}
                <Dropdown as="li" className="nav-item">
                  <Link
                    className="anonymous-link nav-link "
                    to="/partner/client"
                  >
                    Mes clients
                  </Link>
                </Dropdown>
                <Dropdown as="li" className="nav-item">
                  <Link
                    className={"partner-blue-link nav-link "}
                    to="/partner/content/panneau/add"
                  >
                    <svg
                      class="icon-32"
                      width="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.7366 2.76175H8.08455C6.00455 2.75375 4.29955 4.41075 4.25055 6.49075V17.3397C4.21555 19.3897 5.84855 21.0807 7.89955 21.1167C7.96055 21.1167 8.02255 21.1167 8.08455 21.1147H16.0726C18.1416 21.0937 19.8056 19.4087 19.8026 17.3397V8.03975L14.7366 2.76175Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M14.4741 2.75V5.659C14.4741 7.079 15.6231 8.23 17.0431 8.234H19.7971"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M14.2936 12.9141H9.39355"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M11.8442 15.3639V10.4639"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>{" "}
                    Ajouter
                  </Link>
                </Dropdown>

                <Dropdown as="li" className="nav-item">
                  <Link
                    className={"partner-red-link nav-link "}
                    to="/partner/content/reservation/panneau"
                  >
                    <svg
                      class="icon-32"
                      width="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.8397 20.1642V6.54639"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M20.9173 16.0681L16.8395 20.1648L12.7617 16.0681"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M6.91102 3.83276V17.4505"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M2.8335 7.92894L6.91127 3.83228L10.9891 7.92894"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>{" "}
                    Disponibilité
                  </Link>
                </Dropdown>
                <Dropdown as="li" className="nav-item mr-2 m-l-2">
                  <Link to="/partner/mon-panier">
                    <Badge
                      content={this.props.items.length}
                      className={this.state.bounceIn ? "bounceIn" : ""}
                    >
                      <svg
                        width="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.75 3.25L4.83 3.61L5.793 15.083C5.87 16.02 6.653 16.739 7.593 16.736H18.502C19.399 16.738 20.16 16.078 20.287 15.19L21.236 8.632C21.342 7.899 20.833 7.219 20.101 7.113C20.037 7.104 5.164 7.099 5.164 7.099"
                          stroke="#13367c"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.125 10.7949H16.898"
                          stroke="#13367c"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.15435 20.2026C7.45535 20.2026 7.69835 20.4466 7.69835 20.7466C7.69835 21.0476 7.45535 21.2916 7.15435 21.2916C6.85335 21.2916 6.61035 21.0476 6.61035 20.7466C6.61035 20.4466 6.85335 20.2026 7.15435 20.2026Z"
                          fill="currentColor"
                          stroke="#13367c"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M18.4346 20.2026C18.7356 20.2026 18.9796 20.4466 18.9796 20.7466C18.9796 21.0476 18.7356 21.2916 18.4346 21.2916C18.1336 21.2916 17.8906 21.0476 17.8906 20.7466C17.8906 20.4466 18.1336 20.2026 18.4346 20.2026Z"
                          fill="currentColor"
                          stroke="#13367c"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Badge>
                  </Link>
                </Dropdown>
                <Dropdown as="li" className="nav-item">
                  <Link to="/partner/reservation/pending">
                    <Badge
                      content={this.state?.reservationData.length}
                      className={this.state.bounceIn02 ? "bounceIn" : ""}
                    >
                      <svg
                        class="icon-32"
                        width="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 17.8476C17.6392 17.8476 20.2481 17.1242 20.5 14.2205C20.5 11.3188 18.6812 11.5054 18.6812 7.94511C18.6812 5.16414 16.0452 2 12 2C7.95477 2 5.31885 5.16414 5.31885 7.94511C5.31885 11.5054 3.5 11.3188 3.5 14.2205C3.75295 17.1352 6.36177 17.8476 12 17.8476Z"
                          stroke="#13367c"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M14.3889 20.8572C13.0247 22.3719 10.8967 22.3899 9.51953 20.8572"
                          stroke="#13367c"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </Badge>
                  </Link>
                </Dropdown>
                {currentUserInfo && (
                  <Dropdown as="li" className="nav-item m-l-2">
                    <Dropdown.Toggle
                      as={CustomToggle}
                      variant=" nav-link py-0 d-flex align-items-center"
                      href="#"
                      id="navbarDropdown"
                    >
                      <img
                        src={avatars1}
                        alt="User-Profile"
                        className="theme-color-default-img img-fluid avatar avatar-50 avatar-rounded"
                      />
                      <img
                        src={avatars2}
                        alt="User-Profile"
                        className="theme-color-purple-img img-fluid avatar avatar-50 avatar-rounded"
                      />
                      <img
                        src={avatars3}
                        alt="User-Profile"
                        className="theme-color-blue-img img-fluid avatar avatar-50 avatar-rounded"
                      />
                      <img
                        src={avatars5}
                        alt="User-Profile"
                        className="theme-color-green-img img-fluid avatar avatar-50 avatar-rounded"
                      />
                      <img
                        src={avatars6}
                        alt="User-Profile"
                        className="theme-color-yellow-img img-fluid avatar avatar-50 avatar-rounded"
                      />
                      <img
                        src={avatars4}
                        alt="User-Profile"
                        className="theme-color-pink-img img-fluid avatar avatar-50 avatar-rounded"
                      />
                      <div className="caption ms-3 ">
                        <h6 className="mb-0 caption-title">
                          {currentUserInfo?.firstname +
                            " " +
                            currentUserInfo?.lastname}
                        </h6>
                        {currentUserInfo?.roleId === 1 && (
                          <p className="mb-0 caption-sub-title">
                            Agent de regie
                          </p>
                        )}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as="ul" className="dropdown-menu-end">
                      <Link to="/" className="dropdown-item">
                        Aller sur Nymba
                      </Link>
                      <Link
                        to="/partner/account/info-personelles"
                        className="dropdown-item"
                      >
                        Mon compte
                      </Link>
                      <Link
                        to="/partner/content/reservation/panneau"
                        className="dropdown-item"
                      >
                        Mes reservations
                      </Link>
                      <Dropdown.Divider />
                      <Dropdown.Item type="button" onClick={this.logOut}>
                        Se déconnecter
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </ul>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.userInfoReducer;
  return {
    currentUserInfo: addedItemsTmp.addedUserInfos,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addUserInfos: (item) => {
      dispatch(addUserInfos(item));
    },
    removeUserInfos: (item) => {
      dispatch(removeUserInfos(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderStyle1);
