import axios from "axios";
import config from "./config";

export default {
  createStorage: (data) => {
    return axios.post(`${config.URL_END_POINT}/storage/`, data);
  },
  updateStorage: (id, data) => {
    return axios.put(`${config.URL_END_POINT}/storage/update/${id}`, data);
  },
  getStorageList: () => {
    return axios.get(`${config.URL_END_POINT}/storage/list`);
  },
  getStorageLayoutList: () => {
    return axios.get(`${config.URL_END_POINT}/storage_layout/list`);
  },
};
