const componentItem = (props) => {
  return (
    <>
      <div className="update-form">
        <div className="entityCardAddForm mt-4">
          <div className="row">
            <div className="col-md-6">
              <div class="form-item">
                <label>Montant minimum de reservation (2 semaines)</label>
                <input
                  className="form-control"
                  name="prix_minimum"
                  value={props.item?.prix_minimum}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div class="form-item">
                <label>Montant de reservation pour 1 mois</label>
                <input
                  className="form-control"
                  name="prix_mensuel"
                  value={props.item?.prix_mensuel}
                  onChange={props.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default componentItem;
