import React, { Component } from "react";
import Dropzone from "react-dropzone";
import ReactPaginate from "react-paginate";
import ReactAudioPlayer from "react-audio-player";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Tooltip,
  Popover,
  Whisper,
  Button,
  ButtonToolbar,
  SelectPicker,
  Table,
  Pagination,
  Loader,
  Input,
  InputGroup,
  CheckPicker,
} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
// import { CustomProvider } from 'rsuite';
// import frFR from 'rsuite/locales/fr_FR';

import Moment from "react-moment";
import emailjs from "@emailjs/browser";
import { Row, Col, Image, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";
import Pager from "../../../components/pager";
import usedTable from "./components/useTable";

// img
// import shap1 from '../../../assets/images/shapes/01.png'
// import shap2 from '../../../assets/images/shapes/02.png'
// import shap3 from '../../../assets/images/shapes/03.png'
// import shap4 from '../../../assets/images/shapes/04.png'
// import shap5 from '../../../assets/images/shapes/05.png'
// import shap6 from '../../../assets/images/shapes/06.png'

// services importation
import orders from "../../../restApiService/commune";
import regie from "../../../restApiService/regie";
import panneaux from "../../../restApiService/panneaux";

class samples extends Component {
  state = {
    item: [],
    entityData: [],
    regieList: [],
    entityDataTmp: [],
    selectEntityData: [],
    musicGenderData: [],
    occasionData: [],
    files: [],
    files_o: [],
    actionType: "",
    selectEntityID: 0,
    statut: "DISPONIBLE",
    dispoCount: 0,
    indispoCount: 0,
    espaceDataIsLoading: true,
    show: false,
    show1: false,
    show2: false,
    show3: false,
    pageNumber: 1,
    limit: 20,
    currentPage: 0,
    totalItems: 0,
    totalPages: 0,
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  };

  handleList = (name, event) => {
    console.log("event", event);
    let item = { ...this.state.item };
    item[name] = event;
    this.setState({ item });
  };

  switchList = (statut) => {
    this.setState({ statut: statut });
    this.getEntityData(statut);
  };

  onDrop = (files) => {
    this.setState({
      files_o: files,
      files: files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
  };

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  handleClose1 = () => this.setState({ show1: false });
  handleShow1 = () => this.setState({ show1: true, actionType: "add" });

  handleClose2 = () => this.setState({ show2: false });
  // const handleShow2 = () => setShow2(true);

  handleClose3 = () => this.setState({ show3: false });

  // table pagination settings
  calculateRange = (data, rowsPerPage) => {
    const range = [];
    const num = Math.ceil(data.length / rowsPerPage);
    let i = 1;
    for (let i = 1; i <= num; i++) {
      range.push(i);
    }
    return range;
  };

  sliceData = (data, page, rowsPerPage) => {
    return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  };

  getEntityData = (statut) => {
    this.setState({ espaceDataIsLoading: true });
    // console.log(page);
    // .getEntityPaginateList(statut, page)
    panneaux
      .getEntityPaginateList(statut)
      .then((response) => {
        // console.log("first", response.data);
        this.setState({
          entityData: response.data,
          entityDataTmp: response.data,
        });
        this.setState({ espaceDataIsLoading: false });
      })
      .catch((error) => console.log("first"));
  };

  getEntityDispoCountData = () => {
    panneaux
      .getEntityDispoCount()
      .then((response) => {
        console.log("first", response.data);
        this.setState({ dispoCount: response.data });
      })
      .catch((error) => console.log("first"));
  };

  getEntityIndispoCountData = () => {
    panneaux
      .getEntityIndispoCount()
      .then((response) => {
        console.log("first", response.data);
        this.setState({ indispoCount: response.data });
      })
      .catch((error) => console.log("first"));
  };

  selectEntity = (action, item) => {
    console.log("item", item);
    if (action === "read") {
      this.setState({
        selectEntityData: item,
        makeAction: false,
        actionType: "",
      });
    } else {
      this.setState({ item: item, show2: true, actionType: "update" });

      setTimeout(() => {
        console.log("item text", item);
      }, 200);
    }
  };

  handleOpenPwdModal = (id) =>
    this.setState({ show3: true, selectEntityID: id });

  removeItem = (id) => {
    orders
      .deleteOrder(id)
      .then((response) => {
        this.componentDidMount();
        // this.setState({ selectEntityData: this.state.entityData[0] })
      })
      .catch((error) => console.log("first"));
  };

  generateCode() {
    let code = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ012345678901234567890123456789";

    // Copy code
    for (let i = 0; i < 10; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    console.log("code : ", code);
    // this.setState({ transactionCode: code });
  }

  handleChangeLimit = (dataKey) => {
    // setPage(1);
    // setLimit(dataKey);
    this.setState({ pageNumber: 1, limit: dataKey });
  };

  // data = () => {
  //   this.state.entityData.filter((v, i) => {
  //     const start = this.state.limit * (this.state.pageNumber - 1);
  //     const end = start + this.state.limit;
  //     return i >= start && i < end;
  //   });
  // };

  getRegieData = () => {
    regie
      .getEntityList()
      .then((response) => {
        this.setState({
          regieList: response.data.map((item) => ({
            label: item.title,
            value: item.id,
          })),
        });
      })
      .catch((error) => console.log("error"));
  };

  handleSearch = (event) => {
    if (event.target.value.length >= 2) {
      this.setState({
        entityData: this.state.entityDataTmp.filter(
          (item) =>
            (item?.title &&
              item?.title
                .toLowerCase()
                .includes(event.target.value.toLowerCase())) ||
            (item?.code &&
              item?.code
                .toLowerCase()
                .includes(event.target.value.toLowerCase())) ||
            (item?.country?.title &&
              item?.country?.title
                .toLowerCase()
                .includes(event.target.value.toLowerCase()))
        ),
      });
    }
    if (event.target.value.length === 0) {
      this.setState({
        entityData: this.state.entityDataTmp,
      });
    }
  };

  handleSearchByRegie = (event) => {
    this.setState({
      entityData: [],
    });
    if (event.length >= 1) {
      this.setState({
        entityData: this.state.entityDataTmp.filter((person) =>
          event.includes(person.regieId)
        ),
      });
    }
    if (event.length === 0) {
      this.setState({
        entityData: this.state.entityDataTmp,
      });
    }
  };

  componentDidMount() {
    this.getEntityData(this.state.statut);
    this.getRegieData();
    this.getEntityDispoCountData();
    this.getEntityIndispoCountData();
  }

  render() {
    const { Column, HeaderCell, Cell } = Table;
    const {
      item,
      entityData,
      regieList,
      selectEntityData,
      dispoCount,
      indispoCount,
      espaceDataIsLoading,
      pageNumber,
      limit,
    } = this.state;
    const data = entityData.filter((v, i) => {
      const start = limit * (pageNumber - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    const triggerRef = React.createRef();
    const close = () => triggerRef.current.close();
    const pwdTooltip = (
      <Tooltip>
        <i>Modifier le mot de passe</i>
      </Tooltip>
    );
    const delSpeaker = (
      <Popover
        title={"Vous êtes sûr de supprimer " + selectEntityData.title + " ?"}
      >
        <p className="text-right">
          <button className="ent-btn ent-btn-default mr-1" onClick={close}>
            Non
          </button>
          <button
            className="ent-btn ent-btn-default bg-red-l"
            onClick={() => {
              this.removeItem(item.id ?? selectEntityData.id);
              close();
            }}
          >
            Supprimer
          </button>
        </p>
      </Popover>
    );

    return (
      <>
        {/* <CustomProvider locale={frFR}> */}
        <div>
          <Row>
            <Col sm="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">
                      Liste des panneaux ({this.state.entityData.length}){" "}
                      {/* Liste des panneaux ({this.state.totalItems}){" "} */}
                    </h4>
                  </div>

                  <div>
                    <button
                      className={
                        this.state.statut == "DISPONIBLE"
                          ? "switch-btn mr-2 selected"
                          : "switch-btn mr-2"
                      }
                      onClick={() => this.switchList("DISPONIBLE")}
                    >
                      <span>Publies ({dispoCount}) </span>
                    </button>
                    <button
                      className={
                        this.state.statut == "INDISPONIBLE"
                          ? "switch-btn mr-2 selected"
                          : "switch-btn mr-2"
                      }
                      onClick={() => this.switchList("INDISPONIBLE")}
                    >
                      <span>Non publies ({indispoCount}) </span>
                    </button>
                  </div>
                </Card.Header>
                <Card.Body className="px-0">
                  <div className="filter-box">
                    <div className="row">
                      <div className="col-md-8">
                        <InputGroup
                          size="lg"
                          style={{ margin: 10 }}
                          onChange={this.handleSearch}
                        >
                          <Input placeHolder="Trier par Nom, Code, Pays..." />
                          <InputGroup.Addon>
                            <SearchIcon />
                          </InputGroup.Addon>
                        </InputGroup>
                      </div>
                      <div className="col-md-4">
                        <CheckPicker
                          placeholder="Trier pas régie..."
                          data={regieList}
                          onChange={(e) => this.handleSearchByRegie(e)}
                          // style={{ width: 224 }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <Table
                      height={520}
                      data={data}
                      loading={espaceDataIsLoading}
                    >
                      <Column width={350} fixed>
                        <HeaderCell>Nom de l'espace</HeaderCell>
                        <Cell dataKey="title" />
                      </Column>

                      <Column width={100}>
                        <HeaderCell>Format</HeaderCell>
                        <Cell>{(rowData) => rowData.format?.title}</Cell>
                      </Column>

                      <Column width={200}>
                        <HeaderCell>Propriétaire</HeaderCell>
                        <Cell>{(rowData) => rowData.regie?.title}</Cell>
                      </Column>

                      <Column width={200}>
                        <HeaderCell>Code</HeaderCell>
                        <Cell dataKey="code" />
                      </Column>
                      <Column width={200} flexGrow={1}>
                        <HeaderCell>Pays</HeaderCell>
                        <Cell>{(rowData) => rowData.country?.title}</Cell>
                      </Column>
                      <Column width={200} flexGrow={1}>
                        <HeaderCell></HeaderCell>
                        <Cell>
                          {(rowData) => (
                            <div className="flex align-items-center list-user-action">
                              <Link
                                className="btn btn-sm btn-icon"
                                to={
                                  "/admin/content/panneau/edit/" + rowData.slug
                                }
                              >
                                <svg
                                  width="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.7476 20.4428H21.0002"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12.78 3.79479C13.5557 2.86779 14.95 2.73186 15.8962 3.49173C15.9485 3.53296 17.6295 4.83879 17.6295 4.83879C18.669 5.46719 18.992 6.80311 18.3494 7.82259C18.3153 7.87718 8.81195 19.7645 8.81195 19.7645C8.49578 20.1589 8.01583 20.3918 7.50291 20.3973L3.86353 20.443L3.04353 16.9723C2.92866 16.4843 3.04353 15.9718 3.3597 15.5773L12.78 3.79479Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M11.021 6.00098L16.4732 10.1881"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Link>{" "}
                              <Whisper
                                placement="top"
                                trigger="click"
                                ref={triggerRef}
                                controlId="control-id-hover-enterable"
                                speaker={delSpeaker}
                                enterable
                              >
                                <button
                                  className="btn btn-sm btn-icon"
                                  onClick={() =>
                                    this.selectEntity("read", rowData)
                                  }
                                >
                                  <svg
                                    width="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    stroke="currentColor"
                                  >
                                    <path
                                      d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M20.708 6.23975H3.75"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </button>
                              </Whisper>
                            </div>
                          )}
                        </Cell>
                      </Column>
                    </Table>
                    <div style={{ padding: 20 }}>
                      <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={["total", "-", "limit", "|", "pager", "skip"]}
                        total={entityData.length}
                        limitOptions={[
                          10, 30, 50, 100, 200, 300, 400, 500, 600, 700, 800,
                          900, 1000, 2000,
                        ]}
                        limit={limit}
                        activePage={pageNumber}
                        onChangePage={(e) => this.setState({ pageNumber: e })}
                        onChangeLimit={this.handleChangeLimit}
                      />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        {/* </CustomProvider> */}
      </>
    );
  }
}

export default samples;
