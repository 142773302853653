export default {
  data: [
    {
      id: 125,
      lastname: '215',
      firstname: '77130 Miller Ramp',
      email_client: 'jeanmarcboa18@gmail.com',
      roleId: 4,
      id_user: 514,
      photo: null,
      regieId: null,
      ids: 514,
      first: '77130 Miller Ramp',
      last: '215',
      email: 'jeanmarcboa18@gmail.com',
      phone: null,
      password: '$2b$10$BPgD5ubkHCGsja3m0ojWvOOglRuuQEkpKKBFVGIllCUwP.EgA2Zgu',
      createdAt: '2022-06-08T08:43:47.000Z',
      updatedAt: '2022-06-08T08:43:47.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 77,
      lastname: 'David',
      firstname: 'ABA',
      email_client: 'davaba@sodeci.ci',
      roleId: 3,
      id_user: 323,
      photo: null,
      regieId: 60,
      ids: 323,
      first: 'ABA',
      last: 'David',
      email: 'davaba@sodeci.ci',
      phone: '07987662',
      password: '$2a$10$os8TtquBiOfO0/fswqkB3eIBD.VCxaAmHT.VgM00itOWpg4WGkUwy',
      createdAt: '2020-11-20T16:07:49.000Z',
      updatedAt: '2020-11-20T16:07:49.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 82,
      lastname: 'Ané',
      firstname: 'Ablan Esther',
      email_client: 'eane@lonaci.ci',
      roleId: 4,
      id_user: 328,
      photo: null,
      regieId: null,
      ids: 328,
      first: 'Ablan Esther',
      last: 'Ané',
      email: 'eane@lonaci.ci',
      phone: '48183395',
      password: '$2a$10$fc5OEOBpKzy4u2psVJi.wOAADvS2yaSwgERCkRjGYvdNGkfuS4wuW',
      createdAt: '2020-12-17T12:37:44.000Z',
      updatedAt: '2020-12-17T12:37:44.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 24,
      lastname: 'Ousmane',
      firstname: 'Admin',
      email_client: 'superadmin@gmail.com',
      roleId: 1,
      id_user: 56,
      photo: null,
      regieId: null,
      ids: null,
      first: null,
      last: null,
      email: null,
      phone: null,
      password: null,
      createdAt: null,
      updatedAt: null,
      resetPasswordToken: null,
      resetPasswordExpires: null,
    },
    {
      id: 79,
      lastname: 'Kakro',
      firstname: 'Ahmed Marc',
      email_client: 'ahmed@origin7pub.com',
      roleId: 4,
      id_user: 325,
      photo: null,
      regieId: null,
      ids: 325,
      first: 'Ahmed Marc',
      last: 'Kakro',
      email: 'ahmed@origin7pub.com',
      phone: '67838062',
      password: '$2a$10$x3wTbuyArUKKfvk1WSZuG.3.yQUaG36aEBCo7ca97IblhMtStRS6m',
      createdAt: '2020-12-09T16:19:14.000Z',
      updatedAt: '2020-12-09T16:19:14.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 56,
      lastname: 'Kpatico',
      firstname: 'Amélé Rose',
      email_client: 'kamelerose@yahoo.fr',
      roleId: 2,
      id_user: 301,
      photo: null,
      regieId: 22,
      ids: 301,
      first: 'Amélé Rose',
      last: 'Kpatico',
      email: 'kamelerose@yahoo.fr',
      phone: '05490420',
      password: '$2a$10$.vgiY8fIK3TnqJN7jbJhcu2M.CmkicTxFiXCxdPKSY.dMOMPQoL36',
      createdAt: '2020-07-27T10:44:37.000Z',
      updatedAt: '2020-07-27T10:44:37.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 103,
      lastname: 'Kouamé',
      firstname: 'Anne Déborah',
      email_client: 'anne@ibsa.africa',
      roleId: 4,
      id_user: 462,
      photo: null,
      regieId: null,
      ids: 462,
      first: 'Anne Déborah',
      last: 'Kouamé',
      email: 'anne@ibsa.africa',
      phone: '1853271',
      password: '$2a$10$oV0yR/H9Nzg/c..ORbJgj.9I3x3oZ.k4z78YJcWKI7TBAhCxrvnVO',
      createdAt: '2021-03-23T12:38:36.000Z',
      updatedAt: '2021-03-23T12:38:36.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 35,
      lastname: 'Koffi',
      firstname: 'Armel',
      email_client: 'armel@solarpak.net',
      roleId: 4,
      id_user: 144,
      photo: null,
      regieId: null,
      ids: 144,
      first: 'Armel',
      last: 'Koffi',
      email: 'armel@solarpak.net',
      phone: '08535578',
      password: '$2a$10$6WKSUT/fgsIvCnkfN/9CdewyYTTfs93bzHDA2VZvF8wXTN3QqzcDu',
      createdAt: '2020-06-04T16:32:23.000Z',
      updatedAt: '2020-06-05T14:15:09.000Z',
      resetPasswordToken: '086a0a2bf634fe6eb46f93bc2e6e3969cb7303eb',
      resetPasswordExpires: 1591370070174,
    },
    {
      id: 78,
      lastname: 'Kouadjane',
      firstname: 'Arnaud',
      email_client: 'arnaud@origin7pub.com',
      roleId: 1,
      id_user: 324,
      photo: null,
      regieId: null,
      ids: 324,
      first: 'Arnaud',
      last: 'Kouadjane',
      email: 'arnaud@origin7pub.com',
      phone: '0757016977',
      password: '$2a$10$gNcKF9ujT9D2/TlaFcwJPu/h11Sikmsh5W/YQtJeI1fjrpRLtmXn2',
      createdAt: '2020-11-23T11:57:04.000Z',
      updatedAt: '2021-03-30T09:40:17.000Z',
      resetPasswordToken: '5cb5ecdc858d022e0484efdfcadb176c3b0a0d40',
      resetPasswordExpires: 1617100817877,
    },
    {
      id: 86,
      lastname: "Kouassi n'dri",
      firstname: 'Arnaud ekoun',
      email_client: 'arnaudekoun@gmail.com',
      roleId: 4,
      id_user: 332,
      photo: null,
      regieId: null,
      ids: 332,
      first: 'Arnaud ekoun',
      last: "Kouassi n'dri",
      email: 'arnaudekoun@gmail.com',
      phone: '8474914',
      password: '$2a$10$5RMl2mWUAalHL1uiF0mamOm2ZT65L8CPTUaMOigsjfKHvn9k27tvO',
      createdAt: '2020-12-19T12:04:07.000Z',
      updatedAt: '2020-12-19T12:04:07.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 89,
      lastname: 'DOGO',
      firstname: 'Brice',
      email_client: 'bricedogo@yahoo.fr',
      roleId: 4,
      id_user: 336,
      photo: null,
      regieId: null,
      ids: 336,
      first: 'Brice',
      last: 'DOGO',
      email: 'bricedogo@yahoo.fr',
      phone: '8082985',
      password: '$2a$10$LYs8o5fbjpZDUwcKFXm8eOKEnlD8CSKXECUe20ag46bBWhCaPZfBa',
      createdAt: '2020-12-24T14:21:14.000Z',
      updatedAt: '2020-12-24T14:21:14.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 63,
      lastname: 'Serge',
      firstname: 'Coulibaly',
      email_client: 'scoulibaly@pubregie.net',
      roleId: 2,
      id_user: 308,
      photo: null,
      regieId: 19,
      ids: 308,
      first: 'Coulibaly',
      last: 'Serge',
      email: 'scoulibaly@pubregie.net',
      phone: '21251140',
      password: '$2a$10$HPQ6EUrKA9HwcGWWU/eLtuOnrnqIFtX.aabrKWvfQiUFOmVdSKmXa',
      createdAt: '2020-08-04T09:51:15.000Z',
      updatedAt: '2020-08-04T09:51:15.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 93,
      lastname: 'Kouamé',
      firstname: 'Eric',
      email_client: 'eric.kouame@solarpak.net',
      roleId: 4,
      id_user: 340,
      photo: null,
      regieId: null,
      ids: 340,
      first: 'Eric',
      last: 'Kouamé',
      email: 'eric.kouame@solarpak.net',
      phone: '58078785',
      password: '$2a$10$koJ87KHTiK4cvkksRTbFcekF31JqV5HCWrTTrGKXkTuUOt8x.hvUW',
      createdAt: '2021-01-08T12:16:00.000Z',
      updatedAt: '2021-01-08T12:16:00.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 68,
      lastname: ' Danho',
      firstname: 'Eva',
      email_client: 'anniedanho@gmail.com',
      roleId: 1,
      id_user: 313,
      photo: null,
      regieId: null,
      ids: 313,
      first: 'Eva',
      last: ' Danho',
      email: 'anniedanho@gmail.com',
      phone: '59189952',
      password: '$2a$10$/faLiVFuWwHr/tu2VmneYOZ2fAm3F3yOD6/h/4kq/MH5Y6VJS0gg2',
      createdAt: '2020-09-29T10:58:38.000Z',
      updatedAt: '2020-09-29T10:58:38.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 96,
      lastname: 'TAFFANEAU',
      firstname: 'Fanny',
      email_client: 'fanny.TAFFANEAU@SPCA-ci.com',
      roleId: 4,
      id_user: 405,
      photo: null,
      regieId: null,
      ids: 405,
      first: 'Fanny',
      last: 'TAFFANEAU',
      email: 'fanny.TAFFANEAU@SPCA-ci.com',
      phone: '88977777',
      password: '$2a$10$E72DY8a4djaW.v1hJr76OOEpFpaEtX1hQB734Wc6w4IsHJvA/CyQK',
      createdAt: '2021-01-18T16:38:21.000Z',
      updatedAt: '2021-01-18T16:38:21.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 97,
      lastname: "n'dri",
      firstname: 'ferdinand',
      email_client: 'ferdinand.ndri@cgeci.ci',
      roleId: 4,
      id_user: 406,
      photo: null,
      regieId: null,
      ids: 406,
      first: 'ferdinand',
      last: "n'dri",
      email: 'ferdinand.ndri@cgeci.ci',
      phone: '7368763',
      password: '$2a$10$sfHUgh9PNQkOvfgnU.He.OY8KToWKLeGG/rjdTUFuuiHTlFBeP5yO',
      createdAt: '2021-01-20T20:56:57.000Z',
      updatedAt: '2021-01-20T20:56:57.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 55,
      lastname: 'Sraka',
      firstname: 'François ',
      email_client: 'francois.sraka@alshana.com',
      roleId: 2,
      id_user: 300,
      photo: null,
      regieId: 14,
      ids: 300,
      first: 'François ',
      last: 'Sraka',
      email: 'francois.sraka@alshana.com',
      phone: '08701881',
      password: '$2a$10$zT0rLVf0tXnpq3pAccKiy.WM7sUaa81Bg0CY7STQ2feKw.GQ24L8.',
      createdAt: '2020-07-23T16:26:41.000Z',
      updatedAt: '2020-07-23T16:26:41.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 83,
      lastname: 'Douto',
      firstname: 'Géraud',
      email_client: 'geraudouto@gmail.com',
      roleId: 4,
      id_user: 329,
      photo: null,
      regieId: null,
      ids: 329,
      first: 'Géraud',
      last: 'Douto',
      email: 'geraudouto@gmail.com',
      phone: '88765632',
      password: '$2a$10$XnDQMqizPBYtE2ZCfsOaT.Nahz.DDVQQunog13Y0o6u8mRHlvE9UW',
      createdAt: '2020-12-17T14:56:23.000Z',
      updatedAt: '2020-12-17T14:56:23.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 53,
      lastname: 'Varley ',
      firstname: 'Halls Opérations',
      email_client: 'superadmin@gmail.com',
      roleId: 1,
      id_user: 298,
      photo: null,
      regieId: null,
      ids: 298,
      first: 'Halls Opérations',
      last: 'Varley ',
      email: 'superadmin@gmail.com',
      phone: '08320940',
      password: '$2a$10$TAPNKHVyCV7lEv9O6pGs3.fydkgkDyqq5TyBNuCt1F7zpSbDmELgS',
      createdAt: '2020-07-23T16:06:55.000Z',
      updatedAt: '2020-07-23T16:06:55.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 88,
      lastname: 'ADJRABE',
      firstname: 'Hermann ',
      email_client: 'dcm.pyramidemedia@gmail.com',
      roleId: 2,
      id_user: 334,
      photo: null,
      regieId: 59,
      ids: 334,
      first: 'Hermann ',
      last: 'ADJRABE',
      email: 'dcm.pyramidemedia@gmail.com',
      phone: '08037254',
      password: '$2a$10$ggdVeLVBPkvwQhCNcs3xC.ieoLKqUcTTw6bykK0op5CV4cV.NNhMi',
      createdAt: '2020-12-24T11:39:02.000Z',
      updatedAt: '2020-12-24T11:39:02.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 42,
      lastname: 'Nagasaki',
      firstname: 'Hiroshima',
      email_client: 'geogatedproject213@gmail.com',
      roleId: 4,
      id_user: 182,
      photo: null,
      regieId: null,
      ids: 182,
      first: 'Hiroshima',
      last: 'Nagasaki',
      email: 'geogatedproject213@gmail.com',
      phone: '881803454',
      password: '$2a$10$/uhYjD9mxCk/5Bn0fUDYh.l/4ADuLp.LUalYVaZa6Bu4j5TZ.ECra',
      createdAt: '2020-07-06T03:05:54.000Z',
      updatedAt: '2020-07-06T03:05:54.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 107,
      lastname: 'ZINGBE',
      firstname: 'HULRICHE',
      email_client: 'zinto.com@gmail.com',
      roleId: 4,
      id_user: 466,
      photo: null,
      regieId: null,
      ids: 466,
      first: 'HULRICHE',
      last: 'ZINGBE',
      email: 'zinto.com@gmail.com',
      phone: '56407890',
      password: '$2a$10$r5H/T1creVFmbeCQAdcWZeKAzKcCfDkRKey8tk8iwCzi9aCVRkchO',
      createdAt: '2021-07-06T21:03:34.000Z',
      updatedAt: '2021-07-06T21:03:34.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 105,
      lastname: 'Kouadjané',
      firstname: 'Jacob Arnaud',
      email_client: 'akouadjane@gmail.com',
      roleId: 4,
      id_user: 464,
      photo: null,
      regieId: null,
      ids: 464,
      first: 'Jacob Arnaud',
      last: 'Kouadjané',
      email: 'akouadjane@gmail.com',
      phone: '57016977',
      password: '$2a$10$crfiFulZTMfiUnw5JoLsw.RMHaDvSxPUGrSGWURxjankwGq39CqAG',
      createdAt: '2021-05-17T11:56:15.000Z',
      updatedAt: '2021-05-17T11:56:15.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 65,
      lastname: 'Boa',
      firstname: 'Jean-Marc',
      email_client: 'jeanmarcboafb@outlook.fr',
      roleId: 4,
      id_user: 310,
      photo: null,
      regieId: null,
      ids: 310,
      first: 'Jean-Marc',
      last: 'Boa',
      email: 'jeanmarcboafb@outlook.fr',
      phone: '378475598',
      password: '$2a$10$0IdYbyEcDUfYLGp0GxvnIOq08pPJdoFemiMg45A31/OAsuvYwxUgW',
      createdAt: '2020-08-31T23:40:07.000Z',
      updatedAt: '2020-08-31T23:40:07.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 62,
      lastname: 'Yohui',
      firstname: 'Jerôme',
      email_client: 'rcm.ci@gscomgroup.com',
      roleId: 2,
      id_user: 307,
      photo: null,
      regieId: 57,
      ids: 307,
      first: 'Jerôme',
      last: 'Yohui',
      email: 'rcm.ci@gscomgroup.com',
      phone: '87853730',
      password: '$2a$10$XfXYb2QuglDFoeUPA4Bm9.v8Pvg2P9bcs0rRLcciWRgAqRK0DU7wG',
      createdAt: '2020-08-03T14:49:12.000Z',
      updatedAt: '2020-08-03T14:49:12.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 121,
      lastname: 'Tata',
      firstname: 'jiji',
      email_client: 'admin0002@gmail.com',
      roleId: 1,
      id_user: 507,
      photo: null,
      regieId: null,
      ids: 507,
      first: 'jiji',
      last: 'Tata',
      email: 'admin0002@gmail.com',
      phone: '0749095872',
      password: '$2b$10$8/8GdukJDrLC5cmtzGPYDuaOx.gVQ.BfMAzZkmkP2foCt/MQX2wYe',
      createdAt: '2021-09-20T15:20:43.000Z',
      updatedAt: '2021-09-20T15:20:43.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 15,
      lastname: 'admin',
      firstname: 'JM',
      email_client: 'admin@gmail.com',
      roleId: 1,
      id_user: 42,
      photo: null,
      regieId: 47,
      ids: 42,
      first: 'JM',
      last: 'admin',
      email: 'admin@gmail.com',
      phone: '0022500000000',
      password: '$2a$10$36r5KNvRYx2ONpnImur2.Oi7CDql3efBomZn57vTtoAPrhRhajqZW',
      createdAt: '2019-11-04T20:56:32.000Z',
      updatedAt: '2019-11-04T20:56:32.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 108,
      lastname: 'Boka ',
      firstname: 'Jules',
      email_client: 'julesboka@gmail.com',
      roleId: 4,
      id_user: 467,
      photo: null,
      regieId: null,
      ids: 467,
      first: 'Jules',
      last: 'Boka ',
      email: 'julesboka@gmail.com',
      phone: '48315184',
      password: '$2a$10$8g5zRMwqDeF.8/1AZgOBw.YxDJHIffIw0Fsviird7eReDoFkIrz8C',
      createdAt: '2021-07-06T21:32:55.000Z',
      updatedAt: '2021-07-06T21:32:55.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 58,
      lastname: 'Anet',
      firstname: 'Kaoussen',
      email_client: 'anet.kaoussen@mbagroupe.com',
      roleId: 2,
      id_user: 303,
      photo: null,
      regieId: 38,
      ids: 303,
      first: 'Kaoussen',
      last: 'Anet',
      email: 'anet.kaoussen@mbagroupe.com',
      phone: '59767814',
      password: '$2a$10$Zu2649u7MQcSyRgtYZF0Wu8..XypZkMX7hzZhTbolVbaOoSBKmbJ.',
      createdAt: '2020-07-28T16:09:31.000Z',
      updatedAt: '2020-07-28T16:09:31.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 48,
      lastname: 'Sawadogo',
      firstname: 'Kenny Kendra',
      email_client: 'kennykendra76@gmail.com',
      roleId: 2,
      id_user: 240,
      photo: null,
      regieId: 47,
      ids: 240,
      first: 'Kenny Kendra',
      last: 'Sawadogo',
      email: 'kennykendra76@gmail.com',
      phone: '07397078',
      password: '$2a$10$tL4Vt2uyiY0Kd./dYgjr3uhSKM2/.gXIFgMuAKEZQ2VHSaj66WG/u',
      createdAt: '2020-07-12T21:42:50.000Z',
      updatedAt: '2020-11-05T11:46:43.000Z',
      resetPasswordToken: '77e829e14006000ae31ddf41a78c2cfc4cad2d51',
      resetPasswordExpires: 1604580403536,
    },
    {
      id: 101,
      lastname: 'KOUMAN',
      firstname: 'Kouadio Arsène',
      email_client: 'arsenek3@gmail.com',
      roleId: 4,
      id_user: 460,
      photo: null,
      regieId: null,
      ids: 460,
      first: 'Kouadio Arsène',
      last: 'KOUMAN',
      email: 'arsenek3@gmail.com',
      phone: '2999935',
      password: '$2a$10$x4KxIFFJhMQ8iELdILxuoO7BvO/9daHqr7iSHvDKCr05UBLi3k3Iy',
      createdAt: '2021-02-15T13:17:40.000Z',
      updatedAt: '2021-02-15T13:17:40.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 91,
      lastname: 'kouame',
      firstname: 'kouakou',
      email_client: 'kkpclaver@yahoo.fr',
      roleId: 4,
      id_user: 338,
      photo: null,
      regieId: null,
      ids: 338,
      first: 'kouakou',
      last: 'kouame',
      email: 'kkpclaver@yahoo.fr',
      phone: '8350296',
      password: '$2a$10$Hmcx7GSvUVJG25z0O4.md.IuJc9Yv/bsT27X1izQCqgN5K13h8n8u',
      createdAt: '2021-01-06T10:35:08.000Z',
      updatedAt: '2021-01-06T10:35:08.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 64,
      lastname: 'Sanogo',
      firstname: 'Maimouna',
      email_client: 'sanogomaimouna@yahoo.fr',
      roleId: 2,
      id_user: 309,
      photo: null,
      regieId: 58,
      ids: 309,
      first: 'Maimouna',
      last: 'Sanogo',
      email: 'sanogomaimouna@yahoo.fr',
      phone: '07820411',
      password: '$2a$10$Showq8Fv7V4pkXmIZfx7Xu8wG/CApc4drZjCzhb62xwERm0GKiCE.',
      createdAt: '2020-08-26T14:24:11.000Z',
      updatedAt: '2020-08-26T14:24:11.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 85,
      lastname: ' CORREA',
      firstname: 'Martin',
      email_client: 'amartin.correia@gmail.com',
      roleId: 4,
      id_user: 331,
      photo: null,
      regieId: null,
      ids: 331,
      first: 'Martin',
      last: ' CORREA',
      email: 'amartin.correia@gmail.com',
      phone: '8589800',
      password: '$2a$10$eOB2/YgsASojXPaUe/q5POZz3.9iMt5S4uEi/704gFi6agohY6pwu',
      createdAt: '2020-12-18T09:43:07.000Z',
      updatedAt: '2020-12-18T09:43:07.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 123,
      lastname: 'Publoc',
      firstname: 'Mdg',
      email_client: 'publoc@wellcom.mg',
      roleId: 4,
      id_user: 511,
      photo: null,
      regieId: null,
      ids: 511,
      first: 'Mdg',
      last: 'Publoc',
      email: 'publoc@wellcom.mg',
      phone: '22002133',
      password: '$2b$10$njNsmbhYvIyqihkXoZXLBO.doB4rYaKAHBCjz4fNClcKfT1Enf7MG',
      createdAt: '2022-01-08T13:31:14.000Z',
      updatedAt: '2022-01-08T13:31:14.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 74,
      lastname: 'Ousmane',
      firstname: 'mediaways',
      email_client: 'mediaways@gmail.com',
      roleId: 2,
      id_user: 320,
      photo: null,
      regieId: 16,
      ids: 320,
      first: 'mediaways',
      last: 'Ousmane',
      email: 'mediaways@gmail.com',
      phone: '07885192',
      password: '$2a$10$fZvJDr7UAFFSYY4.r33ivOpPsoPn0HYAe.xjqy3608HvA0rsPP1fi',
      createdAt: '2020-10-27T15:36:13.000Z',
      updatedAt: '2020-10-27T15:36:13.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 104,
      lastname: 'coulibaly',
      firstname: 'mohamed',
      email_client: 'athome.boost@gmail.com',
      roleId: 4,
      id_user: 463,
      photo: null,
      regieId: null,
      ids: 463,
      first: 'mohamed',
      last: 'coulibaly',
      email: 'athome.boost@gmail.com',
      phone: '48104248',
      password: '$2a$10$fP8Uk7f0nM0iHmcbnQzdQurIvSqOtHbKwKq/sQj8Lew5cL5ALrv26',
      createdAt: '2021-04-23T15:59:26.000Z',
      updatedAt: '2021-04-23T15:59:26.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 95,
      lastname: 'KOBLAN',
      firstname: 'MOUROUFIET MIREILLE',
      email_client: 'mireillemouroufietkoblan@gmail.com',
      roleId: 4,
      id_user: 404,
      photo: null,
      regieId: null,
      ids: 404,
      first: 'MOUROUFIET MIREILLE',
      last: 'KOBLAN',
      email: 'mireillemouroufietkoblan@gmail.com',
      phone: '77766426',
      password: '$2a$10$oJUgexcfvk9LaciW.GkE9uhwGZgzo7SAx5.NvSuEFoZ8OamRbWApC',
      createdAt: '2021-01-18T15:30:43.000Z',
      updatedAt: '2021-01-18T15:30:43.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 90,
      lastname: 'Amani',
      firstname: 'Myriam',
      email_client: 'myriameamani@gmail.com',
      roleId: 4,
      id_user: 337,
      photo: null,
      regieId: null,
      ids: 337,
      first: 'Myriam',
      last: 'Amani',
      email: 'myriameamani@gmail.com',
      phone: '77587385',
      password: '$2a$10$PGTjUCt44gsBIelBtMit7e.v2nvzZnULl4P4sPwuKRg/.BHOyLkLK',
      createdAt: '2021-01-05T18:04:10.000Z',
      updatedAt: '2021-01-05T18:04:10.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 72,
      lastname: 'admin',
      firstname: 'nene',
      email_client: 'admin003@gmail.com',
      roleId: 2,
      id_user: 318,
      photo: null,
      regieId: 14,
      ids: 318,
      first: 'nene',
      last: 'admin',
      email: 'admin003@gmail.com',
      phone: '59595950',
      password: '$2a$10$HELzMSG4pyiGrfBDCFDIveFTPPW1YxRwcBi0UbAJG4q3ZkJJ4g9TG',
      createdAt: '2020-10-26T12:03:39.000Z',
      updatedAt: '2020-10-26T12:03:39.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 76,
      lastname: 'Ousmimi',
      firstname: 'Oussou',
      email_client: 'erbewill@usa.com',
      roleId: 4,
      id_user: 322,
      photo: null,
      regieId: null,
      ids: 322,
      first: 'Oussou',
      last: 'Ousmimi',
      email: 'erbewill@usa.com',
      phone: '9090909',
      password: '$2a$10$Zsoz.AT4LDhxtmQuNV5W6.FdwJ/O9VkWTZzI.9gX4nqKXJ0T2Ztxu',
      createdAt: '2020-11-05T12:10:40.000Z',
      updatedAt: '2020-11-05T12:26:04.000Z',
      resetPasswordToken: '09c745303eb03b70e8b9ef80a561edb641026e91',
      resetPasswordExpires: 1604582523271,
    },
    {
      id: 99,
      lastname: 'ATLAN ',
      firstname: 'Patrick',
      email_client: 'patrick.atlan@gisti.ci',
      roleId: 4,
      id_user: 408,
      photo: null,
      regieId: null,
      ids: 408,
      first: 'Patrick',
      last: 'ATLAN ',
      email: 'patrick.atlan@gisti.ci',
      phone: '7373081',
      password: '$2a$10$luT2HsmiJ6lIs7eK46rY.OYwU4qMreEXfMQ1iEU65kyKxp9BmEbla',
      createdAt: '2021-02-09T15:55:26.000Z',
      updatedAt: '2021-02-09T15:55:26.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 80,
      lastname: 'Yao',
      firstname: 'Paul Aristide',
      email_client: 'yaopaul@email.com',
      roleId: 4,
      id_user: 326,
      photo: null,
      regieId: null,
      ids: 326,
      first: 'Paul Aristide',
      last: 'Yao',
      email: 'yaopaul@email.com',
      phone: '7000007',
      password: '$2a$10$aQ.NMcOgnKlF4ik0aswrVuqq8SzOucgFOlBMz8RjhyJGVlfW08aoK',
      createdAt: '2020-12-09T18:32:42.000Z',
      updatedAt: '2020-12-09T18:32:42.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 69,
      lastname: 'Degni',
      firstname: 'Philomene',
      email_client: 'philomenedegni@yahoo.fr',
      roleId: 4,
      id_user: 314,
      photo: null,
      regieId: null,
      ids: 314,
      first: 'Philomene',
      last: 'Degni',
      email: 'philomenedegni@yahoo.fr',
      phone: '56116196',
      password: '$2a$10$cEOI7mDMJEdXD82X/Zn/r.9acnohanMSgPk8J1gJjf5xmHWDQwie.',
      createdAt: '2020-10-02T15:46:05.000Z',
      updatedAt: '2020-10-02T15:46:05.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 106,
      lastname: 'Gouri ',
      firstname: 'Roger',
      email_client: 'gouriroger@gmail.com',
      roleId: 2,
      id_user: 465,
      photo: null,
      regieId: 63,
      ids: null,
      first: null,
      last: null,
      email: null,
      phone: null,
      password: null,
      createdAt: null,
      updatedAt: null,
      resetPasswordToken: null,
      resetPasswordExpires: null,
    },
    {
      id: 100,
      lastname: 'KOFFI ',
      firstname: 'RUTH',
      email_client: 'ruthkoffiexceptgroup@gmail.com',
      roleId: 4,
      id_user: 409,
      photo: null,
      regieId: null,
      ids: 409,
      first: 'RUTH',
      last: 'KOFFI ',
      email: 'ruthkoffiexceptgroup@gmail.com',
      phone: '49739604',
      password: '$2a$10$cM8nHr1DfffN0Y02ogaGK.AO9CDzjqFkrDD7Nuh27yYeTDXfQeBHy',
      createdAt: '2021-02-11T09:53:41.000Z',
      updatedAt: '2021-02-11T09:53:41.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 118,
      lastname: 'Koffi',
      firstname: 'Samuel',
      email_client: 'sam@gmail.com',
      roleId: 2,
      id_user: 501,
      photo: null,
      regieId: 67,
      ids: 501,
      first: 'Samuel',
      last: 'Koffi',
      email: 'sam@gmail.com',
      phone: '7097856',
      password: '$2b$10$FlBgKABxENszkIooyATPd.Jnf1x.Yg2GeCq72uw9FNEOJ0IoNZdWi',
      createdAt: '2021-09-17T15:12:55.000Z',
      updatedAt: '2021-09-17T15:12:55.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 70,
      lastname: 'Erbewill',
      firstname: 'Sarx',
      email_client: 'erbewill76@gmail.com',
      roleId: 4,
      id_user: 316,
      photo: null,
      regieId: null,
      ids: 316,
      first: 'Sarx',
      last: 'Erbewill',
      email: 'erbewill76@gmail.com',
      phone: '6013836',
      password: '$2a$10$9Rwhbw3igyYFpyAM3T9SJeB6o2G6yiUf.WSB4xVtTwG0QsT40JnpS',
      createdAt: '2020-10-15T10:21:20.000Z',
      updatedAt: '2020-10-15T10:24:40.000Z',
      resetPasswordToken: '6b52da2e2be85cb2396d6f3af4f71c2ec1b243d2',
      resetPasswordExpires: 1602761080365,
    },
    {
      id: 84,
      lastname: 'Adom',
      firstname: 'Serge',
      email_client: 'adomserge@gmail.com',
      roleId: 4,
      id_user: 330,
      photo: null,
      regieId: null,
      ids: 330,
      first: 'Serge',
      last: 'Adom',
      email: 'adomserge@gmail.com',
      phone: '88550092',
      password: '$2a$10$W7JXXnm3IXmvMN4zBo3kLOznsr3XJHmWHYXFI5SwoH3XY6KV5D06G',
      createdAt: '2020-12-17T20:33:55.000Z',
      updatedAt: '2020-12-17T20:33:55.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 98,
      lastname: 'Coulibaly',
      firstname: 'Seydou',
      email_client: 'seydou@lagencex.com',
      roleId: 4,
      id_user: 407,
      photo: null,
      regieId: null,
      ids: 407,
      first: 'Seydou',
      last: 'Coulibaly',
      email: 'seydou@lagencex.com',
      phone: '8129758',
      password: '$2a$10$YcaJLCar4KAWxInmJGPbNeRhDiQSqpoMGRp1Bna7P1gWq32dMmmEm',
      createdAt: '2021-02-04T16:25:36.000Z',
      updatedAt: '2021-02-04T16:25:36.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 120,
      lastname: 'Aziz',
      firstname: 'Somet',
      email_client: 'azziz@gmail.com',
      roleId: 2,
      id_user: 503,
      photo: null,
      regieId: 67,
      ids: 503,
      first: 'Somet',
      last: 'Aziz',
      email: 'azziz@gmail.com',
      phone: '07590404993',
      password: '$2b$10$9Ug.kk6sdT6CMrL8.u/JM.6fUhL4FDCDEsjGZAudwj0.Zm2fZeKR.',
      createdAt: '2021-09-17T16:37:10.000Z',
      updatedAt: '2021-09-17T16:37:10.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 92,
      lastname: 'matthieu',
      firstname: 'Stephane',
      email_client: 'stephane@makarond.com',
      roleId: 4,
      id_user: 339,
      photo: null,
      regieId: null,
      ids: 339,
      first: 'Stephane',
      last: 'matthieu',
      email: 'stephane@makarond.com',
      phone: '44444433',
      password: '$2a$10$dPh.aRSaICvV3hUtVXiz5O616GC310v8G7f8T/W8mlG5N.tQOzHgC',
      createdAt: '2021-01-08T06:47:52.000Z',
      updatedAt: '2021-01-08T06:47:52.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 67,
      lastname: 'Armelle',
      firstname: 'Tahi',
      email_client: 'gekci.dcm@gmail.com',
      roleId: 2,
      id_user: 312,
      photo: null,
      regieId: 43,
      ids: 312,
      first: 'Tahi',
      last: 'Armelle',
      email: 'gekci.dcm@gmail.com',
      phone: '49585711',
      password: '$2a$10$2yTVZ0SIIDY.6919nLrBgu/c/JvIsHfdJEsISvpNYK5wegK3lAMgS',
      createdAt: '2020-09-18T14:35:25.000Z',
      updatedAt: '2020-09-18T14:35:25.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 102,
      lastname: 'test',
      firstname: 'test',
      email_client: 'test@test.com',
      roleId: 4,
      id_user: 461,
      photo: null,
      regieId: null,
      ids: 461,
      first: 'test',
      last: 'test',
      email: 'test@test.com',
      phone: '22345789',
      password: '$2a$10$W46doNWitM9uEHkzph8jK.IDJA1xNK/1zYjMIFIOc9n4ZHUVEulKi',
      createdAt: '2021-03-15T15:05:21.000Z',
      updatedAt: '2021-03-15T15:05:21.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 66,
      lastname: 'Dogoh-Bibi',
      firstname: 'Thierry',
      email_client: 'pyramidemedia@gmail.com',
      roleId: 2,
      id_user: 311,
      photo: null,
      regieId: 59,
      ids: 311,
      first: 'Thierry',
      last: 'Dogoh-Bibi',
      email: 'pyramidemedia@gmail.com',
      phone: '07482323',
      password: '$2a$10$GYWc9kbbAx1sfMyQXGbrFOk8PXvKhvKOn4lWiIvyfR1vELCy/kBiW',
      createdAt: '2020-09-07T13:02:01.000Z',
      updatedAt: '2020-09-07T13:02:01.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 34,
      lastname: 'Tata',
      firstname: 'Titi',
      email_client: 'terranovavini18@gmail.com',
      roleId: 4,
      id_user: 143,
      photo: null,
      regieId: null,
      ids: 143,
      first: 'Titi',
      last: 'Tata',
      email: 'terranovavini18@gmail.com',
      phone: '901346382',
      password: '$2a$10$S/xJ/EbOS5KvjtnTGV0uC.BZ4ErxRRLb5iIFNZsFVSvXtRst1J9mm',
      createdAt: '2020-06-02T14:49:32.000Z',
      updatedAt: '2020-06-02T14:49:32.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 124,
      lastname: 'Tanella',
      firstname: 'VOZY',
      email_client: 'tanella.cible@gmail.com',
      roleId: 2,
      id_user: 513,
      photo: null,
      regieId: null,
      ids: 513,
      first: 'VOZY',
      last: 'Tanella',
      email: 'tanella.cible@gmail.com',
      phone: '0798496674',
      password: '$2b$10$eJMl5Sx86eQ2lf2LKnA5LuzqmQdHnQWk2/YcPQbDyZ6bbj/vSVPSy',
      createdAt: '2022-03-07T13:58:48.000Z',
      updatedAt: '2022-03-07T13:58:48.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 54,
      lastname: 'Adahi',
      firstname: 'Wilfried Oka',
      email_client: 'AdahiO@globalooh.net',
      roleId: 2,
      id_user: 299,
      photo: null,
      regieId: 56,
      ids: 299,
      first: 'Wilfried Oka',
      last: 'Adahi',
      email: 'AdahiO@globalooh.net',
      phone: '04003395',
      password: '$2a$10$s5p2r50g9jBMNHnT/kYmTeJkT.LTqLAOx/3Hsly01lAXt60HNo2IW',
      createdAt: '2020-07-23T16:08:54.000Z',
      updatedAt: '2020-07-23T16:08:54.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 87,
      lastname: 'Sasso',
      firstname: 'Yao Atto Rodolphe Evrard',
      email_client: 'rodosyao@gmail.com',
      roleId: 4,
      id_user: 333,
      photo: null,
      regieId: null,
      ids: 333,
      first: 'Yao Atto Rodolphe Evrard',
      last: 'Sasso',
      email: 'rodosyao@gmail.com',
      phone: '84200615',
      password: '$2a$10$RTDjP0tmEZVzBdiXsRHWGe82Ouu.OQz0bW56dsQfeCTRwlrGXCPnO',
      createdAt: '2020-12-21T11:42:21.000Z',
      updatedAt: '2020-12-21T11:42:21.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 113,
      lastname: 'Pérou',
      firstname: 'Zalanda',
      email_client: 'perou20@gmail.com',
      roleId: 2,
      id_user: 495,
      photo: null,
      regieId: 67,
      ids: 495,
      first: 'Zalanda',
      last: 'Pérou',
      email: 'perou20@gmail.com',
      phone: '78940404093',
      password: '$2b$10$WF9U4jNLB2CiEmrhUIjyLOgpvlSN73QUv5eh0vvs.xm/.ckl41hOK',
      createdAt: '2021-09-17T14:32:40.000Z',
      updatedAt: '2021-09-17T14:32:40.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
    {
      id: 112,
      lastname: 'Perou',
      firstname: 'Zalando kamagaté ',
      email_client: 'perou2@gmail.com',
      roleId: 2,
      id_user: 490,
      photo: null,
      regieId: null,
      ids: 490,
      first: 'Zalando kamagaté ',
      last: 'Perou',
      email: 'perou2@gmail.com',
      phone: '0704030201',
      password: '$2b$10$QngAUMmxXnsxotASaK0w/.QrJkWIELzytGcC.eeWzLwP9cVd7sS1K',
      createdAt: '2021-09-17T13:53:21.000Z',
      updatedAt: '2021-09-17T13:53:21.000Z',
      resetPasswordToken: '',
      resetPasswordExpires: 0,
    },
  ],
}
