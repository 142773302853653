import React, { Component } from "react";
import { connect } from "react-redux";
import GoogleMapReact from "google-map-react";
import {
  Row,
  Col,
  Form,
  Popover,
  OverlayTrigger,
  Carousel,
  Spinner,
} from "react-bootstrap";
import {
  Popover as Popovers,
  Whisper,
  Button,
  Modal,
  DateRangePicker,
} from "rsuite";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import GoogleMapReact, Marker from 'google-map-react'

// RestApi service import
import auth from "../../../restApiService/auth";
import { addToCart, removeItem } from "../../../store/actions/cartActions";
import { addToWish, removeWishItem } from "../../../store/actions/wishActions";

const { allowedMaxDays } = DateRangePicker;
// const currentUserInfo =
//   localStorage.getItem("userInfo") != undefined
//     ? JSON.parse(localStorage.getItem("userInfo"))
//     : [];

class single_espace extends Component {
  state = {
    userData: [],
    box: "",
    updateAction: false,
    handleLoading: false,
  };

  handleChange = (event) => {
    let userData = { ...this.state.userData };
    userData[event.target.name] = event.target.value;
    this.setState({ userData });
  };

  getUserInfo = () => {
    auth
      .userInfoById(this.props.currentUserInfo?.id)
      .then((response) => {
        this.setState({ userData: response.data[0] });
      })
      .catch((error) => {});
  };

  updateUserInfo = (event, cases) => {
    event.preventDefault();
    this.setState({ handleLoading: true });
    const promiseVar = toast.loading(
      "Mise à jour des information du client en cours..."
    );

    let userData = { ...this.state.userData };
    let update_item = userData;

    if (this.state.box === "civility") {
      delete update_item["firstname"];
      delete update_item["lastname"];
      delete update_item["email"];
      delete update_item["phone"];
      delete update_item["password"];
    }
    if (this.state.box === "name") {
      delete update_item["civility"];
      delete update_item["email"];
      delete update_item["phone"];
      delete update_item["password"];
    }
    if (this.state.box === "email") {
      delete update_item["civility"];
      delete update_item["firstname"];
      delete update_item["lastname"];
      delete update_item["phone"];
      delete update_item["password"];
    }
    if (this.state.box === "phone") {
      delete update_item["civility"];
      delete update_item["firstname"];
      delete update_item["lastname"];
      delete update_item["email"];
      delete update_item["password"];
    }
    if (this.state.box === "password") {
      delete update_item["civility"];
      delete update_item["firstname"];
      delete update_item["lastname"];
      delete update_item["email"];
      delete update_item["phone"];
    }

    if (this.state.box === "password") {
      auth
        .updateUserPassword(userData.id, update_item)
        .then((response) => {
          toast.update(promiseVar, {
            render: "Opération effectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setTimeout(() => {
            this.setState({ handleLoading: false });
          }, 1500);
        })
        .catch((error) => {
          toast.update(promiseVar, {
            render: "Mot de passe incorrecte",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          this.setState({ handleLoading: false });
        });
    } else {
      auth
        .updateUserAccount(userData.id, update_item)
        .then((response) => {
          localStorage.setItem("userInfo", JSON.stringify(response.data[0]));
          toast.update(promiseVar, {
            render: "Opération effectuée avec succès",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setTimeout(() => {
            this.setState({ handleLoading: false });
            window.location.reload();
          }, 1500);
        })
        .catch((error) => {
          toast.update(promiseVar, {
            render: "Une erreur est survenue",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          this.setState({ handleLoading: false });
        });
    }
  };

  handleAction = (box) => {
    this.setState({ box, updateAction: !this.state.updateAction });
  };

  componentDidMount() {
    this.getUserInfo();
  }

  render() {
    const { userData, handleLoading, box, updateAction } = this.state;

    return (
      <>
        <section id="tabs">
          <div className="container">
            <div className="row top-h">
              <div className="col-md-12">
                <h2 className="page-title">Informations personnelles</h2>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item" aria-current="page">
                      Home
                    </li>
                    <li class="breadcrumb-item" aria-current="page">
                      Compte
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Informations personnelles
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="col-md-12">
                <div className="info-item-box mt-5">
                  <div className="info-box">
                    <div className="info-box-content">
                      <label>Civilité</label>

                      {box === "civility" && updateAction ? null : (
                        <span>
                          {userData.civility
                            ? userData.civility
                            : "Non spécifié"}
                        </span>
                      )}
                    </div>
                    <div className="info-box-action">
                      {box !== "civility" && !updateAction && (
                        <button onClick={() => this.handleAction("civility")}>
                          Modifier
                        </button>
                      )}
                      {box === "civility" && updateAction && (
                        <button onClick={() => this.handleAction("")}>
                          Annuler
                        </button>
                      )}
                    </div>
                  </div>
                  {box === "civility" && updateAction && (
                    <div className="info-edit-box mt-4">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group className="form-group">
                            <Form.Select
                              name="civility"
                              value={userData.civility}
                              onChange={this.handleChange}
                            >
                              <option>Sélectionnez une option</option>
                              <option value="M.">M.</option>
                              <option value="Mme">Mme</option>
                              <option value="Mlle">Mlle</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          {handleLoading ? (
                            <Button variant="bg-yellow w-50" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Enregistrement...
                            </Button>
                          ) : (
                            <Button
                              onClick={(e) =>
                                this.updateUserInfo(e, "civility")
                              }
                              type="button"
                              variant="bg-yellow w-50"
                              className="bg-yellow"
                            >
                              Enregistrer
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="info-item-box">
                  <div className="info-box">
                    <div className="info-box-content">
                      <label>Nom complet</label>
                      {box === "uf-ul" && updateAction ? null : (
                        <span>
                          {userData.firstname + " " + userData.lastname}
                        </span>
                      )}
                    </div>
                    <div className="info-box-action">
                      {box !== "uf-ul" && !updateAction && (
                        <button onClick={() => this.handleAction("uf-ul")}>
                          Modifier
                        </button>
                      )}
                      {box === "uf-ul" && updateAction && (
                        <button onClick={() => this.handleAction("")}>
                          Annuler
                        </button>
                      )}
                    </div>
                  </div>
                  {box === "uf-ul" && updateAction && (
                    <div className="info-edit-box mt-4">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="lastname" className="">
                              Nom
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className=""
                              id="lastname"
                              name="lastname"
                              value={userData.lastname}
                              onChange={this.handleChange}
                              placeholder=" "
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="firstname" className="">
                              Prénom
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className=""
                              id="firstname"
                              name="firstname"
                              value={userData.firstname}
                              onChange={this.handleChange}
                              placeholder=" "
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          {handleLoading ? (
                            <Button variant="bg-yellow w-50" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Enregistrement...
                            </Button>
                          ) : (
                            <Button
                              onClick={(e) => this.updateUserInfo(e, "name")}
                              type="button"
                              variant="bg-yellow w-50"
                              className="bg-yellow"
                            >
                              Enregistrer
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="info-item-box">
                  <div className="info-box">
                    <div className="info-box-content">
                      <label>Adresse e-mail</label>

                      {box === "email" && updateAction ? null : (
                        <span>{userData.email}</span>
                      )}
                    </div>
                    <div className="info-box-action">
                      {box !== "email" && !updateAction && (
                        <button onClick={() => this.handleAction("email")}>
                          Modifier
                        </button>
                      )}
                      {box === "email" && updateAction && (
                        <button onClick={() => this.handleAction("")}>
                          Annuler
                        </button>
                      )}
                    </div>
                  </div>
                  {box === "email" && updateAction && (
                    <div className="info-edit-box mt-4">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group className="form-group">
                            <Form.Control
                              type="email"
                              className=""
                              id="email"
                              name="email"
                              value={userData.email}
                              onChange={this.handleChange}
                              placeholder=" "
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          {handleLoading ? (
                            <Button variant="bg-yellow w-50" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Enregistrement...
                            </Button>
                          ) : (
                            <Button
                              onClick={(e) => this.updateUserInfo(e, "email")}
                              type="button"
                              variant="bg-yellow w-50"
                              className="bg-yellow"
                            >
                              Enregistrer
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="info-item-box">
                  <div className="info-box">
                    <div className="info-box-content">
                      <label>Numéro de téléphone</label>

                      {box === "phone" && updateAction ? null : (
                        <span>
                          {userData.phone ? userData.phone : "Non spécifié"}
                        </span>
                      )}
                    </div>
                    <div className="info-box-action">
                      {box !== "phone" && !updateAction && (
                        <button onClick={() => this.handleAction("phone")}>
                          Modifier
                        </button>
                      )}
                      {box === "phone" && updateAction && (
                        <button onClick={() => this.handleAction("")}>
                          Annuler
                        </button>
                      )}
                    </div>
                  </div>
                  {box === "phone" && updateAction && (
                    <div className="info-edit-box mt-4">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group className="form-group">
                            <Form.Control
                              type="text"
                              className=""
                              id="phone"
                              name="phone"
                              value={userData.phone}
                              onChange={this.handleChange}
                              placeholder=" "
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          {handleLoading ? (
                            <Button variant="bg-yellow w-50" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Enregistrement...
                            </Button>
                          ) : (
                            <Button
                              onClick={(e) => this.updateUserInfo(e, "phone")}
                              type="button"
                              variant="bg-yellow w-50"
                              className="bg-yellow"
                            >
                              Enregistrer
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="info-item-box">
                  <div className="info-box">
                    <div className="info-box-content">
                      <label>Mot de passe</label>

                      {box === "password" && updateAction ? null : (
                        <span>Masqué</span>
                      )}
                    </div>
                    <div className="info-box-action">
                      {box !== "password" && !updateAction && (
                        <button onClick={() => this.handleAction("password")}>
                          Modifier
                        </button>
                      )}
                      {box === "password" && updateAction && (
                        <button onClick={() => this.handleAction("")}>
                          Annuler
                        </button>
                      )}
                    </div>
                  </div>
                  {box === "password" && updateAction && (
                    <div className="info-edit-box mt-4">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="old_password" className="">
                              Ancien mot de passe
                            </Form.Label>
                            <Form.Control
                              type="password"
                              className=""
                              id="old_password"
                              name="old_password"
                              value={userData.old_password}
                              onChange={this.handleChange}
                              placeholder=" "
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="new_password" className="">
                              Nouveau mot de passe
                            </Form.Label>
                            <Form.Control
                              type="password"
                              className=""
                              id="new_password"
                              name="new_password"
                              value={userData.new_password}
                              onChange={this.handleChange}
                              placeholder=" "
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          {handleLoading ? (
                            <Button variant="bg-yellow w-50" disabled>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Enregistrement...
                            </Button>
                          ) : (
                            <Button
                              onClick={(e) =>
                                this.updateUserInfo(e, "password")
                              }
                              type="button"
                              variant="bg-yellow w-50"
                              className="bg-yellow"
                            >
                              Enregistrer
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let addedItemsTmp = state.cartReducer;
  let addedWishItemsTmp = state.wishReducer;
  let addedUserItemsTmp = state.userInfoReducer;
  return {
    currentUserInfo: addedUserItemsTmp.addedUserInfos,
    items: addedItemsTmp.addedItems,
    wish_items: addedWishItemsTmp.addedWishItems,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id) => {
      dispatch(addToCart(id));
    },
    removeItem: (id) => {
      dispatch(removeItem(id));
    },
    addToWish: (id) => {
      dispatch(addToWish(id));
    },
    removeWishItem: (id) => {
      dispatch(removeWishItem(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(single_espace);
